<div class="input-group" [formGroup]="formGroup">
  <div class="input-group-prepend" *ngIf="getCurrencyLabelPosition() === 'prepend'">
    <span class="input-group-text">{{ currency | currencyFormat: "symbol" }}</span>
  </div>
  <input type="number" class="form-control" [formControlName]="inputName"
         id="amountExcludingTax"
         [placeholder]="placeholder|translate">
  <div class="input-group-append" *ngIf="getCurrencyLabelPosition() === 'append'">
    <span class="input-group-text">{{ currency | currencyFormat: "symbol" }}</span>
  </div>
</div>
