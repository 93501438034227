<div class="swal2-icon swal2-success swal2-animate-success-icon">
  <div class="swal2-success-circular-line-left"
       style="background-color: rgb(255, 255, 255);"></div>
  <span class="swal2-success-line-tip"></span>
  <span class="swal2-success-line-long"></span>
  <div class="swal2-success-ring"></div>
  <div class="swal2-success-fix"
       style="background-color: rgb(255, 255, 255);"></div>
  <div class="swal2-success-circular-line-right"
       style="background-color: rgb(255, 255, 255);"></div>
</div>

<p class="success-message">
  <strong>{{messageHeader}}</strong> {{messageBody}}
  <strong>{{counter$ | async}}</strong> {{messageEnd}}
</p>
