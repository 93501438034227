import {Component, Input, OnInit} from '@angular/core';
import {SupplierModel} from '../../../../../../shared/models/mission/supplier.model';
import {query, transition, trigger, useAnimation} from '@angular/animations';
import {
  fadeInAnimation,
  scaleIn50StaggerAnimation,
  scaleInAnimation
} from '../../../../../../shared/animation/common.animation';

@Component({
  selector: 'supplier-view-detail',
  templateUrl: './supplier-view-detail.component.html',
  styleUrls: ['./supplier-view-detail.component.scss'],
  animations: [
    trigger('scaleInStagger', [
      transition('* => *', [
        query('.col-md-6:enter', [
          useAnimation(scaleIn50StaggerAnimation)
        ], {optional: true}),
      ]),
    ]),
    trigger('scaleIn', [
      transition(':enter', [
        useAnimation(scaleInAnimation, {
          params: {from: '0.95'}
        })
      ]),
    ]),
    trigger('fadeIn', [
      transition(':enter', [
        useAnimation(fadeInAnimation)
      ])
    ])
  ]
})
export class SupplierViewDetailComponent implements OnInit {

  @Input() supplier: SupplierModel;

  constructor() {
  }

  ngOnInit(): void {
  }

}
