import {Component, OnDestroy} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {BreadcrumbService} from '../../services/breadcrumb.service';
import {BreadCrumb} from './model/breadcrumb.model';
import {faHouse} from '@fortawesome/pro-duotone-svg-icons';

@Component({
  selector: 'breadcrumbs',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnDestroy {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbService
  ) {
    this.subscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event) => {
      this.breadcrumbs = this.breadcrumbService.buildBreadCrumb(this.activatedRoute.root);
    });
  }
  breadcrumbs: BreadCrumb[] = [];
  subscription: Subscription;

  protected readonly faHouse = faHouse;

  transformTranslationPath(urlName: string) {
    return urlName.toLowerCase().replace(/ /g, '-').replace(/\//g, '-');
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
