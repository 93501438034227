<div class="app flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="clearfix">
          <h1 class="float-left display-3 mr-4">500</h1>
          <h4 class="pt-3">{{"exception.500.title"|translate}}</h4>
          <p class="text-muted">{{"exception.500.text"|translate}}</p>
        </div>
        <div class="input-prepend input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <fa-icon icon="fa-search"></fa-icon>
              <i class="fa fa-search"></i>
            </span>
          </div>
          <input id="prependedInput" class="form-control" size="16" type="text"
                 placeholder='{{"exception.500.search_placeholder"|translate}}'>
          <span class="input-group-append">
            <button class="btn btn-info"
                    type="button">{{"exception.500.search_button"|translate}}</button>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
