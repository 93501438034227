import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimezoneDateFormatService {
  formatDateTimeZone(date: string | Date) {
    if (!date) {
      return null;
    }
    const initialDate = new Date(date);
    const formatter = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZone: 'Europe/Paris',
    });
    return new Date(formatter.format(initialDate));
  }
}
