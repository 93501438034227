import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormFeedbackComponent} from './form-feedback/form-feedback.component';
import {ToastrModule} from 'ngx-toastr';
import {AlertComponent} from './components/alert/alert.component';
import {AlertModule} from 'ngx-bootstrap/alert';
import {StatusPipe} from './pipes/status.pipe';
import {ListContentComponent} from './components/list-content/list-content.component';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {ModalModule} from 'ngx-bootstrap/modal';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {CounterComponent} from './components/counter/counter.component';
import {PageMenuComponent} from './components/page-menu/page-menu.component';
import {RouterModule} from '@angular/router';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input-gg';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {
  VerticalPageMenuComponent
} from './components/vertical-page-menu/vertical-page-menu.component';
import {FileDropComponent} from './components/file-drop/file-drop.component';
import {FileDrop2Component} from './components/file-drop2/file-drop.component';
import {NgxFileDropModule} from 'ngx-file-drop';
import {PdfPreviewComponent} from './components/pdf-preview/pdf-preview.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {CountryInputComponent} from './components/country-input/country-input.component';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {NoDataComponent} from './components/no-data/no-data.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {NoContentComponent} from './components/no-content/no-content.component';
import {EllipsisPipe} from './pipes/ellipsis.pipe';
import {CurrencyFormatPipe} from './pipes/currency-format.pipe';
import {AddressPipe} from './pipes/address.pipe';
import {BooleanFormatPipe} from './pipes/boolean-format.pipe';
import {ModalConfirmComponent} from './components/modal-confirm/modal-confirm.component';
import {DefaultPipe} from './pipes/default.pipe';
import {
  ActivityReportModalComponent
} from './components/activity-report-modal/activity-report-modal.component';
import {MatDialogModule} from '@angular/material/dialog';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {PlusMinusInputComponent} from './components/plus-minus-input/plus-minus-input.component';
import {InfoLineComponent} from './components/info-line/info-line.component';
import {UploadModalComponent} from './components/upload-modal/upload-modal.component';
import {ExtendedPdfPreviewComponent} from './components/extended-pdf-preview/extended-pdf-preview.component';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {CguModalComponent} from './components/cgu-modal/cgu-modal.component';

import {ModalIdleWarningComponent} from './components/modal-idle-warning/modal-idle-warning.component';
import {OnlyNumbersDirective} from './only-number/only-numbers.directive';
import {LocalizedDatePipe} from './pipes/localized-date/localized-date.pipe';
import {ActivityAreaDropdownComponent} from './components/activity-area-dropdown/activity-area-dropdown.component';
import {CurrencyInputComponent} from './components/currency-input/currency-input.component';
import {BillingAddressFormComponent} from './components/billing-address-form/billing-address-form.component';
import {environment} from '../../assets/environments/environment';
import {IsupplierSharedModule} from '@i-supplier/angular-shared-module';

@NgModule({
  declarations: [
    FormFeedbackComponent,
    AlertComponent,
    StatusPipe,
    EllipsisPipe,
    AddressPipe,
    BooleanFormatPipe,
    DefaultPipe,
    CurrencyFormatPipe,
    ListContentComponent,
    SpinnerComponent,
    PageMenuComponent,
    PdfPreviewComponent,
    CounterComponent,
    CountryInputComponent,
    VerticalPageMenuComponent,
    FileDropComponent,
    FileDrop2Component,
    NoDataComponent,
    NoContentComponent,
    ModalConfirmComponent,
    ActivityReportModalComponent,
    UploadModalComponent,
    PlusMinusInputComponent,
    CurrencyInputComponent,
    InfoLineComponent,
    BillingAddressFormComponent,
    ExtendedPdfPreviewComponent,
    ModalIdleWarningComponent,
    OnlyNumbersDirective,
    LocalizedDatePipe,
    CguModalComponent,
    ActivityAreaDropdownComponent
  ],
  exports: [
    FormFeedbackComponent,
    ToastrModule,
    AlertComponent,
    StatusPipe,
    DefaultPipe,
    EllipsisPipe,
    AddressPipe,
    BooleanFormatPipe,
    CurrencyFormatPipe,
    PageMenuComponent,
    ListContentComponent,
    BillingAddressFormComponent,
    SpinnerComponent,
    PdfPreviewComponent,
    NgxIntlTelInputModule,
    TooltipModule,
    ModalModule,
    CounterComponent,
    CountryInputComponent,
    CurrencyInputComponent,
    ModalModule, CounterComponent,
    VerticalPageMenuComponent,
    FileDropComponent,
    NoDataComponent,
    NoContentComponent,
    ModalConfirmComponent,
    InfoLineComponent,
    FileDrop2Component,
    ExtendedPdfPreviewComponent,
    OnlyNumbersDirective,
    FontAwesomeModule,
    ModalIdleWarningComponent,
    LocalizedDatePipe,
    ActivityAreaDropdownComponent,
    IsupplierSharedModule
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    AlertModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    TranslateModule.forChild(),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      timeOut: 5000,
      preventDuplicates: false,
      closeButton: true,
      progressBar: true,
      tapToDismiss: false,
      extendedTimeOut: 5000
    }),
    RouterModule,
    FormsModule,
    NgxFileDropModule,
    PdfViewerModule,
    BsDatepickerModule.forRoot(),
    NgxSpinnerModule,
    ReactiveFormsModule,
    MatDialogModule,
    TimepickerModule,
    NgxExtendedPdfViewerModule,
    FontAwesomeModule,
    NgxIntlTelInputModule,
    IsupplierSharedModule.forRoot({
      api_root: environment.api_root
    })
  ]
})
export class SharedModule {
}
