export class CallForTenderListAdvancedSearchModel {
  reference: string;
  title: string;
  buyer: string;
  activityArea: string;

  constructor(object?: any) {
    if (object) {
      this.reference = object.reference;
      this.title = object.title;
      this.buyer = object.buyer;
      this.activityArea = object.activityArea;
    }
  }
}
