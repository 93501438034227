export class ContractAdvancedSearch {
  contractNumber: string;
  expiryDate: string;
  buyerName: string;
  status: string;

  constructor(object?: any) {
    if (object) {
      this.contractNumber = object.contractNumber;
      this.expiryDate = object.expiryDate;
      this.buyerName = object.buyerName;
      this.status = object.status;
    }
  }
}
