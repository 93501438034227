export class ClientModel {
  requestNumber: string;
  poNumber: string;
  tagId: string;

  constructor(object?: any) {
    if (object) {
      this.requestNumber = object.requestNumber;
      this.poNumber = object.poNumber;
      this.tagId = object.tagId;
    }
  }
}
