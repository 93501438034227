import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TranslatorService} from '../../../core/services/translator.service';
import {
  ActivityAreaModel,
  ActivityAreaPageModel,
  LinkActivityAreaCompanyModel
} from '../activity-areas/activity-area.model';
import { environment } from '../../../../assets/environments/environment';
import {commonProperties} from '../../../../assets/environments/environment.common';


@Injectable({
  providedIn: 'root'
})
export class ActivityAreasService {

  constructor(private httpClient: HttpClient, private translatorService: TranslatorService) { }

  searchActivityAreas(label: string, parentActivityArea: string, pageNumber: string, pageSize: string, sortField: string, sortOrder: string): Observable<ActivityAreaPageModel> {
    let params = new HttpParams();
    if (label) {params = params.set('label', label); }
    if (parentActivityArea) {params = params.set('parentActivityArea', parentActivityArea); }
    if (pageNumber) {params = params.set('pageNumber', pageNumber); }
    if (pageSize) {params = params.set('pageSize', pageSize); }
    if (sortField) {params = params.set('sortField', sortField); }
    if (sortOrder) {params = params.set('sortOrder', sortOrder); }

    const options = {
      headers: new HttpHeaders().set('Accept-Language', this.translatorService.getLanguage()),
      params
    };
    return this.httpClient.get<ActivityAreaPageModel>(environment.api_root + commonProperties.activityAreasBasePath, options);
  }

  linkActivityAreaToCompany(params: LinkActivityAreaCompanyModel, companyId: string): Observable<any> {
    const options = {
      headers: new HttpHeaders().set('Accept-Language', this.translatorService.getLanguage())
    };
    return this.httpClient.put<LinkActivityAreaCompanyModel>(
      environment.api_root + commonProperties.activityAreasByCompanyId.replace(':companyId', companyId), params, options
    );
  }

  getActivityAreasByCompany(companyId: string): Observable<ActivityAreaModel[]> {
    const options = {
      headers: new HttpHeaders().set('Accept-Language', this.translatorService.getLanguage()),
    };
    return this.httpClient.get<any>(environment.api_root + commonProperties.activityAreasByCompanyId.replace(':companyId', companyId), options);
  }

  findActivityAreasByCallForTender(callForTenderId: string): Observable<any> {
    const options = {
      headers: new HttpHeaders().set('Accept-Language', this.translatorService.getLanguage()),
    };
    return this.httpClient.get<any>(`${environment.api_root}${commonProperties.activityAreasByCFTyId.replace(':cftID', callForTenderId)}` , options);
  }

  mapActivityAreaForNGModel(jsonArray: any[]): ActivityAreaModel[] {
    return jsonArray.map(item => {
      const mappedItem: ActivityAreaModel = {
        id: item.activityAreaId,
        label: item.label,
      };
      if (item.children && item.children.length > 0) {
        mappedItem.children = this.mapActivityAreaForNGModel(item.children);
      }
      return mappedItem;
    });
  }

  leftSetDifference(arr1: ActivityAreaModel[], arr2: ActivityAreaModel[]) {
    return arr1.filter(x => !arr2.some(y => y.id === x.id));
  }
}
