import {Component, OnInit} from '@angular/core';
import {faTriangleExclamation} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'modal-idle-warning',
  templateUrl: './modal-idle-warning.component.html',
  styleUrls: ['./modal-idle-warning.component.scss']
})
export class ModalIdleWarningComponent implements OnInit {
  protected readonly faTriangleExclamation = faTriangleExclamation;
  progress: number = 0;
  durationInSeconds: number = 120; // 2 minute duration
  constructor() {
  }

  ngOnInit(): void {
    this.startProgressBar();
  }

  startProgressBar() {
    const interval = this.durationInSeconds * 1000 / 100; // Update the progress every 1% of the duration

    const progressInterval = setInterval(() => {
      if (this.progress >= 100) {
        clearInterval(progressInterval);
      } else {
        this.progress++;
      }
    }, interval);
  }
}
