<div class="row mb-5">
  <div class="col-10 offset-1">
    <div class="request-history-container" *ngIf="missionHistoryList" @slideIn>
      <ng-container *ngFor="let status of missionHistoryList">
        <div class="card box-shadow-hover slide-in-animation border-left-blue">
          <div class="card-body">
            <span class="font-weight-bold">{{'Mission_view.status.' + status.status | translate | statusReadable | titlecase}}</span>
            <span class="text-dark-gray ml-2">{{status.date | date: 'd MMMM y, H:mm'}}</span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>


