import {FormControl} from '@angular/forms';

export function maximumSizeFile(size: number, unit: string) {
  return (control: FormControl) => {
    const file = control.value;
    let coefficient = 0;
    if (unit.toLowerCase() === 'o') {
      coefficient = 1;
    } else if (unit.toLowerCase() === 'ko') {
      coefficient = 1000;
    } else if (unit.toLowerCase() === 'mo') {
      coefficient = 1000000;
    }

    if (file) {
      if (file.size > size * coefficient) {
        return {sizeFileExceed: true};
      }
    }

    return null;
  };
}
