import {ActivityTypeEnum} from "./activity-type.enum";

export class ActivityModel {
  id: number;
  employeeName: string;
  projectName: string;
  quantity: number;
  buyerName: string;
  purchaseOrderNumber: string;
  accountingMonth: string;
  type: ActivityTypeEnum;
  duePaymentType: DuePaymentTypeEnum;
  description: string;
  onCallIds: number[];
  feeIds: number[];
}

export enum DuePaymentTypeEnum {
  DATE = 'DATE',
  EVENT = 'EVENT'
}
