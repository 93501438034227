<div class="row mb-4 mt-1">
  <div class="col-3" *ngIf="request.supplierStep.requestId && request.clientStep.billing.contractMode === 'T_M'" @slideIn>
    <supplier-view-detail
      class="slide-in-animation"
      [supplier]="request.supplierStep.supplier"
    ></supplier-view-detail>
  </div>

  <div class="{{request.clientStep.billing.contractMode === 'T_M' ? 'col-5' : 'col-12'}}"
  >
    <billing-view-detail
      class="slide-in-animation"
      [billing]="request.clientStep.billing"
    ></billing-view-detail>
  </div>

  <ng-container *ngIf="this.request.supplierStep.unlinkedProviders">
    <div class="col-4" *ngIf="request.clientStep.billing.contractMode === 'T_M'">
      <div class="box-red-border box-shadow-hover">
        <div class="row">
          <div class="col-3">
            <div class="notif-number">
              {{this.request.supplierStep.unlinkedProviders}}
            </div>
          </div>

          <div class="col-9 notif-container">
            <div
              class="notif-provider">{{'Mission_view.provider.label_attach_provider'|translate}}</div>
            <div class="notif-provider-guide">
              {{'Mission_view.provider.label_attach_provider_details'|translate}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

</div>

<div class="row">
  <div class="col-md-12">
    <div class="mx-4 my-4 d-flex">
      <div class="missions-header-border mr-2"></div>
      <div class="missions-header">
        {{"Mission_view.project.project_title"| translate: {multiple: request.supplierStep.projects.length > 1 ? "s" : ""} }}
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="request.clientStep.billing.contractMode !== 'T_M'">
  <div class="row">
    <ng-container *ngIf="request.supplierStep.projects && buyerId">
      <div
        class="col-3"
        *ngFor="let project of request.supplierStep.projects"
        @slideIn
      >
        <project-view-detail
          class="slide-in-animation"
          *ngIf="request.supplierStep.projects && buyerId"
          [project]="project"
          [buyerId]="buyerId"
        ></project-view-detail>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="request.clientStep.billing.contractMode === 'T_M'">
  <ng-container *ngFor="let project of request.supplierStep.projects">
    <div class="row mb-3">
      <div
        class="col-3"
        *ngIf="request.supplierStep.projects && buyerId"
        @slideIn
      >
        <project-view-detail
          class="slide-in-animation"
          *ngIf="request.supplierStep.projects && buyerId"
          [project]="project"
          [buyerId]="buyerId"
        ></project-view-detail>
      </div>

      <div class="col-9" @slideIn>
        <provider-view-detail
          class="slide-in-animation"
          (newModifyEvent)="ngOnInit()"
          *ngIf="request.clientStep.billing.contractMode === 'T_M'"
          [billingConfig]="request.clientStep.billing"
          [requestId]="request.requestId"
          [providers]="this.getProviders(project.id, this.request.supplierStep.providers)"
          [supplierId]="request.supplierStep.supplier.supplierId"
        ></provider-view-detail>
      </div>
    </div>
  </ng-container>
</ng-container>

<div
  class="row mt-4 pb-5"
  *ngIf="request.clientStep.billing.contractMode !== 'T_M'"
>
  <div class="col-3" *ngIf="request.supplierStep.requestId" @slideIn>
    <supplier-view-detail
      class="slide-in-animation"
      [supplier]="request.supplierStep.supplier"
    ></supplier-view-detail>

    <div class="pt-3">
      <activities-view-detail
        class="slide-in-animation"
        [billings]="request.clientStep.billing"
      ></activities-view-detail>
    </div>
  </div>

  <div class="col-9">
    <total-billings-view-detail
      class="slide-in-animation"
      [billings]="request.clientStep.billing"
    ></total-billings-view-detail>
  </div>
</div>
