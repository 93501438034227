import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActionEnum} from '../../../views/activity-report/models/activity-report.model';

@Component({
  selector: 'activity-report-modal',
  templateUrl: './activity-report-modal.component.html',
  styleUrls: ['./activity-report-modal.component.scss']
})
export class ActivityReportModalComponent {
  ActionEnum = ActionEnum;

  value: number;

  event: boolean;
  type: string = '';
  startDate: string;
  endDate: string;
  myTime;

  form: FormGroup;

  isMeridian = false;
  showSpinners = true;
  maxTime: Date = new Date();
  hoursPlaceholder = 'HH';

  disableForm: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<ActivityReportModalComponent>,
    private fb: FormBuilder) {
    if (data) {
      this.event = data.event;
      this.type = data.type;
      this.startDate = data.startDate;
      this.endDate = data.endDate;
      if (this.type === 'HOURS') {
        this.setInitHours();
      } else {
        if (this.event) {
          this.myTime = Number(data.myTime);
        } else {
          this.myTime = 0;
        }
      }
    }

    this.form = fb.group({
      date: [null, Validators.required],
      isAllDay: [false]
    });
  }

  setInitHours() {
    this.maxTime.setHours(this.data.maxHours);
    this.maxTime.setMinutes(1);
    this.myTime = new Date();
    this.myTime.setHours(this.data.myTime.hours());
    this.myTime.setMinutes(this.data.myTime.minutes());
  }

  valueEventHandler($event: any) {
    this.value = $event;
  }

  submit(action: ActionEnum) {
    if (this.type === 'HOURS') {
      this.dialogRef.close({action: action, value: this.form.value.date});
    } else {
      this.dialogRef.close({action: action, value: this.value});
    }
  }

  delete() {
    this.dialogRef.close({action: ActionEnum.DELETE});
  }

  cancel() {
    this.dialogRef.close({action: ActionEnum.CLOSE});
  }

  eventCheck($event: Event) {
    if (this.form.get('isAllDay').value) {
      if (this.type === 'DAYS') {
        this.myTime = 1;
      } else {
        const time = new Date();
        time.setHours(this.data.maxHours);
        time.setMinutes(0);
        this.myTime = time;
      }
      this.disableForm = true;
    } else {
      if (this.type === 'DAYS') {
        this.myTime = 0;
      } else {
        this.setInitHours();
      }
      this.disableForm = false;
    }
  }

}
