import {Component, OnInit} from '@angular/core';
import {animate, animation, query, stagger, style, transition, trigger, useAnimation} from '@angular/animations';
import {fadeInAnimation, slideIn50StaggerAnimation, slideInAnimation} from '../../../shared/animation/common.animation';
import {ListContentComponent} from '../../../shared/components/list-content/list-content.component';
import {ActivatedRoute, Router} from '@angular/router';
import {InvoiceService} from '../services/invoice.service';
import {ListContentModel} from '../../../shared/models/list-content.model';
import {NGXLogger} from 'ngx-logger';
import {ActivityReportService} from '../../activity-report/services/activity-report.service';
import {MenuItemModel} from '../../../shared/models/menu-item.model';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 't-m-invoice-list',
  templateUrl: './t-m-invoice-list.component.html',
  styleUrls: ['./t-m-invoice-list.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        useAnimation(fadeInAnimation)
      ])
    ]),
    trigger('slideIn', [
      transition(':enter', [
        useAnimation(slideInAnimation)
      ])
    ]),
    trigger('slideInStagger', [
      transition(':enter', [
        query('.slide-in-animation', [
          useAnimation(slideIn50StaggerAnimation)
        ], {optional: true})
      ])
    ]),
    trigger('listStagger', [
      transition('* <=> *', [
        query(
          ':enter',
          useAnimation(animation([
            style({opacity: 0, transform: 'translateY(20px)'}),
            stagger(
              '60ms',
              animate(
                '.2s ease-out',
                style({opacity: 1, transform: 'translateY(0px)'})
              )
            )
          ], null)),
          {optional: true}
        )
      ])
    ])
  ]
})
export class TMInvoiceListComponent extends ListContentComponent implements OnInit {

  listParamValidator = {
    page: RegExp('^[1-9][0-9]*$'),
    size: ['5', '10', '20'],
    sort: RegExp('^(firstName|email|contact\.phones\.workPhoneNumber|accountingMonth)\,(asc|desc)$'),
    search: RegExp('.{3,}'),
  };
  menu: MenuItemModel[] = [];

  constructor(public route: ActivatedRoute,
              public router: Router,
              private invoiceService: InvoiceService,
              private activityReportService: ActivityReportService,
              private translateService: TranslateService,
              private toastrService: ToastrService,
              private logger: NGXLogger) {
    super(router, route);
    this.constructMenu();
  }

  ngOnInit(): void {
    this.sort = 'accountingMonth';
    this.sortDirection = 'asc';
    super.ngOnInit();
    this.subscribeToQueryParam();
  }

  retrieveListContent(params: any): void {
    // this.logger.info('retrieve activity sheets for which we want to generate invoices :', params);
    const page = params.page ? params.page - 1 : 0;
    const size = params.size ? params.size : 5;
    const sort = params.sort ? params.sort : 'accountingMonth,asc';
    const search = params.search ? params.search : null;
    this.subscriptions.push(this.activityReportService.getActivitySheetsWaitingForInvoiceGeneration(page, size, sort, search).subscribe(
      (res: ListContentModel) => {
        // this.logger.info('result is:', res);
        this.listContent = res.content;
        this.totalElements = res.totalElements;
        this.numberOfElements = res.numberOfElements;
        this.isListEmpty = !this.isSearchActive && res.empty;
        if (res.totalPages !== 0 && params.page > res.totalPages) {
          this.updateQueryParam({page: res.totalPages});
        }
        this.firstCallDone = true;
        // this.logger.debug('Employee list retrieved : ', res);
      },
      (error: any) => {
        this.logger.error(error.url, '- STATUS :', error);
        this.toastrService.error(this.translateService.instant('global.ts.error'));
      }
    ));
  }

  private constructMenu():
    void {
    this
      .menu.push(new MenuItemModel(this.translateService.instant('Mission_view.billing.activity_sheets'), 'activity-sheets', ''));
    this
      .menu.push(new MenuItemModel(this.translateService.instant('Mission_view.billing.due_date'), 'due-payments', ''));
    this
      .menu.push(new MenuItemModel(this.translateService.instant('Mission_view.billing.on_call'), 'on-calls', ''));
    this
      .menu.push(new MenuItemModel(this.translateService.instant('Mission_view.billing.fees'), 'fees', ''));
  }
}
