import {Component, OnDestroy, OnInit} from '@angular/core';
import {query, transition, trigger, useAnimation} from '@angular/animations';
import {fadeInAnimation, slideIn50StaggerAnimation, slideInAnimation} from '../../../shared/animation/common.animation';
import {ListContentComponent} from '../../../shared/components/list-content/list-content.component';
import {ActivatedRoute, Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {FormBuilder} from '@angular/forms';
import {InvoiceStatusEnum} from '../models/invoice-status.enum';
import {InvoiceItemModel} from '../models/invoice-item.model';
import {Subscription} from 'rxjs';
import {InvoiceTypeEnum} from '../models/invoice-type.enum';
import * as _ from 'lodash';
import {regex} from '../../../shared/regex/form.regex';
import {InvoiceModeEnum} from '../models/invoice-mode.enum';
import {ListContentModel} from '../../../shared/models/list-content.model';
import {InvoiceService} from '../services/invoice.service';
import {HttpResponse} from '@angular/common/http';
import {ContractModeEnum} from '../models/contract-mode.enum';
import {ModalConfirmComponent} from '../../../shared/components/modal-confirm/modal-confirm.component';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {
  faBan,
  faDownload,
  faFileCircleXmark,
  faFileInvoice,
  faSearch
} from '@fortawesome/pro-duotone-svg-icons';

@Component({
  selector: 'invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        useAnimation(fadeInAnimation)
      ])
    ]),
    trigger('slideIn', [
      transition(':enter', [
        useAnimation(slideInAnimation)
      ])
    ]),
    trigger('slideInStagger', [
      transition(':enter', [
        query('.slide-in-animation', [
          useAnimation(slideIn50StaggerAnimation)
        ], {optional: true})
      ])
    ])
  ]
})
export class InvoiceListComponent extends ListContentComponent implements OnInit, OnDestroy {
  protected readonly faSearch = faSearch;
  protected readonly faFileCircleXmark = faFileCircleXmark;
  protected readonly faDownload = faDownload;
  protected readonly faBan = faBan;
  protected readonly faFileInvoice = faFileInvoice;
  subscriptions: Subscription[] = [];
  listContent: InvoiceItemModel[] = [];
  status = Object.values(InvoiceStatusEnum);
  types = Object.values(InvoiceTypeEnum);
  modes = Object.values(InvoiceModeEnum);
  contractModes = Object.values(ContractModeEnum);

  listParamValidator = {
    page: RegExp('^[1-9][0-9]*$'),
    size: ['5', '10', '20'],
    sort: RegExp('^(number|purchaseOrderNumber|providerName|supplierName|status|buyerName|period|date|transmission|mode|type|credited)\,(asc|desc)$'),
    search: RegExp('.{3,}'),
    number: RegExp('^[a-zA-Z0-9_.-]+$'),
    buyerName: RegExp(regex.companyName),
    supplierName: RegExp(regex.companyName),
    purchaseOrderNumber: RegExp('^[a-zA-Z0-9_.-]+$'),
    status: Object.values(InvoiceStatusEnum),
    mode: Object.values(InvoiceModeEnum),
    contractMode: RegExp(`^(${this.contractModes.join('|')})(\,(${this.contractModes.join('|')}))*$`),
    type: RegExp(`^(${this.types.join('|')})(\,(${this.types.join('|')}))*$`),
    credited: ['true', 'false']
  };

  constructor(
    private invoiceService: InvoiceService,
    private fb: FormBuilder,
    private logger: NGXLogger,
    public route: ActivatedRoute,
    public router: Router,
    private modalService: BsModalService,
    private translateService: TranslateService,
    private toastrService: ToastrService
  ) {
    super(router, route);
    this.advancedSearchForm = this.fb.group({
      number: [null],
      buyerName: [null],
      purchaseOrderNumber: [null],
      status: [null],
      mode: [null],
      contractMode: [null],
      type: [null],
      credited: [null]
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.sort = 'date';
    this.sortDirection = 'desc';
    this.subscribeToQueryParam();
  }

  // Override method
  mapQueryParamToAdvancedSearchForm(params: any): void {
    Object.keys(params).forEach(paramKey => {
      if (this.advancedSearchForm.contains(paramKey)) {
        if (paramKey === 'type') {
          const type = params[paramKey].split(',');
          this.advancedSearchForm.get('type').setValue(_.uniq(type));
        } else if (paramKey === 'contractMode') {
          const contractMode = params[paramKey].split(',');
          this.advancedSearchForm.get('contractMode').setValue(_.uniq(contractMode));
        } else {
          this.advancedSearchForm.get(paramKey).setValue(params[paramKey]);
        }
        this.isAdvancedSearchDisplayed = true;
        this.isSearchActive = true;
      }
    });
  }

  // Override method
  mapAdvancedSearchFormToQueryParam(advancedSearchForm: any): any {
    if (advancedSearchForm.hasOwnProperty('type') && advancedSearchForm.type) {
      advancedSearchForm.type = advancedSearchForm.type.join();
    }
    if (advancedSearchForm.hasOwnProperty('contractMode') && advancedSearchForm.contractMode) {
      advancedSearchForm.contractMode = advancedSearchForm.contractMode.join();
    }
    return advancedSearchForm;
  }

  // Override method
  retrieveListContent(params: any): void {
    const page = params.page ? params.page - 1 : 0;
    const size = params.size ? params.size : 5;
    const sort = params.sort ? params.sort : 'date,desc,providerName,asc';
    const search = params.search ? params.search : null;
    const advancedSearch = this.invoiceService.mapAdvancedSearchForm(this.advancedSearchForm.value);
    this.subscriptions.push(this.invoiceService.getInvoicesByPage(page, size, sort, search, advancedSearch).subscribe(
      (res: ListContentModel) => {
        this.listContent = res.content;
        this.totalElements = res.totalElements;
        this.numberOfElements = res.numberOfElements;
        this.isListEmpty = !this.isSearchActive && res.empty;

        if (res.totalPages !== 0 && params.page > res.totalPages) {
          this.updateQueryParam({page: res.totalPages});
        }

        this.firstCallDone = true;
        // this.logger.debug('invoice list retrieved : ', res);
      },
      (error: any) => {
        this.logger.error(error.url, '- STATUS :', error.status);
      }
    ));
  }

  downloadInvoice(invoice) {
    this.subscriptions.push(this.invoiceService.downloadFile(invoice.id).subscribe(
      (res: HttpResponse<Blob>) => {
        const headers = res.headers;
        const contentDisposition = headers.get('content-disposition');
        let filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
        filename = filename.replace(/"/g, '');
        const a = document.createElement('a');
        a.href = URL.createObjectURL(res.body);
        a.download = filename;
        // this.logger.info('your file: ', a);
        a.click();
        a.remove();
      },
      (error: any) => {
        this.logger.error(error.url, '- STATUS :', error.status);
      }
    ));
  }

  requestCreditNote(invoice) {
    this.subscriptions.push(this.translateService.get('Invoice.request_credit_note_msg').subscribe((data: any) => {
      const initialState = {
        message: data,
        commentAllow: true,
        commentMinLength: 10,
        commentMaxLength: 60,
        commentPlaceholder: data
      };
      const modalRef = this.modalService.show(ModalConfirmComponent, {
        class: 'modal-lg',
        initialState
      });
      this.subscriptions.push(modalRef.content.actionConfirmed.subscribe(
        comment => {
          const body = {
            creditNoteRequested: true,
            creditNoteRequestedComment: comment
          };

          this.subscriptions.push(this.invoiceService.patchInvoice(invoice.id, body).subscribe(
            () => {
              this.retrieveListContent({}); // TODO: Params?
              this.toastrService.success(this.translateService.instant('Invoice.credit_note_request_taken_account'));
            },
            (error: any) => {
              this.logger.error(error.url, '- STATUS :', error);
              this.toastrService.error(this.translateService.instant('Invoice.credit_note_request_failed'));
            }
          ));
        }
      ));
    }));
  }

  displayStatusEnum(enumValue: string): string {
    if (enumValue.startsWith('VALIDATED') || enumValue.startsWith('STAND_BY')) {
      return 'VALIDATED';
    } else if (enumValue.startsWith('WAITING')) {
      return 'WAITING';
    } else {
      return enumValue;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(value => value.unsubscribe());
  }


  cancelCreditNote(id: string) {
    this.subscriptions.push(this.invoiceService.cancelCreditNoteRequest(id).subscribe(
      (res: any) => {
        const invoice = this.listContent.find(c => c.id === res.id);
        if (invoice) {

          invoice.creditNoteRequested = res.creditNoteRequested;
          return;
        }
      },
      (error: any) => {
        this.logger.error(error.url, '- STATUS :', error.status);
      }
    ));
  }
}
