import { Injectable } from '@angular/core';
import {BillingAddressModel} from '../models/billing-address.model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../../../assets/environments/environment';
import {commonProperties} from '../../../../../../assets/environments/environment.common';
import {AuthenticationService} from "../../../../../core/services/authentication.service";

@Injectable({
  providedIn: 'root'
})
export class BillingAddressService {

  constructor(private _httpClient: HttpClient, private authService: AuthenticationService) { }

  createBillingAddress(params: BillingAddressModel): Observable<BillingAddressModel> {
    return this._httpClient.post<BillingAddressModel>(environment.api_root + commonProperties.billingAddress.replace(':companyId', this.authService.getCompanyId()), params);
  }

  retrieveBillingAddresses(active: boolean): Observable<BillingAddressModel[]> {
    const params = { active };
    return this._httpClient.get<BillingAddressModel[]>(environment.api_root + commonProperties.billingAddress.replace(':companyId', this.authService.getCompanyId()), {params});
  }

  retrieveBillingAddress(addressId: string): Observable<BillingAddressModel> {
    return this._httpClient.get<BillingAddressModel>(environment.api_root + commonProperties.billingAddressId.replace(':companyId', this.authService.getCompanyId()).replace(':id', addressId));
  }

  updateBillingAddress(params: BillingAddressModel): Observable<BillingAddressModel> {
    return this._httpClient.put<BillingAddressModel>(environment.api_root + commonProperties.billingAddressId.replace(':companyId', this.authService.getCompanyId()).replace(':id', params.id), params);
  }
}
