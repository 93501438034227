<div class="animated fadeIn">

  <div class="row">
    <div class="col-md-12">
      <h2 class="underline">{{'tm_invoice_list.title'|translate}}</h2>
    </div>
  </div>

  <app-page-menu [menuList]="menu"></app-page-menu>

  <div class="row">

    <div class="col-md-12">

      <table class="table table-sm table-responsive-sm table-outline mb-0">

        <thead class="thead-light mb-4">
        <tr>
          <th><i class="icon-briefcase"></i>{{'tm_invoice_list.column.po_project'|translate}}</th>
          <th class="text-center">{{'tm_invoice_list.column.period'|translate}}</th>
          <th>{{'tm_invoice_list.column.client'|translate}}</th>
          <th class="text-center">{{'tm_invoice_list.column.provider'|translate}}</th>
          <th class="text-center">{{'tm_invoice_list.column.quantity'|translate}}</th>
          <th></th>
        </tr>
        </thead>

        <tbody [@listStagger]='listContent?.length'>

        <tr *ngFor="let activitySheet of listContent">

          <td>
            <div>{{activitySheet.purchaseOrderNumber}}</div>
            <div class="small text-muted">
              <span>{{activitySheet.projectName}}</span>
            </div>
          </td>
          <td class="text-center">
            {{activitySheet.accountingMonth | date: 'MMM yyyy'}}
          </td>
          <td>
            {{activitySheet.buyerCompanyName}}
          </td>

          <td class="text-center">
            {{activitySheet.employeeName}}
          </td>
          <td class="text-center">
            {{activitySheet.sum}} {{activitySheet.type | lowercase}}
          </td>

          <td class="text-center">
            <a class="btn btn-sm btn-action" [routerLink]="[activitySheet.id,'update']">
              <i class="las la-pen"></i> {{"tm_invoice_list.button_generate"|translate}}
            </a>
          </td>
        </tr>

        </tbody>

      </table>

    </div>

  </div>

  <div class="row mt-4 slide-in-animation">
    <div class="col-md-12">
      <pagination class="float-right" [totalItems]="totalElements" [maxSize]="5"
                  [boundaryLinks]="true"
                  [ngModel]="page"
                  (pageChanged)="changePage($event)"
                  [itemsPerPage]="size"
                  previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;"
                  lastText="&raquo;">
      </pagination>
    </div>
  </div>

</div>
