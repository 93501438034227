import {Injectable} from '@angular/core';
import {AuthenticationService} from "./authentication.service";

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private authService: AuthenticationService) {
  }

  isRole(role: string | string[]): boolean {
    const authorities = this.authService.decodeToken(AuthenticationService.AUTHORITIES_CLAIM);
    if (typeof role === 'string') {
      return authorities.includes(role);
    } else {
      return role.some(r => authorities.includes(r));
    }
  }
}
