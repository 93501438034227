<div class="animated fadeIn" *ngIf="selectedPurchaseOrder">
  <div class="row">
    <div class="col-md-12">
      <h2 class="underline">
        {{ "Purchase_orders.po_n" | translate }}
        {{ selectedPurchaseOrder?.purchaseOrderNumber }}
        <span *ngIf="selectedPurchaseOrder " class="ml-2" [ngClass]="['badge', resolveBadgeStatus(selectedPurchaseOrder)]">
          {{"Purchase_orders.common.status." + selectedPurchaseOrder?.status| translate}}
        </span>
      </h2>
    </div>
  </div>

  <div class="row mb-5">
    <div class="col-md-8">
      <alert [type]="getAlertType()" *ngIf="selectedPurchaseOrder?.status !== 'GENERATED'">
        <div class="row">
          <div class="col-md-1">
            <lord-icon
              src="https://cdn.lordicon.com/nocovwne.json"
              trigger="loop"
              style="width: 50px; height: 50px"
            >
            </lord-icon>
          </div>

          <div class="col my-auto">
            <strong>
              {{"Purchase_orders.view.messages." + selectedPurchaseOrder.status| translate }}
            </strong>
            <span class="font-italic">
              {{ selectedPurchaseOrder.reason }}
            </span>
          </div>
        </div>
      </alert>

      <div class="row">
        <div class="col">
          <div class="card box-shadow-hover">
            <div class="card-body" *ngIf="supplierPOFile">
              <extended-pdf-preview
                [source]="supplierPOFile"
                [config]="pdfConfig"
              ></extended-pdf-preview>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="card">
        <div class="box-shadow-hover">
          <isp-list-group *ngIf="itemsList && itemsList.length > 0" [items]="itemsList" [selectable]="false"></isp-list-group>
        </div>
      </div>
      <div class="mb-3 d-flex flex-column text-align-center wid-100 slide-in-animation">
        <div class="action-buttons container-manual-upload">
          <isp-button
            class="action-buttons"
            [type]="ButtonTypeEnum.CLASSIC"
            [text]="'global.button.download'|translate"
            [colour]="IspColours.primary"
            [icon]="faDownload"
            [style]="'w-70'"
            [tooltip]="([PurchaseOrderStatusEnum.CANCELLED, PurchaseOrderStatusEnum.GENERATED, PurchaseOrderStatusEnum.REFUSED_BY_SUPPLIER].includes(this.selectedPurchaseOrder.status))?('Purchase_orders.view.tooltips.only-signed'| translate):null"
            [disabled]="[PurchaseOrderStatusEnum.CANCELLED, PurchaseOrderStatusEnum.GENERATED, PurchaseOrderStatusEnum.REFUSED_BY_SUPPLIER].includes(this.selectedPurchaseOrder.status)"

            (trigger)="downloadPurchaseOrder()"
          ></isp-button>
        </div>
      </div>
    </div>
  </div>
</div>
