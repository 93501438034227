import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../../core/guards/auth.guard';
import {RoleGuard} from '../../core/guards/role-guard.service';
import {ToGenerateComponent} from './to-generate/to-generate.component';
import {
  ActivitySheetToGenerateComponent
} from './to-generate/activity-sheet-to-generate/activity-sheet-to-generate.component';
import {
  DuePaymentsToGenerateComponent
} from './to-generate/due-payments-to-generate/due-payments-to-generate.component';
import {OnCallsToGenerateComponent} from './to-generate/on-calls-to-generate/on-calls-to-generate.component';
import {FeesToGenerateComponent} from './to-generate/fees-to-generate/fees-to-generate.component';
import {ProformaGenerationComponent} from './to-generate/proforma-generation/proforma-generation.component';
import {InvoiceListComponent} from './invoice-list/invoice-list.component';
import {CreditNoteListComponent} from './credit-note-list/credit-note-list.component';
import {CompletedGuard} from '../../core/guards/completed.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'to-generate',
    pathMatch: 'full'
  },
  {
    path: 'to-generate',
    component: ToGenerateComponent,
    canActivate: [AuthGuard, RoleGuard, CompletedGuard],
    data: {
      expectedRoles: ['ROLE_SUPPLIER', 'ROLE_SUPPLIER_ADMIN', 'ROLE_FREELANCER'],
      breadcrumb: 'To Generate'
    },
    children: [
      {
        path: '',
        redirectTo: 'activity-sheets',
        pathMatch: 'full'
      },
      {
        path: 'activity-sheets',
        component: ActivitySheetToGenerateComponent,
        data: {
          breadcrumb: 'activity-sheets'
        }
      },
      {
        path: 'due-payments',
        component: DuePaymentsToGenerateComponent,
        data: {
          breadcrumb: 'due-payments'
        }
      },
      {
        path: 'on-calls',
        component: OnCallsToGenerateComponent,
        data: {
          breadcrumb: 'on-calls'
        }
      },
      {
        path: 'fees',
        component: FeesToGenerateComponent,
        data: {
          breadcrumb: 'fees'
        }
      }
    ]
  },
  {
    path: ':type/:id/proforma-generation',
    component: ProformaGenerationComponent,
    canActivate: [AuthGuard, RoleGuard, CompletedGuard],
    data: {
      expectedRoles: ['ROLE_SUPPLIER', 'ROLE_SUPPLIER_ADMIN', 'ROLE_FREELANCER'],
      breadcrumb: 'Proforma generation'
    }
  },
  {
    path: 'invoices',
    component: InvoiceListComponent,
    canActivate: [AuthGuard, RoleGuard, CompletedGuard],
    data: {
      expectedRoles: ['ROLE_SUPPLIER', 'ROLE_SUPPLIER_ADMIN', 'ROLE_FREELANCER'],
      breadcrumb: 'Invoices'
    }
  },
  {
    path: 'credit-notes',
    component: CreditNoteListComponent,
    canActivate: [AuthGuard, RoleGuard, CompletedGuard],
    data: {
      expectedRoles: ['ROLE_SUPPLIER', 'ROLE_SUPPLIER_ADMIN', 'ROLE_FREELANCER'],
      breadcrumb: 'Credit Notes'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InvoiceRoutingModule {
}
