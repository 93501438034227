import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit {

  // Input variables
  message: string;
  additionalMessage: string;
  commentAllow: boolean = false;
  commentMaxLength: number = 255;
  commentMinLength: number = 0;
  commentPlaceholder: string;
  blueStyle = false;


  // Output variables
  @Output() actionConfirmed = new EventEmitter<string>();
  @Output() actionCanceled = new EventEmitter();

  // Intern variables
  commentForm: FormGroup;

  constructor(public modalRef: BsModalRef,
              private fb: FormBuilder) {
    this.commentForm = this.fb.group({
      comment: [null, [Validators.required, Validators.maxLength(this.commentMaxLength), Validators.minLength(this.commentMinLength)]]
    });
  }

  ngOnInit(): void {
    this.setCommentForValidation()
  }

  private setCommentForValidation() {
    if(this.commentMinLength > 0){
      this.commentForm.get('comment').setValidators([Validators.required, Validators.maxLength(this.commentMaxLength), Validators.minLength(this.commentMinLength)]);
    }else{
      this.commentForm.get('comment').setValidators([Validators.maxLength(this.commentMaxLength), Validators.minLength(this.commentMinLength)]);
    }
    this.commentForm.get('comment').updateValueAndValidity();
  }

  confirmAction(): void {
    if (this.commentAllow && this.commentForm.get('comment').value) {
      this.actionConfirmed.emit(this.commentForm.get('comment').value.trim());
    } else {
      this.actionConfirmed.emit();
    }
    this.modalRef.hide();
  }

  cancelAction(): void {
    this.actionCanceled.emit();
    this.modalRef.hide();
  }

}
