import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ListContentComponent} from '../../../shared/components/list-content/list-content.component';
import {ActivatedRoute, Router} from '@angular/router';
import {query, transition, trigger, useAnimation} from '@angular/animations';
import {slideIn50StaggerAnimation, slideInAnimation} from '../../../shared/animation/common.animation';
import {CallForTenderListItemModel} from '../../../shared/models/call-for-tender/call-for-tender-list-item.model';
import * as moment from 'moment';
import {CategoryModel} from '../../../shared/models/call-for-tender/category.model';
import {FormBuilder} from '@angular/forms';
import {CallForTenderService} from '../services/call-for-tender.service';
import {ToastrService} from 'ngx-toastr';
import {NGXLogger} from 'ngx-logger';
import {CallForTenderModel} from '../../../shared/models/call-for-tender/call-for-tender.model';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {faDownload, faExclamationTriangle, faFileText, faGlobe, faIndustry, faInstitution, faSortAmountDesc, } from '@fortawesome/free-solid-svg-icons';
import {faCalendar, faClock} from '@fortawesome/free-regular-svg-icons';
import { combineLatest } from 'rxjs';
import { CompanyModel } from '../../../shared/models/companies/company.model';
import {CFTAttachmentModel} from '../../../shared/models/call-for-tender/call-for-tender-attachment.model';
import {ActivityAreaModel} from '../../company-config/activity-areas/activity-area.model';
import {
  ActivityAreaDropdownComponent
} from '../../../shared/components/activity-area-dropdown/activity-area-dropdown.component';
import {ActivityAreasService} from '../../company-config/services/activity-areas.service';

@Component({
  selector: 'call-for-tender-list',
  templateUrl: './call-for-tender-list.component.html',
  styleUrls: ['./call-for-tender-list.component.scss'],
  animations: [
    trigger('slideIn', [
      transition(':enter', [
        useAnimation(slideInAnimation)
      ])
    ]),
    trigger('slideInStagger', [
      transition(':enter', [
        query('.slide-in-animation', [
          useAnimation(slideIn50StaggerAnimation)
        ], {optional: true})
      ])
    ])
  ]
})
export class CallForTenderListComponent extends ListContentComponent implements OnInit, OnDestroy {
  protected readonly faSortAmountDesc = faSortAmountDesc;
  protected readonly faDownload = faDownload;
  protected readonly faFileText = faFileText;
  protected readonly faCalendar = faCalendar;
  protected readonly faClock = faClock;
  protected readonly faExclamationTriangle = faExclamationTriangle;
  protected readonly faGlobe = faGlobe;
  protected readonly faInstitution = faInstitution;
  protected readonly faIndustry = faIndustry;
  @ViewChild('callForTenderDetailModal', {static: true}) callForTenderDetailModal: { show: () => void; hide: () => void; };
  @ViewChild('activityAreaDropDownSelector') activityAreaDropDownSelector: ActivityAreaDropdownComponent;
  callForTenderDetailModalValue: CallForTenderModel = new CallForTenderModel();
  cftAttachmentsModalValue: CFTAttachmentModel[] = [];
  listContent: CallForTenderListItemModel[] = [];
  isLast: boolean;
  username: string;
  userRole: string;
  userId: string;
  existingCompany: CompanyModel;
  private countries: any[];
  private companyId: string;
  private currentCountry: any;
  listParamValidator = {
    sort: RegExp('^(MODIFIED_DATE|CREATED_DATE|REFERENCE|TITLE|CLOSED_DATE)\,(ASC|DESC)$'),
    reference: RegExp('.+'),
    title: RegExp('.+'),
    buyer: RegExp('.+'),
    activityArea: RegExp('.+')
  };
  protected attachments: any;
  mandatorySkills: any[] = [];
  optionalSkills: any[] = [];
  activityAreas: ActivityAreaModel[];

  constructor(fb: FormBuilder,
              public router: Router,
              public route: ActivatedRoute,
              private callForTenderService: CallForTenderService,
              private toastrService: ToastrService,
              private translateService: TranslateService,
              private logger: NGXLogger,
              private authService: AuthenticationService) {
    super(router, route);
    this.advancedSearchForm = fb.group({
      reference: [],
      title: [],
      buyer: [],
      activityArea: []
    });
  }

  ngOnInit(): void {
    this.username = this.authService.decodeToken('sub');
    this.userRole = this.authService.getRole();
    this.userId = this.authService.getUserId();
    this.companyId = this.authService.getCompanyId();

    super.ngOnInit();

    this.isInfiniteList = true;
    this.sort = 'MODIFIED_DATE';
    this.sortDirection = 'DESC';
    this.page = 0;
    this.retrieveCountry();
    this.subscribeToQueryParam();
  }

  private retrieveCountry(): void {
    const countriesObs = this.callForTenderService.getCountries();
    const companyObs = this.callForTenderService.getCompanyById(this.companyId);

    this.subscriptions.push(combineLatest(countriesObs, companyObs).subscribe(
      (res: any[]) => {
        this.existingCompany = res[1];
        this.countries = res[0];
        this.currentCountry = this.countries.find(item => item.alpha2Code === this.existingCompany.origin);
      },
      (error: any) => {
        this.logger.error(error.url, '- STATUS :', error.status);
      }
    ));
  }

  setActivityAreaFilterValue($event: ActivityAreaModel[]) {
    this.advancedSearchForm.get('activityArea').setValue($event ? $event['activityAreaId'] : null);
    this.advancedSearchForm.get('activityArea').markAsDirty();
    this.advancedSearchForm.get('activityArea').markAsTouched();
  }

  retrieveListContent(params: any): void {
    const page = this.page ? this.page : 0;
    const advancedSearch = this.callForTenderService.mapAdvancedSearchForm(params);

    this.callForTenderService.getActivityAreaCallForTenderList(page, this.sortDirection, this.sort, advancedSearch).subscribe({
      next: res => {
        res.page.isFirst ? this.listContent = res.content : this.listContent = this.listContent.concat(res.content);
        this.isListEmpty = !this.isSearchActive && res.empty;
        this.firstCallDone = true;
        this.isLast = res.page.isLast;

        if (this.userRole === 'ROLE_FREELANCER') {
          this.checkFreelanceOffer();
        }
      },
      error: err => {
        this.logger.error(err.url, '- STATUS :', err.status);
        this.toastrService.error(this.translateService.instant('global.ts.error'));
      }
    });
  }

  getExpiration(endDate: string): number {
    return moment(endDate).diff(moment(), 'days') + 1;
  }

  setSort(value: any): void {
    const sort = value + ',' + this.sortDirection;
    this.page = 0;
    this.updateQueryParam({sort});
  }

  setSortDirection(): void {
    const sort = this.sort + ',' + (this.sortDirection === 'ASC' ? 'DESC' : 'ASC');
    this.page = 0;
    this.updateQueryParam({sort});
  }

  resetAdvancedSearchForm(): void {
    if (this.isAdvancedSearchFormEmpty()) {
      return;
    }

    this.activityAreaDropDownSelector.selectedActivityAreas = null;
    this.advancedSearchForm.reset();
    if (this.isSearchActive) {
      this.isSearchActive = false;
      this.page = 0;
      this.updateQueryParam({...this.advancedSearchForm.value});
    }
  }

  getNextPageCallForTenderList(): void {
    if (!this.isLast) {
      this.page += 1;
      this.refreshListContent();
    }
  }

  openCallForTenderDetailModal($event: { id: string; activityArea: ActivityAreaModel; }): void {
    this.subscriptions.push(this.callForTenderService.getCFTAttachmentsByCFTId($event.id).subscribe(value => {
      this.attachments = value;
    }));
    this.callForTenderService.getCallForTenderById($event.id).subscribe({
      next: res => {
        this.callForTenderDetailModalValue = res;
        this.callForTenderDetailModalValue.activityArea = $event.activityArea;
        this.mandatorySkills = [];
        this.optionalSkills = [];

        this.mandatorySkills = this.callForTenderDetailModalValue.cftSkills.filter((skill: any) => skill.isMandatory);
        this.optionalSkills = this.callForTenderDetailModalValue.cftSkills.filter((skill: any) => !skill.isMandatory);

        this.callForTenderService.getCFTAttachmentsByCFTId($event.id).subscribe(data => {
          this.cftAttachmentsModalValue = data;
        });
        this.callForTenderDetailModal.show();
        this.markCallForTenderHasViewed($event.id);
        this.callForTenderDetailModalValue.freelanceOfferExist = this.listContent.filter(item => item.id === $event.id)[0].freelanceOfferExist;
      },
      error: err => {
        this.logger.error(err.url, '- STATUS :', err.status);
        this.toastrService.error(this.translateService.instant('global.ts.error'));
      }
    });
  }

  markCallForTenderHasViewed(callForTenderId: string): void {
    this.subscriptions.push(
      this.callForTenderService.markCallForTenderHasViewed(callForTenderId).subscribe()
    );
  }

  mapAdvancedSearchFormToQueryParam(advancedSearchForm: any): any {
    if (advancedSearchForm.hasOwnProperty('categories') && advancedSearchForm.categories) {
      advancedSearchForm.categories = advancedSearchForm.categories.map(c => c.id).join();
    }
    return advancedSearchForm;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(value => value.unsubscribe());
  }

  backToCftList(): void {
    this.callForTenderDetailModal.hide();
  }


  downloadFile(callForTender: CallForTenderModel): void {
    this.subscriptions.push(this.callForTenderService.downloadFile(this.callForTenderDetailModalValue.id).subscribe(
      (res) => {
        const blobUrl = URL.createObjectURL(res);
        const anchor = document.createElement('a');

        const extension = this.cftAttachmentsModalValue.length <= 1 ? this.cftAttachmentsModalValue[0].fileType : 'zip';

        anchor.href = blobUrl;
        anchor.download = `${callForTender.reference}.${extension}`;

        anchor.click();
        URL.revokeObjectURL(blobUrl);
      },
      (error: any) => {
        this.logger.error(error.url, '- STATUS :', error.status);
        this.toastrService.error(this.translateService.instant('global.ts.error'));
      })
    );
  }

  checkFreelanceOffer(): void {
    this.listContent.forEach(item => {

      this.subscriptions.push(this.callForTenderService.checkFreelanceOffer(item.id, this.userId).subscribe(
        (res: any) => {
         if (res) {
           item.freelanceOfferExist  = true;
         }
        },
        (error: any) => {
          this.logger.error(error.url, '- STATUS :', error.status);
          this.toastrService.error(this.translateService.instant('global.ts.error'));
        })
      );
    });
  }
}
