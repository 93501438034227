<mat-dialog-content>
  <form [formGroup]="form">
    <div class="text-center">
      <div *ngIf="event">
        <strong>{{ startDate | titlecase }}</strong>
      </div>
      <div *ngIf="!event && startDate === endDate">
        <strong>{{ startDate | titlecase }}</strong>
      </div>
      <div *ngIf="!event && startDate !== endDate">
        <strong>{{ startDate | titlecase }} {{ 'global.common.to' | translate }} {{ endDate | titlecase }}</strong>
      </div>
    </div>
    <div class="form-group">
      <ng-container *ngIf="type === 'HOURS'; else days">
        <div class="d-flex justify-content-center">
          <label for="formGroupExampleInput">{{'Activity_report.modal.hours'|translate}}</label>
          <label class="ml-4"
                 for="formGroupExampleInput">{{'Activity_report.modal.minutes'|translate}}</label>
        </div>
        <div class="d-flex justify-content-center">
          <timepicker
            id="formGroupExampleInput"
            formControlName="date"
            [max]="maxTime"
            [hoursPlaceholder]="hoursPlaceholder"
            [minuteStep]="15"
            [showMeridian]="isMeridian"
            [showSpinners]="showSpinners"
            [(ngModel)]="myTime"
            [disabled]="disableForm"
          >
          </timepicker>
        </div>
      </ng-container>
    </div>


    <div class="custom-control custom-checkbox mb-1 d-flex">
      <input
        id="allDay"
        formControlName="isAllDay"
        (change)="eventCheck($event)"
        type="checkbox"
        class="custom-control-input"
      />
      <label class="custom-control-label" for="allDay">
        {{'Activity_report.modal.all_day' | translate}}
      </label>
    </div>

  </form>
</mat-dialog-content>


<mat-dialog-actions class="mb-1 d-flex justify-content-center">
  <ng-container *ngIf="event">
    <button type="button" class="btn btn-light-primary"
            (click)="delete()">{{'Activity_report.modal.button_delete'|translate}}</button>
    <button type="submit" class="btn button-green ml-3"
            (click)="submit(ActionEnum.UPDATE)">{{'Activity_report.modal.button_update'|translate}}</button>
  </ng-container>
  <ng-container *ngIf="!event">
    <button type="submit" class="btn button-green"
            (click)="submit(ActionEnum.POST)">{{'Activity_report.modal.button_create'|translate}}</button>
  </ng-container>
  <button type="button" class="btn btn-outline-blue ml-3"
          (click)="cancel()">{{'Activity_report.modal.button_go_back'|translate}}</button>
</mat-dialog-actions>

<ng-template #days>
  <div class="d-flex justify-content-center">
    <plus-minus-input (value)="valueEventHandler($event)" [disableForm]="disableForm"
                      [initValue]="myTime"></plus-minus-input>
  </div>
</ng-template>
