import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {ListContentModel} from '../../../shared/models/list-content.model';
import {CreditNoteAdvancedSearchModel} from '../models/credit-note-advanced-search.model';
import {commonProperties} from '../../../../assets/environments/environment.common';
import {environment} from '../../../../assets/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CreditNoteService {

  constructor(private httpClient: HttpClient) {}

  getCreditNotesByPage(
    page: number,
    size: number,
    sort: string,
    search: string,
    advancedSearch: CreditNoteAdvancedSearchModel
  ): Observable<ListContentModel> {
    const CREDIT_NOTE_TYPE = 'SUPPLIER';
    const API_URL = environment.api_root + commonProperties.creditNotes;

    let params = new HttpParams()
      .set('creditNoteType', CREDIT_NOTE_TYPE)
      .set('page', page.toString())
      .set('size', size.toString())

    params = params.set('creditNoteType', 'SUPPLIER');
    if (search) {
      params = params.set('search', search);
    } else if (advancedSearch) {
      if (advancedSearch.number) {
        params = params.set('creditNoteNumber', advancedSearch.number);
      }
      if (advancedSearch.invoiceNumber) {
        params = params.set('invoiceNumber', advancedSearch.number);
      }
      if (advancedSearch.buyerName) {
        params = params.set('buyerName', advancedSearch.buyerName);
      }
      if (advancedSearch.purchaseOrderNumber) {
        params = params.set('purchaseOrderNumber', advancedSearch.purchaseOrderNumber);
      }
      if (advancedSearch.status) {
        params = params.set('status', advancedSearch.status);
      }
      if (advancedSearch.type) {
        params = params.set('types', advancedSearch.type);
      }
      if (advancedSearch.mode) {
        params = params.set('modes', advancedSearch.mode);
      }
    }
    params = params.set('page', page.toString()).set('size', size.toString());

    if (sort) {
      const sortPattern = /([^;,]+,[^;,]+);?/g;
      const sortMatches = sort.match(sortPattern);

      if (sortMatches) {
        const sortPairs = sortMatches.map(match => match.split(','));
        sortPairs.forEach(pair => {
          params = params.append('sort', pair[0] + ',' + pair[1]);
        });
      }
    }

    return this.httpClient.get<ListContentModel>(API_URL , {params});
  }

  downloadFile(id): Observable<HttpResponse<Blob>> {
    const headers = new HttpHeaders().set('Accept', 'application/pdf');
    const params = new HttpParams();
    params.set('proforma', 'false');
    return this.httpClient.get<Blob>(environment.api_root + commonProperties.creditNoteFile.replace(':id', id), {
      observe: 'response',
      headers: headers,
      params: params,
      responseType: 'blob' as 'json'
    });
  }


  mergeValues(obj, src) {
    this.forEach(src, (value, key) => {
      if (value !== null) {
        if (typeof (value) === 'object') {
          this.mergeValues(obj[key], value);
        } else {
          obj[key] = value;
        }
      }
    });
  }

  private forEach(target, fn) {
    const keys = Object.keys(target);
    let i = -1;
    while (++i < keys.length) {
      fn(target[keys[i]], keys[i]);
    }
  }

  mapAdvancedSearchForm(params: any): CreditNoteAdvancedSearchModel {
    return new CreditNoteAdvancedSearchModel(params);
  }

}
