<div class="card box-shadow-hover cursor-pointer"
     [ngClass]="{'status-accepted': callForTenderOffer.status === 'ACCEPTED',
                 'status-sent': callForTenderOffer.status === 'SENT',
                 'status-rejected': callForTenderOffer.status === 'REJECTED',
                 'status-published': callForTenderOffer.status === 'PUBLISHED',
                 'status-rejected-by-sa': callForTenderOffer.status === 'REJECTED_BY_SA',
                 'status-closed-by-supplier': callForTenderOffer.status === 'CLOSED_BY_SUPPLIER',
                 'status-cancelled': callForTenderOffer.status === 'CANCELLED',
                 'status-selected': callForTenderOffer.status === 'SELECTED',
                 'status-replied': callForTenderOffer.status === 'REPLIED',
                 'status-under-review': callForTenderOffer.status === 'UNDER_REVIEW'}">
  <div class="card-body pb-3">
    <div class="row">
      <div class="col-md-9">
        <div class="d-flex align-items-center">
          <h4 class="mr-2">
            <span
              [title]="callForTenderOffer.title">{{callForTenderOffer.providerFirstName}} {{callForTenderOffer.providerLastName | uppercase}}
              ({{callForTenderOffer.nickName | uppercase}}
              ) - {{callForTenderOffer.title | ellipsis: 50}}</span>
          </h4>
          <span class="badge float-right mb-2"
                [ngClass]="{'badge-success': callForTenderOffer.status === 'ACCEPTED',
                 'badge-warning': ['SENT','UNDER_REVIEW'].includes(callForTenderOffer.status),
                 'badge-danger': callForTenderOffer.status === 'REJECTED',
                 'badge-pink': callForTenderOffer.status === 'PUBLISHED',
                 'badge-gray': callForTenderOffer.status === 'REJECTED_BY_SA',
                 'badge-darker-red': callForTenderOffer.status === 'CLOSED_BY_SUPPLIER',
                 'badge-purple': callForTenderOffer.status === 'SELECTED',
                 'badge-dark': callForTenderOffer.status === 'CANCELLED',
                 'badge-info': callForTenderOffer.status === 'REPLIED'}">
            {{"Call_for_tender_offer_list.statuses." + callForTenderOffer.status | translate}}
          </span>

          <ng-container *ngIf="['REJECTED_BY_SA', 'REJECTED'].includes(callForTenderOffer.status)">
            <span class="ml-2 mb-1">
              <mat-icon style="color: #e0564b" matTooltipClass="tooltipRejectedReason"
                        [matTooltip]="('Call_for_tender_offer_list.reject-comment-heading' | translate) + (callForTenderOffer.moderationComment ? callForTenderOffer.moderationComment : '--')">
                error_outline
              </mat-icon>
            </span>
          </ng-container>

        </div>
        <div class="text-dark-gray mb-1">
          <span class="mr-3">
            {{'Call_for_tender_offer_card.by'|translate}} {{callForTenderOffer.buyerName}} - {{callForTenderOffer.title}}
          </span>
          <span class="mr-3" title="Rate">
            <fa-icon [icon]="faMoneyBill1" class="mr-1"></fa-icon>
            {{callForTenderOffer.callForTender.contractMode === 'FIXED_PRICE' ? callForTenderOffer.amount : callForTenderOffer.dailyRate }}
            {{callForTenderOffer.currency | currencyFormat: 'symbol'}}
            {{callForTenderOffer.callForTender.contractMode === 'T_M' ? (callForTender.dailyRateByDate ? '/ Day' : '/ Hour') : ''}}
          </span>
          <span class="mr-3" title="Contract Mode">
            <fa-icon [icon]="faFile" class="mr-1"></fa-icon>
            {{callForTenderOffer.callForTender.contractMode | statusReadable |titlecase}}
          </span>
        </div>
        <div class="text-dark-gray mb-2">
          <span class="mr-3" [title]="'activity-area.title'|translate">
            <fa-icon [icon]="faIndustry" class="mr-1"></fa-icon>
            {{ callForTenderOffer.activityArea.label }}
          </span>
          <span class="mr-3" title="Start date">
            <fa-icon [icon]="faCalendar" class="mr-1"></fa-icon>
            {{callForTender.startDate | date: 'dd MMM. yyyy'}}
          </span>
          <span class="mr-3" title="Mission duration">
            <fa-icon [icon]="faClock" class="mr-1"></fa-icon>
            {{callForTender.quantity}} {{callForTender.quantityByDate ? 'Days' : 'Hours'}}
          </span>
          <span class="mr-3" title="Workplace">
            <fa-icon [icon]="faMapMarker" class="mr-1"></fa-icon>
            {{callForTender.workplace.city}} - {{callForTender.workplace.country}}
          </span>
        </div>
        <div>
          <span *ngFor="let technology of callForTender.cftSkills" class="badge badge-gray mr-1">
            {{technology['name']}}
          </span>
        </div>
      </div>
      <div class="col-md-3"
           *ngIf="['SENT', 'REJECTED_BY_SA'].includes(callForTenderOffer.status)">
        <div class="d-flex justify-content-end">
          <button class="btn btn-outline-primary" (click)="deleteOffer()"
                  *ngIf="callForTenderOffer.status === 'SENT'">
            {{'Call_for_tender_offer_card.delete_btn'|translate}}
          </button>
          <button class="btn btn-outline-primary ml-1"
                  [routerLink]="['/marketplace/calls-for-tender', callForTenderOffer.id, 'offer-update']">
            {{'Call_for_tender_offer_card.update_btn'|translate}}
          </button>
        </div>
      </div>

      <div class="col-md-3"
           *ngIf="callForTenderOffer.status === 'SELECTED'">
        <div class="d-flex justify-content-end">
          <button class="btn btn-outline-primary" (click)="showTimeSlotModal()">
            {{'Call_for_tender_offer_card.time_slot_btn'|translate}}
          </button>
        </div>
      </div>

      <div class="col-md-3"
           *ngIf="callForTenderOffer.status === 'REPLIED' && callForTenderOffer.priceNegotiation && callForTenderOffer.negotiation">
        <div class="d-flex justify-content-end">
          <button class="btn btn-outline-info" (click)="showNegotiationModal()"
                  [disabled]="callForTenderOffer.auditRecords.length == 3">
            {{'call_for_tender_negotiation_card.negotiation_btn'|translate}}
          </button>
        </div>

        <div class="text-dark-gray d-flex justify-content-end mt-2" style="margin-left: -50px;"
             *ngIf="callForTenderOffer.status === 'REPLIED' && callForTenderOffer.auditRecords.length == 3">
          {{'call_for_tender_negotiation_card.client_negotiation'|translate : {amount: callForTenderOffer.priceNegotiation, currency: callForTenderOffer.currency | currencyFormat: 'symbol'} }}
        </div>

        <div class="text-dark-gray d-flex justify-content-end mt-2" style="margin-left: -50px;"
             *ngIf="callForTenderOffer.status === 'REPLIED' && (callForTenderOffer.auditRecords.length == 2 || callForTenderOffer.auditRecords.length == 4) && callForTenderOffer.negotiation">
          {{'call_for_tender_negotiation_card.proposed_negotiation_client'|translate : {amount: callForTenderOffer.priceNegotiation, currency: callForTenderOffer.currency | currencyFormat: 'symbol'} }}
        </div>


      </div>
      <div class="text-dark-gray d-flex justify-content-end mt-2" style="margin-left: 50px;"
        *ngIf="callForTenderOffer.status === 'REPLIED' && (callForTenderOffer.auditRecords.length == 2 || callForTenderOffer.auditRecords.length == 4) && !callForTenderOffer.negotiation">
          {{'call_for_tender_negotiation_card.accepted_negotiation'|translate : {amount: callForTenderOffer.priceNegotiation, currency: callForTenderOffer.currency | currencyFormat: 'symbol'} }}
      </div>
    </div>
  </div>
</div>
