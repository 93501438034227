import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {CountryISO, SearchCountryField} from 'ngx-intl-tel-input-gg';
import {NGXLogger} from 'ngx-logger';
import {ToastrService} from 'ngx-toastr';
import {Subject, Subscription} from 'rxjs';
import {regex} from '../../../shared/regex/form.regex';
import {AuthorityModel, EmployeeRef, ProviderTypeEnum} from '../../../shared/models/employee/employee-post-body.model';
import {UserEmailStatusEnum, UserStatusModel} from '../../../shared/models/employee/user-status.model';
import {EmployeeService} from '../services/employee.service';
import {MyCompanyService} from '../../company-config/services/my-company.service';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {NavigationService} from '../../../shared/utils/navigation.service';

@Component({
  selector: 'app-create-employee',
  templateUrl: './create-employee.component.html',
  styleUrls: ['./create-employee.component.scss']
})
export class CreateEmployeeComponent implements OnInit, OnDestroy {

  constructor(
    private employeeService: EmployeeService,
    private companyService: MyCompanyService,
    private fb: FormBuilder,
    private router: Router,
    private toastrService: ToastrService,
    private authService: AuthenticationService,
    private logger: NGXLogger,
    private translateService: TranslateService,
    private navigationService: NavigationService
  ) {
    this.createEmployeeForm = this.fb.group({
      firstName: [null, [Validators.required, Validators.pattern(regex.firstName), Validators.maxLength(50)]],
      lastName: [null, [Validators.required, Validators.pattern(regex.lastName), Validators.maxLength(50)]],
      email: [null, [Validators.required, Validators.pattern(regex.email), Validators.maxLength(100)]],
      phoneNumber: [null, [Validators.required]],
      title: [null, [Validators.pattern(regex.title), Validators.maxLength(50)]],
      authority: [null, [Validators.required]],
      suppliers: [null],
      providerType: [ProviderTypeEnum.EMPLOYEE]
    });
  }

  createEmployeeForm: FormGroup;
  searchCountryField = SearchCountryField;
  countryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.France];
  roles: AuthorityModel[] = [];
  suppliersList: any[] = [];
  suppliersInput$ = new Subject<string>();
  supplierLoading = false;
  isProvider: boolean = false;

  private subscriptions: Subscription[] = [];
  protected readonly ProviderTypeEnum = ProviderTypeEnum;

  ngOnInit(): void {
    this.retrieveCompanyAuthorities();
    this.listeningToCreateEmployeeFormChanges();
    this.loadSuppliers();
  }

  private listeningToCreateEmployeeFormChanges(): void {
    this.subscriptions.push(this.createEmployeeForm.get('authority').valueChanges.subscribe((authority: any) => {
      if (authority.name == 'PROVIDER') {
        this.isProvider = true;
        // this.createEmployeeForm.get('suppliers').clearValidators();
        // this.createEmployeeForm.get('suppliers').setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(5)]);
        if (this.authService.getRole() == 'ROLE_SUPPLIER') {
          let supplier: EmployeeRef = new EmployeeRef();
          supplier.id = this.authService.getEmployeeId();
          supplier.firstName = this.authService.getName();
          supplier.lastName = '';
          if (this.createEmployeeForm.get('supplier') != null) {
            let suppliers = this.createEmployeeForm.get('supplier').value;
            suppliers.push(supplier);
            this.createEmployeeForm.patchValue({suppliers: suppliers})
          } else {
            this.createEmployeeForm.patchValue({suppliers: [supplier]})
          }
        }
      } else {
        this.isProvider = false;
        this.createEmployeeForm.get('suppliers').clearValidators();
      }
      this.createEmployeeForm.get('suppliers').updateValueAndValidity();
    }));
    this.subscriptions.push(this.createEmployeeForm.get('email').valueChanges.subscribe(emailChanges => {
      if (!this.createEmployeeForm.get('email').errors) {
        this.testEmailEmployee(emailChanges);
      }
    }));
  }

  private testEmailEmployee(userEmail: string): void {
    this.subscriptions.push(this.employeeService.testUserEmail(userEmail).subscribe(
      (res: UserStatusModel) => {
        if (res.status === UserEmailStatusEnum.TAKEN) {
          this.createEmployeeForm.get('email').setErrors({emailUsed: true});
        }
      }, error => {
        this.logger.error(error.url, '- STATUS :', error.status);
      })
    );
  }

  createEmployeeFormSubmit(): void {
    const previousUrl = this.navigationService.getPreviousUrl();
    const result = this.employeeService.mapFormValueToResource(this.createEmployeeForm.value);
    this.employeeService.createEmployee(result).subscribe({
      next: (res: any) => {
        this.toastrService.success(this.translateService.instant('employees.creation.success', { firstname: res.firstName, lastname: res.lastName }));
        if (previousUrl.includes('mission')) {
          this.router.navigateByUrl(previousUrl);
        } else {
          this.router.navigate(['employees']).then();
        }
      },
      error: () => this.toastrService.error(this.translateService.instant('global.ts.error'))
    });
  }

  retrieveCompanyAuthorities(): void {
    this.subscriptions.push(this.companyService.getUserAllowedRolesForEmployeeCreation().subscribe(
      (res: any) => {
        this.roles = res;
        this.setEmployeeToCreate();
      },
      (error: any) => {
        this.logger.error(error.url, '- STATUS :', error.status);
      })
    );
  }

  setEmployeeToCreate(): void {
    const employeeToCreate = JSON.parse(localStorage.getItem('employeeToCreate'));
    if (employeeToCreate) {
      employeeToCreate.authority = this.roles.find(role => role.name === employeeToCreate.role);
      this.createEmployeeForm.patchValue(employeeToCreate);
      localStorage.removeItem('employeeToCreate');
    }
  }

  loadSuppliers() {
    this.subscriptions.push(
      this.suppliersInput$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(value => this.supplierLoading = true)
      ).subscribe(value => {
        this.subscriptions.push(this.employeeService.getSuppliers(value).subscribe(
          (res: any) => {
            this.suppliersList = res.content;
            this.supplierLoading = false;
          },
          (error: any) => {
            this.suppliersList = [];
            this.logger.error('an error occurred when loading suppliers:', error);
            this.supplierLoading = false;
          }
        ));
      })
    );
  }

  compareSupplier(supplier1: any, supplier2: any): boolean {
    if (supplier1 && supplier2) {
      return supplier1.id === supplier2.id;
    }
    return false;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(value => value.unsubscribe());
  }
}
