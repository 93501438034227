export const validatedStatus = [
  'SENT',
  'TAKEN_INTO_ACCOUNT',
  'PROVIDER_ASSIGNED',
  'LEGAL_DOCUMENTS_VALIDATED',
  'CONTRACT_SIGNED',
  'ORDER_VALIDATED',
  'MISSION_IN_PROGRESS',
  'MISSION_COMPLETED',
];

export const problemRefusedStatus = [
  'CONTRACT_REFUSED',
  'ORDER_REFUSED',
  'CANCELLED',
];

export const waitingBuyerStatus = [
  'LEGAL_DOCUMENTS_RECEIVED',
  'CONTRACT_RECEIVED',
  'ORDER_WAITING',
  'ORDER_RECEIVED',
];

export const toDoStatus = [
  'CONTACT_INITIATED',
  'WAITING_FOR_LEGAL_DOCUMENTS',
  'CONTRACT_SENT',
  'ORDER_SENT',
];
