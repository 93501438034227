import {Component, OnInit} from '@angular/core';
import {ListContentComponent} from '../../../shared/components/list-content/list-content.component';
import {ActivatedRoute, Router} from '@angular/router';
import {PurchaseOrderModel} from '../../../shared/models/purchase-order/purchase-order.model';
import {ListContentModel} from '../../../shared/models/list-content.model';
import {ToastrService} from 'ngx-toastr';
import {NGXLogger} from 'ngx-logger';
import {PurchaseOrderService} from '../services/purchase-order-service';
import {FormBuilder} from '@angular/forms';
import {PurchaseOrderSharedService} from '../services/purchase-order-shared.service';
import {PurchaseOrderStatusEnum} from '../../../shared/enums/Purchase-order.status.enum';
import {PurchaseOrderViewComponent} from '../purchase-order-view/purchase-order-view.component';
import {query, transition, trigger, useAnimation} from '@angular/animations';
import {
  fadeInAnimation,
  slideIn50StaggerAnimation,
  slideInAnimation
} from '../../../shared/animation/common.animation';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'purchase-order-list',
  templateUrl: './purchase-order-list.component.html',
  styleUrls: ['./purchase-order-list.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        useAnimation(fadeInAnimation)
      ])
    ]),
    trigger('slideIn', [
      transition(':enter', [
        useAnimation(slideInAnimation)
      ])
    ]),
    trigger('slideInStagger', [
      transition(':enter', [
        query('.slide-in-animation', [
          useAnimation(slideIn50StaggerAnimation)
        ], {optional: true})
      ])
    ])
  ]
})
export class PurchaseOrderListComponent extends ListContentComponent implements OnInit {

  listContent: PurchaseOrderModel[] = [];
  status = Object.keys(PurchaseOrderStatusEnum);
  statusForFilter = Object.keys(PurchaseOrderStatusEnum).filter(x => x != PurchaseOrderStatusEnum.EXPIRED);

  listParamValidator = {
    page: RegExp('^[1-9][0-9]*$'),
    size: ['6', '9', '12'],
    search: RegExp('.{3,}'),
    purchaseOrderNumber: RegExp('.+'),
    sort: RegExp('^(purchaseOrderNumber|status|buyerName|requestNumber|createdDate)\,(asc|desc)$'),
    buyerName: RegExp('.+'),
    requestNumber: RegExp('.+'),
    status: RegExp(`^(${this.status.join('|')})(\,(${this.status.join('|')}))*$`),
  };

  constructor(public router: Router,
              public route: ActivatedRoute,
              private translateService: TranslateService,
              private toastrService: ToastrService,
              private fb: FormBuilder,
              private sharedPurchaseOrderService: PurchaseOrderSharedService,
              private purchaseOrderService: PurchaseOrderService,
              private logger: NGXLogger) {
    super(router, route);
    this.advancedSearchForm = this.fb.group({
      purchaseOrderNumber: [null],
      buyerName: [null],
      requestNumber: [null],
      status: [null]
    });
  }

  ngOnInit(): void {
    this.size = 6;
    this.sort = 'status';
    this.sortDirection = 'asc';

    super.ngOnInit();
    this.subscribeToQueryParam();
  }


  retrieveListContent(params): void {
    const page = params.page ? params.page - 1 : 0;
    const size = params.size ? params.size : 6;
    const search = params.search ? params.search : null;
    const sort = params.sort ? params.sort : 'status,asc';
    const advancedSearch = this.purchaseOrderService.mapAdvancedSearchForm(params);
    this.subscriptions.push(this.purchaseOrderService.getPurchaseOrdersByPage(page, size, sort, search, advancedSearch).subscribe(
      (res: ListContentModel) => {
        this.listContent = res.content;
        this.totalElements = res.totalElements;
        this.numberOfElements = res.numberOfElements;
        this.isListEmpty = !this.isSearchActive && res.empty;

        if (res.totalPages !== 0 && params.page > res.totalPages) {
          this.updateQueryParam({page: res.totalPages});
        }

        this.firstCallDone = true;
      },
      (error: any) => {
        this.logger.error(error.url, '- STATUS :', error.status);
        this.toastrService.error(this.translateService.instant('global.ts.error'));
      })
    );
  }

  getPurchaseOrderClass(contract: any): string {
    return PurchaseOrderViewComponent.getPurchaseOrderClass(contract);
  }

  clickOnRow(purchaseOrder: PurchaseOrderModel) {
    this.sharedPurchaseOrderService.setSelectedPurchaseOrder(purchaseOrder);
    this.router.navigate(['/purchase-orders/' + purchaseOrder.id + '/purchase-order']).then();
  }
}
