import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {ToastrService} from 'ngx-toastr';
import {MissionService} from '../../services/mission.service';
import {HistoryModel} from '../../../../shared/models/mission/history.model';
import {query, transition, trigger, useAnimation} from '@angular/animations';
import {slideIn50StaggerAnimation} from '../../../../shared/animation/common.animation';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'mission-history',
  templateUrl: './mission-history.component.html',
  styleUrls: ['./mission-history.component.scss'],
  animations: [
    trigger('slideIn', [
      transition(':enter', [
        query('.slide-in-animation', [
          useAnimation(slideIn50StaggerAnimation)
        ], {optional: true})
      ])
    ])
  ]
})
export class MissionHistoryComponent implements OnInit, OnDestroy {

  missionHistoryList: HistoryModel[];

  private subscriptions: Subscription[] = [];

  constructor(private missionService: MissionService,
              private route: ActivatedRoute,
              private router: Router,
              private logger: NGXLogger,
              private translateService: TranslateService,
              private toastrService: ToastrService) {
  }

  ngOnInit(): void {
    const missionId = this.route.parent.snapshot.paramMap.get('missionId');
    this.getMissionHistoryList(missionId);
  }

  private getMissionHistoryList(missionId: string): void {
    this.subscriptions.push(this.missionService.getHistoryListByMissionId(missionId).subscribe(
      (res: any) => {
        this.missionHistoryList = res;
      },
      (error: any) => {
        this.logger.error(error.url, '- STATUS :', error.status);
        this.toastrService.error(this.translateService.instant('global.ts.error'));
        this.router.navigate(['/mission']).then();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
