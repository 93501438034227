<div class="modal-body p-5">
  <div class="icon-warning {{blueStyle? 'blue-exclamation-mark': ''}}">
    <div class="icon-warning-content">!</div>
  </div>
  <ng-container *ngIf="commentAllow">
    <form [formGroup]="commentForm" (ngSubmit)="confirmAction()">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <h4 class="font-weight-bold mb-2">{{message}}</h4>
        <p class="text-dark-gray mt-2" *ngIf="additionalMessage">{{additionalMessage}}</p>

        <div class="form-group w-100 mt-3">
              <textarea class="form-control" cols="30" rows="3" formControlName="comment"
                        maxlength="255"
                        [placeholder]=commentPlaceholder>
              </textarea>
          <span class="float-right mr-1 text-dark-gray" *ngIf="this.commentForm.get('comment').value">
            {{this.commentForm.get('comment').value.length}}/{{commentMaxLength}}
          </span>
          <span class="float-right mr-1 text-dark-gray" *ngIf="!this.commentForm.get('comment').value">
            0/{{commentMaxLength}}
          </span>
          <app-form-feedback
            *ngIf="commentForm.get('comment').errors?.required && (commentForm.get('comment').dirty || commentForm.get('comment').touched)"
            message="This field is required">
          </app-form-feedback>
          <app-form-feedback
            *ngIf="commentForm.get('comment').errors?.maxlength && (commentForm.get('comment').dirty || commentForm.get('comment').touched)"
            message="This field accept a maximum length of {{commentMaxLength}} characters">
          </app-form-feedback>
          <app-form-feedback
            *ngIf="commentForm.get('comment').errors?.minlength && (commentForm.get('comment').dirty || commentForm.get('comment').touched)"
            message="This field accept a minimum length of {{commentMinLength}} characters">
          </app-form-feedback>
        </div>

        <div class="mt-2">
          <button class="btn btn-outline-warning px-5 mr-3" type="button" (click)="cancelAction()">{{'Modal_confirm.cancel'|translate}}</button>
          <button class="btn btn-primary px-5" type="submit" [disabled]="!commentForm.valid">{{'Modal_confirm.yes_i_confirm'|translate}}</button>
        </div>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="!commentAllow">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h4 class="font-weight-bold mb-2">{{message}}</h4>
      <p class="text-dark-gray mb-0" *ngIf="additionalMessage">{{additionalMessage}}</p>
      <div class="mt-4">
        <button class="btn btn-outline-warning px-5 mr-3 {{blueStyle? 'btn-outline-blue': ''}}" type="button" (click)="cancelAction()">{{'Modal_confirm.cancel'|translate}}</button>
        <button class="btn btn-primary px-5 {{blueStyle? 'button-blue': ''}}" type="button" (click)="confirmAction()">{{'Modal_confirm.yes_i_confirm'|translate}}</button>
      </div>
    </div>
  </ng-container>
</div>
