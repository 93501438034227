import {Component, OnDestroy} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NGXLogger} from 'ngx-logger';
import {Subscription} from 'rxjs';
import {AuthenticationService} from "../core/services/authentication.service";
import {TranslatorService} from "../core/services/translator.service";
import {regex} from "../shared/regex/form.regex";

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnDestroy {
  isSuccess: boolean;
  isError: boolean;
  forgetPasswordForm: FormGroup;

  // languages
  languages: string[];
  selectedLanguage: string;

  private subscriptions: Subscription[] = [];

  constructor(private fb: FormBuilder,
              private authService: AuthenticationService,
              private translatorService: TranslatorService,
              private logger: NGXLogger) {
    this.forgetPasswordForm = this.fb.group({
      email: [null, [Validators.required, Validators.pattern(regex.email), Validators.maxLength(100)]],
    });
    this.isSuccess = null;
    this.languages = this.translatorService.languages;
    this.selectedLanguage = this.translatorService.getLanguage();
    this.changeLang(this.selectedLanguage);
  }

  forgetPasswordFormSubmit(): void {
    this.subscriptions.push(this.authService.forgetPassword(this.forgetPasswordForm.value.email).subscribe(
      () => {
        this.isSuccess = true;
      }, error => {
        this.logger.error(error.url, '- STATUS :', error.status);
      })
    );
  }

  changeLang(event: any): void {
    this.translatorService.changeLang(event);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
