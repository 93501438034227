import {animate, animation, query, stagger, style, transition, trigger, useAnimation} from '@angular/animations';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {fadeInAnimation, slideIn50StaggerAnimation, slideInAnimation} from '../../../shared/animation/common.animation';
import {EmployeeModel} from '../../../shared/models/employee/employee.model';
import {EmployeeService} from '../services/employee.service';
import {ListContentComponent} from '../../../shared/components/list-content/list-content.component';
import {ListContentModel} from '../../../shared/models/list-content.model';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {AuthorityEnum} from '../../../shared/enums/AuthorityEnum';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {faPencil, faSearch, faUsers} from '@fortawesome/free-solid-svg-icons';
import {faAdd} from '@fortawesome/pro-duotone-svg-icons';
import {ButtonTypeEnum, IspColoursEnum} from '@i-supplier/angular-shared-module';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        useAnimation(fadeInAnimation)
      ])
    ]),
    trigger('slideIn', [
      transition(':enter', [
        useAnimation(slideInAnimation)
      ])
    ]),
    trigger('slideInStagger', [
      transition(':enter', [
        query('.slide-in-animation', [
          useAnimation(slideIn50StaggerAnimation)
        ], {optional: true})
      ])
    ]),
    trigger('listStagger', [
      transition('* <=> *', [
        query(
          ':enter',
          useAnimation(animation([
            style({opacity: 0, transform: 'translateY(20px)'}),
            stagger(
              '60ms',
              animate(
                '.2s ease-out',
                style({opacity: 1, transform: 'translateY(0px)'})
              )
            )
          ], null)),
          {optional: true}
        )
      ])
    ])
  ]
})
export class EmployeeListComponent extends ListContentComponent implements OnInit {
  protected readonly faSearch = faSearch;
  protected readonly faUsers = faUsers;
  protected readonly faPencil = faPencil;
  protected readonly IspColours = IspColoursEnum;
  protected readonly ButtonTypeEnum = ButtonTypeEnum;
  protected readonly faAdd = faAdd;
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private employeeService: EmployeeService,
    public authService: AuthenticationService,
    private fb: FormBuilder,
    private logger: NGXLogger,
    private translateService: TranslateService,
    private toastrService: ToastrService
  ) {
    super(router, route);

    this.advancedSearchForm = this.fb.group({
      firstName: [null],
      lastName: [null],
      email: [null],
      disabled: [null]
    });
    if (this.isSupplier()) {
      this.simpleSearchForm = this.fb.group({
        managing: ['true']
      });
      this.myProviders = true;
      this.subscribeToManagingChanges();
      this.updateQueryParam({managing: true});
    }
  }

  listContent: EmployeeModel[] = [];
  listParamValidator = {
    page: RegExp('^[1-9][0-9]*$'),
    size: ['5', '10', '20'],
    sort: RegExp('^(firstName|email|contact\.phones\.workPhoneNumber|authorities)\,(asc|desc)$'),
    search: RegExp('.{3,}'),
    firstName: RegExp('.+'),
    lastName: RegExp('.+'),
    email: RegExp('.+'),
    disabled: 'boolean',
    managing: 'boolean'
  };

  simpleSearchForm: FormGroup;
  private myProviders: boolean = false;

  private;

  ngOnInit(): void {
    super.ngOnInit();
    super.subscribeToQueryParam();
  }

  retrieveListContent(params: any): void {
    const page = params.page ? params.page - 1 : 0;
    const size = params.size ? params.size : 5;
    const sort = params.sort ? params.sort : 'roles.roleAdminOrder,firstName,asc';
    const search = params.search ? params.search : null;
    const advancedSearch = this.employeeService.mapAdvancedSearchForm(params);
    this.subscriptions.push(this.employeeService.getEmployeesByPage(page, size, sort, search, advancedSearch, this.isAdvancedSearchDisplayed, this.myProviders).subscribe(
      (res: ListContentModel) => {
        this.listContent = res.content;
        this.totalElements = res.totalElements;
        this.numberOfElements = res.numberOfElements;
        this.isListEmpty = !this.isSearchActive && res.empty;
        if (res.totalPages !== 0 && params.page > res.totalPages) {
          this.updateQueryParam({page: res.totalPages});
        }
        this.firstCallDone = true;
        this.retrieveEmployeeAvatars();
      },
      (error: any) => {
        this.logger.error(error.url, '- STATUS :', error);
        this.toastrService.error(this.translateService.instant('global.ts.error'));
      }
    ));
  }

  basicSearchCompany(value
                       :
                       string
  ):
    void {
    if (value.length >= 3
    ) {
      this.isSearchActive = true;
      this.updateQueryParam({page: 1, search: value});
    } else if (this.isSearchActive) {
      this.isSearchActive = false;
      if (this.isSupplier()) {
        this.updateQueryParam({page: 1, search: null, managing: this.myProviders});
      } else {
        this.updateQueryParam({page: 1, search: null});
      }
    }
  }

  isSupplier()
    :
    boolean {
    return this.authService.getRole() == AuthorityEnum.SUPPLIER;
  }

  retrieveEmployeeAvatars()
    :
    void {
    this.listContent.forEach(employee => {
      this.subscriptions.push(this.employeeService.getEmployeePictureByEmployeeId(employee.id).subscribe(
        (res: any) => {
          const reader = new FileReader();
          reader.addEventListener('load', () => {
            employee.avatar = reader.result as string;
          }, false);
          if (res) {
            reader.readAsDataURL(res);
          }
        },
        (error: any) => {
          this.logger.error(error.url, '- STATUS :', error.status);
        }
      ));
    });
  }

  private subscribeToManagingChanges() {
    this.subscriptions.push(
      this.simpleSearchForm.get('managing').valueChanges.subscribe(
        (value: string) => {
          this.myProviders = JSON.parse(value);
          this.updateQueryParam({page: 1, ...{managing: this.myProviders}});
        }
      )
    );
  }
}
