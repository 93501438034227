import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ProviderModel} from "../../../shared/models/mission/provider.model";
import {commonProperties} from '../../../../assets/environments/environment.common';
import {environment} from '../../../../assets/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  constructor(private httpClient: HttpClient) {
  }

  mapProviderFormValueToResource(formValue): ProviderModel {
    const providerMapped = new ProviderModel(formValue);

    if (formValue.employee) {
      providerMapped.employeeId = formValue.employee.id;
    }
    return providerMapped;
  }

  editProvider(requestId: string, provider: ProviderModel): Observable<any> {
    const url = environment.api_root + commonProperties.providerByRequestIdByProviderId.replace(':requestId', requestId).replace(':providerId', provider.id);
    return this.httpClient.patch(url, {employeeId: provider.employeeId});
  }


  uploadResume(requestId: string, providerId: string, file: File): Observable<any> {
    const url = environment.api_root + commonProperties.providerResume.replace(':requestId', requestId).replace(':providerId', providerId);
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('type', 'RESUME');
    return this.httpClient.post(url, formData);
  }

  downloadResume(requestId: string, providerId: string): Observable<Blob> {
    const url = environment.api_root + commonProperties.providerResume.replace(':requestId', requestId).replace(':providerId', providerId);
    return this.httpClient.get(url, {responseType: 'blob'});
  }
}
