import {ContractModeEnum} from '../../enums/CallForTenderOffers.enum';
import {RequestStatusEnum} from "../mission/mission-list-model";

export class OnCallModel {
  id: number;
  purchaseOrderNumber: string;
  requestId: number;
  projectName: string;
  buyerCompanyName: string;
  supplierCompanyName: string;
  workItemReference: string;
  workUnit: string;
  workUnitDescription: string;
  date: string;
  submissionDate: string;
  dailyRate: number;
  time: string;
  priceAmount: number;
  status: string;
  // sendDate: string
  comment: string;
  quantity: number;
  refusalComment: string;
  fileContent: string;
  fileName: string;
  rateType: string;
  currency: string;
  onCallConfigId: number;
}

export class OnCallEnvelopeModel {
  purchaseOrderId: string;
  purchaseOrderNumber: string;
  requestId: number;
  requestStatus: RequestStatusEnum;
  contractMode: ContractModeEnum;
  buyerTenantId: string;
  buyerCompanyName: string;
  supplierTenantId: number;
  supplierEmployeeId: number;
  onCall: number;
  onCallUsed: number;
  onCallAvailable: number;
  projectId: number;
  projectManagerId: number;
  projectName: string;
  supplierCompanyName: string;
  dailyRate: number;
  dailyRateByDate: boolean;
  startDate: string;
  endDate: string;
}

export class OnCallPostBody {
  buyerTenantId: string;
  comment: string;
  dailyRate: number;
  date: string;
  fileContent: string;
  fileName: string;
  onCallConfigId: string;
  purchaseOrderNumber: number;
  quantity: number;
  time: string;
  workItemId: number;
  type: string;
  isPeriodCheck: boolean;
  datesRange: Date[];
  startDateRange: Date;
  endDateRange: Date;
}

export class OnCallParamModel {
  id: string;
  createdBy: string;
  createdDate: string;
  modifiedBy: string;
  modifiedDate: string;
  name: string;
  workItems: WorkItemModel[];
}

export class WorkItemModel {
  id: number;
  description: string;
  hourDay: number;
  billable: string;
  recoverable: string;
  reference: string;
  value: number;
  workUnit: string;
}

