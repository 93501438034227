<div class="animated fadeIn mb-5" *ngIf="this.activity">
  <div class="row">
    <div class="col-md-12">
      <div class="header">{{"proforma_generation.title"|translate}}</div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-9">
      <div class="pdf-viewer-container box-shadow-hover">
        <app-spinner *ngIf="loading" [display]="loading"></app-spinner>
        <div class="pdf-view">
          <app-pdf-preview
            *ngIf="proformaFile; else noPreview"
            [srcPdf]="proformaFile"
            [pdfZoom]="'page-height'"
          ></app-pdf-preview>
        </div>

        <ng-template #noPreview>
          <div *ngIf="!loading" class="invoice-image">
            <img src="assets/img/proforma/invoice.svg" alt="Document Upload"/>
          </div>
        </ng-template>
      </div>
    </div>

    <div class="col-md-3">
      <!--      Generate Proforma-->
      <div
        *ngIf="!proformaFile"
        class="generate-proforma box-shadow-hover text-align-center"
      >
        <div class="box-header">{{"proforma_generation.section"|translate}}</div>

        <form
          *ngIf="invoiceProformaGenerationForm"
          [formGroup]="invoiceProformaGenerationForm"
          (ngSubmit)="invoiceProformaGenerationFormSubmit()"
        >

          <div class="mlr-auto mt-2 wid-90">
            <mat-form-field class="wid-100 text-align-center">
              <mat-label>{{"proforma_generation.edit.invoice_number"|translate}}
              </mat-label>
              <input
                matInput
                formControlName="invoiceNumber"
                placeholder='{{"proforma_generation.edit.invoice_number_placeholder"|translate}}'
                required
              />
              <button
                type="button"
                mat-button
                matSuffix
                mat-icon-button
                aria-label="Clear"
                *ngIf="
                  canDisplay(invoiceProformaGenerationForm, 'invoiceNumber')
                "
                (click)="
                  clearInputField(
                    invoiceProformaGenerationForm,
                    'invoiceNumber'
                  )
                "
              >
                <fa-icon [icon]="faTimes"></fa-icon>
              </button>

              <mat-error
                *ngIf="invoiceProformaGenerationForm.get('invoiceNumber').errors?.required"
                [innerHTML]="'proforma_generation.feedback.required_field' | translate"
              >
              </mat-error>

              <mat-error
                *ngIf="invoiceProformaGenerationForm.get('invoiceNumber').errors?.pattern"
                [innerHTML]="'proforma_generation.feedback.noLeadingTrailingSpacesPattern' | translate"
              >
              </mat-error>

            </mat-form-field>
          </div>

          <div class="generate-proforma-wish">
            {{"proforma_generation.edit.due_payment"|translate}}
            <span class="dotted-spaced">
              <input
                min="1"
                class="generate-proforma-input-advance"
                type="text"
                formControlName="advancePaymentInDays"
              />
              *
            </span>
            {{"proforma_generation.edit.due_payment_2"|translate}}
          </div>

          <mat-error
            *ngIf="
              invoiceProformaGenerationForm.get('advancePaymentInDays').dirty &&
              (invoiceProformaGenerationForm.get('advancePaymentInDays').errors
                ?.min ||
                invoiceProformaGenerationForm.get('advancePaymentInDays').errors
                  ?.max)
            "
          >
            {{"proforma_generation.feedback.value_out_of_range"|translate: {maxPayment: this.supplierInvoiceConfig.billableUnder} }}
          </mat-error>

          <mat-error
            *ngIf="invoiceProformaGenerationForm.get('advancePaymentInDays').errors?.required" [innerHTML]="'proforma_generation.feedback.required_field' | translate"
          >
          </mat-error>

          <mat-error
            *ngIf="invoiceProformaGenerationForm.get('advancePaymentInDays').errors?.pattern" [innerHTML]="'proforma_generation.feedback.pattern_number' | translate"
          >
          </mat-error>


          <div class="alert-info" *ngIf="!invoiceProformaGenerationForm.invalid && advancePaymentInDaysValueChanged">
            {{
            "Invoice.advance_payment_discount"
              | translate: {discountPercentage: this.advancePaymentDiscount}
            }}
            <a
              matTooltip='{{ "proforma_generation.reset_btn" | translate }}'
              (click)="resetAdvancePayment()"
            >
              <strong>{{ "Call_for_tender.reset_btn" | translate }}</strong>
            </a>
          </div>

          <button
            class="btn generate-proforma-button"
            type="submit"
            [disabled]="
              invoiceProformaGenerationForm.invalid || proformaFile != null
            "
          >
            {{ "proforma_generation.button_generate" | translate }}
          </button>
        </form>
      </div>

      <div *ngIf="proformaFile">
        <form
          *ngIf="invoiceTransmitForm"
          [formGroup]="invoiceTransmitForm"
          (ngSubmit)="invoiceTransmitFormSubmit()"
        >
          <!--      Submit Invoice-->
          <ng-container *ngIf="!manualInvoiceFileToUpload">
            <div class="submit-buttons">
              <div>
                <button
                  type="submit"
                  class="btn validate-invoice-button btn-block"
                  style="border: 2px solid #2ecc71; background: #2ecc71"
                  [disabled]="performingValidation"
                >
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="performingValidation"></span>
                  {{ "proforma_generation.button_validate" | translate }}
                </button>
              </div>

              <div class="upload-buttons-container">
                <Button
                  *ngIf="!this.advancePaymentInDaysValueChanged"
                  class="btn upload-button"
                  type="button"
                  (click)="openModal()"
                >
                  {{ "proforma_generation.button_upload" | translate }}
                </Button>
                <Button
                  class="btn back-button"
                  (click)="cancelProforma()"
                  type="button"
                >{{ "proforma_generation.button_go_back" | translate }}
                </Button
                >
              </div>
            </div>
          </ng-container>

          <!--      Manual Invoice Form-->
          <ng-container *ngIf="manualInvoiceFileToUpload">
            <div class="manual-form-wrapper box-shadow-hover">
              <div class="box-header ml-3">
                {{ "proforma_generation.label_details_manual_invoice" | translate }}
              </div>

              <div class="mlr-auto mt-2 wid-90">
                <label class="mb-1 float-left" for="date-input">{{'proforma_generation.edit.invoice_date' | translate}}</label>
                <input type="text" class="form-control" id="date-input" bsDatepicker readonly formControlName="invoiceDate"
                       placeholder="{{'proforma_generation.edit.select_date' | translate}}"
                       [bsConfig]="{
                       minDate: isDuePayment() ? null : accountingMonth,
                       maxDate:currentDate,
                       dateInputFormat:'DD/MM/YYYY',
                       showPreviousMonth:false,
                       containerClass:'theme-blue'}">
              </div>

              <div class="mlr-auto mt-2 wid-90">
                <mat-form-field class="wid-100 text-align-center">
                  <mat-label>{{ "proforma_generation.edit.tax_amount" | translate }}</mat-label>
                  <input
                    type="number"
                    matInput
                    min="0"
                    formControlName="taxAmount"
                    placeholder='{{ "proforma_generation.edit.tax_amount_placeholder" | translate }}'
                    required
                  />
                  <button
                    mat-button
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    *ngIf="canDisplay(invoiceTransmitForm, 'taxAmount')"
                    (click)="clearInputField(invoiceTransmitForm, 'taxAmount')"
                  >
                    <fa-icon [icon]="faTimes"></fa-icon>
                  </button>
                  <mat-error
                    *ngIf="
                      invoiceTransmitForm.get('taxAmount').errors?.required
                    " [innerHTML]="'proforma_generation.feedback.required_field' | translate">
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="mlr-auto mt-2 wid-90">
                <mat-form-field class="wid-100 text-align-center">
                  <mat-label>{{ "proforma_generation.edit.taxless_total_amount" | translate }}</mat-label>
                  <input
                    type="number"
                    matInput
                    min="0"
                    formControlName="taxlessTotal"
                    placeholder='{{ "proforma_generation.edit.taxless_total_amount_placeholder" | translate }}'
                    required
                  />
                  <button
                    mat-button
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    *ngIf="canDisplay(invoiceTransmitForm, 'taxlessTotal')"
                    (click)="
                      clearInputField(invoiceTransmitForm, 'taxlessTotal')
                    "
                  >
                    <fa-icon [icon]="faTimes"></fa-icon>
                  </button>
                  <mat-error
                    *ngIf="
                      invoiceTransmitForm.get('taxlessTotal').errors?.required
                    " [innerHTML]="'proforma_generation.feedback.required_field' | translate">
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="mlr-auto mt-2 wid-90">
                <mat-form-field class="wid-100 text-align-center">
                  <mat-label>{{ "proforma_generation.edit.total_incl_tax" | translate }}</mat-label>
                  <input
                    type="number"
                    matInput
                    min="0"
                    formControlName="totalIncludingTax"
                    placeholder='{{ "proforma_generation.edit.total_incl_tax_placeholder" | translate }}'
                    required
                  />
                  <button
                    mat-button
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    *ngIf="canDisplay(invoiceTransmitForm, 'totalIncludingTax')"
                    (click)="
                      clearInputField(invoiceTransmitForm, 'totalIncludingTax')
                    "
                  >
                    <fa-icon [icon]="faTimes"></fa-icon>
                  </button>
                  <mat-error
                    *ngIf="
                      invoiceTransmitForm.get('totalIncludingTax').errors
                        ?.required
                    " [innerHTML]="'proforma_generation.feedback.required_field' | translate">
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="d-flex justify-content-center pb-3 pt-1">
                <button
                  [disabled]="!invoiceTransmitForm.valid || performingValidation"
                  type="submit"
                  class="btn validate-invoice-button wid-90"
                  style="border: 2px solid #2ecc71; background: #2ecc71"
                >
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="performingValidation"></span>
                  {{ "proforma_generation.button_validate" | translate }}
                </button>
              </div>
            </div>

            <div>
              <Button
                class="btn btn-outline-info wid-100 my-3"
                style="border-radius: 4px"
                type="button"
                (click)="clearFile()"
              >
                {{ "proforma_generation.button_go_back" | translate }}
              </Button>
            </div>
          </ng-container>
        </form>
      </div>

      <!--      Invoice Info-->
      <div class="invoice-info box-shadow-hover">
        <div class="box-header text-align-center">
          {{ "proforma_generation.label_invoice_info" | translate }}
        </div>

        <ng-container *ngIf="isActivitySheet()">
          <hr/>
          <div class="invoice-info-content">
            <div class="ellipse-icon d-xl-block d-md-none d-block" style="background-color: #3498db33">
              <mat-icon class="mat-icon-list" style="color: #1192d8">
                calendar_month
              </mat-icon>
            </div>
            <div>
              <div
                class="invoice-info-content-header">{{ "proforma_generation.label_invoice_accounting_month" | translate }}</div>
              <div class="invoice-info-content-subheader">
                {{ activity.accountingMonth | date: "MMM yyyy" }}
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container>
          <hr/>
          <div class="invoice-info-content">
            <div class="ellipse-icon d-xl-block d-md-none d-block" style="background-color: #27e47233">
              <mat-icon class="mat-icon-list" style="color: #00e774">
                engineering
              </mat-icon>
            </div>
            <div>
              <div
                class="invoice-info-content-header">{{ "proforma_generation.label_invoice_project" | translate }}</div>
              <div class="invoice-info-content-subheader">
                {{ activity.projectName }}
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container>
          <hr/>
          <div class="invoice-info-content">
            <div class="ellipse-icon d-xl-block d-md-none d-block" style="background-color: #f1c40f33">
              <mat-icon class="mat-icon-list" style="color: #E7B505BF">
                summarize
              </mat-icon>
            </div>
            <div>
              <div
                class="invoice-info-content-header">{{ "proforma_generation.label_invoice_purchase_order" | translate }}</div>
              <div class="invoice-info-content-subheader">
                {{ activity.purchaseOrderNumber }}
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container>
          <hr/>
          <div class="invoice-info-content">
            <div class="ellipse-icon d-xl-block d-md-none d-block" style="background-color: #9b59b633">
              <mat-icon class="mat-icon-list" style="color: #9B59B6">
                person
              </mat-icon>
            </div>
            <div>
              <div
                class="invoice-info-content-header">{{ "proforma_generation.label_invoice_client" | translate }}</div>
              <div class="invoice-info-content-subheader">
                {{ activity.buyerName }}
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="isActivitySheet()">
          <hr/>
          <div class="invoice-info-content">
            <div class="ellipse-icon d-xl-block d-md-none d-block" style="background-color: #504E4B33">
              <mat-icon class="mat-icon-list" style="color: #343a40">
                badge
              </mat-icon>
            </div>
            <div>
              <div
                class="invoice-info-content-header">{{ "proforma_generation.label_invoice_employee" | translate }}</div>
              <div class="invoice-info-content-subheader">
                {{ activity.employeeName }}
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="isActivitySheet()">
          <hr/>
          <div class="invoice-info-content">
            <div class="ellipse-icon d-xl-block d-md-none d-block" style="background-color: #d3fae3">
              <mat-icon class="mat-icon-list" style="color: #2ECC71">
                scale
              </mat-icon>
            </div>
            <div>
              <div
                class="invoice-info-content-header">{{ "proforma_generation.label_invoice_quantity" | translate }}</div>
              <div class="invoice-info-content-subheader">
                {{ activity.quantity }}
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="isDuePaymentByDate()">
          <hr/>
          <div class="invoice-info-content">
            <div class="ellipse-icon d-xl-block d-md-none d-block" style="background-color: #b70d0e6f">
              <mat-icon class="mat-icon-list" style="color: #970203">
                timelapse
              </mat-icon>
            </div>
            <div>
              <div
                class="invoice-info-content-header">{{ "proforma_generation.label_invoice_due_for" | translate }}</div>
              <div class="invoice-info-content-subheader">
                {{ activity.description }}
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="isDuePaymentByEvent()">
          <hr/>
          <div class="invoice-info-content">
            <div class="ellipse-icon d-xl-block d-md-none d-block" style="background-color: #b70d0e6f">
              <mat-icon class="mat-icon-list" style="color: #970203">
                event
              </mat-icon>
            </div>
            <div>
              <div
                class="invoice-info-content-header">{{ "proforma_generation.label_invoice_due_on" | translate }}</div>
              <div class="invoice-info-content-subheader">
                {{ activity.description }}
              </div>
            </div>
          </div>
        </ng-container>

      </div>
    </div>
  </div>
</div>
