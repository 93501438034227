import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
import {CallForTenderModel} from '../../../shared/models/call-for-tender/call-for-tender.model';
import * as moment from 'moment';
import {CategoryModel} from '../../../shared/models/call-for-tender/category.model';
import {WorkPlaceModel} from '../../../shared/models/call-for-tender/work-place.model';
import {LeagueMinimalModel} from '../../../shared/models/call-for-tender/league-minimal.model';
import {
  CallForTenderListAdvancedSearchModel
} from '../../../shared/models/call-for-tender/call-for-tender-list-advanced-search.model';
import {combineLatest, forkJoin, Observable, of, switchMap} from 'rxjs';
import {ListContentModel} from '../../../shared/models/list-content.model';
import {ContractModeEnum} from '../../../shared/enums/CallForTenderOffers.enum';
import {commonProperties} from '../../../../assets/environments/environment.common';
import {environment} from '../../../../assets/environments/environment';
import {CFTAttachmentModel} from '../../../shared/models/call-for-tender/call-for-tender-attachment.model';
import {TranslatorService} from '../../../core/services/translator.service';
import {ActivityAreasService} from '../../company-config/services/activity-areas.service';
import {CallForTenderListItemModel} from '../../../shared/models/call-for-tender/call-for-tender-list-item.model';
import {map} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class CallForTenderService {

  constructor(private httpClient: HttpClient, private translatorService: TranslatorService, private activityAreasService: ActivityAreasService) {}

  mapAdvancedSearchForm(params: any): CallForTenderListAdvancedSearchModel {
    return new CallForTenderListAdvancedSearchModel(params);
  }

  getCallForTenderList(page: number, sort: string, advancedSearch: CallForTenderListAdvancedSearchModel): Observable<ListContentModel> {
    let params = new HttpParams();
    if (advancedSearch) {
      if (advancedSearch.reference) {
        params = params.set('reference', advancedSearch.reference);
      }
      if (advancedSearch.title) {
        params = params.set('title', advancedSearch.title);
      }
      if (advancedSearch.buyer) {
        params = params.set('buyer', advancedSearch.buyer);
      }
    }

    params = params.set('size', '10').set('page', page.toString()).set('sort', sort);
    return this.httpClient.get<ListContentModel>(environment.api_root + commonProperties.callsForTender, {params});
  }

  getActivityAreaCallForTenderList(page: number, sortOrder: string, sortField: string, advancedSearch: CallForTenderListAdvancedSearchModel): Observable<ListContentModel> {
    let params = new HttpParams()
      .set('pageSize', '10')
      .set('pageNumber', page.toString())
      .set('sortOrder', sortOrder)
      .set('sortField', sortField);
    if (advancedSearch.activityArea) {params = params.set('activity-area', advancedSearch.activityArea); }
    if (advancedSearch.buyer) {params = params.set('buyer', advancedSearch.buyer); }
    if (advancedSearch.title) {params = params.set('title', advancedSearch.title); }
    if (advancedSearch.reference) {params = params.set('reference', advancedSearch.reference); }

    const options = {
      headers: new HttpHeaders().set('Accept-Language', this.translatorService.getLanguage()),
      params
    };
    return this.httpClient.get<ListContentModel>(environment.api_root + commonProperties.activityAreasMarketPlaceSearch, options).pipe(
      switchMap(res => {
        if (res.content.length === 0) {
          return of(res);
        }
        const observables = res.content.map(cft => {
          const callForTenderObs = this.getCallForTenderById(cft.id);

          return callForTenderObs.pipe(map(data => {
            // Find the corresponding activity area in res.content
            const activityArea = res.content.find(item => item.id === cft.id)?.activityAreas;

            // Assign activityArea directly
            data.activityArea = activityArea[0];
            return data;
          }));
        });

        return combineLatest(observables).pipe(map((dataArray: CallForTenderListItemModel[]) => {
          dataArray.forEach((data, index) => {
            res.content[index] = data;
          });
          return res;
        }));
      })
    );
  }

  getCategoryList(): Observable<CategoryModel[]> {
    return this.httpClient.get<CategoryModel[]>(environment.api_root + commonProperties.categoryList);
  }

  getCallForTenderById(callForTenderId: string): Observable<CallForTenderModel> {
    return this.httpClient.get<CallForTenderModel>(environment.api_root + commonProperties.callsForTenderById.replace(':callForTenderId', callForTenderId));
  }

  downloadFile(callForTenderId: string) {
    const headers = new HttpHeaders({
      'Accept': 'application/zip',
      'Content-Type': 'application/json'
    });

    return this.httpClient.get(environment.api_root + commonProperties.callsForTenderFile.replace(':callForTenderId', callForTenderId), {
      headers,
      responseType: 'blob'
    });
  }

  markCallForTenderHasViewed(callForTenderId: string): Observable<void> {
    return this.httpClient.post<void>(environment.api_root + commonProperties.callsForTenderView.replace(':callForTenderId', callForTenderId), null);
  }


  checkFreelanceOffer(callForTenderId: string, freelanceId: string): Observable<any> {
    return this.httpClient.post<any>(environment.api_root + commonProperties.getOfferFreelance.replace(':callForTenderId', callForTenderId).replace(':freelanceId', freelanceId), null);
  }

  getCountries() {
    return this.httpClient.get(environment.api_root + commonProperties.countries);
  }

  getCompanyById(companyId: string) {
    return this.httpClient.get(environment.api_root + commonProperties.myCompany.replace(':companyId', companyId));
  }

  getCFTAttachmentsByCFTId(callForTenderId: string): Observable<CFTAttachmentModel[]> {
    return this.httpClient.get<any>(environment.api_root + commonProperties.callsForTenderAttachments.replace(':callForTenderId', callForTenderId));
  }
}
