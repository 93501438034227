<ng-select
  *ngIf="initialActivityAreas && initialActivityAreas.length > 0"
  [items]="activityAreas"
  (search)="searchActivityArea($event.term, parentActivityArea)"
  (change)="change.emit($event)"
  [multiple]="config.multiple"
  [closeOnSelect]="config.closeOnSelect"
  [searchable]="true"
  [(ngModel)]="selectedActivityAreas"
  [placeholder]="placeholder | translate"
  class="ng-select-custom"
>
  <ng-template ng-notfound-tmp>
    <div class="ng-option ng-option-disabled ng-star-inserted">
      {{'activity-area.search.not-found'|translate}}
    </div>
  </ng-template>
</ng-select>
<div>
  <i class="text-dark-gray" *ngIf="initialActivityAreas && initialActivityAreas.length === 0">
    {{'activity-area.no-specificity'|translate}}
  </i>
</div>
