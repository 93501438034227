export class CreditNoteAdvancedSearchModel {
  number: string;
  invoiceNumber: string;
  purchaseOrderNumber: string;
  buyerName: string;
  status: string;
  type: string;
  mode: string;

  constructor(object?: any) {
    if (object) {
      this.number = object.number;
      this.purchaseOrderNumber = object.purchaseOrderNumber;
      this.status = object.status;
      this.type = object.type;
      this.mode = object.mode;
    }
  }
}
