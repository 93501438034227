<div class="addition-documents-download-box box-shadow-hover">
  <div class="px-4 py-4">
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <div class="mr-4">
          <img src="assets/img/icon/document.svg" alt="document"/>
        </div>

        <div
          class="download-text">{{"Mission_view.additional_document.text_download_document"|translate}}</div>
      </div>

      <div>
        <button
          type="button"
          class="btn button-blue px-5"
          (click)="downloadAttachment('ADDITIONAL_DOCUMENT')"
        >
          <i class="fa fa-download mr-3" aria-hidden="true"></i>
          {{"Mission_view.additional_document.button_download"|translate}}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="addition-documents-uploaded-box mt-4 box-shadow-hover"
  *ngIf="fileUploaded"
>
  <div class="px-4 py-4">
    <div class="d-flex justify-content-between">
      <div class="d-flex flex-row">
        <div class="additional-documents-status">
          {{ "Legal_documents.status" | translate }} :
        </div>
        <div
          class="badge additional-documents-status-box"
          [ngClass]="{
            'color-expired': additionalDocumentAttachment.status === 'EXPIRED',
            'color-submitted': additionalDocumentAttachment.status === 'SENT',
            'color-refused': additionalDocumentAttachment.status === 'REFUSED',
            'color-validated':
              additionalDocumentAttachment.status === 'VALIDATED'
          }"
        >
          {{ additionalDocumentAttachment.status }}
        </div>
      </div>

      <div>
        <button
          *ngIf="additionalDocumentAttachment.status === 'REFUSED'"
          type="button"
          class="btn button-darkblue px-5"
          (click)="uploadNewDocument()"
        >
          {{"Mission_view.additional_document.button_cancel"|translate}}
        </button>

        <button
          type="button"
          class="btn button-blue {{
            additionalDocumentAttachment.status === 'REFUSED' && 'px-5'
          }}"
          (click)="downloadAttachment('ATTACHMENT')"
        >
          <i class="fa fa-download mr-3" aria-hidden="true"></i>
          {{"Mission_view.additional_document.button_download_attachment"|translate}}
        </button>
      </div>
    </div>

    <ng-container *ngIf="additionalDocumentAttachment.status === 'REFUSED'">
      <!--    <ng-container>-->
      <div class="additional-documents-reason color-refused">
        <span
        ><strong>{{ "Additional_documents.reason" | translate }} : </strong
        >{{ additionalDocumentAttachment.reason }}</span
        >
      </div>
    </ng-container>
  </div>
</div>

<div class="mt-4" *ngIf="!fileUploaded">
  <file-drop2
    (fileAction)="getFile($event)"
  ></file-drop2>
</div>
