<div class="box box-shadow-hover">
  <div class="mx-4 py-4">
    <div class="d-flex">
      <div class="missions-header-border mr-2"></div>
      <div class="missions-header">{{'Mission_view.supplier.Supplier_title'|translate}}</div>
    </div>

    <div class="d-flex mt-3">
      <div class="mr-2">
        <ngx-avatars
          [name]="supplier.pointOfContact.firstName +' '+ supplier.pointOfContact.lastName"
          bgColor="#A83C1B88"
          fgColor="#fff"
          size="50" textSizeRatio="2"
          initialsSize="2">
        </ngx-avatars>
      </div>

      <div class="d-flex flex-column">
        <div class="mission-details-name">
          {{ supplier.pointOfContact.firstName }}
          {{ supplier.pointOfContact.lastName }}
        </div>
        <div class="mission-details-email">
          {{ supplier.pointOfContact.email }}
        </div>
        <div class="mission-details-number">
          {{ supplier.pointOfContact.phoneNumber }}
        </div>
      </div>
    </div>
  </div>
</div>
