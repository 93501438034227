export const commonProperties = {
  token: 'access_token',
  tokenExpiration: 'access_token_expiration',
  login: 'authMgtApi/login',
  logout: 'authMgtApi/logout',
  tokenValidation: 'authMgtApi/token',
  refreshToken: 'authMgtApi/refresh-token',
  activateAccount: 'authMgtApi/activate-account',
  forgetPassword: 'authMgtApi/forget-password',
  resetPassword: 'authMgtApi/reset-password',
  userEmailTest: 'authMgtApi/status',
  companyCreation: 'enterpriseMgtApi/companies',
  myCompany: 'enterpriseMgtApi/companies/:companyId',
  authoritiesByCompanyType: 'authMgtApi/authorities',
  lang: 'authMgtApi/users/:employeeId/language',
  changePassword: 'authMgtApi/change-password',
  userById: 'authMgtApi/issuers/:userId',

  cgu: 'authMgtApi/cgu',
  cguUser: 'authMgtApi/users/cgu',


  suppliersList: 'enterpriseMgtApi/companies',
  supplierById: 'enterpriseMgtApi/companies/:supplierId',
  legalForms: 'enterpriseMgtApi/companies/legal-forms',

  // Employee
  employeesList: 'enterpriseMgtApi/employees',
  authorities: 'authMgtApi/authorities',
  employeeById: 'enterpriseMgtApi/employees/:employeeId',
  accountByEmployeeId: 'authMgtApi/users',
  employeePictureById: 'enterpriseMgtApi/employees/:employeeId/picture',
  myPicture: 'enterpriseMgtApi/employees/:employeeId/picture',
  employeeRoleById: 'authMgtApi/users/:employeeId',
  myProfile: 'enterpriseMgtApi/employees/:employeeId',
  updateMyProfile: 'authMgtApi/users/:employeeId',
  projectManagers: 'enterpriseMgtApi/employees',
  employeeSuppliersById: 'enterpriseMgtApi/employees/:employeeId/suppliers',
  billingAddress: 'enterpriseMgtApi/companies/:companyId/addresses',
  billingAddressId: 'enterpriseMgtApi/companies/:companyId/addresses/:id',

  // Countries
  countries: 'static/countries.json',

  // Legal document
  legalDocument: 'legalDocumentsMgtApi/legal-documents',
  legalDocumentConfiguration: 'legalDocumentsMgtApi/legal-documents/configuration',
  legalDocumentByType: 'legalDocumentsMgtApi/legal-documents/:type',

  // Marketplace
  callsForTender: 'marketplaceMgtApi/callsForTender',
  categoryList: 'marketplaceMgtApi/categories',
  callsForTenderById: 'marketplaceMgtApi/callsForTender/:callForTenderId',
  callsForTenderActions: 'marketplaceMgtApi/callsForTender/:callForTenderId/actions',
  createOffer: 'marketplaceMgtApi/offers',
  callForTenderOffers: 'marketplaceMgtApi/offers',
  callForTenderOfferById: 'marketplaceMgtApi/offers/:offerId',
  callForTenderOfferResume: 'marketplaceMgtApi/offers/:offerId/attachment',
  callsForTenderAttachments: 'marketplaceMgtApi/callsForTender/:callForTenderId/attachments',
  callsForTenderOfferActions: 'marketplaceMgtApi/offers/:callForTenderOfferId/meetings',
  callsForTenderFile: 'marketplaceMgtApi/callsForTender/:callForTenderId/attachment',
  callsForTenderView: 'marketplaceMgtApi/callsForTender/view/:callForTenderId',
  getOfferFreelance: 'marketplaceMgtApi/offers/:callForTenderId?freelanceId=:freelanceId',
  updateOfferNegotiation: 'marketplaceMgtApi/offers/:callForTenderOfferId/negotiation',

  additionalDocumentsByMissionId: 'requestMgtApi/requests/:missionId/additional-documents',
  postAndGetAdditionalDocumentsByMissionId: 'requestMgtApi/requests/:missionId/additional-documents/:additionalDocId/attachment',
  requestClientStepById: 'requestMgtApi/requests/clientStep/:requestId',
  missionHistoryStatus: 'requestMgtApi/requests/:missionId/history',
  missionSupplierStepById: 'requestMgtApi/requests/supplierStep/:missionId',
  mission: 'requestMgtApi/requests',
  duePayments: 'requestMgtApi/due-payments',
  duePaymentsById: 'requestMgtApi/due-payments/:duePaymentId',

  billingConfigById: 'enterpriseMgtApi/billing-params/:billingParamId',

  providerResume: 'requestMgtApi/requests/:requestId/providers/:providerId/resume',
  providerByRequestIdByProviderId: 'requestMgtApi/requests/:requestId/providers/:providerId',

  // contract
  contractList: 'contractMgtApi/contracts',
  contractFile: 'contractMgtApi/contracts/:contractId/download',
  contractSignedById: 'contractMgtApi/contracts/:contractId/signed',
  contractAction: 'contractMgtApi/contracts/:contractId/actions',
  contractsWaiting: 'contractMgtApi/kpis/contract-waiting',
  contractVersions: 'contractMgtApi/contract-versions',
  templateContractVersionsGenerateSignatureLinks: 'contractMgtApi/contract-versions/:contractVersionId/generate-signature-links',
  refuseFrameworkAgreement: 'contractMgtApi/contracts/:contractId/refuse',

  // purchase order
  purchaseOrdersList: 'purchaseOrderMgtApi/purchase-orders',
  purchaseOrderById: 'purchaseOrderMgtApi/purchase-orders/:purchaseOrderId',
  purchaseOrderActions: 'purchaseOrderMgtApi/purchase-orders/:purchaseOrderId/actions',
  purchaseOrderSignedById: 'purchaseOrderMgtApi/purchase-orders/:purchaseOrderId/signed',
  purchaseOrderFileById: 'purchaseOrderMgtApi/purchase-orders/:purchaseOrderId/file',
  purchaseOrdersWaiting: 'purchaseOrderMgtApi/kpis/purchase-order-waiting',

  // activity-report
  activitySheet: 'activityReportMgtApi/activitysheet',
  activitySheetById: 'activityReportMgtApi/activitysheet/:id',
  activitySheetAccountingMonthSummary: 'activityReportMgtApi/activitysheet/accountingMonthSummary',
  activitySheetPendingInvoices: 'activityReportMgtApi/kpis/uninvoiced',


  activitySheetPostEntry: 'activityReportMgtApi/activitysheet/:id/entry',
  activitySheetPostEntries: 'activityReportMgtApi/activitysheet/:id/entries',
  // patch and delete
  activitySheetEntry: 'activityReportMgtApi/activitysheet/:id/entry/:entryId',
  activitySheetEntries: 'activityReportMgtApi/activitysheet/:id/entries',
  activitySheetGetFile: 'activityReportMgtApi/activitysheet/:id/getfile',
  activitySheetUploadFile: 'activityReportMgtApi/activitysheet/:id/uploadfile',
  activitySheetDeleteFile: 'activityReportMgtApi/activitysheet/:id/deletefile',

  // fees
  feesList: 'activityReportMgtApi/fees',
  feesGrouped: 'activityReportMgtApi/fees/groups',
  feeById: 'activityReportMgtApi/fees/:feeId',
  feeFileById: 'activityReportMgtApi/fees/:feeId/file',
  missionEnvelopeList: 'activityReportMgtApi/request-envelope?activityType=:activityType',

  // on-call
  activityReportOnCalls: 'activityReportMgtApi/on-calls',
  onCallsGrouped: 'activityReportMgtApi/on-calls/groups',
  activityReportOnCallById: 'activityReportMgtApi/on-calls/:id',

  onCallsEnvelope: 'activityReportMgtApi/request-envelope?activityType=:activityType',
  onCallFileById: 'activityReportMgtApi/on-calls/:onCallId/file',

  onCallParams: 'enterpriseMgtApi/on-call-params',
  onCallParamById: 'enterpriseMgtApi/on-call-params/:id',

  purchaseOrderContract: 'purchaseOrderMgtApi/purchase-orders/:purchaseOrderId/contract',

  // invoices
  invoices: 'invoiceMgtApi/supplier-invoices',
  supplierInvoiceConfig: 'invoiceMgtApi/supplier-invoices/invoice-config',
  invoicePatch: 'invoiceMgtApi/supplier-invoices/:id',
  invoiceById: 'invoiceMgtApi/supplier-invoices/:id',
  invoiceFile: 'invoiceMgtApi/supplier-invoices/:id/file',
  invoiceDiscount: 'invoiceMgtApi/discount-business-plans/getDiscountPercent?days=:advanceDays',
  billableCompanies: 'invoiceMgtApi/invoice-configs/billable-companies',

  creditNotes: 'invoiceMgtApi/credit-notes',
  creditNoteById: 'invoiceMgtApi/credit-notes/:id',
  creditNoteFile: 'invoiceMgtApi/credit-notes/:id/file',
  cancelCreditNoteRequestSupplier: 'invoiceMgtApi/supplier-invoices/cancelCreditNoteRequest/:invoiceId',

  // ActivityArea
  activityAreasByCompanyId: 'activityAreaMgtApi/api/v1/company/:companyId/activityArea',
  activityAreasByCFTyId: 'activityAreaMgtApi/api/v1/call-for-tender/:cftID/activityArea',
  activityAreasBasePath: 'activityAreaMgtApi/api/v1/activityArea',
  activityAreasMarketPlaceSearch: 'activityAreaMgtApi/api/v1/call-for-tender'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
