<div class="animated fadeIn">
  <div class="row">
    <div class="col-md-12">
      <div class="header">{{"to_generate.title" | translate}}</div>
    </div>
  </div>

  <ul class="nav nav-tabs">
    <ng-container *ngFor="let item of menu">
      <li class="nav-item">
        <div
          class="tab-link tab-label"
          routerLinkActive="active"
          [routerLink]="item.link"
        >
          {{ item.title }}

          <ng-container *ngIf="item.icon">
            &nbsp;
            <span class="badge badge-pill badge-primary align-text-top">
              {{ item.icon }}
            </span>
          </ng-container>
        </div>
      </li>
    </ng-container>
  </ul>

  <div class="row">
    <div class="col-md-12 container-block">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
