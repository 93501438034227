import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ListContentModel} from '../../../shared/models/list-content.model';
import {OnCallEnvelopeModel, OnCallModel} from '../../../shared/models/on-call/on-call.model';
import {commonProperties} from '../../../../assets/environments/environment.common';

import {environment} from '../../../../assets/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OnCallService {

  constructor(
    private httpClient: HttpClient
  ) { }

  mapAdvancedSearchForm(params: any) {
    const advancedSearch: OnCallModel = new OnCallModel();
    if (params.purchaseOrderNumber) {
      advancedSearch.purchaseOrderNumber = params.purchaseOrderNumber;
    }
    if (params.buyerCompanyName) {
      advancedSearch.buyerCompanyName = params.buyerCompanyName;
    }
    if (params.projectName) {
      advancedSearch.projectName = params.projectName;
    }
    if (params.date) {
      advancedSearch.date = params.date;
    }
    if (params.status) {
      advancedSearch.status = params.status;
    }
    return advancedSearch;
  }

  // Activity report microservice
  getOnCallByPage(page: number, size: number, sort: string, search: string, advancedSearch): Observable<ListContentModel> {
    let params = new HttpParams();
    if (search) {
      params = params.set('search', search);
    } else if (advancedSearch) {
      if (advancedSearch.purchaseOrderNumber) {
        params = params.set('purchaseOrderNumber', advancedSearch.purchaseOrderNumber);
      }
      if (advancedSearch.buyerCompanyName) {
        params = params.set('buyerCompanyName', advancedSearch.buyerCompanyName);
      }
      if (advancedSearch.projectName) {
        params = params.set('projectName', advancedSearch.projectName);
      }
      if (advancedSearch.status) {
        params = params.set('status', advancedSearch.status);
      }
      if (advancedSearch.date) {
        const periodRange = advancedSearch.date.split(',');
        params = params.set('startDate', periodRange[0]);
        params = params.set('endDate', periodRange[1]);
      }
    }

    params = params.set('size', size.toString());
    params = params.set('page', page.toString());
    params = params.set('sort', sort);
    return this.httpClient.get<ListContentModel>(environment.api_root + commonProperties.activityReportOnCalls, {params});
  }

  getOnCallById(id): Observable<any> {
    return this.httpClient.get(environment.api_root + commonProperties.activityReportOnCallById.replace(':id', id));
  }

  postOnCall(body) {
    return this.httpClient.post(environment.api_root + commonProperties.activityReportOnCalls, body);
  }

  patchOnCall(id, body) {
    return this.httpClient.patch(environment.api_root + commonProperties.activityReportOnCallById.replace(':id', id), body);
  }

  putOnCall(id, body) {
    return this.httpClient.put(environment.api_root + commonProperties.activityReportOnCallById.replace(':id', id), body);
  }

  getPurchaseOrderDescList(type): Observable<any> {
    return this.httpClient.get<OnCallEnvelopeModel[]>(environment.api_root + commonProperties.onCallsEnvelope.replace(':activityType', type));
  }

  downloadOnCallFileById(id) {
    const headers = new HttpHeaders().set('Accept', 'application/pdf');
    const URL = environment.api_root + commonProperties.onCallFileById.replace(':onCallId', id);
    return this.httpClient.get<Blob>(URL, {
      observe: 'response',
      headers: headers,
      responseType: 'blob' as 'json'
    });
  }

  // Company microservice
  getOnCallParams(buyerTenantId): Observable<any> {
    let params = new HttpParams();
    params = params.set('enterpriseId', buyerTenantId);

    return this.httpClient.get(environment.api_root + commonProperties.onCallParams, {params});
  }

  getOnCallParam(id: string): Observable<any> {
    return this.httpClient.get(environment.api_root + commonProperties.onCallParamById.replace(':id', id));
  }

  getPurchaseOrderContract(id: string): Observable<any> {
    return this.httpClient.get(environment.api_root + commonProperties.purchaseOrderContract.replace(':purchaseOrderId', id));
  }

  cancelOncall(oncallId: string): Observable<any> {
    return this.httpClient.delete<any>(environment.api_root + commonProperties.activityReportOnCallById.replace(':id', oncallId.toString()));
  }
}
