import {CategoryModel} from "./category.model";
import {WorkPlaceModel} from "./work-place.model";
import {LeagueMinimalModel} from "./league-minimal.model";
import {ActivityAreaModel} from "../../../views/company-config/activity-areas/activity-area.model";

export class CallForTenderListItemModel {
  id: string;
  reference: string;
  title: string;
  startDate: string;
  quantity: string;
  quantityByDate: string;
  closedDate: string;
  category: CategoryModel;
  workplace: WorkPlaceModel;
  status: string;
  offers: number;
  technologies: string[];
  buyerName: string;
  open: boolean;
  leagues: LeagueMinimalModel[];
  billingConfigId: string;
  contractId: string;
  contractMode: string;
  endDate: string;
  dailyRateByDate: string;
  minDailyRate: number;
  maxDailyRate: number;
  minAmount: number;
  maxAmount: number;
  currency: string;
  tenantId: string;
  freelanceOfferExist: boolean;
  countries: string[];
  otherCategory: string;
  publicationDate: Date;
  activityArea: ActivityAreaModel;

  constructor(id: string, reference: string, title: string, startDate: string, quantity: string, quantityByDate: string,
              closedDate: string, status: string, offers: number, technologies: string[], billingConfigId: string,
              contractId: string, contractMode: string, endDate: string, dailyRateByDate: string, minDailyRate: number, maxDailyRate: number,
              minAmount: number, maxAmount: number, workplace: any, tenantId: string) {
    this.id = id;
    this.reference = reference;
    this.title = title;
    this.startDate = startDate;
    this.quantity = quantity;
    this.quantityByDate = quantityByDate;
    this.closedDate = closedDate;
    this.status = status;
    this.offers = offers;
    this.technologies = technologies;
    this.billingConfigId = billingConfigId;
    this.contractId = contractId;
    this.contractMode = contractMode;
    this.endDate = endDate;
    this.dailyRateByDate = dailyRateByDate;
    this.minDailyRate = minDailyRate;
    this.maxDailyRate = maxDailyRate;
    this.minAmount = minAmount;
    this.maxAmount = maxAmount;
    this.workplace = new WorkPlaceModel(workplace);
    this.tenantId = tenantId;
  }
}
