import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {MissionService} from '../../services/mission.service';
import {AdditionalDocumentsModel} from '../../../../shared/models/mission/additional-documents.model';
import {NGXLogger} from 'ngx-logger';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {query, transition, trigger, useAnimation} from '@angular/animations';
import {slideIn20StaggerAnimation, slideIn50StaggerAnimation} from '../../../../shared/animation/common.animation';

@Component({
  selector: 'additional-documents',
  templateUrl: './additional-documents.component.html',
  styleUrls: ['./additional-documents.component.scss'],
  animations: [
    trigger('slideInStagger', [
      transition(':enter', [
        query('.slide-in-animation', [
          useAnimation(slideIn20StaggerAnimation)
        ], {optional: true})
      ])
    ])
  ]
})
export class AdditionalDocumentsComponent implements OnInit, OnDestroy {
  additionalDocumentsList: AdditionalDocumentsModel[] = [];
  hasDocument: boolean = true;
  userLang: string;
  missionId: string;

  private subscriptions: Subscription[] = [];
  firstCallDone: boolean;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private router: Router,
    private missionService: MissionService,
    private logger: NGXLogger,
    private translateService: TranslateService,
    private toastrService: ToastrService
  ) {

  }

  ngOnInit(): void {
    this.userLang = this.authService.getUserLang();
    this.subscriptions.push(this.route.parent.paramMap.subscribe(param => {
      this.missionId = param.get('missionId');
      this.firstCallDone = true;
      this.getAdditionalDocuments();
    }));
  }

  getAdditionalDocuments(): void {
    this.subscriptions.push(this.missionService.getMissionAdditionalDocumentsById(this.missionId).subscribe(
      (addDocumentResponse: any) => {

        if (!addDocumentResponse.length) {
          this.hasDocument = false;
        }

        for (const addDocumentResponseElement of addDocumentResponse) {
          this.subscriptions.push(
            this.missionService.getMissionAdditionalDocumentsAttachmentById(this.missionId, addDocumentResponseElement.id).subscribe(
              addDocStatus => {
                this.hasDocument = true;
                addDocumentResponseElement.status = this.statusSwitch(addDocStatus[0].status);
                this.additionalDocumentsList.push(addDocumentResponseElement);
            },
            (error: any) => {
                this.hasDocument = false;
              this.logger.error(error.url, '- STATUS :', error.status);
              // this.logger.debug('Additional documents with request id', this.missionId, 'not found. Redirect to request list');
            }

          ));
        }
      },
      (error: any) => {
        this.logger.error(error.url, '- STATUS :', error.status);
        this.toastrService.error(this.translateService.instant('global.ts.error'));
        this.router.navigate(['/mission']).then();
      }, () => {
        this.firstCallDone = false;
      }
    ));
  }

  statusSwitch(status) {
    switch (status) {
      case 'VALIDATED':
        return {
          icon: 'check_mark',
          color: 'color: #2ECC71;',
        };
      case 'REFUSED':
        return {
          icon: 'x_mark',
          color: '#E74C3C;',
        };
      case 'SENT':
        return {
          icon: 'material/update',
          color: 'color: #ECE81A;',
        };
      default:
        break;
    }
  }

  updateDocumentStatus($event) {
    const objWithIdIndex = this.additionalDocumentsList.findIndex((obj) => obj.id === $event.addDocId);
    this.additionalDocumentsList[objWithIdIndex].status = this.statusSwitch($event.file.status);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
