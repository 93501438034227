import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {NGXLogger} from 'ngx-logger';
import {FormBuilder} from '@angular/forms';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ListContentComponent} from '../../../shared/components/list-content/list-content.component';
import {ActivatedRoute, Router} from '@angular/router';
import {OnCallService} from '../services/on-call.service';
import {OnCallEnvelopeModel, OnCallModel} from '../../../shared/models/on-call/on-call.model';
import {animate, animation, query, stagger, style, transition, trigger, useAnimation} from '@angular/animations';
import {fadeInAnimation, slideIn50StaggerAnimation, slideInAnimation} from '../../../shared/animation/common.animation';
import {CreateOnCallModalComponent} from '../create-on-call-modal/create-on-call-modal.component';
import {ListContentModel} from '../../../shared/models/list-content.model';
import {UpdateOnCallModalComponent} from '../update-on-call-modal/update-on-call-modal.component';
import {TranslateService} from '@ngx-translate/core';
import { ModalConfirmComponent } from '../../../shared/components/modal-confirm/modal-confirm.component';
import {AuthenticationService} from '../../../core/services/authentication.service';
import { FeeStatusEnum } from '../../../shared/enums/Fee.status.enum';
import { regex } from '../../../shared/regex/form.regex';
import * as moment from 'moment';
import * as _ from 'lodash';
@Component({
  selector: 'on-call-list',
  templateUrl: './on-call-list.component.html',
  styleUrls: ['./on-call-list.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        useAnimation(fadeInAnimation)
      ])
    ]),
    trigger('slideIn', [
      transition(':enter', [
        useAnimation(slideInAnimation)
      ])
    ]),
    trigger('slideInStagger', [
      transition(':enter', [
        query('.slide-in-animation', [
          useAnimation(slideIn50StaggerAnimation)
        ], {optional: true})
      ])
    ]),
    trigger('listStagger', [
      transition('* <=> *', [
        query(
          ':enter',
          useAnimation(animation([
            style({opacity: 0, transform: 'translateY(20px)'}),
            stagger(
              '60ms',
              animate(
                '.2s ease-out',
                style({opacity: 1, transform: 'translateY(0px)'})
              )
            )
          ], null)),
          {optional: true}
        )
      ])
    ])
  ]
})
export class OnCallListComponent extends ListContentComponent implements OnInit {
  listContent: OnCallModel[] = [];
  onCallEnvelopeList: OnCallEnvelopeModel[] = [];
  status = Object.values(FeeStatusEnum);

  listParamValidator = {
    page: RegExp('^[1-9][0-9]*$'),
    size: ['5', '10', '20'],
    sort: RegExp('^(date|submissionDate|purchaseOrderNumber|buyerCompanyName|buyer.projectName|config.workItemReference|config.workUnit|time|quantity|priceAmount|status)\,(asc|desc)$'),
    search: RegExp('.{3,}'),
    disabled: 'boolean',
    purchaseOrderNumber: RegExp('^[a-zA-Z0-9_-]+$'),
    buyerCompanyName: RegExp(regex.companyName),
    projectName: RegExp(regex.companyName),
    date: 'rangeDate',
    status: RegExp(`^(${this.status.join('|')})(\,(${this.status.join('|')}))*$`),
  };

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private authService: AuthenticationService,
    private toastrService: ToastrService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private logger: NGXLogger,
    private onCallService: OnCallService,
    private translateService: TranslateService
  ) {
    super(router, route);
    this.advancedSearchForm = this.fb.group({
      purchaseOrderNumber: [null],
      buyerCompanyName: [null],
      requestNumber: [null],
      status: [null],
      date: [null],
      projectName: [null]
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    super.subscribeToQueryParam();
    this.initPurchaseOrderList();
  }

  private initPurchaseOrderList() {
    this.subscriptions.push(this.onCallService.getPurchaseOrderDescList('ON_CALL').subscribe((response) => {
        this.onCallEnvelopeList = response.content;
      },
      (error: any) => {
        this.onCallEnvelopeList = [];
        this.logger.error(error.url, '- STATUS :', error.status);
        this.toastrService.error(this.translateService.instant('global.ts.error'));
      }
    ));
  }

  // Override method
  mapQueryParamToAdvancedSearchForm(params: any): void {
    Object.keys(params).forEach(paramKey => {
      if (this.advancedSearchForm.contains(paramKey)) {
        if (paramKey === 'date') {
          this.advancedSearchForm.get('date').setValue(params[paramKey].split(',').map(date => new Date(date)));
        } else if (paramKey === 'status') {
          const type = params[paramKey].split(',');
          this.advancedSearchForm.get('status').setValue(_.uniq(type));
        } else {
          this.advancedSearchForm.get(paramKey).setValue(params[paramKey]);
        }
        this.isAdvancedSearchDisplayed = true;
        this.isSearchActive = true;
      }
    });
  }

  // Override method
  mapAdvancedSearchFormToQueryParam(advancedSearchForm: any): any {
    this.logger.info('your params in mapAdvancedSearchFormToQueryParam:', advancedSearchForm);
    if (advancedSearchForm.hasOwnProperty('date') && advancedSearchForm.date) {
      advancedSearchForm.date = advancedSearchForm.date.map(date => moment(date).format('YYYY-MM-DD')).join();
    }
    if (advancedSearchForm.hasOwnProperty('status') && advancedSearchForm.status) {
      advancedSearchForm.status = advancedSearchForm.status.join();
    }
    return advancedSearchForm;
  }

  retrieveListContent(params: any): void {
    const page = params.page ? params.page - 1 : 0;
    const size = params.size ? params.size : 5;
    const sort = params.sort ? params.sort : 'submissionDate,desc';
    const search = params.search ? params.search : null;
    const advancedSearch = this.onCallService.mapAdvancedSearchForm(params);
    this.logger.info('your advanced search model is: ', advancedSearch);

    this.subscriptions.push(this.onCallService.getOnCallByPage(page, size, sort, search, advancedSearch).subscribe(
      (res: ListContentModel) => {
        this.listContent = res.content;
        this.totalElements = res.totalElements;
        this.numberOfElements = res.numberOfElements;
        this.isListEmpty = !this.isSearchActive && res.empty;

        if (res.totalPages !== 0 && params.page > res.totalPages) {
          this.updateQueryParam({page: res.totalPages});
        }

        this.firstCallDone = true;
      },
      (error: any) => {
        this.logger.error(error.url, '- STATUS :', error);
        this.toastrService.error(this.translateService.instant('global.ts.error'));
      }
    ));
  }

  createOnCall() {
    const initialState = {
      onCallEnvelopeList: this.onCallEnvelopeList
    };
    const modalRef = this.modalService.show(CreateOnCallModalComponent, {
      class: 'modal-lg',
      initialState
    });
    this.subscriptions.push(modalRef.content.actionConfirmed.subscribe(
      (data) => {
        if (data) {
          this.refreshListContent();
        }
      }
    ));
  }

  clickOnRow(onCall) {
    if (!this.onCallEnvelopeList || this.onCallEnvelopeList.length == 0) { return; }
    const initialState = {
      currentOnCall: onCall,
      currentOnCallEnvelope: this.onCallEnvelopeList.find(aOnCall => aOnCall.purchaseOrderNumber === onCall.purchaseOrderNumber)
    };
    const modalRef = this.modalService.show(UpdateOnCallModalComponent, {
      class: 'modal-lg modal-right',
      initialState
    });
    this.subscriptions.push(modalRef.content.actionConfirmed.subscribe(
      (data) => {
        if (data) {
          this.refreshListContent();
        }
      }
    ));
  }

  confirmOncallCancellation(oncallId: string) {
    this.subscriptions.push(this.translateService.get('Fee.modal.cancel.cancel-the-oncall').subscribe((data: any) => {
      const initialState = {
        message: data, commentRequired: false,
      };
      const modalRef = this.modalService.show(ModalConfirmComponent, {
        class: 'modal-lg',
        initialState
      });
      this.subscriptions.push(modalRef.content.actionConfirmed.subscribe(
        () => {
          this.cancelOncall(oncallId);
        }
      ));
    }));
  }

  cancelOncall(oncallId: string) {
    this.subscriptions.push(this.onCallService.cancelOncall(oncallId).subscribe(
      (res: any) => {
        this.refreshListContent();
        this.logger.info('onCall cancelled:', res);
      },
      (error: any) => {
        this.logger.error('An error occurred when cancelling the onCall:', error);
      }
    ));
  }

  displayCancel(onCall: OnCallModel) {
    return (onCall.status === 'SUBMITTED' || onCall.status === 'REFUSED');
  }

  isProvider() {
    return this.authService.isUserProvider();
  }

  clearAdvanceSearchDate(formControl: string) {
    this.advancedSearchForm.get(formControl).reset();
    this.advancedSearchForm.get(formControl).markAsDirty();
  }
}
