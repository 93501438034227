import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
  CallForTenderOfferListItemModel
} from '../../../../shared/models/call-for-tender/call-for-tender-offer-list-item.model';
import {CallForTenderModel} from '../../../../shared/models/call-for-tender/call-for-tender.model';
import {Subscription} from 'rxjs';
import {CallForTenderService} from '../../services/call-for-tender.service';
import {ToastrService} from 'ngx-toastr';
import {NGXLogger} from 'ngx-logger';
import {TranslateService} from '@ngx-translate/core';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {faCalendar, faClock, faMoneyBill1} from '@fortawesome/free-regular-svg-icons';
import {faFile, faIndustry, faMapMarker, faTag} from '@fortawesome/free-solid-svg-icons';

marker('Call_for_tender_offer_list.statuses.SENT');
marker('Call_for_tender_offer_list.statuses.PUBLISHED');
marker('Call_for_tender_offer_list.statuses.SELECTED');
marker('Call_for_tender_offer_list.statuses.REPLIED');
marker('Call_for_tender_offer_list.statuses.REJECTED');
marker('Call_for_tender_offer_list.statuses.REJECTED_BY_SA');
marker('Call_for_tender_offer_list.statuses.CLOSED_BY_SUPPLIER');
marker('Call_for_tender_offer_list.statuses.ACCEPTED');
marker('Call_for_tender_offer_list.statuses.CANCELLED');

@Component({
  selector: 'app-call-for-tender-offers-list-card',
  templateUrl: './call-for-tender-offers-list-card.component.html',
  styleUrls: ['./call-for-tender-offers-list-card.component.scss']
})
export class CallForTenderOffersListCardComponent implements OnInit, OnDestroy {
  protected readonly faMoneyBill1 = faMoneyBill1;
  protected readonly faFile = faFile;
  protected readonly faIndustry = faIndustry;
  protected readonly faCalendar = faCalendar;
  protected readonly faClock = faClock;
  protected readonly faMapMarker = faMapMarker;

  @Input() callForTenderOffer: CallForTenderOfferListItemModel;
  @Output() callForTenderOfferDetail = new EventEmitter<string>();
  @Output() callForTenderOfferTimeSlot = new EventEmitter<string[]>();
  @Output() callForTenderOfferNegotiation = new EventEmitter<string[]>();
  callForTender: CallForTenderModel = new CallForTenderModel();
  private subscriptions: Subscription[] = [];
  page: number = 0;

  constructor(private callForTenderService: CallForTenderService,
              private toastrService: ToastrService,
              private translateService: TranslateService,
              private logger: NGXLogger, ) {
  }

  ngOnInit(): void {
    this.callForTenderDetail(this.callForTenderOffer.callForTenderId);
  }

  /* TODO: to remove this repetitive api call - to include below in callForTenderOffer --
  activity area
  dailyRateByDate
  startDate
  quantity
  quantityByDate
  workplace Entity
  cftSkills
  */
  callForTenderDetail(callForTenderId: string): void {
    this.subscriptions.push(this.callForTenderService.getCallForTenderById(callForTenderId).subscribe(
      (res: CallForTenderModel) => {
        this.callForTender = res;
      }, (error: any) => {
        this.logger.error(error.url, '- STATUS :', error.status);
        this.toastrService.error(this.translateService.instant('global.ts.error'));
      }
    ));
  }

  deleteOffer(): void {
    this.callForTenderOfferDetail.emit(this.callForTenderOffer.id);
  }

  showTimeSlotModal() {
    this.callForTenderOfferTimeSlot.emit([this.callForTenderOffer.id, this.callForTenderOffer.callForTenderId]);
  }

  showNegotiationModal() {
    this.callForTenderOfferNegotiation.emit([this.callForTenderOffer.id, this.callForTenderOffer.callForTenderId]);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
