import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FileUtilsService} from '../../../../../shared/utils/file-utils.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AdditionalDocumentsModel} from '../../../../../shared/models/mission/additional-documents.model';
import {Subscription} from 'rxjs';
import {requiredFileType} from '../../../../../shared/validators/required-file-type.validator';
import {MissionService} from '../../../services/mission.service';
import {NGXLogger} from 'ngx-logger';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from "@ngx-translate/core";
import {EventBusService} from '../../../../../shared/utils/event-bus.service';
import {EventData} from '../../../../../shared/models/event.model';

@Component({
  selector: 'additional-documents-section',
  templateUrl: './additional-documents-section.component.html',
  styleUrls: ['./additional-documents-section.component.scss']
})
export class AdditionalDocumentsSectionComponent implements OnDestroy, OnChanges {

  constructor(
    private _formBuilder: FormBuilder,
    private missionService: MissionService,
    private logger: NGXLogger,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private eventBusService: EventBusService
  ) {
    this.additionalDocumentFileForm = this._formBuilder.group({
      fileContent: [null],
      fileName: [null, [requiredFileType('pdf')]],
    });
  }
  @Input() additionalDocument: AdditionalDocumentsModel;
  @Output() updateDocumentStatus: EventEmitter<object> = new EventEmitter<object>();
  missionId: string;
  disableUploadFile: boolean = true;
  fileUploaded: boolean = false;
  additionalDocumentFileForm: FormGroup;
  additionalDocumentAttachment: AdditionalDocumentsModel;
  private subscriptions: Subscription[] = [];

  ngOnChanges(changes: SimpleChanges) {
    this.fileUploaded = false;
    this.subscriptions.push(this.route.parent.paramMap.subscribe(param => {
      this.missionId = param.get('missionId');
      if (this.additionalDocument) {
        this.getAdditionalDocumentsAttachment();
      }
    }));
  }

  downloadAttachment(type: string) {
    // this.callInit.emit();
    const link = document.createElement('a');

    if (type === 'ADDITIONAL_DOCUMENT') {
      link.href = this.additionalDocument.fileContent;
      link.download = this.additionalDocument.fileName;
    } else if (type === 'ATTACHMENT') {
      link.href = this.additionalDocumentAttachment.fileContent;
      link.download = this.additionalDocumentAttachment.fileName;
    }

    link.click();
    this.disableUploadFile = false;
  }

  async getFile($event: File) {
    if ($event) {
      const fileBase64 = await FileUtilsService.convertFileToBase64($event);
      this.additionalDocumentFileForm.get('fileContent').setValue(fileBase64);
      this.additionalDocumentFileForm.get('fileName').setValue($event.name);
      this.subscriptions.push(
        this.missionService.postMissionAdditionalDocumentsAttachmentById(
          this.missionId,
          this.additionalDocument.id,
          this.additionalDocumentFileForm.value
        ).subscribe(
          (response) => {
            this.eventBusService.emit(new EventData('additionalDocumentChangeStatus', true));

            this.updateDocumentStatus.emit({addDocId: this.additionalDocument.id, file: response});

            this.getAdditionalDocumentsAttachment();
          },
          (error: any) => {
            this.logger.error(error.url, '- STATUS :', error.status);
            this.toastrService.error(this.translateService.instant('global.ts.error'));
          }
        ));
    }
  }

  getAdditionalDocumentsAttachment() {
    this.subscriptions.push(this.missionService.getMissionAdditionalDocumentsAttachmentById(
      this.missionId,
      this.additionalDocument.id
    ).subscribe(
      (res: any) => {
        this.additionalDocumentAttachment = res[0];
        if (res[0].fileContent) {
          this.fileUploaded = true;
        }
      },
      (error: any) => {
        this.logger.error(error.url, '- STATUS :', error.status);
        // this.logger.debug('Additional documents with request id', this.missionId, 'not found. Redirect to request list');
      }
    ));
  }

  uploadNewDocument() {
    this.fileUploaded = false;
    this.disableUploadFile = false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
