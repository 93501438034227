import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CountryModel} from '../models/country.model';
import {catchError, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { TranslatorService } from '../../core/services/translator.service';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor(private httpClient: HttpClient,
              private translatorService: TranslatorService) {
  }

  getCountries(): Observable<CountryModel[]> {
    const response$ = this.httpClient.get<Object[]>('https://restcountries.com/v3.1/all');
    return response$.pipe(
      map(res => res.map((c) => {
        return {
          name: c['name']['common'],
          region: c['region'],
          code: c['cca2'],
        };
      }))
    );
  }

  search(search: string): Observable<CountryModel[]> {
    const lang = this.translatorService.getLanguage();
    const response$ = this.httpClient.get<Object[]>('https://restcountries.com/v3.1/translation/' + search + '?fullText=false');
    return response$.pipe(
      map(res => res.map((c) => {
        return {
          name: lang === 'FR' ? c['translations']['fra']['common'] : c['name']['common'],
          region: c['region'],
          code: c['cca2']
        };
      })),
      catchError(err => {
        throw err;
      })
    );
  }
}
