<div class="card box-shadow-hover"
     [ngClass]="toDoStatus.includes(mission.status) ? 'border-red' : 'border-dark-blue'"
     (click)="viewMission()">

  <div class="card-header">
    <div class="row">
      <div class="col-md-9">
        <h4 class="card-title">{{ mission.requestNumber }}</h4><br>
        <span
          class="float-left text-muted small">PO : {{mission['poNumber'] | default: '--'}}</span>
      </div>
      <div class="col-md-3">
        <span
          class="badge float-right"
          [ngClass]="{
          'badge-success': validatedStatus.includes(mission.status),
          'badge-primary': problemRefusedStatus.includes(mission.status),
          'badge-info': waitingBuyerStatus.includes(mission.status),
          'badge-purple': toDoStatus.includes(mission.status)
          }">
          {{ "Mission_view.status." + mission.status | translate }}
        </span>
      </div>
    </div>
  </div>

  <div class="card-body py-0">
    <div class="row">
      <div class="col">
        <label class="card-subheader">{{'Mission_list.card.date'|translate}}</label> <br>
        <span class="fa fa-calendar-check-o"></span>
        <span class="date">{{ mission["startDate"] | date: "dd.MM.yyyy" }}</span>
      </div>
      <div class="col">
        <label class="card-subheader">{{'Mission_list.card.mission_end_date'|translate}}</label>
        <br>
        <span class="fa fa-calendar-times-o"></span>
        <span class="date">{{ mission["endDate"] | date: "dd.MM.yyyy" }}</span>
      </div>
    </div>
    <hr>
  </div>


  <div class="card-footer pt-0">
    <div class="row">
      <div class="col">
        <div class="d-flex">
          <div class="date-icon mr-2">
            <i class="fa fa-file-o" aria-hidden="true"></i>
          </div>
          <div>
            <div class="card-subheader">{{'Mission_list.card.buyer'|translate}}</div>
            <div class="sub-data">{{ mission.buyer }}</div>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="d-flex">
          <div class="date-icon mr-2">
            <i class="fa fa-eur" aria-hidden="true"></i>
          </div>
          <div>
            <div class="card-subheader">{{'Mission_list.card.contract'|translate}}</div>
            <div class="sub-data">
              {{ 'Invoice.mode.'+mission.contractMode | translate }}
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>
