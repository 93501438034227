export class WorkPlaceModel {
  street1: string;
  street2: string;
  postCode: string;
  stateOrProvince: string;
  city: string;
  country: string;

  constructor(object?: WorkPlaceModel) {
    if (object) {
      this.street1 = object.street1;
      this.street2 = object.street2;
      this.postCode = object.postCode;
      this.stateOrProvince = object.stateOrProvince;
      this.city = object.city;
      this.country = object.country;
    }
  }
}
