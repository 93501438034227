export class CallForTenderOfferModel {
  id: string;
  title: string;
  providerId: string;
  providerFirstName: string;
  providerLastName: string;
  providerEmail: string;
  supplierName : string;
  dailyRate: number;
  amount: number;
  resumeFileName: string;
  resumeFileContent: string
  comment: string;
  callForTenderId: string;


  constructor(object?: any) {
    if (object) {
      this.id = object.id;
      this.title = object.title;
      this.providerId = object.providerId;
      this.providerFirstName = object.providerFirstName;
      this.providerLastName = object.providerLastName;
      this.providerEmail = object.providerEmail;
      this.supplierName = object.supplierName;
      this.dailyRate = object.dailyRate;
      this.amount = object.amount;
      this.resumeFileName = object.resumeFileName;
      this.resumeFileContent = object.resumeFileContent;
      this.comment = object.comment;
      this.callForTenderId = object.callForTenderId;

    }
  }
}
