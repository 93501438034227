export class PurchaseOrderDetailModel {
  startDate: string;
  endDate: string;
  quantity: string;
  quantityUsed: number;
  dailyRate: string;
  dailyRateByDate: boolean;
  workHoursPerDay: string;
  fees: string;
  feesUsed: number;
  onCall: string;
  onCallUsed: number;
  registrationNumber: string;

  constructor(object?: PurchaseOrderDetailModel) {
    if (object) {
      this.startDate = object.startDate;
      this.endDate = object.endDate;
      this.quantity = object.quantity;
      this.quantityUsed = object.quantityUsed;
      this.dailyRate = object.dailyRate;
      this.dailyRateByDate = object.dailyRateByDate;
      this.workHoursPerDay = object.workHoursPerDay;
      this.fees = object.fees;
      this.feesUsed = object.feesUsed;
      this.onCall = object.onCall;
      this.onCallUsed = object.onCallUsed;
      this.registrationNumber = object.registrationNumber;
    }
  }
}
