import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry} from "ngx-file-drop";
import {NGXLogger} from "ngx-logger";
import {FormBuilder, FormGroup} from "@angular/forms";
import {requiredFileType} from "../../validators/required-file-type.validator";
import {maximumSizeFile} from "../../validators/maximum-size-file.validator";
import {faUpload} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'file-drop',
  templateUrl: './file-drop.component.html',
  styleUrls: ['./file-drop.component.scss']
})
export class FileDropComponent implements OnInit {
  protected readonly faUpload = faUpload;
  public files: NgxFileDropEntry[] = [];
  @Input() disabled: boolean
  @Output() fileAction = new EventEmitter<File>();
  fileUploadForm: FormGroup;

  constructor(private logger: NGXLogger,
              private fb: FormBuilder,) {
    this.fileUploadForm = this.fb.group({
      attachmentFileName: [null, [requiredFileType('pdf')]],
      attachmentFile: [null, [maximumSizeFile(2, 'mo')]],
    });
  }

  ngOnInit(): void {
  }


  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {

      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.fileUploadForm.get('attachmentFileName').setValue(file.name);
          this.fileUploadForm.get('attachmentFile').setValue(file);
          if (this.fileUploadForm.valid) {
            // this.logger.debug('file upload: ', file.name);
            this.fileAction.emit(file);
            this.files = [];
          }
        });
      } else {
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        this.logger.error(droppedFile.relativePath, fileEntry);
      }
    }
  }

  public fileOver(event) {
    this.logger.debug('file over: ', event);
  }

  public fileLeave(event) {
    this.logger.debug('file leave: ', event);
  }
}
