export class UserStatusModel {
  status: UserEmailStatusEnum;
  employeeId: string;

  constructor(object?: any) {
    if (object) {
      this.status = object.status;
      this.employeeId = object.employeeId;
    }
  }
}

export enum UserEmailStatusEnum {
  TAKEN = 'TAKEN',
  AVAILABLE = 'AVAILABLE',
}
