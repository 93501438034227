<div class="row">
  <div class="col">
    <ul class="nav nav-pills">
      <ng-container *ngFor="let item of menuList">
        <li class="nav-item">
          <a [routerLink]="item.link" class="nav-link"
             routerLinkActive="active">{{item.title}}
            <i class="{{item.icon}}" aria-hidden="true"></i></a>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
