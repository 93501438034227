export class CategoryModel {
  id: string;
  name: string;

  constructor(object?: any) {
    if (object) {
      this.id = object.id;
      this.name = object.name;
    }
  }
}
