import {Component, Input, OnInit} from '@angular/core';
import {MissionBillingModel} from '../../../../../../shared/models/mission/mission-billing.model';
import {transition, trigger, useAnimation} from '@angular/animations';
import {fadeInAnimation, slideInAnimation} from '../../../../../../shared/animation/common.animation';

@Component({
  selector: 'total-billings-view-detail',
  templateUrl: './total-billings-view-detail.component.html',
  styleUrls: ['./total-billings-view-detail.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        useAnimation(fadeInAnimation)
      ])
    ]),
    trigger('slideIn', [
      transition(':enter', [
        useAnimation(slideInAnimation)
      ])
    ])
  ]
})
export class TotalBillingsViewDetailComponent implements OnInit {
  @Input() billings: MissionBillingModel;

  constructor() { }

  ngOnInit(): void {
  }

  getTotalDuePayment(): number {
    return this.billings.dueDatePayment.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0);
  }

}
