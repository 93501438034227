import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivityAreaModel} from '../../../views/company-config/activity-areas/activity-area.model';
import {NgSelectConfigModel} from './ng-select-config.model';
import {ActivityAreasService} from '../../../views/company-config/services/activity-areas.service';
import {AuthenticationService} from '../../../core/services/authentication.service';

@Component({
  selector: 'app-activity-area-dropdown',
  templateUrl: './activity-area-dropdown.component.html',
  styleUrls: ['./activity-area-dropdown.component.scss']
})
export class ActivityAreaDropdownComponent implements OnInit {
  activityAreas: ActivityAreaModel[] = [];
  initialActivityAreas: ActivityAreaModel[] = [];
  @Output() change = new EventEmitter<ActivityAreaModel[]>();
  @Input() parentActivityArea: ActivityAreaModel;
  @Input() isMultiple: boolean = false;
  @Input() config: NgSelectConfigModel;
  @Input() selectedActivityAreas: ActivityAreaModel[];
  @Input() placeholder: string;
  @Input() public: boolean = true;

  constructor(
    private service: ActivityAreasService,
    private authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.searchActivityArea('', this.parentActivityArea);
  }

  searchActivityArea(term: string, parentActivityArea?: ActivityAreaModel) {
    this.retrieveActivityAreaSearchList(term, parentActivityArea);
  }

  retrieveActivityAreaSearchList(searchValue: string, parentActivityArea?: ActivityAreaModel) {
    if (this.public) {
      this.service.searchActivityAreas(searchValue, parentActivityArea ? parentActivityArea.id : null, '', '30', 'LABEL', 'ASC').subscribe(data => {
        this.activityAreas = data.content;
        if (searchValue === '') {
          this.initialActivityAreas = this.activityAreas;
        }
      });
    } else {
      this.service.getActivityAreasByCompany(this.authService.getCompanyId()).subscribe({
        next: data => {
          this.activityAreas = data.filter(tmp => {
            return tmp.label.toLowerCase().includes(searchValue.toLowerCase());
          });
          if (searchValue === '') {
            this.initialActivityAreas = this.activityAreas;
          }
        }
      });
    }
  }

}
