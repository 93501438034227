import {PointOfContactModel} from './point-of-contact.model';
import {ProjectModel} from './project.model';
import {PurchaseOrderDetailModel} from "./purchase-order-detail.model";
import {WorkPlaceModel} from "./work-place.model";

export class ProviderModel {
  id: string;
  projectId: string;
  employeeId: string;
  pointOfContact: PointOfContactModel;
  title: string;
  experienceLevel: string;
  purchaseOrder: PurchaseOrderDetailModel;
  workPlace: WorkPlaceModel;
  cvLink: string;

  constructor(object?: ProviderModel) {
    if (object) {
      this.id = object.id;
      this.projectId = object.projectId;
      this.employeeId = object.employeeId;
      this.pointOfContact = new PointOfContactModel(object.pointOfContact);
      this.title = object.title;
      this.experienceLevel = object.experienceLevel;
      this.purchaseOrder = new PurchaseOrderDetailModel(object.purchaseOrder);
      this.workPlace = new WorkPlaceModel(object.workPlace);
      this.cvLink = object.cvLink;
    } else {
      this.pointOfContact = new PointOfContactModel();
      this.purchaseOrder = new PurchaseOrderDetailModel();
      this.workPlace = new WorkPlaceModel();
    }
  }
}

export class ProviderDisplayModel extends ProviderModel {
  project: ProjectModel;
  extraRowExtend: boolean;

  constructor(object?: any) {
    super(object);
    if (object) {
      this.project = object.project;
    } else {
      this.project = new ProjectModel();
    }
    this.extraRowExtend = false;
  }

}
