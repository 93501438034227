<label for="origin">{{'Legal_information.country'| translate}} <span class="text-primary">* </span></label>
<ng-select id="origin"
           [items]="countries"
           [(ngModel)]="selectedValue"
           placeholder="Select a country"
           class="ng-select ng-select-custom"
           [searchFn]="onOriginSearch"
           (change)="onChange($event)"
           bindLabel="origin"
           (blur)="onTouched()">
  <ng-template ng-option-tmp let-item="item" let-index="index">
    <div class="flex-align-center">
      <div class="iti__flag mr-2" [ngClass]="'iti__'+item.alpha2Code |lowercase"></div>
      <span>{{item.name}}</span>
    </div>
  </ng-template>
  <ng-template ng-label-tmp let-item="item">
    <div class="flex-align-center">
      <div class="iti__flag mr-2" [ngClass]="'iti__'+item.alpha2Code |lowercase"></div>
      <span>{{item.name}}</span>
    </div>
  </ng-template>
</ng-select>


