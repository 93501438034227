import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'plus-minus-input',
  templateUrl: './plus-minus-input.component.html',
  styleUrls: ['./plus-minus-input.component.scss']
})
export class PlusMinusInputComponent implements OnInit, OnChanges {


  @Input() initValue: number = 0;
  @Input() step: number = 0.25;
  @Input() max: number = 1;
  @Input() min: number = 0;
  @Input() disableForm: boolean = false;
  @Output() value = new EventEmitter<number>();

  disableMinus: boolean;
  disablePlus: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.disableForm) {
      this.disablePlus = true;
      this.disablePlus = true;
      this.value.emit(this.initValue);
    } else {
      this.handleForm();
    }
  }

  ngOnInit(): void {
    this.handleForm();
  }

  handleForm() {
    this.initValue <= this.min ? this.disableMinus = true : this.disableMinus = false;
    this.initValue >= this.max ? this.disablePlus = true : this.disablePlus = false;
    this.value.emit(this.initValue);
  }

  handleMinus() {
    this.disablePlus = false;
    this.initValue = this.initValue - this.step;
    this.value.emit(this.initValue);
    if (this.initValue === this.min) {
      this.disableMinus = true;
    }
  }

  handlePlus() {
    this.disableMinus = false;
    this.initValue = this.initValue + this.step;
    this.value.emit(this.initValue);
    if (this.initValue === this.max) {
      this.disablePlus = true;
    }
  }

  protected readonly faMinus = faMinus;
  protected readonly faPlus = faPlus;
}
