import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

// Import Containers
import {DefaultLayoutComponent} from './containers';

import {P404Component} from './views/error/404.component';
import {P500Component} from './views/error/500.component';
import {SignupComponent} from './signup/signup.component';
import {LoginComponent} from './login/login.component';
import {AccountValidationComponent} from './account-validation/account-validation.component';
import {ForgetPasswordComponent} from './forget-password/forget-password.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import { DynamicDefaultRouteGuard } from './core/guards/dynamic-default-route.guard';
import { AuthGuard } from './core/guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard, DynamicDefaultRouteGuard]
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'forget-password',
    component: ForgetPasswordComponent,
    data: {
      title: 'Forget Password Page'
    }
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    data: {
      title: 'Reset Password Page'
    }
  },
  {
    path: 'signup',
    component: SignupComponent,
    data: {
      title: 'Signup Page'
    }
  },
  {
    path: 'account-validation',
    component: AccountValidationComponent,
    data: {
      title: 'Account Validation Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,

    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'company-config',
        loadChildren: () => import('./views/company-config/company-config.module').then(m => m.CompanyConfigModule),
        data: {
          breadcrumb: 'Company config'
        }
      },
      {
        path: 'graphical-charter',
        loadChildren: () => import('./views/graphical-charter/graphical-charter.module').then(m => m.GraphicalCharterModule)
      },
      {
        path: 'employees',
        loadChildren: () => import('./views/employee/employee.module').then(m => m.EmployeeModule),
        data: {
          breadcrumb: 'Employees'
        }
      },
      {
        path: 'marketplace',
        loadChildren: () => import('./views/marketplace/marketplace.module').then(m => m.MarketplaceModule),
        data: {
          breadcrumb: 'Marketplace'
        }
      },
      {
        path: 'mission',
        loadChildren: () => import('./views/mission/mission.module').then(m => m.MissionModule),
        data: {
          breadcrumb: 'Missions'
        }
      },
      {
        path: 'on-call',
        loadChildren: () => import('./views/on-call/on-call.module').then(m => m.OnCallModule),
        data: {
          breadcrumb: 'On call'
        }
      },
      {
        path: 'contracts',
        loadChildren: () => import('./views/contract/contract.module').then(m => m.ContractModule),
        data: {
          breadcrumb: 'Contracts'
        }
      },
      {
        path: 'purchase-orders',
        loadChildren: () => import('./views/purchase-order/purchase-order.module').then(m => m.PurchaseOrderModule),
        data: {
          breadcrumb: 'Purchase orders'
        }
      },
      {
        path: 'activity-reports',
        loadChildren: () => import('./views/activity-report/activity-report.module').then(m => m.ActivityReportModule),
        data: {
          breadcrumb: 'Activity reports'
        }
      },
      {
        path: 'fees',
        loadChildren: () => import('./views/fees/fees.module').then(m => m.FeesModule),
        data: {
          breadcrumb: 'Fees'
        }
      },
      {
        path: 'accounting',
        loadChildren: () => import('./views/invoice/invoice.module').then(m => m.InvoiceModule),
        data: {
          breadcrumb: 'Invoice'
        }
      }, {
        path: 'profile',
        loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfileModule),
        data: {
          breadcrumb: 'Profile',
        }
      }

    ]
  },
  {path: '**', component: P404Component}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
