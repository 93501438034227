import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../services/authentication.service';
import {CompanyStatusEnum} from '../../shared/enums/Company.status.enum';
import {NGXLogger} from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class CompletedGuard implements CanActivate {
  constructor(private router: Router,
              private authService: AuthenticationService,
              private logger: NGXLogger) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.getCompanyStatus() === CompanyStatusEnum.IN_PROGRESS
      || this.authService.getCompanyStatus() === CompanyStatusEnum.SUBMITTED) {
      Promise.resolve(this.router.navigate(['company-config/legal-information'])).then(() => this.logger.debug('Complete company information'));
      return false;
    } else {
      return true;
    }
  }

}
