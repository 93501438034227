<div class="modal-body p-5" *ngIf="currentOnCall">
  <h5>{{'On_call.update_on_call'|translate}}</h5>
  <hr/>
  <div class="row">
    <div class="col">
      <alert *ngIf="currentOnCall.status === OnCallStatusEnum.REFUSED" type='danger' class="text-center">
        <strong>{{'Activity_report.refusal_reason_colon'|translate}}</strong>
        {{currentOnCall.refusalComment}}
      </alert>
    </div>
  </div>

  <form [formGroup]="onCallForm" (ngSubmit)="updateAction()">

    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label for="purchaseOrder">{{'On_call.po_num'|translate}}<span class="text-primary">*</span></label>
          <input type="text" class="form-control" id="purchaseOrder" formControlName="purchaseOrder">
          <app-form-feedback *ngIf="onCallForm.get('purchaseOrder').errors?.required &&
                (onCallForm.get('purchaseOrder').dirty || onCallForm.get('purchaseOrder').touched)"
                             message="{{'global.feedback.required'|translate}}">
          </app-form-feedback>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="date">{{'On_call.date'|translate}}<span class="text-primary">*</span></label>
          <input type="date" class="form-control" id="date" formControlName="date">
          <app-form-feedback *ngIf="onCallForm.get('date').errors?.required &&
                (onCallForm.get('date').dirty || onCallForm.get('date').touched)"
                             message="{{'global.feedback.required'|translate}}">
          </app-form-feedback>
          <app-form-feedback *ngIf="onCallForm.get('date').errors?.pattern
                && (onCallForm.get('date').dirty || onCallForm.get('date').touched)"
                             message="{{'global.feedback.pattern_date'|translate}}">
          </app-form-feedback>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group">
          <label for="type">{{'On_call.type'|translate}}<span class="text-primary">*</span></label>
          <div>
            <select class="form-control custom-select" formControlName="type" id="type" (change)="selectedType()">
              <option *ngFor="let aType of currentParam?.workItems" [ngValue]="aType.reference"
                      [selected]="aType.reference === currentOnCall.workItemReference">
                {{aType.reference}} - {{aType.description}}
              </option>

            </select>
            <app-form-feedback *ngIf="onCallForm.get('type').errors?.required &&
                        (onCallForm.get('type').dirty || onCallForm.get('type').touched)"
                               message="{{'global.feedback.required'|translate}}">
            </app-form-feedback>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">

        <div class="row">
          <div class="col-md-12">
            <div *ngIf="!isProvider()" class="form-group">
              <label for="dailyRate">{{currentOnCall.rateType === OnCallUnitTypeEnum.DAYS ? ('On_call.daily_rate'|translate) : ('On_call.hourly_rate'|translate)}}<span class="text-primary">*</span></label>
              <input type="number" class="form-control" id="dailyRate" formControlName="dailyRate">
              <app-form-feedback *ngIf="onCallForm.get('dailyRate').errors?.required &&
                (onCallForm.get('dailyRate').dirty || onCallForm.get('dailyRate').touched)"
                                 message="{{'global.feedback.required'|translate}}">
              </app-form-feedback>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="quantity">{{'On_call.quantity'|translate}} <span
                class="text-primary">*</span></label>
              <input type="number" class="form-control" formControlName="quantity" id="quantity">
              <app-form-feedback *ngIf="onCallForm.get('quantity').errors?.required &&
                (onCallForm.get('quantity').dirty || onCallForm.get('quantity').touched)"
                                 message="{{'global.feedback.required'|translate}}">
              </app-form-feedback>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="time">{{'On_call.start_time'|translate}} <span class="text-primary">*</span></label>
              <timepicker class="d-flex justify-content-center" [showMeridian]="false"
                          formControlName="time" id="time"></timepicker>
              <app-form-feedback *ngIf="onCallForm.get('time').errors?.required &&
                (onCallForm.get('time').dirty || onCallForm.get('time').touched)"
                                 message="{{'global.feedback.required'|translate}}">
              </app-form-feedback>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row my-2">
      <div class="col-md-6">

        <div *ngIf="onCallForm.get('fileName').value" class="row d-flex align-items-center justify-content-center">
          <div class="col-2"></div>
          <div class="col-8">
            <div class="form-group my-auto">
              <button type="button" class="btn btn-outline-warning btn-block"
                      [ngClass]="fileUploaded === false ? 'no-download' : ''" (click)="downloadOnCall()">
                <i class="fa fa-file" aria-hidden="true"></i>
                {{(onCallForm.get('fileName').value.length > 18) ?
                (onCallForm.get('fileName').value | slice:0:18) + '...' :
                onCallForm.get('fileName').value }}
              </button>
            </div>
          </div>
          <div class="col-2">
            <div>
              <i *ngIf="currentOnCall.status === OnCallStatusEnum.REFUSED" class="fa fa-times fa-lg delete"
                 aria-hidden="true" (click)="deleteFile()"></i>
            </div>
          </div>
        </div>

        <div *ngIf="!onCallForm.get('fileName').value" class="h-100 d-flex align-items-center justify-content-center">
          {{'On_call.no_file'|translate}}
        </div>
      </div>

      <div class="col-md-6 d-flex align-items-center justify-content-center">
        <div *ngIf="currentOnCall.status === OnCallStatusEnum.REFUSED" class="row">
          <div class="form-group my-auto">
            <button type="button" class="btn btn-outline-info px-5"
                    (click)="fileUpload.click()">
            <span>
              <i class="fa fa-upload" aria-hidden="true"></i>
              {{'On_call.upload_new_file'|translate}}
            </span>
              <ng-container>
                <input #fileUpload type="file" class="custom-file-input" multiple formControlName="file" id="file"
                       (change)="onFileChange($event.target.files)" style="display:none;">
              </ng-container>
            </button>
          </div>
        </div>
      </div>
      <app-form-feedback
        *ngIf="onCallForm.get('file').errors?.requiredFileType"
        message="{{'global.feedback.format_pdf_cv'|translate}}">
      </app-form-feedback>
      <app-form-feedback
        *ngIf="onCallForm.get('fileSize').errors?.sizeFileExceed"
        message="{{'global.feedback.size_cv'|translate: {size: 2} }}">
      </app-form-feedback>
    </div>

    <div class="row">
      <div class="col">
        <div class="form-group last-form-group">
          <label for="comment">{{'On_call.comment'|translate}}
          </label>
          <textarea id="comment" class="form-control" rows="3" formControlName="comment"
                    maxlength="255"
                    placeholder="{{'On_call.comment'|translate}}">
                  </textarea>
        </div>
      </div>
    </div>

    <div *ngIf="this.currentOnCall.status === OnCallStatusEnum.REFUSED" class="row">
      <div class="col d-flex flex-column justify-content-center align-items-center">
        <div class="mt-4">
          <button class="btn btn-outline-warning px-5 mr-3" type="button"
                  (click)="cancelAction()">{{'On_call.button_cancel'|translate}}
          </button>
          <button type="submit" class="btn btn-primary px-5"
                  [disabled]="!onCallForm.valid">{{'On_call.button_update'|translate}}</button>
        </div>
      </div>
    </div>

  </form>
</div>
