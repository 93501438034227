export const regex = {
  companyName: '^[a-zA-ZÀ-ÿ0-9](?!.*[^\\w\\s]{2})[a-zA-ZÀ-ÿ0-9\\s\\W]*[a-zA-ZÀ-ÿ0-9]$',
  firstName: '^[a-zA-ZÀ-ÿ]+(?:[\' -][a-zA-ZÀ-ÿ]+)*$',
  lastName: '^[a-zA-ZÀ-ÿ]+(?:[\' -][a-zA-ZÀ-ÿ]+)*$',
  email: '^[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]{2,}\\.[a-z]{2,20}$',
  shareCapital: '^[0-9]+(?:[ ][A-Z]{3})',
  commentName: '^(?:[A-Za-zÀ-ÿ0-9,\'.-]+[ ]?)*$',
  phoneNumber: '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[- \./0-9]*$',
  title: '^[a-zA-ZÀ-ÿ]+(?:[ -\'][a-zA-ZÀ-ÿ]+)*$',
  incorporationNumber: '^[A-Z0-9]*$',
  city: '^[a-zA-ZÀ-ÿ]+(?:[ \'-][a-zA-ZÀ-ÿ]+)*$',
  street: '^(?:[A-Za-zÀ-ÿ0-9,\'.-]+[ ]?)*$',
  postCode: '^[A-Z0-9]*$',
  stateOrProvince: '^[a-zA-ZÀ-ÿ]+(?:[ .\',-][a-zA-ZÀ-ÿ]+)*$',
  country: '^[a-zA-ZÀ-ÿ]+(?:[ \'-][a-zA-ZÀ-ÿ]+)*$',
  tenDigitAndTwoDecimalPlaces: '^[0-9]{1,10}(\\.[0-9]{1,2})?$',
  tenDigitAndTwoDecimalPlacesWithRange: '^[0-9]{1,10}(\\.(25|5|75|0))?$',
  vatNumber: '^[A-Z]{2}([0-9]{11})$',
  registrationNumber: '^[0-9]*$',
  taxAmount: '^(\\d+(?:[\\.]\\d{0,1}[^,.])?)$',
  numberOnlyPattern: '^[0-9]*$',
  noLeadingTrailingSpacesPattern: '^[^\\s]+(\\s+[^\\s]+)*$',
  maxLengthEscapeSpace: '^(?:\\S\\s*){1,34}$',
  internationalPostalCode: '^[a-zA-ZÀ-ÿ0-9]+(?:[ .\'/-_:][a-zA-ZÀ-ÿ0-9]+)*$',
};
