import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ListContentModel} from '../../../shared/models/list-content.model';
import {FeePostBody} from '../../../shared/models/fees/fee-post-body.model';
import {FeeDetailsModel} from '../../../shared/models/fees/fee-details.model';
import {FeePatchBody} from '../../../shared/models/fees/fee-patch-body.model';
import {commonProperties} from '../../../../assets/environments/environment.common';
import {environment} from '../../../../assets/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class FeesService {
  constructor(private httpClient: HttpClient) {
  }

  mapAdvancedSearchForm(params: any) {
    const advancedSearch: FeeDetailsModel = new FeeDetailsModel();
    if (params.purchaseOrderNumber) {
      advancedSearch.purchaseOrderNumber = params.purchaseOrderNumber;
    }
    if (params.buyerCompanyName) {
      advancedSearch.buyerCompanyName = params.buyerCompanyName;
    }
    if (params.projectName) {
      advancedSearch.projectName = params.projectName;
    }
    if (params.date) {
      advancedSearch.date = params.date;
    }
    if (params.status) {
      advancedSearch.status = params.status;
    }
    return advancedSearch;
  }

  getFeesByPage(page: number, size: number, sort: string, search: string, advancedSearch): Observable<ListContentModel> {
    let params = new HttpParams();
    if (search) {
      params = params.set('search', search);
    } else if (advancedSearch) {
      if (advancedSearch.purchaseOrderNumber) {
        params = params.set('purchaseOrderNumber', advancedSearch.purchaseOrderNumber);
      }
      if (advancedSearch.buyerCompanyName) {
        params = params.set('buyerCompanyName', advancedSearch.buyerCompanyName);
      }
      if (advancedSearch.projectName) {
        params = params.set('projectName', advancedSearch.projectName);
      }
      if (advancedSearch.status) {
        params = params.set('status', advancedSearch.status);
      }
      if (advancedSearch.date) {
        const periodRange = advancedSearch.date.split(',');
        params = params.set('startDate', periodRange[0]);
        params = params.set('endDate', periodRange[1]);
      }
    }

    params = params.set('size', size.toString());
    params = params.set('page', page.toString());
    params = params.set('sort', sort);

    return this.httpClient.get<ListContentModel>(environment.api_root + commonProperties.feesList, {params});
  }

  getFeeById(feeId: number): Observable<FeeDetailsModel> {
    return this.httpClient.get<FeeDetailsModel>(environment.api_root + commonProperties.feeById.replace(':feeId', feeId.toString()));
  }

  downloadFeeFileById(feeId: number) {
    const headers = new HttpHeaders().set('Accept', 'application/pdf');
    const URL = environment.api_root + commonProperties.feeFileById.replace(':feeId', feeId.toString());
    return this.httpClient.get<Blob>(URL, {
      observe: 'response',
      headers: headers,
      responseType: 'blob' as 'json'
    });
  }

  postFee(data: FeePostBody): Observable<FeeDetailsModel> {
    return this.httpClient.post<FeeDetailsModel>(environment.api_root + commonProperties.feesList, data);
  }

  patchFee(feeId: string, data: FeePatchBody): Observable<FeeDetailsModel> {
    return this.httpClient.patch<FeeDetailsModel>(environment.api_root + commonProperties.feeById.replace(':feeId', feeId), data);
  }

  cancelFee(feeId: string): Observable<any> {
    return this.httpClient.delete<any>(environment.api_root + commonProperties.feeById.replace(':feeId', feeId.toString()));
  }

  mergeValues(obj, src) {
    this.forEach(src, (value, key) => {
      if (value !== null && typeof (value) === 'object') {
        this.mergeValues(obj[key], value);
      } else {
        obj[key] = value;
      }
    });
  }

  private forEach(target, fn) {
    const keys = Object.keys(target);
    let i = -1;
    while (++i < keys.length) {
      fn(target[keys[i]], keys[i]);
    }
  }

}
