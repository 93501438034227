import {NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {JwtModule} from '@auth0/angular-jwt';
import {JwtInterceptorService} from './services/jwt-interceptor.service';
import {AuthenticationService} from './services/authentication.service';
import {HeadersInterceptorService} from './services/headers-interceptor.service';
import {BreadcrumbComponent} from "./components/breadcrumb/breadcrumb.component";
import {RouterLinkWithHref} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {SharedModule} from "../shared/shared.module";

export function jwtTokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  declarations: [BreadcrumbComponent],
    imports: [
        CommonModule,
        HttpClientModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: jwtTokenGetter
            }
        }),
        RouterLinkWithHref,
        TranslateModule,
        SharedModule
    ],
  exports: [BreadcrumbComponent],
  providers: [
    AuthenticationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeadersInterceptorService,
      multi: true
    },
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parent: CoreModule) {
    if (parent) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
