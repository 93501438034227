<div class="row">
  <div class="col-sm-auto">
    <div class="circle" [ngClass]="[color]">
      <fa-icon size="lg" [style.color]="iconColor" [icon]="icon"></fa-icon>
    </div>
  </div>
  <div class="col-sm-auto label-position text-muted">
    {{title}}
    <br>
    <h3>{{value}}</h3>
  </div>
</div>
