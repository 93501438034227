import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from "../../shared/shared.module";
import {OnCallListComponent} from "./on-call-list/on-call-list.component";
import { CreateOnCallModalComponent } from './create-on-call-modal/create-on-call-modal.component';
import {OnCallRoutingModule} from "./on-call-routing.module";
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TimepickerModule} from "ngx-bootstrap/timepicker";
import { UpdateOnCallModalComponent } from './update-on-call-modal/update-on-call-modal.component';
import {PaginationModule} from "ngx-bootstrap/pagination";
import {AlertModule} from "ngx-bootstrap/alert";
import {MatTooltipModule} from '@angular/material/tooltip';
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {NgSelectModule} from "@ng-select/ng-select";

@NgModule({
  declarations: [
    OnCallListComponent,
    CreateOnCallModalComponent,
    UpdateOnCallModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    OnCallRoutingModule,
    TranslateModule,
    ReactiveFormsModule,
    TimepickerModule,
    FormsModule,
    PaginationModule,
    AlertModule,
    MatTooltipModule,
    BsDatepickerModule,
    BsDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgSelectModule,
    BsDatepickerModule
  ]
})
export class OnCallModule { }
