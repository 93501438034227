import {HttpEventType} from '@angular/common/http';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {of, Subscription} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {EmployeeService} from '../../services/employee.service';
import {faPencil} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-update-employee-picture',
  templateUrl: './update-employee-picture.component.html',
  styleUrls: ['./update-employee-picture.component.scss']
})
export class UpdateEmployeePictureComponent implements OnInit, OnDestroy {
  protected readonly faPencil = faPencil;

  @Input() canEdit: boolean;

  @Input() name: string;

  employeeImageForm: FormGroup;
  savePictureText = 'Save picture';
  disableButton = false;
  imageSrc: any;
  file: any;
  progress: boolean | number = false;

  private employeeId: string;
  private companyId: string;

  private subscriptions: Subscription[] = [];

  constructor(fb: FormBuilder,
              private employeeService: EmployeeService,
              private activatedRoute: ActivatedRoute,
              private logger: NGXLogger
  ) {
    this.employeeId = this.activatedRoute.snapshot.paramMap.get('employeeId');
    this.companyId = this.activatedRoute.snapshot.paramMap.get('companyId');
    this.employeeImageForm = fb.group({
      file: [null]
    });
  }

  ngOnInit(): void {
    this.getEmployeePicture();
  }

  employeeImageFormSubmit(): void {
    this.progress = 0; // starts spinner
    const formdata: FormData = new FormData();
    formdata.append('file', this.file);

    this.subscriptions.push(this.employeeService.uploadEmployeeAvatar(this.employeeId, formdata).pipe(map(
        (event: any) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              this.progress = event.loaded / event.total;
              return {status: 'progress', message: this.progress};
            case HttpEventType.Response:
              return event.body;
            default:
              return `Unhandled event: ${event.type}`;
          }
        }),
      catchError(err => {
        return of(err);
      })).subscribe(
      el => {
        if (this.progress === 1) {
          this.progress = false;
          this.savePictureText = 'Picture saved';
          this.disableButton = true;
        }
      },
      error => {
        this.logger.error(error.url, '- STATUS :', error.status);
        this.imageSrc = null;
      })
    );
  }

  uploadPhoto(event): void {
    if (event.target.files && event.target.files[0]) {
      this.file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => this.imageSrc = reader.result;
      reader.readAsDataURL(this.file);
      this.disableButton = false;
      this.employeeImageFormSubmit();
    }
  }

  private getEmployeePicture(): void {
    this.subscriptions.push(this.employeeService.getEmployeePictureByCompanyIdAndEmployeeId(this.employeeId).subscribe(
      (res: any) => {
        if (res && res.status !== 204) {
          const reader = new FileReader();
          reader.addEventListener('load', () => {
            this.imageSrc = reader.result as string;
          }, false);
          if (res) {
            reader.readAsDataURL(res);
          }
        }
      },
      (error: any) => {
        this.logger.error(error.url, '- STATUS :', error.status);
      }
    ));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
