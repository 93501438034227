export class MenuItemModel {
  title: string;
  link: string;
  icon: string;
  color?: string;
  checked?: boolean;
  subtitle?: string;

  constructor(title: string, link: string, icon: string, color?: string, checked?: boolean, subtitle?: string) {
    this.title = title;
    this.link = link;
    this.icon = icon;
    this.color = color;
    this.checked = checked;
    this.subtitle = subtitle;
  }
}
