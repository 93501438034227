import {CommonModule, NgIf} from '@angular/common';
import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {CreateEmployeeComponent} from './create-employee/create-employee.component';
import {EmployeeListComponent} from './employee-list/employee-list.component';
import {EmployeeRoutingModule} from './employee-routing.module';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input-gg';
import {AvatarModule} from "ngx-avatars";
import {PaginationModule} from "ngx-bootstrap/pagination";
import {UpdateEmployeeComponent} from "./update-employee/update-employee.component";
import {UpdateEmployeePictureComponent} from "./update-employee/update-employee-picture/update-employee-picture.component";
import {NgSelectModule} from "@ng-select/ng-select";
import {TranslateModule} from "@ngx-translate/core";
import {ButtonsModule} from "ngx-bootstrap/buttons";

@NgModule({
  declarations: [
    EmployeeListComponent,
    CreateEmployeeComponent,
    UpdateEmployeeComponent,
    UpdateEmployeePictureComponent
  ],
    imports: [
        CommonModule,
        EmployeeRoutingModule,
        SharedModule,
        ReactiveFormsModule,
        NgxIntlTelInputModule,
        FormsModule,
        AvatarModule,
        PaginationModule,
        NgSelectModule,
        TranslateModule,
        ButtonsModule,
        AvatarModule,
        NgIf
    ]
})
export class EmployeeModule {
}
