import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CallForTenderListComponent} from "./call-for-tender-list/call-for-tender-list.component";
import {AuthGuard} from "../../core/guards/auth.guard";
import {
  CallForTenderOfferCreationComponent
} from "./call-for-tender-offer-creation/call-for-tender-offer-creation.component";
import {CallForTenderOffersListComponent} from "./call-for-tender-offers-list/call-for-tender-offers-list.component";
import {CompletedGuard} from "../../core/guards/completed.guard";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'calls-for-tender',
    pathMatch: 'full'
  },
  {
    path: 'calls-for-tender',
    data: {
      breadcrumb: 'Calls For Tender'
    },
    children: [
      {
        path: '',
        component: CallForTenderListComponent,
        canActivate: [AuthGuard, CompletedGuard],
      },
      {
        path: ':callForTenderId/offer-creation',
        component: CallForTenderOfferCreationComponent,
        canActivate: [AuthGuard, CompletedGuard],
        data: {
          breadcrumb: 'Offer Creation',
        }
      },
      {
        path: ':offerId/offer-update',
        component: CallForTenderOfferCreationComponent,
        canActivate: [AuthGuard, CompletedGuard],
        data: {
          breadcrumb: 'Update',
        }
      }
    ]
  },
  {
    path: 'calls-for-tender-offers',
    component: CallForTenderOffersListComponent,
    canActivate: [AuthGuard, CompletedGuard],
    data: {
      breadcrumb: 'Offers List',
    }
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MarketplaceRoutingModule {
}
