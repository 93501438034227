<div class="text-center">
  <form [formGroup]="employeeImageForm" (ngSubmit)="employeeImageFormSubmit()">
    <div class="avatar-upload">
      <div class="avatar-edit text-center" *ngIf="canEdit">
        <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg"
               (change)="uploadPhoto($event)"/>
        <label for="imageUpload"><fa-icon [icon]="faPencil"></fa-icon></label>
      </div>
      <ng-container *ngIf="imageSrc; else emptyNameBlock">
        <ngx-avatars [src]="imageSrc" bgColor="#F7F7F9" fgColor="#8B5D5D" size="160"
                    textSizeRatio="2">
        </ngx-avatars>
      </ng-container>
      <ng-template #emptyNameBlock>
        <ngx-avatars [name]="name" bgColor="#F7F7F9" fgColor="#8B5D5D" size="160" textSizeRatio="2" initialsSize="2">
        </ngx-avatars>
      </ng-template>
    </div>


    <button *ngIf="file?.name" class="btn btn-primary btn-sm" type="submit"
            [disabled]="employeeImageForm.invalid || disableButton ">
      {{savePictureText}}
    </button>
  </form>

</div>

