<main class="d-flex align-items-center min-vh-100 py-3 py-md-0">
  <div class="container">
    <div class="card login-card">
      <div class="row no-gutters">
        <div class="col-md-5">
          <img src="assets/img/isupplier-bg.jpg" alt="login" class="login-card-img">
        </div>
        <div class="col-md-7">
          <div class="card-body">
            <div class="brand-wrapper">
              <img src="assets/img/brand/logo.svg" alt="logo" class="img-fluid">
            </div>
            <p class="forgot-password-message text-center">{{'Forgot_password_title'|translate}}</p>
            <app-alert></app-alert>
            <form [formGroup]="forgetPasswordForm" (ngSubmit)="forgetPasswordFormSubmit()"
                  *ngIf="!isSuccess">

              <div class="form-group">
                <label for="email" hidden></label>
                <input class="form-control" id="email" type="text" formControlName="email"
                       placeholder="{{'Forgot_password.email_address'|translate}}" required/>
                <app-form-feedback *ngIf="forgetPasswordForm.get('email').errors?.required &&
                (forgetPasswordForm.get('email').dirty || forgetPasswordForm.get('email').touched)"
                                   message="{{'Forgot_password.input_form_control.field_required'|translate}}">
                </app-form-feedback>
                <app-form-feedback *ngIf="forgetPasswordForm.get('email').errors?.pattern
                && (forgetPasswordForm.get('email').dirty || forgetPasswordForm.get('email').touched)"
                                   message="{{'Forgot_password.input_form_control.field_content_email'|translate}}">
                </app-form-feedback>
                <app-form-feedback *ngIf="forgetPasswordForm.get('email').errors?.maxlength
                && (forgetPasswordForm.get('email').dirty || forgetPasswordForm.get('email').touched)"
                                   message="{{'Forgot_password.input_form_control.field_maximum_100'|translate}}">
                </app-form-feedback>
              </div>

              <button class="btn btn-primary px-4" type="submit"
                      [disabled]="!forgetPasswordForm.valid">
                <em class="fa fa-unlock"></em> {{'Forgot_password.recover_Password'|translate}}
              </button>

              <a type="button" class="btn btn-link px-0 float-right" [routerLink]="['/login']">
                Cancel
              </a>
            </form>

            <div class="text-center" *ngIf="isSuccess">

              <div class="swal2-icon swal2-success swal2-animate-success-icon">
                <div class="swal2-success-circular-line-left"
                     style="background-color: rgb(255, 255, 255);"></div>
                <span class="swal2-success-line-tip"></span>
                <span class="swal2-success-line-long"></span>
                <div class="swal2-success-ring"></div>
                <div class="swal2-success-fix"
                     style="background-color: rgb(255, 255, 255);"></div>
                <div class="swal2-success-circular-line-right"
                     style="background-color: rgb(255, 255, 255);"></div>
              </div>

              <p class="success-message">
                {{'Forgot_password.forgot_password_msg'|translate}}
                <strong>{{forgetPasswordForm.value.email}}</strong> {{'Forgot_password.forgot_password_msg2'|translate}}
                <br/>
                {{'Forgot_password.forgot_password_msg3'|translate}}
              </p>

              <a class="btn btn-primary px-4  mb-2" [routerLink]="['/login']">
                {{'Back_to_login'|translate}}
              </a>

            </div>

          </div>
        </div>
      </div>
    </div>
    <nav class="login-card-footer-nav float-right">
      <span>{{'Language' | translate}}:</span>
      <ng-select [items]="languages" [(ngModel)]="selectedLanguage" [clearable]="false"
                 (change)="changeLang($event)" class="example-wrapper">
        <ng-template ng-label-tmp let-item="item">
          <div class="flex-align-center">
            <div class="iti__flag mr-2"
                 [ngClass]="item == 'EN' ? 'iti__gb' : 'iti__'+item | lowercase"></div>
            <span>{{item === 'US' ? 'EN' : item}}</span>
          </div>
        </ng-template>
      </ng-select>
    </nav>
  </div>
</main>
