export enum RoleEnum {
  FREELANCER = "ROLE_FREELANCER",
  PROVIDER = "PROVIDER"
}

export enum ContractModeEnum {
  ALL = "ALL",
  T_M = "T_M",
  BILL_ONLY = "BILL_ONLY",
  FIXED_PRICE = "FIXED_PRICE"
}

export enum CallForTenderOfferStatusEnum {
  SENT = "SENT",
  REJECTED = "REJECTED",
  SELECTED = "SELECTED",
  REPLIED = "REPLIED",
  PUBLISHED = "PUBLISHED",
  ACCEPTED = "ACCEPTED",
  REJECTED_BY_SA = "REJECTED_BY_SA",
  CANCELLED = "CANCELLED",
  CLOSED_BY_SUPPLIER = "CLOSED_BY_SUPPLIER"
}

export enum OfferTimeSlotStatusEnum {
  REFUSED = "REFUSED",
  ACCEPTED = "ACCEPTED"

}

export enum OfferNegotiationEnum {
  ACCEPT = 'ACCEPT',
  NEGOTIATING = 'NEGOTIATING',
  CLOSE = 'CLOSE',
}

