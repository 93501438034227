import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ProjectModel} from '../../../../../../shared/models/mission/project.model';
import {MyCompanyService} from '../../../../../company-config/services/my-company.service';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'project-view-detail',
  templateUrl: './project-view-detail.component.html',
  styleUrls: ['./project-view-detail.component.scss']
})
export class ProjectViewDetailComponent {

  @Input() project: ProjectModel;
  @Input() buyerId: string;
  
}
