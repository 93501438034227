import {Component, Input, OnInit} from '@angular/core';
import {PdfConfigModel} from '../../models/pdf-config.model';

@Component({
  selector: 'extended-pdf-preview',
  templateUrl: './extended-pdf-preview.component.html',
  styleUrls: ['./extended-pdf-preview.component.scss']
})
export class ExtendedPdfPreviewComponent implements OnInit {
  @Input() source;
  @Input() config: PdfConfigModel;

  constructor() { }

  ngOnInit(): void {}

}
