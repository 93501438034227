import {PointOfContactModel} from "./point-of-contact.model";

export class SupplierModel {
  id: string;
  supplierId: string;
  supplierName: string;
  pointOfContact: PointOfContactModel;

  constructor(object?: any) {
    if (object) {
      this.id = object.id;
      this.supplierId = object.supplierId;
      this.supplierName = object.supplierName;
      this.pointOfContact = new PointOfContactModel(object.pointOfContact);
    } else {
      this.pointOfContact = new PointOfContactModel();
    }

  }
}
