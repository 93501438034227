<div class="animated fadeIn">

  <div class="row mb-1" *ngIf="missionClient" @fadeIn>
    <div class="col-md-12">
      <div class="d-flex">
        <div>
          <div class="header">
            {{missionNumber + ' : ' + missionClient}}
          </div>

          <div class="sub-header" *ngIf="this.poNumber">
            {{"Mission_view.po_number"|translate}} {{this.poNumber}}
          </div>
        </div>

        <span
          class="ml-2 status-badge"
          [ngClass]="{
          'badge-success': validatedStatus.includes(this.missionStatus),
          'badge-primary': problemRefusedStatus.includes(this.missionStatus),
          'badge-info': waitingBuyerStatus.includes(this.missionStatus),
          'badge-purple': toDoStatus.includes(this.missionStatus)
          }"
        >
          {{ "Mission_view.status." + this.missionStatus | translate }}
        </span>
      </div>
    </div>
  </div>

  <ul class="nav nav-tabs" (click)="tabClick()">
    <ng-container *ngFor="let item of menuList">
      <li class="nav-item">
        <div class="tab-link"
             [ngClass]="item.icon === 'warning' ? 'tab-label-error' : 'tab-label'"
             routerLinkActive="active"
             [routerLink]="item.link"
        >
          <ng-container *ngIf="item.icon === 'warning'">
            <img
              alt="Warning"
              class="tab-icon"
              [src]="'assets/img/icon/warning.svg'"
            />
          </ng-container>
          {{ item.title }}
        </div>
      </li>
    </ng-container>
  </ul>
  <div class="mt-3">
    <router-outlet></router-outlet>
  </div>
</div>
