import {Component, HostListener, OnInit} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {IconSetService} from '@coreui/icons-angular';
import {freeSet} from '@coreui/icons';
import {TranslateService} from '@ngx-translate/core';
import {TranslatorService} from './core/services/translator.service';
import {AuthenticationService} from './core/services/authentication.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ModalIdleWarningComponent} from './shared/components/modal-idle-warning/modal-idle-warning.component';
import {commonProperties} from '../assets/environments/environment.common';

@Component({
  selector: 'body',
  template: '<router-outlet></router-outlet>',
  providers: [IconSetService],
})
export class AppComponent implements OnInit {
  userId: any;
  modalRef: BsModalRef<ModalIdleWarningComponent>;
  isAuthenticated: boolean = false;
  private isActive = false;

  constructor(
    private router: Router,
    public iconSet: IconSetService,
    private translate: TranslateService,
    private translator: TranslatorService,
    private authService: AuthenticationService,
    private modalService: BsModalService
  ) {
    iconSet.icons = {...freeSet};
    this.translate.use(this.translator.getLanguage());

  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);

    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.isAuthenticated = this.authService.isAuthenticated();
      }
    });
    setInterval(() => {
      if (!localStorage.getItem(commonProperties.tokenExpiration) || !localStorage.getItem(commonProperties.token)) {
        return;
      }
      const date = new Date(localStorage.getItem(commonProperties.tokenExpiration));
      const actual = new Date();
      const remainingMinutes = (date.getTime() - actual.getTime()) / 60 / 1000;
      if (this.isActive) {
        if (remainingMinutes <= 20) {
          this.refreshToken();
        }
      } else if (remainingMinutes <= 0) {
        this.router.navigate(['login']);
        if (this.modalRef) {
          this.modalRef.hide();
          this.modalRef = null;
        }
      } else if (remainingMinutes <= 2) {
        if (!this.modalRef) {
          this.modalRef = this.modalService.show(ModalIdleWarningComponent, {class: 'modal-lg modal-center'});
        }
      }
      this.isActive = false;
    }, 10000);

  }

  private refreshToken() {
    if (!this.authService.isAuthenticated()) {
      return;
    }
    this.authService.refreshToken().subscribe(data => {
      if (data) {
        this.authService.storeToken(data);
      }
    });
  }

  @HostListener('window:mousemove') refreshUserState() {
    if (!this.isActive) {
      this.isActive = true;
      if (this.modalRef) {
        if (this.authService.isAuthenticated()) {
          this.refreshToken();
        } else {
          this.router.navigate(['login']);
        }
        this.modalRef.hide();
        this.modalRef = null;
      }
    }
  }
}
