import {Component, Input} from '@angular/core';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'info-line',
  templateUrl: './info-line.component.html',
  styleUrls: ['./info-line.component.scss']
})
export class InfoLineComponent {
  @Input() title: string;
  @Input() valueText: string;
  @Input() value: string;
  @Input() icon: IconProp;
  @Input() color: string;
  @Input() iconColor: string;
}
