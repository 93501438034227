import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {MissionService} from '../../services/mission.service';
import {query, transition, trigger, useAnimation} from '@angular/animations';
import {slideIn50StaggerAnimation} from '../../../../shared/animation/common.animation';
import {RequestDetailModel} from '../../../../shared/models/mission/mission-detail.model';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'mission-detail',
  templateUrl: './mission-detail.component.html',
  styleUrls: ['./mission-detail.component.scss'],
  animations: [
    trigger('slideIn', [
      transition(':enter', [
        query('.slide-in-animation', [
          useAnimation(slideIn50StaggerAnimation)
        ], {optional: true})
      ])
    ])
  ]
})
export class MissionDetailComponent implements OnInit, OnDestroy {

  request: RequestDetailModel = new RequestDetailModel();
  poNumber: string;
  buyerId: string;

  private subscriptions: Subscription[] = [];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private logger: NGXLogger,
              private toastrService: ToastrService,
              private translateService: TranslateService,
              private missionService: MissionService) {
  }

  ngOnInit(): void {
    this.subscriptions.push(this.route.parent.paramMap.subscribe(param => {
        const missionId = param.get('missionId');
        this.getClientStep(missionId);
        this.getSupplierStep(missionId);
        this.request.requestId = missionId;
      }
    ));
  }

  getClientStep(missionId: any) {
    this.subscriptions.push(this.missionService.getMissionClientStepById(missionId).subscribe(
      (res: any) => {
        this.request.clientStep = res;
        this.poNumber = res.client.poNumber;
        this.buyerId = res.buyerId;
      }, error => {
        this.logger.error(error.url, '- STATUS :', error);
        this.toastrService.error(this.translateService.instant('global.ts.error'));
        this.router.navigate(['/mission']).then();
      }
    ));
  }


  getSupplierStep(missionId: any) {
    this.subscriptions.push(this.missionService.getMissionSupplierStepById(missionId).subscribe((data: any) => {
        this.request.supplierStep = data;

      }, error => {
        this.logger.error(error.url, '- STATUS :', error.status);
      this.toastrService.error(this.translateService.instant('global.ts.error'));
        this.router.navigate(['/mission']).then();
      }
    ));
  }

  getProviders(projectId, providers) {
    return providers.filter(provider => provider.projectId === projectId);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
