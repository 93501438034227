import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PurchaseOrderRoutingModule} from './purchase-order-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../../shared/shared.module';
import {PurchaseOrderListComponent} from './purchase-order-list/purchase-order-list.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {PurchaseOrderViewComponent} from './purchase-order-view/purchase-order-view.component';
import {PurchaseOrderCardComponent} from './purchase-order-list/purchase-order-card/purchase-order-card.component';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {AlertModule} from 'ngx-bootstrap/alert';
import {NgxFileDropModule} from 'ngx-file-drop';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    declarations: [
        PurchaseOrderListComponent,
        PurchaseOrderViewComponent,
        PurchaseOrderCardComponent
    ],
  imports: [
    CommonModule,
    PurchaseOrderRoutingModule,
    SharedModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    PaginationModule,
    AlertModule,
    NgxFileDropModule,
    TranslateModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PurchaseOrderModule {
}
