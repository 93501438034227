import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-upload-components',
  templateUrl: './upload-modal.component.html',
  styleUrls: ['./upload.modal.component.scss'],
})
export class UploadModalComponent {
  isFileUploaded: boolean;
  spinnerDisplay: boolean;
  source: any;
  file: any;

  constructor(
    private modalRef: MatDialogRef<UploadModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  close(): void {
    this.modalRef.close();
  }

  async getFile($event: File) {
    this.spinnerDisplay = true;
    if ($event) {
      this.isFileUploaded = true;
      this.file = $event;
      this.setPreviewFromFile($event);
    }
    this.spinnerDisplay = false;
  }

  private setPreviewFromFile(file: File) {
    this.source = URL.createObjectURL(new Blob([file], { type: file.type }));
  }

  reset() {
    this.isFileUploaded = false;
    this.source = null;
  }

  transferFile() {
    const data = {file: this.file, preview: this.source};
    this.modalRef.close(data);
  }

}
