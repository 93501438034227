export class ProjectManager {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  role: string;
  employeeId: number;
  companyId?: number;
  projectId?: number;
  authorities?: string;

  constructor(object?: ProjectManager) {
    this.authorities = object.authorities;
    this.companyId = object.companyId;
    this.email = object.email;
    this.firstName = object.firstName;
    this.id = object.id;
    this.lastName = object.lastName;
    this.phoneNumber = object.phoneNumber;
    this.employeeId = object.employeeId;
    this.role = object.role;
    this.projectId = object.projectId;
  }
}

export class ProjectModel {
  id?: string;
  projectName: string;
  projectManagers?: ProjectManager[];

  constructor(object?: ProjectModel) {
    if (object) {
      this.id = object.id;
      this.projectName = object.projectName;
      this.projectManagers = object.projectManagers;
    }
  }
}
