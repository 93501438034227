import {query, transition, trigger, useAnimation} from '@angular/animations';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {CountryISO, SearchCountryField} from 'ngx-intl-tel-input-gg';
import {NGXLogger} from 'ngx-logger';
import {ToastrService} from 'ngx-toastr';
import {forkJoin, Subject, Subscription} from 'rxjs';
import {AlertService} from '../../../core/services/alert.service';
import {regex} from '../../../shared/regex/form.regex';
import {UserEmailStatusEnum, UserStatusModel} from '../../../shared/models/employee/user-status.model';
import {EmployeeService} from '../services/employee.service';
import {slideIn50StaggerAnimation} from '../../../shared/animation/common.animation';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {AlertEnum} from '../../../shared/enums/Alert.enum';
import {MyCompanyService} from '../../company-config/services/my-company.service';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-update-employee',
  templateUrl: './update-employee.component.html',
  styleUrls: ['./update-employee.component.scss'],
  animations: [
    trigger('slideInAnimation', [
      transition(':enter', [
        query('.slide-in-animation', [
          useAnimation(slideIn50StaggerAnimation)
        ], {optional: true})
      ])
    ])
  ]
})
export class UpdateEmployeeComponent implements OnInit, OnDestroy {

  employee: any;
  updateEmployeeForm: FormGroup;
  searchCountryField = SearchCountryField;
  countryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.France];
  modalRef: BsModalRef;
  account: any;

  suppliersList: any[] = [];
  suppliersInput$ = new Subject<string>();
  supplierLoading = false;
  isProvider: boolean = false;

  private company: any;
  private employeeId: any;
  private subscriptions: Subscription[] = [];

  constructor(private employeeService: EmployeeService,
              private companyService: MyCompanyService,
              private authService: AuthenticationService,
              private translateService: TranslateService,
              private fb: FormBuilder,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private toastrService: ToastrService,
              private alertService: AlertService,
              private logger: NGXLogger) {
    this.updateEmployeeForm = this.fb.group({
      id: [null],
      firstName: [null, [Validators.required, Validators.pattern(regex.firstName), Validators.maxLength(50)]],
      lastName: [null, [Validators.required, Validators.pattern(regex.lastName), Validators.maxLength(50)]],
      email: [null, [Validators.required, Validators.pattern(regex.email), Validators.maxLength(100)]],
      phoneNumber: [null, [Validators.required]],
      title: [null, [Validators.pattern(regex.title), Validators.maxLength(50)]],
      suppliers: [null]
    });
  }

  ngOnInit(): void {
    this.listeningToCreateEmployeeFormChanges();
    this.employeeId = this.activatedRoute.snapshot.paramMap.get('employeeId');

    this.subscriptions.push(this.companyService.getCompanyById(this.authService.getCompanyId()).subscribe(
      (res: any) => {
        this.company = res;
        this.retrieveEmployeeById(this.employeeId);
        this.retrieveEmployeeAccountById(this.employeeId);
      },
      (error: any) => {
        this.logger.error(error.url, '- STATUS :', error.status);
      }
    ));
    this.loadSuppliers();
  }

  private listeningToCreateEmployeeFormChanges(): void {
    this.subscriptions.push(this.updateEmployeeForm.get('email').valueChanges.subscribe(emailChanges => {
      if (!this.updateEmployeeForm.get('email').errors && this.updateEmployeeForm.get('email').value) {
        this.testEmailEmployee(emailChanges);
      }
    }));
  }

  private testEmailEmployee(userEmail: string): void {
    this.subscriptions.push(this.employeeService.testUserEmail(userEmail).subscribe(
      (res: UserStatusModel) => {
        if (res.status === UserEmailStatusEnum.TAKEN) {
          if (res.employeeId === this.employeeId) {
            return;
          }
          this.updateEmployeeForm.get('email').setErrors({emailUsed: true});
        }
      })
    );
  }

  private retrieveEmployeeById(employeeId: any): void {
    this.subscriptions.push(this.employeeService.getEmployeeById(employeeId).subscribe(
      (res: any) => {
        this.employee = res;
        this.updateEmployeeForm.patchValue(this.employee);
        if (res.authorities[0].name === 'PROVIDER') {
          this.isProvider = true;
          this.updateEmployeeForm.updateValueAndValidity();
        }
        if (!this.canEdit()) {
          this.updateEmployeeForm.disable();
          this.updateEmployeeForm.get('suppliers').enable();
        }
      },
      (error: any) => {
        this.logger.error(error.url, '- STATUS :', error.status);
        if (error.error.status === 404) {
          this.alertService.addAlert('<strong>Employee not found!</strong> The employee doesn\'t exist', AlertEnum.WARNING, true);
          this.router.navigate(['employees']).then(() =>
            this.logger.debug('Redirection to employees list'));
        }
      }
    ));
  }

  private retrieveEmployeeAccountById(employeeId: any): void {
    this.subscriptions.push(this.authService.getEmployeeAccount(employeeId).subscribe(
      (res: any) => {
        this.account = res[0];
      },
      (error: any) => {
        this.logger.error(error.url, '- STATUS :', error.status);
      }
    ));
  }

  updateEmployeeFormSubmit(): void {
    const body = this.updateEmployeeForm.value;
    if (body.phoneNumber) {
      body.phoneNumber = body.phoneNumber.internationalNumber.replace(/\s/g, '').replace('-', '');
    }
    const observables$ = [];
    if (this.canEdit()) {
      observables$.push(this.employeeService.updateEmployeeByEmployeeId(this.employeeId, body));
    }
    if (this.isProvider) {
      const suppliers = [];
      body.suppliers.forEach((value: any) => suppliers.push(this.employeeService.mapSuppliersToEmployeeRef(value)));
      observables$.push(this.employeeService.updateSuppliers(this.employeeId, {
        suppliers: suppliers
      }));
    }
    delete body.suppliers;

    this.subscriptions.push(forkJoin(observables$).subscribe(
      (res: any) => {
        this.employee = res[0];
        this.toastrService.info(this.translateService.instant('employees.edit.success'));
        this.router.navigate(['/employees']);
      },
      (error: any) => {
        this.logger.error(error.url, '- STATUS :', error.status);
      }
    ));
  }

  loadSuppliers() {
    this.subscriptions.push(
      this.suppliersInput$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(value => this.supplierLoading = true)
      ).subscribe(value => {
        this.subscriptions.push(this.employeeService.getSuppliers(value).subscribe(
          (res: any) => {
            this.suppliersList = res.content;
            this.supplierLoading = false;
          },
          (error: any) => {

            this.suppliersList = [];
            this.logger.error('an error occurred when loading suppliers:', error);
            this.supplierLoading = false;
          }
        ));
      })
    );
  }

  compareSupplier(supplier1: any, supplier2: any): boolean {
    if (supplier1 && supplier2) {
      return supplier1.id === supplier2.id;
    }
    return false;
  }

  canEdit() {
    return this.authService.getEmployeeId() == this.employeeId;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(value => value.unsubscribe());
  }

}
