import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {PurchaseOrderModel} from "../../../shared/models/purchase-order/purchase-order.model";

@Injectable({
  providedIn: 'root'
})
export class PurchaseOrderSharedService {

  private selectedPurchaseOrder = new BehaviorSubject<PurchaseOrderModel>(null);

  constructor() {
  }

  public setSelectedPurchaseOrder(purchaseOrder: PurchaseOrderModel) {
    this.selectedPurchaseOrder.next(purchaseOrder);
  }

  public getSelectedPurchaseOrder(): Observable<PurchaseOrderModel> {
    return this.selectedPurchaseOrder.asObservable();
  }
}
