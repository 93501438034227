import {DuePaymentModel} from './due-payment.model';

export class MissionBillingModel {
  id: string;
  billingConfigId: string;
  contractId: string;
  contractName: string;
  contractMode: string;
  typeId: string;
  currency: string;
  clientValidation: boolean;
  amount: number;
  amountUsed: number;
  dueDatePayment: DuePaymentModel[];
  nbBills: number;
  duePaymentByDate: boolean;
  startDate: string;
  endDate: string;
  fees: number;
  feesUsed: number;
  onCall: number;
  onCallUsed: number;
  quantityByDate: boolean;

  constructor(object?: MissionBillingModel) {
    if (object) {
      this.id = object.id;
      this.billingConfigId = object.billingConfigId;
      this.contractId = object.contractId;
      this.contractName = object.contractName;
      this.contractMode = object.contractMode;
      this.typeId = object.typeId;
      this.currency = object.currency;
      this.clientValidation = object.clientValidation;
      this.amount = object.amount;
      this.amountUsed = object.amountUsed;
      this.dueDatePayment = object.dueDatePayment;
      this.nbBills = object.nbBills;
      this.duePaymentByDate = object.duePaymentByDate;
      this.onCall = object.onCall;
      this.onCallUsed = object.onCallUsed;
      this.fees = object.fees;
      this.feesUsed = object.feesUsed;
      this.startDate = object.startDate;
      this.endDate = object.endDate;
      this.quantityByDate = object.quantityByDate;
    }
  }
}
