import {Directive, ElementRef, HostListener, Renderer2} from '@angular/core';

@Directive({
  selector: '[onlyNumbers]'
})
export class OnlyNumbersDirective {
  constructor(private el: ElementRef<HTMLInputElement>, private renderer: Renderer2) { }


  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow number and separators
    const pattern = /[0-9]|\./;
    const inputChar = event.key;

    // Allow shortcut
    if (event.ctrlKey || event.altKey || event.metaKey) {
      return;
    }

    // Allow only some control keys
    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Delete'];
    if (!allowedKeys.includes(inputChar) && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedInput: string = (event.clipboardData).getData('text/plain').replace(/[^0-9.,]/g, '');

    // Update input with cleaned value
    this.renderer.setProperty(this.el.nativeElement, 'value', pastedInput);

    // Notify form control
    const eventInput = new Event('input', { bubbles: true });
    this.el.nativeElement.dispatchEvent(eventInput);
  }
}
