export interface MissionListModel {
  requestId: string;
  requestNumber: string;
  buyer: string;
  createdDate: string;
  contractMode: string;
  dailyRate: string;
  amount: string;
  nbOfBills: string;
  currency: string;
  status: RequestStatusEnum;
}

export enum RequestStatusEnum {
  SENT = 'SENT',
  TAKEN_INTO_ACCOUNT = 'TAKEN_INTO_ACCOUNT',
  CONTACT_INITIATED = 'CONTACT_INITIATED',
  PROVIDER_ASSIGNED = 'PROVIDER_ASSIGNED',
  WAITING_FOR_LEGAL_DOCUMENTS = 'WAITING_FOR_LEGAL_DOCUMENTS',
  LEGAL_DOCUMENTS_RECEIVED = 'LEGAL_DOCUMENTS_RECEIVED',
  LEGAL_DOCUMENTS_VALIDATED = 'LEGAL_DOCUMENTS_VALIDATED',
  CONTRACT_SENT = 'CONTRACT_SENT',
  CONTRACT_RECEIVED = 'CONTRACT_RECEIVED',
  CONTRACT_REFUSED = 'CONTRACT_REFUSED',
  CONTRACT_SIGNED = 'CONTRACT_SIGNED',
  ORDER_WAITING = 'ORDER_WAITING',
  ORDER_SENT = 'ORDER_SENT',
  ORDER_RECEIVED = 'ORDER_RECEIVED',
  ORDER_REFUSED = 'ORDER_REFUSED',
  ORDER_VALIDATED = 'ORDER_VALIDATED',
  MISSION_IN_PROGRESS = 'MISSION_IN_PROGRESS',
  MISSION_COMPLETED = 'MISSION_COMPLETED',
  CANCELLED = 'CANCELLED',
}
