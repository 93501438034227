import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../../core/guards/auth.guard';
import {CreateEmployeeComponent} from './create-employee/create-employee.component';
import {EmployeeListComponent} from './employee-list/employee-list.component';
import {RoleGuard} from '../../core/guards/role-guard.service';
import {UpdateEmployeeComponent} from './update-employee/update-employee.component';
import {CompletedGuard} from '../../core/guards/completed.guard';

const routes: Routes = [
  {
    path: '',
    component: EmployeeListComponent,
    canActivate: [AuthGuard, RoleGuard, CompletedGuard],
    data: {
      expectedRoles: ['ROLE_FREELANCER', 'ROLE_SUPPLIER', 'ROLE_SUPPLIER_ADMIN'],
    },
  },
  {
    path: 'create',
    component: CreateEmployeeComponent,
    canActivate: [AuthGuard, RoleGuard, CompletedGuard],
    data: {
      expectedRoles: ['ROLE_FREELANCER', 'ROLE_SUPPLIER', 'ROLE_SUPPLIER_ADMIN'],
      breadcrumb: 'Creation'
    }
  },
  {
    path: ':employeeId/update',
    component: UpdateEmployeeComponent,
    canActivate: [AuthGuard, RoleGuard, CompletedGuard],
    data: {
      expectedRoles: ['ROLE_FREELANCER', 'ROLE_SUPPLIER', 'ROLE_SUPPLIER_ADMIN'],
      breadcrumb: 'Update'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class EmployeeRoutingModule {
}
