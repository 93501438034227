import {Component, OnDestroy, OnInit} from '@angular/core';
import {InvoiceService} from '../../services/invoice.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ActivityTypeEnum} from '../../models/activity-type.enum';
import {ActivityModel, DuePaymentTypeEnum} from '../../models/activity.model';
import {FileUtilsService} from '../../../../shared/utils/file-utils.service';
import {requiredFileType} from '../../../../shared/validators/required-file-type.validator';
import {maximumSizeFile} from '../../../../shared/validators/maximum-size-file.validator';
import {
  ProformaBodyResourceModel,
  SupplierInvoiceConfig
} from '../../models/proforma-body-resource.model';
import {ToastrService} from 'ngx-toastr';
import {ToasterEnum} from '../../../../shared/enums/Toaster.enum';
import {MatDialog} from '@angular/material/dialog';
import {
  UploadModalComponent
} from '../../../../shared/components/upload-modal/upload-modal.component';
import {TranslateService} from '@ngx-translate/core';
import {BadgesService} from '../../../../shared/service/badges.service';
import {regex} from '../../../../shared/regex/form.regex';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {ActivityReportService} from "../../../activity-report/services/activity-report.service";
import {FeesService} from "../../../fees/services/fees.service";
import {OnCallService} from "../../../on-call/services/on-call.service";

@Component({
  selector: 'proforma-generation',
  templateUrl: './proforma-generation.component.html',
  styleUrls: ['./proforma-generation.component.scss']
})
export class ProformaGenerationComponent implements OnDestroy, OnInit {
  protected readonly faTimes = faTimes;
  proformaFile;
  proformaData: ProformaBodyResourceModel;
  activity: ActivityModel;
  invoiceProformaGenerationForm: FormGroup;
  invoiceTransmitForm: FormGroup;
  manualInvoiceFilename;
  manualInvoiceFileToUpload: File = null;
  advancePaymentInDaysValueChanged: boolean;
  advancePaymentDiscount: string = '--';
  subscriptions: Subscription[] = [];
  loading: boolean = false;
  supplierInvoiceConfig: SupplierInvoiceConfig;
  performingValidation = false;
  public currentDate: Date;
  public accountingMonth: Date;

  constructor(private invoiceService: InvoiceService,
              private fb: FormBuilder,
              private toastrService: ToastrService,
              private translateService: TranslateService,
              private activityReportService: ActivityReportService,
              private feeService: FeesService,
              private onCallService: OnCallService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              public uploadModal: MatDialog,
              private badgeService: BadgesService,
              private logger: NGXLogger,
  ) {
    this.currentDate = new Date();
  }

  getSupplierInvoiceConfig() {
    this.subscriptions.push(this.invoiceService.getSupplierInvoiceConfig(this.invoiceService.generateActivityId(this.activity))
      .subscribe((res: any) => {
        this.supplierInvoiceConfig = res;
        if (this.supplierInvoiceConfig) {
          this.constructInvoiceProformaGenerationForm();
        }
      }));
  }

  ngOnInit(): void {
    let ids = null;
    switch (this.activatedRoute.snapshot.params['type']) {
      case 'due-payment':
        this.subscriptions.push(this.invoiceService.getDuePaymentsById(this.activatedRoute.snapshot.params['id']).subscribe(value => {
          this.activity = this.invoiceService.mapDuePaymentToActivity(value);
          this.accountingMonth = new Date(this.activity.accountingMonth);
          this.getSupplierInvoiceConfig();
        }));
        break;
      case 'activity-sheet':
        this.subscriptions.push(this.activityReportService.getActivitySheetById(this.activatedRoute.snapshot.params['id']).subscribe(value => {
          this.activity = this.invoiceService.mapActivitySheetToActivity(value);
          this.accountingMonth = new Date(this.activity.accountingMonth);
          this.getSupplierInvoiceConfig();
        }));
        break;
      case 'on-call':
        ids = this.activatedRoute.snapshot.params['id'].split(',');
        this.onCallService.getOnCallById(parseInt(ids[0])).subscribe(value => {
          this.activity = this.invoiceService.mapOnCallToActivity(value)
          this.activity.onCallIds = ids;
          this.accountingMonth = new Date(this.activity.accountingMonth);
          this.getSupplierInvoiceConfig();
        });
        break;
      case 'fee':
        ids = this.activatedRoute.snapshot.params['id'].split(',');
        this.feeService.getFeeById(parseInt(ids[0])).subscribe(value => {
          this.activity = this.invoiceService.mapFeeToActivity(value)
          this.activity.feeIds = ids;
          this.accountingMonth = new Date(this.activity.accountingMonth);
          this.getSupplierInvoiceConfig();
        });
        break;
      default:
        this.router.navigate(['/accounting']);
    }
  }

  canDisplay(formGroup: FormGroup, formControl: string): boolean {
    if (formGroup.get(formControl).disabled) {
      return false;
    } else if (formGroup.get(formControl).pristine) {
      return false;
    } else {
      return formGroup.get(formControl).value !== '';
    }
  }

  clearInputField(formGroup: FormGroup, formControl: string): void {
    formGroup.get(formControl).reset();
  }

  invoiceProformaGenerationFormSubmit() {
    this.loading = true;
    // this.logger.info('Your form value is:', this.invoiceProformaGenerationForm.value);
    const proformaBodyRequest = this.invoiceService.prepareProformaBody(
      this.invoiceProformaGenerationForm.value,
      this.activity,
      this.advancePaymentInDaysValueChanged
    );
    // this.logger.info('Your proforma body request is:', proformaBodyRequest);

    this.subscriptions.push(
      this.invoiceService.generateProforma(proformaBodyRequest).subscribe(
        (res: any) => {
          this.invoiceProformaGenerationForm.disable();
          this.loading = false;
          this.proformaFile = URL.createObjectURL(res.body);
          this.constructInvoiceTransmitForm(proformaBodyRequest);
        },
        async (error: Required<{ url: string, status: number, error: any }>) => {
          const message = JSON.parse(await error.error.text());
          console.log(message.errors.supplierInvoicePostResource);
          if (message.errors.supplierInvoicePostResource != undefined && message.errors.supplierInvoicePostResource.length > 0) {
            message.errors.supplierInvoicePostResource.forEach(res => this.toastrService.error(res.message.replaceAll('&eacute;', 'é').replaceAll('&agrave;', 'à')));
          } else {
            this.toastrService.error(this.translateService.instant('proforma_generation.proforma-generation-ts.invoice-generation-form-submit.error400'));
          }
          this.loading = false;
          this.logger.error('An error occurred during proforma generation:', error);
        }
      )
    );
  }

  cancelProforma() {
    this.invoiceProformaGenerationForm.enable();
    this.invoiceProformaGenerationForm.reset();
    this.invoiceProformaGenerationForm.get('advancePaymentInDays').patchValue(this.supplierInvoiceConfig.billableUnder);
    this.manualInvoiceFileToUpload = null;
    this.proformaFile = null;
    this.loading = false;
  }

  invoiceTransmitFormSubmit() {
    this.performingValidation = true;
    const transmitData = Object.assign(new ProformaBodyResourceModel(), this.proformaData);
    transmitData.proforma = false;

    if (this.invoiceTransmitForm.enabled) {
      transmitData.manualInvoice = true;
      this.invoiceService.mergeValues(transmitData, this.invoiceTransmitForm.value);
      transmitData.invoiceDate.setHours(this.currentDate.getHours());
    }

    this.subscriptions.push(this.invoiceService.generateProforma(transmitData).subscribe(() => {
          this.performingValidation = false;
          this.loading = false;
          this.toastrService.success(this.translateService.instant(`Invoice.ts.${transmitData.manualInvoice ? 'transmit_manual' : 'transmit_auto'}`));
          this.badgeService.refreshInvoiceToGenerateBadge();
          this.router.navigate(['/accounting/invoices']);
        },
        (err: any) => {
          this.performingValidation = false;
          this.loading = false;
          this.handleValidationError(err.error);
        }
      )
    );
  }

  clearFile() {
    this.manualInvoiceFilename = null;
    this.manualInvoiceFileToUpload = null;
    this.invoiceTransmitForm.reset();
    this.disableManualInvoice();
  }

  async onFileChange(file: File) {
    this.manualInvoiceFilename = file.name;
    this.manualInvoiceFileToUpload = file;
    this.enableManualInvoice();
    this.invoiceTransmitForm.get('fileSize').setValue(this.manualInvoiceFileToUpload);
    const fileBase64 = await FileUtilsService.convertFileToBase64(this.manualInvoiceFileToUpload);
    this.invoiceTransmitForm.get('fileName').setValue(this.manualInvoiceFileToUpload.name);
    this.invoiceTransmitForm.get('fileContent').setValue(fileBase64);
  }

  enableManualInvoice() {
    this.invoiceTransmitForm.enable();
  }

  disableManualInvoice() {
    this.invoiceTransmitForm.disable();
  }

  isDuePaymentByDate() {
    return (this.activity.type === ActivityTypeEnum.DUE_PAYMENT && this.activity.duePaymentType === DuePaymentTypeEnum.DATE);
  }

  isDuePaymentByEvent() {
    return (this.activity.type === ActivityTypeEnum.DUE_PAYMENT && this.activity.duePaymentType === DuePaymentTypeEnum.EVENT);
  }

  isDuePayment() {
    return (this.activity.type === ActivityTypeEnum.DUE_PAYMENT);
  }

  isActivitySheet() {
    return this.activity.type === ActivityTypeEnum.ACTIVITY_SHEET;
  }

  /**
   * Build reactive form with rules on advancePayment changes
   * @private
   */
  private constructInvoiceProformaGenerationForm() {
    this.invoiceProformaGenerationForm = this.fb.group({
      invoiceNumber: [null, [Validators.pattern(regex.noLeadingTrailingSpacesPattern), Validators.required]],
      advancePaymentInDays: [this.supplierInvoiceConfig.billableUnder, [Validators.min(3)]]
    });

    const advancePaymentInDaysControl = this.invoiceProformaGenerationForm.get('advancePaymentInDays');
    this.subscriptions.push(advancePaymentInDaysControl.valueChanges.subscribe(advancePaymentDays => {
      if (advancePaymentDays) {
        if ([this.supplierInvoiceConfig.billableUnder.toString()].includes(advancePaymentDays)) {
          this.resetAdvancePayment();
          return;
        } else if (advancePaymentDays !== this.supplierInvoiceConfig.billableUnder) {
          this.advancePaymentInDaysValueChanged = true;
          advancePaymentInDaysControl.setValidators([
            Validators.required,
            Validators.pattern(regex.numberOnlyPattern),
            Validators.min(3),
            Validators.max(this.supplierInvoiceConfig.billableUnder)
          ]);
          advancePaymentInDaysControl.updateValueAndValidity({emitEvent: false}); // Unsubscribe before update
          advancePaymentInDaysControl.markAsTouched();

          if (this.invoiceProformaGenerationForm.get('advancePaymentInDays').valid) {
            this.getDiscount(advancePaymentDays);
          }
        }
      } else {
        this.advancePaymentDiscount = '--';
      }
    }));
  }

  resetAdvancePayment() {
    this.advancePaymentInDaysValueChanged = false;
    this.clearInputField(this.invoiceProformaGenerationForm, 'advancePaymentInDays');
    this.invoiceProformaGenerationForm.get('advancePaymentInDays').clearValidators();
    this.invoiceProformaGenerationForm.get('advancePaymentInDays').patchValue(this.supplierInvoiceConfig.billableUnder);
  }

  constructInvoiceTransmitForm(proformaData: ProformaBodyResourceModel) {
    this.proformaData = proformaData;
    this.invoiceTransmitForm = this.fb.group({
      fileSize: [null, [maximumSizeFile(2, 'mo')]],
      fileContent: [null, Validators.required],
      fileName: [null, [requiredFileType('pdf')]],
      invoiceDate: [null, [Validators.required]],
      taxAmount: [null, [Validators.required]],
      taxlessTotal: [null, [Validators.required]],
      totalIncludingTax: [null, [Validators.required]]
    });
    this.invoiceTransmitForm.disable();
  }

  openModal(): void {
    const modalRef = this.uploadModal.open(UploadModalComponent, {
      width: '658px',
      data: {},
    });

    modalRef.afterClosed().subscribe((response) => {
      if (response) {
        this.onFileChange(response.file);
        this.proformaFile = response.preview;
      }
    });
  }

  getDiscount(advancePayment) {
    this.invoiceService.getAdvancePaymentDiscount(advancePayment).subscribe(data => {
        this.advancePaymentDiscount = data.discountPercentage;
      },
      (error: any) => {
        this.advancePaymentDiscount = '--';
        this.logger.error('An error occurred fetching discount info:', error);
      });
  }

  private handleValidationError(error: any) {
    this.logger.error('An error occurred during invoice transmission:', error);
    if (!error.errors.supplierInvoicePostResource) {
      this.toastrService.error(this.translateService.instant('Invoice.ts.transmit_failure'));
      return;
    }

    const errorsArray = error.errors.supplierInvoicePostResource;
    if (errorsArray.find(element => element.message === 'CONTRACT_STATUS_EXPIRED')) {
      this.toastrService.error(this.translateService.instant('Invoice.error_message.CONTRACT_STATUS_EXPIRED'));
    }

    if (errorsArray.find(element => element.message === 'CONTRACT_STATUS_INVALID')) {
      this.toastrService.error(this.translateService.instant('Invoice.error_message.CONTRACT_STATUS_INVALID'));
    }

    if (errorsArray.find(element => element.message === 'INVALID_LEGAL_DOCUMENTS')) {
      this.toastrService.error(this.translateService.instant('Invoice.error_message.INVALID_LEGAL_DOCUMENTS'));
    }

    if (errorsArray.find(element => element.message === 'TAX_AMOUNT_INVALID')) {
      this.toastrService.error(this.translateService.instant('Invoice.error_message.TAX_AMOUNT_INVALID'));
    }

    if (errorsArray.find(element => element.message === 'TAXLESS_TOTAL_INVALID')) {
      this.toastrService.error(this.translateService.instant('Invoice.error_message.TAXLESS_TOTAL_INVALID'));
    }

    if (errorsArray.find(element => element.message === 'TOTAL_INCLUDING_TAX_INVALID')) {
      this.toastrService.error(this.translateService.instant('Invoice.error_message.TOTAL_INCLUDING_TAX_INVALID'));
    }
  }

  /**
   * Clear the activity for which the generation was started and unsubscribe from all the subscriptions made
   */
  ngOnDestroy(): void {
    this.subscriptions.map(subscription => subscription.unsubscribe());
  }
}
