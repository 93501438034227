import {Component, Input, OnInit} from "@angular/core";
import {transition, trigger, useAnimation} from "@angular/animations";
import {fadeInAnimation, slideInAnimation} from "../../../../../../shared/animation/common.animation";
import {MissionBillingModel} from "../../../../../../shared/models/mission/mission-billing.model";
import {MissionClientStepModel} from "../../../../../../shared/models/mission/mission-client-step.model";

@Component({
  selector: 'activities-view-detail',
  templateUrl: './activities-view-detail.component.html',
  styleUrls: ['./activities-view-detail.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        useAnimation(fadeInAnimation)
      ])
    ]),
    trigger('slideIn', [
      transition(':enter', [
        useAnimation(slideInAnimation)
      ])
    ])
  ]
})
export class ActivitiesViewDetailComponent {

  @Input() billings: MissionBillingModel;
  clientStep: MissionClientStepModel;
}
