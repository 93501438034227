import {NgModule} from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {OnCallListComponent} from "./on-call-list/on-call-list.component";
import {AuthGuard} from "../../core/guards/auth.guard";
import {CompletedGuard} from "../../core/guards/completed.guard";

const routes: Routes = [
  {
    path: '',
    component: OnCallListComponent,
    canActivate: [AuthGuard, CompletedGuard],
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OnCallRoutingModule {
}
