import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {NGXLogger} from "ngx-logger";
import {Observable, timer} from "rxjs";
import {map, take} from "rxjs/operators";

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss']
})
export class CounterComponent implements OnInit {

  counter$: Observable<number>;
  @Input() count: number;
  @Input() messageHeader: string;
  @Input() messageBody: string;
  @Input() messageEnd: string;

  constructor(public router: Router,
              public logger: NGXLogger) {

  }

  executeCounter(): void {
    this.counter$ = timer(0, 1000).pipe(
      take(this.count),
      map(() => {
        if (this.count === 1) {
          this.router.navigate(['/login']).then(() =>
            this.logger.debug("Redirection to login page"));
        }
        return --this.count;
      })
    );
  }

  ngOnInit(): void {
    this.executeCounter();
  }

}
