export class CallForTenderOffersListAdvancedSearchModel {
  reference: string;
  title: string;
  buyer: string;
  provider: string;
  status: string;

  constructor(object?: any) {
    if (object) {
      this.reference = object.reference;
      this.title = object.title;
      this.buyer = object.buyer;
      this.provider = object.provider;
      this.status = object.status;
    }
  }
}
