import {SupplierModel} from './supplier.model';
import {ProjectModel} from './project.model';
import {ProviderModel} from './provider.model';

export class MissionSupplierStepModel {
  requestId: string;
  unlinkedProviders: number;
  supplier: SupplierModel;
  projects: ProjectModel[];
  providers: ProviderModel[];

  constructor(object?: any) {
    if (object) {
      this.requestId = object.requestId;
      this.unlinkedProviders = object.unlinkedProviders;
      this.supplier = new SupplierModel(object.supplier);
      this.projects = object.projects;
      this.providers = object.providers;
    } else {
      this.supplier = new SupplierModel();
      this.projects = [];
      this.providers = [];
    }
  }
}
