<div
  *ngIf="
    billings.contractMode === 'FIXED_PRICE' ||
    billings.contractMode === 'BILL_ONLY'
  "
  @slideIn
>
  <div class="row">
    <div class="col">
      <table class="table table-material box-shadow-hover table-responsive-sm">
        <thead class="thead-normal">
        <tr>
          <th class="text-left">
            {{'Mission_view.billing.'+(billings.duePaymentByDate | booleanFormat: ["due_date", "event"])|translate}}
          </th>

          <th class="text-right">
            {{ "Mission_view.billing.amount" | translate }}
          </th>
        </tr>
        </thead>

        <tbody>
        <tr *ngFor="let payment of billings.dueDatePayment; let i = index">
          <td class="text-left" *ngIf="billings.duePaymentByDate">
            {{ payment.dueDate | localizedDate }}
          </td>
          <td class="text-left" *ngIf="!billings.duePaymentByDate">
            {{ payment.event }}
          </td>
          <td class="text-right text-highlight">
            {{ payment.amount }}
            {{ billings.currency | currencyFormat: "symbol" }}
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td class="text-left font-weight-strong">{{'Mission_view.billing.total' | translate}}</td>
          <td class="text-right text-highlight font-weight-strong">
            {{ getTotalDuePayment() }}
            {{ billings.currency | currencyFormat: "symbol" }}
          </td>
        </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>
