<main class="d-flex align-items-center min-vh-100 py-3 py-md-0">
  <div class="container">
    <div class="card login-card">
      <div class="row no-gutters">
        <div class="col-md-5">
          <img src="assets/img/isupplier-bg.jpg" alt="login" class="login-card-img">
        </div>
        <div class="col-md-7">
          <div class="card-body">
            <div class="brand-wrapper">
              <img src="assets/img/brand/logo.svg" alt="logo" class="img-fluid">
            </div>
            <p class="login-card-description">{{'Login.login_msg'|translate}}</p>
            <app-alert></app-alert>
            <form [formGroup]="loginForm" (ngSubmit)="submitLogin()">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <fa-icon [icon]="faUser"></fa-icon>
                  </span>
                </div>
                <input type="text" class="form-control" placeholder="{{'Login.email'|translate}}"
                       autocomplete="email"
                       formControlName="email" required>
              </div>
              <div class="input-group mb-4">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <fa-icon [icon]="faLock"></fa-icon>
                  </span>
                </div>
                <input type="password" class="form-control"
                       placeholder="{{'Login.password'|translate}}"
                       autocomplete="current-password"
                       formControlName="password" required>
              </div>
              <div class="row">
                <div class="col-6">
                  <button type="submit" [disabled]="!loginForm.valid || isLoggingIn || !isModified"
                          class="btn btn-primary px-4">{{'Login.login_button'|translate}}</button>
                </div>
                <div class="col-6 text-right">
                  <button type="button" class="btn btn-link px-0"
                          [routerLink]="['/forget-password']">{{'Forgot_password_title'|translate}}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <nav class="login-card-footer-nav float-right">
      <span class="mr-2">{{'Language' | translate}}:</span>
      <ng-select [items]="languages" [(ngModel)]="selectedLanguage" [clearable]="false"
                 (change)="changeLang($event)" class="example-wrapper">
        <ng-template ng-label-tmp let-item="item">
          <div class="flex-align-center">
            <div class="iti__flag mr-2"
                 [ngClass]="item == 'EN' ? 'iti__gb' : 'iti__'+item | lowercase"></div>
            <span>{{item === 'US' ? 'EN' : item}}</span>
          </div>
        </ng-template>
      </ng-select>
    </nav>
  </div>
</main>
