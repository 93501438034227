import {Component} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {NGXLogger} from 'ngx-logger';
import {MyCompanyService} from '../../../views/company-config/services/my-company.service';

@Component({
  selector: 'app-country-input',
  templateUrl: './country-input.component.html',
  styleUrls: ['./country-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CountryInputComponent,
      multi: true,
    },
  ],
})
export class CountryInputComponent implements ControlValueAccessor {
  countries: any[];
  selectedValue: any;
  disabled = false;
  changed: (selectedValue: any) => void;
  onTouched: () => void;

  constructor(private companyService: MyCompanyService, private logger: NGXLogger) {
    this.getCountries();
  }

  onOriginSearch(term: string, item: any) {
    return item.name.toLowerCase().includes(term.toLowerCase());
  }

  writeValue(value): void {
    this.selectedValue = value;
  }

  registerOnChange(fn: any): void {
    this.changed = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange(event: any) {
    this.changed(event);
  }

  getCountries(): void {
    this.companyService.getCountries().subscribe({
      next: (res: any) => this.countries = res,
      error: err => this.logger.error(err.url, '- STATUS :', err.status)
    });
  }
}
