export class ActivityAreaPageModel {
  content: ActivityAreaModel[];
  page: Page;
}

interface Page {
  totalElements: number;
  totalPages: number;
}

export class ActivityAreaModel {
  id: string;
  label?: string;
  children?: ActivityAreaModel[];

  constructor(id: string, label?: string, children?: ActivityAreaModel[]) {
    this.id = id;
    this.label = label;
    this.children = children;
  }
}

export class LinkActivityAreaCompanyModel {
  activityAreas: ActivityAreaModel[];
}

export class TranslationsModel {
  language: string;
  label: string;

  constructor(language: string, label: string) {
    this.language = language;
    this.label = label;
  }
}
