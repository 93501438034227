import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ProformaBodyResourceModel, SupplierInvoiceConfig} from '../models/proforma-body-resource.model';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {ActivityTypeEnum} from '../models/activity-type.enum';
import {ActivityModel, DuePaymentTypeEnum} from '../models/activity.model';
import {ListContentModel} from '../../../shared/models/list-content.model';
import {InvoiceAdvancedSearchModel} from '../models/invoice-advanced-search.model';
import {InvoicePatchBody} from '../models/invoice-patch-body.model';
import {InvoiceItemModel} from '../models/invoice-item.model';
import {commonProperties} from '../../../../assets/environments/environment.common';
import {environment} from '../../../../assets/environments/environment';

import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(private httpClient: HttpClient) {
  }

  generateActivityId(activity: ActivityModel) {
    const proformaBodyResource = new ProformaBodyResourceModel();
    if (activity.type == ActivityTypeEnum.ACTIVITY_SHEET) {
      proformaBodyResource.activitySheetId = activity.id;
    } else if (activity.type == ActivityTypeEnum.DUE_PAYMENT) {
      proformaBodyResource.duePaymentId = activity.id;
    } else if (activity.type == ActivityTypeEnum.FEE) {
      proformaBodyResource.feeIds = activity.feeIds;
    } else {
      proformaBodyResource.onCallIds = activity.onCallIds;
    }
    return proformaBodyResource;
  }

  prepareProformaBody(value: any, activity: ActivityModel, isAdvancePayment: boolean) {
    const proformaBodyResource = this.generateActivityId(activity);
    proformaBodyResource.invoiceNumber = value.invoiceNumber;
    proformaBodyResource.advancePayment = isAdvancePayment;

    if (isAdvancePayment) {
      proformaBodyResource.advancePaymentInDays = value.advancePaymentInDays;
    }

    return proformaBodyResource;
  }

  getDuePaymentsById(id: any) {
    return this.httpClient.get(environment.api_root + commonProperties.duePaymentsById.replace(':duePaymentId', id));
  }

  getDuePaymentsWaitingForGeneration(page: number, size: any, sort: any, search: any) {
    let params = new HttpParams();
    if (search) {
      params = params.set('search', search);
    }
    params = params.set('validated', 'true');
    params = params.set('invoicable', 'true');
    params = params.set('invoiceGenerated', 'false');
    params = params.set('size', size.toString());
    params = params.set('page', page.toString());
    params = params.set('sort', sort);

    return this.httpClient.get(environment.api_root + commonProperties.duePayments, {params});
  }

  getFeesWaitingForGeneration(page: number, size: any, sort: any, search: any) {
    let params = new HttpParams();
    if (search) {
      params = params.set('search', search);
    }
    params = params.set('status', 'VALIDATED');
    params = params.set('invoiceGenerated', 'false');
    params = params.set('invoicable', 'true');
    params = params.set('size', size.toString());
    params = params.set('page', page.toString());
    params = params.set('sort', sort);
    return this.httpClient.get(environment.api_root + commonProperties.feesGrouped, {params});
  }

  getOnCallsWaitingForGeneration(page: number, size: any, sort: any, search: any) {
    let params = new HttpParams();
    if (search) {
      params = params.set('search', search);
    }
    params = params.set('status', 'VALIDATED');
    params = params.set('invoicable', 'true');
    params = params.set('invoiceGenerated', 'false');
    params = params.set('size', size.toString());
    params = params.set('page', page.toString());
    // params = params.set('sort', sort);
    return this.httpClient.get(environment.api_root + commonProperties.onCallsGrouped, {params});
  }

  generateProforma(proformaBodyRequest: ProformaBodyResourceModel) {
    const headers = new HttpHeaders().set('Accept', 'application/pdf');

    return this.httpClient.post<any>(environment.api_root + commonProperties.invoices, proformaBodyRequest, {
      observe: 'response',
      headers: headers,
      responseType: proformaBodyRequest.proforma ? 'blob' as 'json' : 'json',
    });
  }

  getSupplierInvoiceConfig(proformaBodyRequest: ProformaBodyResourceModel) {
    let params = new HttpParams();
    if (proformaBodyRequest.activitySheetId) {
      params = params.set('activitySheetId', proformaBodyRequest.activitySheetId.toString());

    } else if (proformaBodyRequest.duePaymentId) {
      params = params.set('duePaymentId', proformaBodyRequest.duePaymentId.toString());

    } else if (proformaBodyRequest.onCallIds) {
      params = params.set('onCallIds', proformaBodyRequest.onCallIds.toString());

    } else if (proformaBodyRequest.feeIds) {
      params = params.set('feeIds', proformaBodyRequest.feeIds.toString());

    }
    return this.httpClient.get<SupplierInvoiceConfig>(environment.api_root + commonProperties.supplierInvoiceConfig, {params});
  }

  patchInvoice(supplierInvoiceId: string, data: InvoicePatchBody): Observable<InvoiceItemModel> {
    return this.httpClient.patch<InvoiceItemModel>(environment.api_root + commonProperties.invoicePatch.replace(':id', supplierInvoiceId), data);
  }

  getInvoicesByPage(page: number, size: number, sort: string, search: string, advancedSearch: InvoiceAdvancedSearchModel): Observable<ListContentModel> {
    let params = new HttpParams();
    if (search) {
      params = params.set('search', search);
    } else if (advancedSearch) {
      if (advancedSearch.number) {
        params = params.set('invoiceNumber', advancedSearch.number);
      }
      if (advancedSearch.purchaseOrderNumber) {
        params = params.set('purchaseOrderNumber', advancedSearch.purchaseOrderNumber);
      }
      if (advancedSearch.buyerName) {
        params = params.set('buyerName', advancedSearch.buyerName);
      }
      if (advancedSearch.supplierName) {
        params = params.set('supplierName', advancedSearch.supplierName);
      }
      if (advancedSearch.status) {
        params = params.set('status', advancedSearch.status);
      }
      if (advancedSearch.type) {
        params = params.set('types', advancedSearch.type);
      }
      if (advancedSearch.mode) {
        params = params.set('modes', advancedSearch.mode);
      }
      if (advancedSearch.contractMode) {
        params = params.set('contractModes', advancedSearch.contractMode);
      }
      if (advancedSearch.credited) {
        params = params.set('credited', String(advancedSearch.credited));
      }
    }

    params = params.set('page', page.toString()).set('size', size.toString());

    if (sort) {
      const sortPattern = /([^;,]+,[^;,]+);?/g;
      const sortMatches = sort.match(sortPattern);

      if (sortMatches) {
        const sortPairs = sortMatches.map(match => match.split(','));
        sortPairs.forEach(pair => {
          params = params.append('sort', pair[0] + ',' + pair[1]);
        });
      }
    }

    return this.httpClient.get<ListContentModel>(environment.api_root + commonProperties.invoices, {params})
    .pipe<ListContentModel>(
      map(
        listContentModel => {
          listContentModel.content.forEach(
            (invoice: InvoiceItemModel) => {
              invoice.status = this.transformStatusEnum(invoice.status);
            }
          )
          return listContentModel;
        }
      )
    );
  }

  downloadFile(id): Observable<HttpResponse<Blob>> {
    const headers = new HttpHeaders().set('Accept', 'application/pdf');
    const params = new HttpParams();
    params.set('proforma', 'false');
    return this.httpClient.get<Blob>(environment.api_root + commonProperties.invoiceFile.replace(':id', id), {
      observe: 'response',
      headers: headers,
      params: params,
      responseType: 'blob' as 'json'
    });
  }

  mapActivitySheetToActivity(activitySheet: any): ActivityModel {
    const activity = new ActivityModel();
    activity.id = activitySheet.id;
    activity.employeeName = activitySheet.employeeName;
    activity.accountingMonth = activitySheet.accountingMonth;
    activity.purchaseOrderNumber = activitySheet.purchaseOrderNumber;
    activity.buyerName = activitySheet.buyerCompanyName;
    activity.quantity = activitySheet.sum;
    activity.projectName = activitySheet.projectName;
    activity.type = ActivityTypeEnum.ACTIVITY_SHEET;
    return activity;
  }

  mapDuePaymentToActivity(duePayment: any): ActivityModel {
    const activity = new ActivityModel();
    activity.id = duePayment.id;
    activity.buyerName = duePayment.buyerName;
    activity.projectName = duePayment.projectName;
    activity.purchaseOrderNumber = duePayment.purchaseOrderNumber;

    if (duePayment.type === DuePaymentTypeEnum.DATE) {
      activity.duePaymentType = DuePaymentTypeEnum.DATE;
      activity.description = duePayment.dueDate;
    } else {
      activity.duePaymentType = DuePaymentTypeEnum.EVENT;
      activity.description = duePayment.event;
    }
    activity.type = ActivityTypeEnum.DUE_PAYMENT;

    return activity;
  }

  mapFeeToActivity(fee: any) {
    const activity = new ActivityModel();
    activity.buyerName = fee.buyerCompanyName;
    activity.employeeName = fee.employeeName;
    activity.feeIds = fee.ids;
    activity.quantity = fee.quantity;
    activity.accountingMonth = fee.period;
    activity.purchaseOrderNumber = fee.purchaseOrderNumber;
    activity.projectName = fee.projectName;
    activity.type = ActivityTypeEnum.FEE;
    return activity;
  }


  mapOnCallToActivity(onCall: any) {
    const activity = new ActivityModel();
    activity.buyerName = onCall.buyerCompanyName;
    activity.employeeName = onCall.employeeName;
    activity.onCallIds = onCall.ids;
    activity.quantity = onCall.quantity;
    activity.accountingMonth = onCall.period;
    activity.purchaseOrderNumber = onCall.purchaseOrderNumber;
    activity.projectName = onCall.projectName;
    activity.type = ActivityTypeEnum.ON_CALL;
    return activity;
  }

  mergeValues(obj, src) {
    this.forEach(src, (value, key) => {
      if (value !== null) {
        if (typeof value === 'object') {
          if (obj[key] === undefined || typeof obj[key] !== 'object') {
            obj[key] = {}; // Create a new object if key doesn't exist or is not an object
          }

          if (value instanceof Date) {
            obj[key] = new Date(value); // Copy the Date object
          } else {
            this.mergeValues(obj[key], value);
          }
        } else {
          obj[key] = value;
        }
      }
    });
  }

  private forEach(target, fn) {
    const keys = Object.keys(target);
    let i = -1;
    while (++i < keys.length) {
      fn(target[keys[i]], keys[i]);
    }
  }

  mapAdvancedSearchForm(params: any): InvoiceAdvancedSearchModel {
    return new InvoiceAdvancedSearchModel(params);
  }

  getAdvancePaymentDiscount(advancePayment): Observable<any> {
    return this.httpClient.get<any>(environment.api_root + commonProperties.invoiceDiscount.replace(':advanceDays', advancePayment));
  }


  private transformStatusEnum(enumValue: string): string {
    if (enumValue.startsWith('VALIDATED')) {
      return 'VALIDATED';
    } else if (enumValue.startsWith('STAND_BY')) {
      return 'STAND_BY';
    } else if (enumValue.startsWith('WAITING')) {
      return 'WAITING';
    } else {
      return enumValue;
    }
  }


  cancelCreditNoteRequest(id: string) {
    return this.httpClient.patch(environment.api_root + commonProperties.cancelCreditNoteRequestSupplier.replace(':invoiceId', id), {});
  }
}
