import {CommonModule, TitleCasePipe} from '@angular/common';
import {NgModule} from '@angular/core';

import {MarketplaceRoutingModule} from './marketplace-routing.module';
import {SharedModule} from '../../shared/shared.module';
import {CallForTenderListComponent} from './call-for-tender-list/call-for-tender-list.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {NgSelectModule} from '@ng-select/ng-select';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {CallForTenderListCardComponent} from './call-for-tender-list/call-for-tender-list-card/call-for-tender-list-card.component';
import {TranslateModule} from '@ngx-translate/core';
import { CallForTenderOfferCreationComponent } from './call-for-tender-offer-creation/call-for-tender-offer-creation.component';
import {AvatarModule} from 'ngx-avatars';
import { CallForTenderOffersListComponent } from './call-for-tender-offers-list/call-for-tender-offers-list.component';
import {CallForTenderOffersListCardComponent} from './call-for-tender-offers-list/call-for-tender-offers-list-card/call-for-tender-offers-list-card.component';
import {AlertModule} from 'ngx-bootstrap/alert';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';


@NgModule({
  declarations: [
    CallForTenderListComponent,
    CallForTenderListCardComponent,
    CallForTenderOfferCreationComponent,
    CallForTenderOffersListComponent,
    CallForTenderOffersListCardComponent,
  ],
    imports: [
        CommonModule,
        MarketplaceRoutingModule,
        SharedModule,
        ReactiveFormsModule,
        PaginationModule,
        NgSelectModule,
        InfiniteScrollModule,
        TranslateModule.forChild(),
        AvatarModule,
        FormsModule,
        AlertModule,
        MatIconModule,
        MatTooltipModule,
        TitleCasePipe
    ]
})
export class MarketplaceModule {
}
