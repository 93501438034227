import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import {AlertService} from '../../../core/services/alert.service';
import {NGXLogger} from 'ngx-logger';
import {requiredFileType} from '../../../shared/validators/required-file-type.validator';
import {maximumSizeFile} from '../../../shared/validators/maximum-size-file.validator';
import {FileUtilsService} from '../../../shared/utils/file-utils.service';
import {
  OnCallEnvelopeModel,
  OnCallModel,
  OnCallParamModel, OnCallPostBody,
  WorkItemModel
} from '../../../shared/models/on-call/on-call.model';
import {OnCallService} from '../services/on-call.service';
import {OnCallStatusEnum} from '../../../shared/enums/OnCall.status.enum';
import * as moment from 'moment';
import {ContractModeEnum} from '../../../shared/enums/CallForTenderOffers.enum';
import {OnCallUnitTypeEnum} from '../../../shared/enums/OnCallUnitTypeEnum';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from "../../../core/services/authentication.service";

@Component({
  selector: 'update-on-call-modal',
  templateUrl: './update-on-call-modal.component.html',
  styleUrls: ['./update-on-call-modal.component.scss']
})
export class UpdateOnCallModalComponent implements OnInit, OnDestroy {
  @Output() actionConfirmed = new EventEmitter<any>();
  @Output() actionCanceled = new EventEmitter();

  subscriptions: Subscription[] = [];
  OnCallStatusEnum = OnCallStatusEnum;
  OnCallUnitTypeEnum = OnCallUnitTypeEnum;

  source: any;
  fileToUpload: File = null;
  labelImport: string;
  fileUploaded: boolean = false;

  onCallForm: FormGroup;

  currentOnCallEnvelope: OnCallEnvelopeModel;
  currentOnCall: OnCallModel;
  currentParam: OnCallParamModel = null;
  currentType: WorkItemModel;

  timepickerTime: Date;

  constructor(public modalRef: BsModalRef,
              private onCallService: OnCallService,
              private authService: AuthenticationService,
              private toastrService: ToastrService,
              private alertService: AlertService,
              private fb: FormBuilder,
              private logger: NGXLogger,
              private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.onCallForm = this.fb.group({
      purchaseOrder: [null, [Validators.required]],
      date: [null, [Validators.required]],
      type: [null, [Validators.required]],
      dailyRate: [null, [Validators.required]],
      time: [null, [Validators.required, Validators.min(0)]],
      quantity: [null, [Validators.required, Validators.min(0)]],
      comment: [null],
      file: [null, [requiredFileType(['pdf'])]],
      fileContent: [null],
      fileName: [null, [requiredFileType('pdf')]],
      fileSize: [null, [maximumSizeFile(2, 'mo')]],
    });

    if (this.currentOnCall.workUnit === 'HOUR') {
      this.timepickerTime = new Date();
      this.timepickerTime.setHours(Number(this.currentOnCall.time.substring(0, 2)));
      this.timepickerTime.setMinutes(Number(this.currentOnCall.time.substring(3, 5)));
    }

    this.onCallForm.patchValue({
      purchaseOrder: this.currentOnCall.purchaseOrderNumber,
      type: this.currentOnCall.workItemReference,
      date: this.currentOnCall.date,
      dailyRate: this.currentOnCallEnvelope.dailyRate ? this.currentOnCallEnvelope.dailyRate : this.currentOnCall.dailyRate,
      quantity: this.currentOnCall.quantity,
      time: this.timepickerTime,
      comment: this.currentOnCall.comment,
      fileName: this.currentOnCall.fileName
    });

    if (this.onCallForm.get('fileName').value !== null) {
      this.fileUploaded = true;
    }

    this.selectedPO();
    this.onCallForm.get('purchaseOrder').disable();
    if (this.currentOnCall.status !== OnCallStatusEnum.REFUSED) {
      this.onCallForm.disable();
    }
  }

  selectedPO() {
    if (this.currentOnCallEnvelope.contractMode === ContractModeEnum.BILL_ONLY || this.currentOnCallEnvelope.contractMode === ContractModeEnum.FIXED_PRICE) {
      this.onCallForm.get('dailyRate').enable();
    } else {
      this.onCallForm.get('dailyRate').disable();
    }


    this.subscriptions.push(this.onCallService.getOnCallParam(this.currentOnCall.onCallConfigId.toString()).subscribe((res: OnCallParamModel) => {
      res.workItems = res.workItems.sort((a, b) => {
        return a.reference > b.reference ? 1 : -1;
      });
      this.currentParam = res;
      this.selectedType();
    }));
  }

  toggleTime(string) {
    if (string === 'DAY' || string === 'PACKAGE_DAY') {
      this.onCallForm.get('time').disable();
    } else {
      if (this.currentOnCall.status === OnCallStatusEnum.REFUSED) {
        this.onCallForm.get('time').enable();
      }
    }
  }

  selectedType() {
    this.currentType = this.currentParam.workItems.find(aType => aType.reference === this.onCallForm.get('type').value);
    this.toggleTime(this.currentType.workUnit);
  }

  async onFileChange(files: FileList) {
    this.labelImport = Array.from(files)
      .map(f => f.name)
      .join(', ');
    this.fileToUpload = files.item(0);
    this.onCallForm.get('fileSize').setValue(this.fileToUpload);
    const fileBase64 = await FileUtilsService.convertFileToBase64(this.fileToUpload);
    this.onCallForm.get('fileName').setValue(this.fileToUpload.name);
    this.onCallForm.get('fileContent').setValue(fileBase64);
    this.fileUploaded = false;
  }

  downloadOnCall() {
    if (this.fileUploaded === true) {
      this.subscriptions.push(this.onCallService.downloadOnCallFileById(this.currentOnCall.id).subscribe(
        (data: any) => {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(data.body);
          a.download = this.currentOnCall.fileName;
          // this.logger.info('your file: ', a);
          a.click();
          a.remove();
        },
        (error: any) => {
          this.logger.error(error.url, '- STATUS :', error);
        }
      ));
    }
  }

  updateAction(): void {
    this.logger.info('updating on-call', this.onCallForm.getRawValue());

    this.onCallService.putOnCall(this.currentOnCall.id, this.mapModalDataToPutBody(this.onCallForm.getRawValue())).subscribe({
      next: value => {
        this.actionConfirmed.emit(value);
        this.toastrService.info(this.translateService.instant('On_call_ts.successful_on_call_creation'));
        this.modalRef.hide();
      },
      error: err => {
        this.logger.error(err.url, '- STATUS :', err.status);
        if (err.error.errors.onCallResource.length > 0 ) {
          err.error.errors.onCallResource.filter(x => !x.message.endsWith('Invalid On-Call POST/PUT resource')).forEach(res => this.toastrService.info(res.message.replaceAll('&eacute;', 'é').replaceAll('&agrave;', 'à')));
        } else {
          this.toastrService.error(this.translateService.instant('global.ts.error'));
        }
        this.modalRef.hide();
      }
    });
  }

  deleteFile() {
    this.onCallForm.get('fileName').setValue(null);
    this.onCallForm.get('fileContent').setValue(null);
    this.fileUploaded = false;
  }

  cancelAction(): void {
    this.actionCanceled.emit();
    this.modalRef.hide();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(value => value.unsubscribe());
  }

  mapModalDataToPutBody(object: any): OnCallPostBody {
    const result = new OnCallPostBody();

    result.buyerTenantId = this.currentOnCallEnvelope.buyerTenantId;
    if (object.comment) {
      result.comment = object.comment;
    }
    result.dailyRate = object.dailyRate;
    result.date = object.date;
    if (object.fileContent) {
      result.fileContent = object.fileContent;
      result.fileName = object.fileName;
    }
    result.onCallConfigId = this.currentParam.id;
    result.purchaseOrderNumber = object.purchaseOrder;
    result.quantity = object.quantity;
    if (this.currentType.workUnit === 'HOUR') {
      result.time = moment(object.time).format('HH:mm');
    }
    result.workItemId = this.currentType.id;
    result.type = object.type;
    result.isPeriodCheck = false;

    return result;
  }

  isProvider() {
    return this.authService.isUserProvider();
  }
}
