export class PointOfContactModel {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;

  constructor(object?: PointOfContactModel) {
    if (object) {
      this.firstName = object.firstName;
      this.lastName = object.lastName;
      this.email = object.email;
      this.phoneNumber = object.phoneNumber;
    }
  }
}
