import {CategoryModel} from './category.model';
import {WorkPlaceModel} from './work-place.model';
import {LeagueMinimalModel} from './league-minimal.model';
import {ActivityAreaModel} from '../../../views/company-config/activity-areas/activity-area.model';

export class CallForTenderModel {
  id: string;
  reference: string;
  title: string;
  startDate: string;
  quantity: string;
  quantityByDate: string;
  closedDate: string;
  category: CategoryModel;
  adr: number;
  currency: string;
  status: string;
  technologies: string[];
  workplace: WorkPlaceModel;
  nbDaysTeleworking: number;
  allowBusinessTrip: boolean;
  businessTripComment: string;
  description: string;
  buyerName: string;
  open: boolean;
  watching: boolean;
  createdBy: string;
  leagues: LeagueMinimalModel[];
  tenantId: string;
  billingConfigId: string;
  contractId: string;
  contractMode: string;
  endDate: string;
  dailyRateByDate: string;
  minDailyRate: number;
  maxDailyRate: number;
  minAmount: number;
  maxAmount: number;
  attachmentFile: string;
  attachmentFiles: string;
  freelanceOfferExist: boolean;
  cftSkills: string[];
  otherCategory: string;
  activityArea: ActivityAreaModel;

  constructor(object?: any) {
    if (object) {
      this.id = object.id;
      this.reference = object.reference;
      this.title = object.title;
      this.startDate = object.startDate;
      this.quantity = object.quantity;
      this.quantityByDate = object.quantityByDate;
      this.closedDate = object.closedDate;
      this.category = new CategoryModel(object.category);
      this.adr = object.adr;
      this.currency = object.currency;
      this.status = object.status;
      this.technologies = object.technologies;
      this.watching = object.watching;
      this.createdBy = object.createdBy;
      this.workplace = new WorkPlaceModel(object.workplace);
      this.nbDaysTeleworking = object.nbDaysTeleworking;
      this.allowBusinessTrip = object.allowBusinessTrip;
      this.businessTripComment = object.businessTripComment;
      this.description = object.description;
      this.buyerName = object.buyerName;
      this.open = object.open;
      this.leagues = object.leagues;
      this.tenantId = object.tenantId;
      this.billingConfigId = object.billingConfigId;
      this.contractId = object.contractId;
      this.contractMode = object.contractMode;
      this.endDate = object.endDate;
      this.dailyRateByDate = object.dailyRateByDate;
      this.minDailyRate = object.minDailyRate;
      this.maxDailyRate = object.maxDailyRate;
      this.minAmount = object.minAmount;
      this.maxAmount = object.maxAmount;
      this.attachmentFile = object.attachmentFile;
      this.attachmentFiles = object.attachmentFiles;
      this.cftSkills = object.cftSkills;

    } else {
      this.workplace = new WorkPlaceModel();
      this.category = new CategoryModel();
    }
  }
}
