<div @slideInStagger>
  <div class="row mt-4 slide-in-animation">
    <div class="col-md-12">
      <table class="table table-material box-shadow-hover table-responsive-sm">
        <thead class="thead-normal">
        <tr>
          <th class="text-center">{{"to_generate.column.project"|translate}}</th>
          <th class="text-center">{{"to_generate.column.purchase_order"|translate}}</th>
          <th class="text-center">{{"to_generate.column.period"|translate}}</th>
          <th>{{"to_generate.column.client"|translate}}</th>
          <th class="text-center">{{"to_generate.column.contract"|translate}}</th>
          <th class="text-center">{{"to_generate.column.provider"|translate}}</th>
          <th class="text-center">{{"to_generate.column.quantity"|translate}}</th>
          <th class="text-center">{{"to_generate.column.action"|translate}}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let fee of listContent; let i = index">
          <td class="text-center">
            {{ fee.projectName }}
          </td>
          <td class="text-center">
          {{ fee.purchaseOrderNumber }}
          </td>
          <td class="text-center">
            {{ fee.period | date: "MMM yyyy" }}
          </td>
          <td>
            {{ fee.client }}
          </td>
          <td class="text-center">
            {{ fee.contractMode }}
          </td>

          <td class="text-center">
            {{ fee.consultant }}
          </td>
          <td class="text-center">
            {{ fee.quantity }}
          </td>
          <td class="text-center">
            <button
              class="btn btn-outline-purple"
              type="button"
              [routerLink]="['/accounting/fee/', fee.ids.toString(), 'proforma-generation']"
            >
              <fa-icon [icon]="faPencil"></fa-icon>
              {{"to_generate.button_generate"|translate}}
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="row mt-2 slide-in-animation">
    <div class="col-md-12">
      <pagination
        class="float-right"
        [totalItems]="totalElements"
        [maxSize]="5"
        [boundaryLinks]="true"
        [ngModel]="page"
        (pageChanged)="changePage($event)"
        [itemsPerPage]="size"
        previousText="&lsaquo;"
        nextText="&rsaquo;"
        firstText="&laquo;"
        lastText="&raquo;"
      >
      </pagination>
    </div>
  </div>
</div>
