import {Component, OnDestroy, OnInit} from '@angular/core';
import {MenuItemModel} from '../../../shared/models/menu-item.model';
import {Subscription} from 'rxjs';
import {ActivityReportService} from '../../activity-report/services/activity-report.service';
import {NGXLogger} from 'ngx-logger';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'to-generate',
  templateUrl: './to-generate.component.html',
  styleUrls: ['./to-generate.component.scss'],
})
export class ToGenerateComponent implements OnInit, OnDestroy {
  menu: MenuItemModel[] = [];
  private subscriptions: Subscription[] = [];

  constructor(
    private activityReportService: ActivityReportService,
    private translateService: TranslateService,
    private logger: NGXLogger
  ) {
    this.constructMenu();
  }

  ngOnInit(): void {
  }

  private constructMenu(): void {
    this.subscriptions.push(
      this.activityReportService.getActivitySheetPendingInvoices().subscribe(
        (response) => {
          this.menu = [];
          this.subscriptions.push(this.translateService.get('Mission_view.billing.activity_sheets')
            .subscribe((translated: string) => {
              this.menu.push(
                new MenuItemModel(
                  translated,
                  'activity-sheets',
                  response.activitySheet
                )
              );
            })
          );

          this.subscriptions.push(this.translateService.get('Mission_view.billing.due_date')
            .subscribe((translated: string) => {
              this.menu.push(
                new MenuItemModel(
                  translated,
                  'due-payments',
                  response.duePayment
                )
              );
            })
          );

          this.subscriptions.push(this.translateService.get('Mission_view.billing.on_call')
            .subscribe((translated: string) => {
              this.menu.push(
                new MenuItemModel(translated, 'on-calls', response.onCall)
              );
            })
          );

          this.subscriptions.push(this.translateService.get('Mission_view.billing.fees')
            .subscribe((translated: string) => {
              this.menu.push(
                new MenuItemModel(translated, 'fees', response.fee)
              );
            })
          );
        },
        (error: any) => {
          this.logger.error(error.url, '- STATUS :', error.status);
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((value) => value.unsubscribe());
  }

}
