<pdf-viewer class="pdf-viewer"
            [src]="srcPdf"
            [show-all]="false"
            [original-size]="false"
            [(page)]="numPage"
            (after-load-complete)="afterLoadComplete($event)"
            zoom="{{pdfZoom}}">
</pdf-viewer>
<div class="d-flex justify-content-center align-items-center mt-3" *ngIf="totalPages > 1">
  <button class="btn btn-light" (click)="decrement();$event.stopPropagation();false"
          [disabled]="numPage === 1">{{'pdf_component.prev_page'|translate}}
  </button>
  <span class="h4 mx-1">{{numPage}}/{{totalPages}}</span>
  <button class="btn btn-light" (click)="increment(); $event.stopPropagation();false"
          [disabled]="numPage === totalPages">{{'pdf_component.next_page'|translate}}
  </button>
</div>
