<div class="row">
  <div class="col-md-12">
    <h2 class="underline">{{"employees.creation.title"|translate}}</h2>
  </div>
</div>

<form [formGroup]="createEmployeeForm" (ngSubmit)="createEmployeeFormSubmit()">

  <div class="row">
    <div class="col-md-12 ">

      <h3>{{"employees.creation.section"|translate}}</h3>

      <div class="card shadow-sm">
        <div class="card-body">

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="firstName">{{"employees.creation.first_name.label"|translate}} <span
                  class="text-primary">*</span></label>
                <input type="text" class="form-control" id="firstName" formControlName="firstName">
                <app-form-feedback *ngIf="createEmployeeForm.get('firstName').errors?.required &&
                (createEmployeeForm.get('firstName').dirty || createEmployeeForm.get('firstName').touched)"
                                   message='{{"employees.creation.first_name.feedback.required"|translate}}'>
                </app-form-feedback>
                <app-form-feedback *ngIf="createEmployeeForm.get('firstName').errors?.pattern
                && (createEmployeeForm.get('firstName').dirty || createEmployeeForm.get('firstName').touched)"
                                   message='{{"employees.creation.first_name.feedback.characterset"|translate}}'>
                </app-form-feedback>
                <app-form-feedback
                  *ngIf="createEmployeeForm.get('firstName').errors?.maxlength  && (createEmployeeForm.get('firstName').dirty || createEmployeeForm.get('firstName').touched)"
                  message='{{"employees.creation.first_name.feedback.maxlength"|translate}}'>
                </app-form-feedback>
              </div>

            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="lastName">{{"employees.creation.last_name.label"|translate}} <span
                  class="text-primary">*</span></label>
                <input type="text" class="form-control" id="lastName" formControlName="lastName">
                <app-form-feedback *ngIf="createEmployeeForm.get('lastName').errors?.required &&
                (createEmployeeForm.get('lastName').dirty || createEmployeeForm.get('lastName').touched)"
                                   message='{{"employees.creation.last_name.feedback.required"|translate}}'>
                </app-form-feedback>
                <app-form-feedback *ngIf="createEmployeeForm.get('lastName').errors?.pattern
                && (createEmployeeForm.get('lastName').dirty || createEmployeeForm.get('lastName').touched)"
                                   message='{{"employees.creation.last_name.feedback.characterset"|translate}}'>
                </app-form-feedback>
                <app-form-feedback
                  *ngIf="createEmployeeForm.get('lastName').errors?.maxlength  && (createEmployeeForm.get('lastName').dirty || createEmployeeForm.get('lastName').touched)"
                  message='{{"employees.creation.last_name.feedback.maxlength"|translate}}'>
                </app-form-feedback>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group ">
                <label for="email">{{"employees.creation.email.label"|translate}} <span
                  class="text-primary">*</span></label>
                <input type="text" class="form-control" id="email" formControlName="email"
                       maxlength="100">

                <app-form-feedback *ngIf="createEmployeeForm.get('email').errors?.required &&
                (createEmployeeForm.get('email').dirty || createEmployeeForm.get('email').touched)"
                                   message='{{"employees.creation.email.feedback.required"|translate}}'>
                </app-form-feedback>
                <app-form-feedback *ngIf="createEmployeeForm.get('email').errors?.pattern
                && (createEmployeeForm.get('email').dirty || createEmployeeForm.get('email').touched)"
                                   message='{{"employees.creation.email.feedback.characterset"|translate}}'>
                </app-form-feedback>
                <app-form-feedback *ngIf="createEmployeeForm.get('email').errors?.maxlength
                && (createEmployeeForm.get('email').dirty || createEmployeeForm.get('email').touched)"
                                   message='{{"employees.creation.email.feedback.maxlength"|translate}}'>
                </app-form-feedback>
                <app-form-feedback *ngIf="createEmployeeForm.get('email').errors?.emailUsed
                && (createEmployeeForm.get('email').dirty || createEmployeeForm.get('email').touched)"
                                   message='{{"employees.creation.email.feedback.exists"|translate}}'>
                </app-form-feedback>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group last-form-group">
                <label for="phoneNumber">{{"employees.creation.phoneNumber.label"|translate}} <span
                  class="text-primary">*</span></label><br>
                <ngx-intl-tel-input
                  [cssClass]="'form-control'"
                  [preferredCountries]="preferredCountries"
                  [enableAutoCountrySelect]="true"
                  [enablePlaceholder]="true"
                  [searchCountryFlag]="true"
                  [searchCountryField]="[searchCountryField.Iso2, searchCountryField.Name]"
                  [selectFirstCountry]="false"
                  [selectedCountryISO]="countryISO.France"
                  [maxLength]="15"
                  [phoneValidation]="true"
                  [separateDialCode]="false"
                  [id]="'phoneNumber'"
                  formControlName="phoneNumber">
                </ngx-intl-tel-input>
                <app-form-feedback *ngIf="createEmployeeForm.get('phoneNumber').errors?.required &&
                                          createEmployeeForm.get('phoneNumber').touched"
                                   message='{{"employees.creation.phoneNumber.feedback.required"|translate}}'>
                </app-form-feedback>
                <app-form-feedback *ngIf="createEmployeeForm.get('phoneNumber').errors?.validatePhoneNumber &&
                                          createEmployeeForm.get('phoneNumber').touched"
                                   message='{{"employees.creation.phoneNumber.feedback.characterset"|translate}}'>
                </app-form-feedback>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group last-form-group">
                <label for="title">{{"employees.creation.title_field.label"|translate}} </label>
                <input type="text" class="form-control" id="title" formControlName="title">

                <app-form-feedback *ngIf="createEmployeeForm.get('title').errors?.pattern
                && (createEmployeeForm.get('title').dirty || createEmployeeForm.get('title').touched)"
                                   message='{{"employees.creation.title_field.feedback.characterset"|translate}}'>
                </app-form-feedback>
                <app-form-feedback
                  *ngIf="createEmployeeForm.get('title').errors?.maxlength  && (createEmployeeForm.get('title').dirty || createEmployeeForm.get('title').touched)"
                  message='{{"employees.creation.title_field.feedback.maxlength"|translate}}'>
                </app-form-feedback>
              </div>

            </div>
            <div class="col-md-6">
              <div class="form-group ">
                <label for="authority">{{"employees.creation.role.label"|translate}} <span
                  class="text-primary">*</span></label>
                <select class="form-control custom-select" id="authority"
                        formControlName="authority">
                  <option *ngFor="let role of roles"
                          [ngValue]="role">{{'global.role.' + role.name.toLowerCase() | translate | statusReadable}}</option>
                </select>
                <app-form-feedback *ngIf="createEmployeeForm.get('authority').errors?.required &&
                (createEmployeeForm.get('authority').dirty || createEmployeeForm.get('authority').touched)"
                                   message='{{"employees.creation.role.feedback.required"|translate}}'>
                </app-form-feedback>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="isProvider">
            <div class="col-md-6 ">
              <div class="form-group last-form-group">
                <label for="authority">
                  {{"employees.creation.suppliers.label"|translate}} <span class="text-primary">*</span>
                </label>

                <ng-select [items]="suppliersList"
                           [multiple]="true"
                           [searchable]="true"
                           [hideSelected]="true"
                           [typeahead]="suppliersInput$"
                           [compareWith]="compareSupplier"
                           bindLabel="name"
                           typeToSearchText="{{'employees.creation.suppliers.input-search-text'|translate}}"
                           formControlName="suppliers"
                           placeholder="{{'employees.creation.suppliers.input-placeholder'|translate}}"
                           class="ng-select-custom w-100">
                  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <input id="item-{{index.id}}" type="checkbox" [checked]="item$.selected"/>
                    <span class="ml-1" [title]="item.name">{{item.firstName + ' '
                    + item.lastName}}</span>
                  </ng-template>
                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngFor="let item of items | slice:0:5">
                    <span class="ng-value-label" [class.item-disabled]="item.disabled"
                          [title]="item.name">
                      {{item.firstName + ' ' + item.lastName | ellipsis: 18}}
                    </span>
                    </div>
                    <div class="ng-value" *ngIf="items.length > 5">
                      <span
                        class="ng-value-label">{{items.length
                      - 5}} {{'employees.creation.suppliers.input-items-length-exceeded'|translate}}</span>
                    </div>

                  </ng-template>
                </ng-select>
                <app-form-feedback *ngIf="createEmployeeForm.get('suppliers').errors?.required &&
                (createEmployeeForm.get('suppliers').dirty || createEmployeeForm.get('suppliers').touched)"
                                   message="{{'global.feedback.required'|translate}}">
                </app-form-feedback>
                <app-form-feedback *ngIf="createEmployeeForm.get('suppliers').errors?.maxlength &&
                (createEmployeeForm.get('suppliers').dirty || createEmployeeForm.get('suppliers').touched)"
                                   message="{{'employees.creation.suppliers.feedback.maxLength'|translate}}">
                </app-form-feedback>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="providerType">
                  {{'global.common.type'|translate}} <span class="text-primary">*</span>
                </label>
                <div id="providerType">
                  <div class="custom-control custom-radio custom-control-inline cursor-pointer">
                    <input class="custom-control-input" type="radio" id="typeEmployee" [value]="ProviderTypeEnum.EMPLOYEE" name="providerType" formControlName="providerType">
                    <label class="custom-control-label" for="typeEmployee">
                      {{'employees.creation.provider.type.'+ProviderTypeEnum.EMPLOYEE|translate}}
                    </label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline cursor-pointer">
                    <input class="custom-control-input" type="radio" id="typeSubcontractor" [value]="ProviderTypeEnum.SUBCONTRACTOR" name="providerType" formControlName="providerType">
                    <label class="custom-control-label" for="typeSubcontractor">
                      {{'employees.creation.provider.type.'+ProviderTypeEnum.SUBCONTRACTOR|translate}}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <button type="submit" class="btn btn-primary float-right"
              [disabled]="!createEmployeeForm.valid">
        {{"employees.creation.button_submit"|translate}}
      </button>
    </div>
  </div>
</form>



