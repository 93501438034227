<div class="animated fadeIn">

  <div class="row">
    <div class="col-md-12">
      <h2 class="underline">{{"employees.list.title"|translate}}</h2>
    </div>
  </div>


  <div class="row mb-3" @slideIn>
    <div class="col-sm-5">
      <div class="input-group search-input-group">
        <label for="example-search-input"></label>
        <input class="form-control search-input border-right-0 border" type="search" value="search"
               id="example-search-input"
               [value]="basicSearch"
               placeholder='{{"employees.list.basic_search_placeholer"|translate}}'
               (input)="triggerBasicSearch($event.target.value)">
        <span class="input-group-append">
          <div class="input-group-text bg-transparent">
            <fa-icon [icon]="faSearch"></fa-icon>
          </div>
        </span>
      </div>
    </div>

    <div class="col-sm-3">
      <form *ngIf="isSupplier()" [formGroup]="simpleSearchForm">
        <div data-toggle="buttons" class="btn-group btn-group-toggle float-left mr-3">
          <div class="btn-group btn-group-toggle float-left " data-toggle="buttons" btnRadioGroup formControlName="managing">
            <label class="btn btn-outline-secondary" btnRadio="true" id="true">
              {{"employees.list.role_selector_my_providers"|translate}}
            </label>
            <label class="btn btn-outline-secondary" btnRadio="false" id="false">
              {{"employees.list.role_selector_all"|translate}}
            </label>
          </div>
        </div>
      </form>
    </div>

    <div class="col-sm-4 d-none d-md-block a">
      <isp-button
        class="float-right"
        *ngIf="!this.authService.isUserProvider() && !this.authService.isUserFreelancer()"
        [icon]="faAdd"
        [type]="ButtonTypeEnum.CLASSIC"
        [colour]="IspColours.primary"
        [text]="'employees.list.new_employee_button'|translate"
        [routerLink]="['create']"
      ></isp-button>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <table class="table table-sm table-responsive-sm table-outline mb-0">
        <thead class="thead-light mb-4">
        <tr>
          <th class="text-center"><fa-icon [icon]="faUsers"></fa-icon></th>
          <th>{{"employees.list.column.user"|translate}}</th>
          <th class="text-center">{{"employees.list.column.email"|translate}}</th>
          <th class="text-center">{{"employees.list.column.phone"|translate}}</th>
          <th class="text-center">{{"employees.list.column.role"|translate}}</th>
          <th></th>
        </tr>
        </thead>

        <tbody [@listStagger]='listContent.length'>
        <tr *ngFor="let employee of listContent">
          <td class="text-center avatar-cell">
            <div class="avatar">
              <ngx-avatars *ngIf="!employee.avatar"
                          [name]="employee.firstName+' '+employee.lastName"
                          size="40"
                          [style.width]="'100%'"
                          [style.height]="'100%'"
                          [style.max-height]="'100%'"
                          [className]="'img-avatar img-fluid'"></ngx-avatars>
              <ngx-avatars *ngIf="employee.avatar" [src]="employee.avatar"
                          size="40"
                          [style.width]="'100%'"
                          [style.height]="'100%'"
                          [style.max-height]="'100%'"
                          [className]="'img-avatar img-fluid'"></ngx-avatars>
            </div>
          </td>
          <td>
            <div>{{employee.firstName | titlecase}} {{employee.lastName | uppercase}}</div>
            <div class="small text-muted">
              <span>{{"employees.list.content.label_new"|translate}}</span>
              | {{"employees.list.content.label_registered"|translate}} {{employee.registeredDate |date: 'dd/MM/yyyy'}}
            </div>
          </td>
          <td class="text-center">
            {{employee.email}}
          </td>
          <td class="text-center">
            {{employee.phoneNumber}}
          </td>

          <td class="text-center">
            <span
              class="badge"
              [ngClass]="{
                  'badge-primary': employee.authorities==='BUYER_ADMIN' || employee.authorities==='SUPPLIER_ADMIN',
                  'badge-info': employee.authorities ==='BUYER' || employee.authorities ==='SUPPLIER',
                  'badge-purple': employee.authorities==='PROJECT_MANAGER' || employee.authorities==='PROVIDER'}">
                {{'global.role.' + employee.authorities.toLowerCase() | translate | statusReadable | titlecase}}
              </span>
          </td>

          <td class="text-center">
            <a *ngIf="!this.authService.isUserProvider()" class="btn btn-sm btn-action"
               [routerLink]="[employee.id,'update']">
              <fa-icon [icon]="faPencil"></fa-icon>
              {{"employees.list.content.edit_button"|translate}}
            </a>
          </td>
        </tr>

        </tbody>

      </table>

    </div>

  </div>

  <div class="row mt-4 slide-in-animation">
    <div class="col-md-12">
      <pagination class="float-right" [totalItems]="totalElements" [maxSize]="5"
                  [boundaryLinks]="true"
                  [ngModel]="page"
                  (pageChanged)="changePage($event)"
                  [itemsPerPage]="size"
                  previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;"
                  lastText="&raquo;">
      </pagination>
    </div>
  </div>

</div>
