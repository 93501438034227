import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileUtilsService {

  constructor() {
  }

  static downloadFile(data: Blob, filename: string): void {
    filename = filename.replace('"', '').replace('"', '');
    const a = document.createElement('a');
    document.body.appendChild(a);
    const blob = new Blob([data], {type: 'application/doc'});
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  static convertFileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
    });
  }

}
