<div class="card mb-2 box-shadow-hover cursor-pointer status-waiting-for-replies" (click)="onCallForTenderDetail()">
  <div class="card-body pb-3">
    <div class="row">
      <div class="col-md-9">
        <div class="d-flex align-items-center">
          <h4 class="mr-2 mb-1">
            <span [title]="callForTender.title">{{callForTender.title | ellipsis: 50}}</span>
            <span class="text-dark-gray"> - {{callForTender.reference}}</span>
          </h4>
          <span class="badge float-right"
                [ngClass]="{'badge-success': callForTender.status === 'WAITING_FOR_REPLIES',
                        'badge-warning': callForTender.status === 'SENT',
                        'badge-danger': callForTender.status === 'REJECTED',
                        'badge-gray': callForTender.status === 'CLOSED'}">
            {{'marketplace.call-for-tenders.status.'+callForTender.status | translate | titlecase}}
          </span>
        </div>
        <div class="text-dark-gray mb-1">
          <span
            class="">{{'Call_for_tender.list_card.by'|translate:{buyer: callForTender.buyerName} }}</span>
          <span class="ml-1" *ngIf="callForTender.status !== 'SENT' && callForTender.status !== 'REJECTED'">|
            <span>
              {{'Call_for_tender.list_card.publication-date' | translate}} {{callForTender.publicationDate | date: 'dd MMM yyyy'}}

            </span>
          </span>
        </div>
        <div class="text-dark-gray mb-2">
          <span class="mr-3" [title]="'activity-area.title'|translate">
            <fa-icon [icon]="faIndustry" class="mr-1"></fa-icon>
            {{callForTender.activityArea?.label}}
          </span>
          <span class="mr-3" title="Start date">
            <fa-icon [icon]="faCalendar" class="mr-1"></fa-icon>
            {{callForTender.startDate | date: 'dd MMM. yyyy'}}
          </span>
          <span class="mr-3" title="Mission duration">
            <fa-icon [icon]="faClock"></fa-icon>
            {{callForTender.quantity}} {{callForTender.quantityByDate ? 'Days' : 'Hours'}}
          </span>
          <span class="mr-3" title="Workplace">
            <fa-icon [icon]="faMapMarker" class="mr-1"></fa-icon>
            {{callForTender.workplace.city}} - {{callForTender.workplace.country}}
          </span>
          <span class="visibility mr-3" title="Visibility">
            <ng-container *ngIf="callForTender.open; else callForTenderNotOpen">
              <fa-icon [icon]="faGlobe" class="mr-1"></fa-icon>
              Public
            </ng-container>
            <ng-template #callForTenderNotOpen>
              <fa-icon [icon]="faInstitution" class="mr-1"></fa-icon>
              <span class="league-list" [title]="stringifyListOfLeagues()">
                <ng-container *ngFor="let league of callForTender.leagues; let index = index">
                  {{league.name}}<span *ngIf="index !== callForTender.leagues.length - 1">,</span>
                </ng-container>
              </span>
            </ng-template>
          </span>
          <span class="mr-3" title="Contract Mode">
            <fa-icon [icon]="faFile" class="mr-1"></fa-icon>
            {{callForTender.contractMode| statusReadable |titlecase}}
          </span>
          <span class="mr-3" title="Rate">
            <fa-icon [icon]="faMoneyBill1" class="mr-1"></fa-icon>
            <ng-container *ngIf="shouldDisplayRange();else displayMaxRate">
              {{callForTender.contractMode === 'FIXED_PRICE' ? callForTender.minAmount : callForTender.minDailyRate}}{{callForTender.currency | currencyFormat: 'symbol'}} -
            </ng-container>

            <ng-template #displayMaxRate>
              {{'marketplace.call-for-tender-list.max-rate'|translate}}
            </ng-template>
            {{callForTender.contractMode === 'FIXED_PRICE' ? callForTender.maxAmount : callForTender.maxDailyRate}}{{callForTender.currency | currencyFormat: 'symbol'}}
            <ng-container *ngIf="callForTender.contractMode !== 'FIXED_PRICE'">{{ (callForTender.dailyRateByDate ? 'marketplace.by-day' : 'marketplace.by-hour')|translate }}</ng-container>
          </span>
        </div>
      </div>
      <div class="col-md-3">
        <div class="row mb-1 d-flex justify-content-end mr-1">
          <button class="btn btn-outline-primary" *ngIf="callForTender.freelanceOfferExist" disabled
            [routerLink]="['/marketplace/calls-for-tender', callForTender.id, 'offer-creation']">
            {{'Call_for_tender.offer_sent'|translate}}
          </button>
          <button class="btn btn-outline-primary" *ngIf="!callForTender.freelanceOfferExist"
                  [routerLink]="['/marketplace/calls-for-tender', callForTender.id, 'offer-creation']">
            {{'Call_for_tender.create_new_offer_btn'|translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
