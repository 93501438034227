import {Component, Input, OnInit} from '@angular/core';
import {MissionListModel} from '../../../../shared/models/mission/mission-list-model';
import {Router} from '@angular/router';
import {
  problemRefusedStatus,
  toDoStatus,
  validatedStatus,
  waitingBuyerStatus
} from '../../../../shared/enums/RequestStatusEnum';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';

marker('Mission_view.status.CONTACT_INITIATED');
marker('Mission_view.status.CONTRACT_RECEIVED');
marker('Mission_view.status.CONTRACT_REFUSED');
marker('Mission_view.status.CONTRACT_SENT');
marker('Mission_view.status.CONTRACT_SIGNED');
marker('Mission_view.status.LEGAL_DOCUMENTS_RECEIVED');
marker('Mission_view.status.LEGAL_DOCUMENTS_VALIDATED');
marker('Mission_view.status.MISSION_COMPLETED');
marker('Mission_view.status.MISSION_IN_PROGRESS');
marker('Mission_view.status.ORDER_RECEIVED');
marker('Mission_view.status.ORDER_REFUSED');
marker('Mission_view.status.ORDER_SENT');
marker('Mission_view.status.ORDER_VALIDATED');
marker('Mission_view.status.ORDER_WAITING');
marker('Mission_view.status.SENT');
marker('Mission_view.status.SUPPLIER_ACCOUNT_CREATED');
marker('Mission_view.status.TAKEN_INTO_ACCOUNT');
marker('Mission_view.status.WAITING_FOR_LEGAL_DOCUMENTS');
marker('Mission_view.status.PROVIDER_ASSIGNED');
marker('Mission_view.status.CANCELLED');

@Component({
  selector: 'mission-list-card',
  templateUrl: './mission-list-card.component.html',
  styleUrls: ['./mission-list-card.component.scss']
})
export class MissionListCardComponent implements OnInit {

  @Input() mission: MissionListModel;
  validatedStatus = validatedStatus;
  problemRefusedStatus = problemRefusedStatus;
  waitingBuyerStatus = waitingBuyerStatus;
  toDoStatus = toDoStatus;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  viewMission() {
    this.router.navigate(['/mission', this.mission.requestId, 'detail']).then();
  }

}
