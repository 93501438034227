<ng-container>
  <table
    class="table table-material box-shadow-hover table-hover table-responsive-sm"
  >
    <thead class="thead-normal">
    <tr>
      <th></th>

      <th class="text-left">
        {{ "Mission_view.provider.name" | translate }}
      </th>
      <th class="text-left">
        {{ "Mission_view.provider.quantity" | translate }}
      </th>
      <th class="text-center">
        {{ "Mission_view.provider.daily_rate" | translate }}
      </th>
      <th class="text-center">
        {{ "Mission_view.provider.unit" | translate }}
      </th>
      <th class="text-center">
        {{ "Mission_view.provider.fees" | translate }}
      </th>
      <th class="text-center">
        {{ "Mission_view.provider.on_call" | translate }}
      </th>
      <th class="text-center">
        {{ "Mission_view.provider.start_date" | translate }}
      </th>
      <th class="text-center">
        {{ "Mission_view.provider.end_date" | translate }}
      </th>
    </tr>
    </thead>
    <tbody>
    <tr
      *ngFor="let provider of providers"
      (click)="openEditProviderModal(provider)"
      class="cursor-pointer"
    >
      <td class="text-left">
        <img
          *ngIf="!provider.hasOwnProperty('employeeId')"
          alt="Warning"
          class="tab-icon"
          [src]="'assets/img/icon/warning.svg'"
        />
      </td>

      <td class="text-left text-info">
        {{ provider.pointOfContact.firstName }}
        {{ provider.pointOfContact.lastName }}
      </td>
      <td class="text-center">
        {{ provider.purchaseOrder.quantity }}
        <span *ngIf="provider.purchaseOrder.quantity">
          {{ (billingConfig.quantityByDate ? "global.common.days"
          : "global.common.hours") | translate }}
          </span>
      </td>
      <td class="text-center">{{ provider.purchaseOrder.dailyRate }}</td>
      <td class="text-center">
        {{ (provider.purchaseOrder.dailyRateByDate ? "global.common.days"
        : "global.common.hours") | translate}}
      </td>
      <td class="text-center">{{ provider.purchaseOrder.fees }}</td>
      <td class="text-center">{{ provider.purchaseOrder.onCall }}</td>
      <td class="text-center">
        {{ provider.purchaseOrder.startDate | date: "dd/MM/yyyy" }}
      </td>
      <td class="text-center text-danger">
        {{ provider.purchaseOrder.endDate | date: "dd/MM/yyyy" }}
      </td>
    </tr>
    </tbody>
  </table>
</ng-container>

<div
  class="modal fade"
  bsModal
  #providerModal="bs-modal"
  [config]="{ backdrop: 'static' }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-static-name"
>
  <div class="modal-dialog modal-lg modal-right">
    <div class="modal-content">
      <div class="mx-4 my-4 d-flex">
        <div class="missions-header-border mr-2"></div>
        <div class="missions-header">
          {{ "Mission_view.provider.the_provider" | translate }}
        </div>
      </div>

      <form
        [formGroup]="providerForm"
        (ngSubmit)="submitProviderForm()"
        class="provider-form"
      >
        <div class="modal-body">
          <div *ngIf="userRole != 'ROLE_FREELANCER'" class="row" @fadeIn>
            <div class="col">
              <div class="form-group">
                <label
                >{{ "Mission_view.provider.select_employee" | translate
                  }}<span class="text-primary ml-1">*</span></label
                >

                <div class="input-group mb-3">
                  <ng-select
                    [items]="employeeSearchList"
                    [typeahead]="$employeeSearch"
                    (search)="searchEmployee($event)"
                    [virtualScroll]="true"
                    (scrollToEnd)="onScrollEmployeeSearch()"
                    [labelForId]="'employee'"
                    class="ng-select ng-select-custom wid-70"
                    placeholder='{{ "Mission_view.provider.select_employee_placeholder" | translate}}'
                    formControlName="employee"
                  >
                    <ng-template ng-label-tmp let-item="item">
                      {{ item.firstName }} {{ item.lastName }}
                    </ng-template>
                    <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-index="index"
                      let-search="searchTerm"
                    >
                      <p class="mb-0">
                        {{ item.firstName }} {{ item.lastName }}
                      </p>
                      <p class="mb-0 pl-1 text-dark-gray">{{ item.email }}</p>
                    </ng-template>
                  </ng-select>
                  <div class="input-group-append">
                    <button class="btn button-darkblue wid-100" type="button" (click)="goToCreateProvider()"
                      *ngIf="supplierId && !providerForm.get('employeeId').value && userRole != 'ROLE_FREELANCER'">
                      <i class="fa fa-plus" aria-hidden="true"></i>
                      {{ "Mission_view.provider.add_new_employee" | translate}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ng-container *ngIf="userRole == 'ROLE_FREELANCER'">
            <div
              class="card box-shadow-hover slide-in-animation"
              @providerSectionDisplay
            >
              <div class="card-body d-flex align-items-center">
                <div class="provider-logo">
                  <ngx-avatars
                    [name]="userName"
                    bgColor="#F7F7F9"
                    fgColor="#8B5D5D"
                    size="80"
                    textSizeRatio="2"
                    initialsSize="2"
                  >
                  </ngx-avatars>
                </div>
                <div class="supplier-info ml-3">
                  <p class="font-weight-bold mb-1">
                    {{ userName }}{{ " (" + nickName + ")" | uppercase }}
                  </p>
                </div>
              </div>
            </div>
          </ng-container>

          <div class="box resume-box box-shadow-hover">
            <div class="row">
              <div class="col-1">
                <div class="ml-3 mt-3">
                  <ngx-avatars
                    [name]="userName"
                    bgColor="#F7F7F9"
                    fgColor="#8B5D5D"
                    size="50"
                    textSizeRatio="2"
                    initialsSize="2"
                  >
                  </ngx-avatars>
                </div>
              </div>

              <div class="col-7">
                <div class="my-2">
                  <div class="resume-header">
                    {{ providerModalValue.title | default: "-" }}
                  </div>
                  <div class="resume-subheader">
                    {{ "Mission_view.provider.experience_level" | translate }} :
                    {{ providerModalValue.experienceLevel | default: "-" }}
                  </div>
                </div>
              </div>

              <div class="col-4">
                <div class="ml-2 mt-3">
                  <input
                    class="input-file"
                    type="file"
                    accept="application/pdf"
                    id="cvFile"
                    (change)="setResumeToProviderForm($event)"
                  />
                  <label
                    class="btn btn-outline-blue px-3"
                    for="cvFile"
                    *ngIf="!providerForm.get('cvLink').value"
                  >
                    <i class="fa fa-download" aria-hidden="true"></i>
                    {{"Mission_view.provider.upload_cv"|translate}}
                  </label>
                  <ng-container *ngIf="providerForm.get('cvLink').value">
                    <div class="d-flex align-items-center">
                      <a
                        class="btn btn-success cursor-pointer mr-2"
                        (click)="downloadResume()"
                        [title]="
                          'Download : ' + providerForm.get('cvLink').value
                        "
                      >
                        <i class="fa fa-file" aria-hidden="true"></i>
                      </a>
                      <label
                        for="cvFile"
                        class="btn btn-primary mb-0 cursor-pointer"
                        title='{{"Mission_view.provider.feedback.size_cv"|translate: {size: 2} }}'
                      >
                        <i class="fa fa-upload" aria-hidden="true"></i>
                      </label>
                    </div>
                  </ng-container>
                  <app-form-feedback
                    *ngIf="providerForm.get('cvLink').errors?.requiredFileType"
                    message='{{"Mission_view.provider.feedback.format_pdf_cv"|translate}}'
                  >
                  </app-form-feedback>
                  <app-form-feedback
                    *ngIf="providerForm.get('cvFile').errors?.sizeFileExceed"
                    message='{{"Mission_view.provider.feedback.size_cv"|translate}}'
                  >
                  </app-form-feedback>
                </div>
              </div>
            </div>
          </div>

          <div class="mission-side-pane-header mt-5 mb-3">
            {{ "Mission_view.provider.purchase_order" | translate }}
          </div>

          <div class="card-box">
            <div class="row py-3 mx-2">
              <div class="col-6">
                <div class="d-flex justify-content-between">
                  <div class="mission-side-pane-card-title">
                    {{ "Mission_view.provider.mission_duration" | translate }}
                  </div>
                  <div class="text-muted">
                    {{
                    providerModalValue.purchaseOrder.startDate
                      | date: "dd/MM/yyyy"
                    }}
                    -
                    {{
                    providerModalValue.purchaseOrder.endDate
                      | date: "dd/MM/yyyy"
                    }}
                  </div>
                </div>

                <hr/>

                <div class="d-flex justify-content-between">
                  <div class="mission-side-pane-card-title">
                    {{ "Mission_view.provider.quantity" | translate }}
                  </div>
                  <div class="text-muted">
                    {{ providerModalValue.purchaseOrder.quantity }}
                    {{
                    providerModalValue.purchaseOrder.dailyRateByDate
                      ? "Days"
                      : "Hours"
                    }}
                  </div>
                </div>

                <hr/>

                <div class="d-flex justify-content-between">
                  <div class="mission-side-pane-card-title">
                    {{ "Mission_view.provider.daily_rate" | translate }}
                  </div>
                  <div class="text-muted">
                    {{ providerModalValue.purchaseOrder.dailyRate }}
                    {{ billingConfig.currency | currencyFormat: "symbol" }}
                  </div>
                </div>

                <hr/>

                <div class="d-flex justify-content-between">
                  <div class="mission-side-pane-card-title">
                    {{ "Mission_view.provider.unit" | translate }}
                  </div>
                  <div class="text-muted">
                    {{
                    providerModalValue.purchaseOrder.dailyRateByDate
                      ? "Days"
                      : "Hours"
                    }}
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div
                  *ngIf="
                    !billingConfig.quantityByDate ||
                    !providerModalValue.purchaseOrder.dailyRateByDate
                  "
                  class="d-flex justify-content-between"
                >
                  <div class="mission-side-pane-card-title">
                    Work hours per day
                  </div>
                  <div class="text-muted">
                    {{ providerModalValue.purchaseOrder.workHoursPerDay }}
                  </div>
                </div>

                <div class="d-flex justify-content-between">
                  <div class="mission-side-pane-card-title">
                    {{ "Mission_view.provider.fees" | translate }}
                  </div>
                  <div class="text-muted">
                    {{ providerModalValue.purchaseOrder.fees | default: "0" }}
                    {{ billingConfig.currency | currencyFormat: "symbol" }}
                  </div>
                </div>

                <hr/>

                <div class="d-flex justify-content-between">
                  <div class="mission-side-pane-card-title">
                    {{ "Mission_view.provider.on_call" | translate }}
                  </div>
                  <div class="text-muted">
                    {{ providerModalValue.purchaseOrder.onCall | default: "0" }}
                    {{ billingConfig.currency | currencyFormat: "symbol" }}
                  </div>
                </div>

                <hr/>

                <div class="d-flex justify-content-between">
                  <div class="mission-side-pane-card-title">
                    {{ "Mission_view.provider.reg_nbr" | translate }}
                  </div>
                  <div class="text-muted">
                    {{
                    providerModalValue.purchaseOrder.registrationNumber
                      | default: "-"
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mission-side-pane-header mt-5 mb-3">
            {{ "Mission_view.validated_activities.title" | translate }}
          </div>

          <div class="card-box">
            <div class="row py-3 mx-2">
              <div class="col-12">
                <div class="d-flex justify-content-between">
                  <div class="mission-side-pane-card-title">
                    {{ "Mission_view.validated_activities.quantity" | translate }}
                  </div>
                  <div class="text-muted">
                    {{ providerModalValue.purchaseOrder.quantityUsed }}
                    {{ providerModalValue.purchaseOrder.dailyRateByDate ? ("global.common.days" | translate) : ("global.common.hours" | translate) }}
                  </div>
                </div>

                <hr/>

                <div class="d-flex justify-content-between">
                  <div class="mission-side-pane-card-title">
                    {{ "Mission_view.validated_activities.fee" | translate }}
                  </div>
                  <div class="text-muted">
                    {{ providerModalValue.purchaseOrder.feesUsed | default: "0" }}
                    {{ billingConfig.currency | currencyFormat: "symbol" }}
                  </div>
                </div>

                <hr/>

                <div class="d-flex justify-content-between">
                  <div class="mission-side-pane-card-title">
                    {{ "Mission_view.validated_activities.onCall" | translate }}
                  </div>
                  <div class="text-muted">
                    {{ providerModalValue.purchaseOrder.onCallUsed | default: "0" }}
                    {{ billingConfig.currency | currencyFormat: "symbol" }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mission-side-pane-header mt-5 mb-3">
            {{ "Mission_view.provider.workplace" | translate }}
          </div>

          <div class="card-box">
            <div class="row py-3 mx-2">
              <div class="col-6">
                <div class="d-flex flex-column">
                  <div class="mission-side-pane-card-title">
                    {{ 'Legal_information.billing-address.create-billing-address.street1'|translate }}
                  </div>
                  <div class="text-muted">
                    {{ providerModalValue.workPlace.street1 }}
                  </div>
                </div>

                <hr/>

                <div class="d-flex flex-column">
                  <div class="mission-side-pane-card-title">
                    {{ "Mission_view.provider.city" | translate }}
                  </div>
                  <div class="text-muted">
                    {{ providerModalValue.workPlace.city }}
                  </div>
                </div>

                <hr/>

                <div class="d-flex flex-column">
                  <div class="mission-side-pane-card-title">
                    {{ "Mission_view.provider.post_code" | translate }}
                  </div>
                  <div class="text-muted">
                    {{ providerModalValue.workPlace.postCode }}
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div class="d-flex flex-column">
                  <div class="mission-side-pane-card-title">
                    {{ 'Legal_information.billing-address.create-billing-address.street2'|translate }}
                  </div>
                  <div class="text-muted">
                    {{ providerModalValue.workPlace.street2 | default: "-" }}
                  </div>
                </div>

                <hr/>

                <div class="d-flex flex-column">
                  <div class="mission-side-pane-card-title">
                    {{ "Mission_view.provider.state_or_province" | translate }}
                  </div>
                  <div class="text-muted">
                    {{
                    providerModalValue.workPlace.stateOrProvince
                      | default: "-"
                    }}
                  </div>
                </div>

                <hr/>

                <div class="d-flex flex-column">
                  <div class="mission-side-pane-card-title">
                    {{ "Mission_view.provider.country" | translate }}
                  </div>
                  <div class="text-muted">
                    {{ providerModalValue.workPlace.country }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            class="btn btn-link px-5"
            type="button"
            (click)="providerModal.hide()"
          >
            {{ "Mission_view.provider.cancel" | translate }}
          </button>

          <button
            class="btn button-blue px-5"
            type="submit"
            [disabled]="!providerForm.valid"
          >
            {{ "Mission_view.provider.edit" | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
