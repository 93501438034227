<ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" contentClassName="ngx-file-drop-content"
               accept=".pdf" multiple="false" [disabled]="disabled"
               (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)" dropZoneClassName="ngx-file-drop-zone">
  <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">

    <div class="d-flex flex-column justify-content-center align-items-center">
      <div class="p-2">{{'Legal_documents.drag_and_drop'|translate}}</div>
      <div class="p-2"><fa-icon [icon]="faUpload"></fa-icon></div>
      <div class="p-2">{{'Legal_documents.select_one'|translate}}</div>
      <div class="p-2">
        <button class="btn btn-outline-info" type="button" (click)="openFileSelector()"
                [disabled]="disabled">{{'Legal_documents.browse'|translate}}</button>
      </div>
    </div>

  </ng-template>
</ngx-file-drop>
<div class="d-flex justify-content-center">
  <app-form-feedback
    *ngIf="fileUploadForm.get('attachmentFileName').errors?.requiredFileType"
    message="{{'Legal_documents.input_form_control.field_type_pdf'|translate}}">
  </app-form-feedback>
  <app-form-feedback
    *ngIf="fileUploadForm.get('attachmentFile').errors?.sizeFileExceed"
    message="{{'Legal_documents.input_form_control.field_maximum_2_mo'|translate}}">
  </app-form-feedback>
</div>
