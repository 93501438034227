<main class="d-flex align-items-center min-vh-100 py-3 py-md-0">
  <div class="container">
    <div class="card login-card">
      <div class="row no-gutters">
        <div class="d-none d-lg-inline-flex col-lg-5">
          <img src="../../assets/img/isupplier-bg.jpg" alt="login" class="login-card-img">
        </div>
        <div class="col col-lg-7">
          <div class="card-body max-height">
            <div class="brand-wrapper">
              <img src="assets/img/brand/logo.svg" alt="logo" class="img-fluid">
            </div>
            <app-alert></app-alert>
            <div *ngIf="!isSuccess">
              <form [formGroup]="signupForm" (ngSubmit)="createCompanyFormSubmit()">
                <div class="form-group">
                  <label for="company" class="required">{{'Signup.company_name' | translate}} </label>
                  <input type="text" id="company" placeholder="" class="form-control" formControlName="name">
                  <app-form-feedback
                    *ngIf="signupForm.get('name').errors?.required && (signupForm.get('name').dirty || signupForm.get('name').touched)"
                    [message]="'Signup.input_form_control.field_required'|translate"
                  ></app-form-feedback>
                  <app-form-feedback
                    *ngIf="signupForm.get('name').errors?.pattern && (signupForm.get('name').dirty || signupForm.get('name').touched)"
                    [message]="'Signup.input_form_control.field_content'|translate"
                  ></app-form-feedback>
                  <app-form-feedback
                    *ngIf="signupForm.get('name').errors?.maxlength  && (signupForm.get('name').dirty || signupForm.get('name').touched)"
                    [message]="'Signup.input_form_control.field_maximum_75'|translate"
                  ></app-form-feedback>
                </div>

                <div class="form-group">
                  <label for="companyType"
                         class="required">{{'Signup.type' | translate}}</label><br>

                  <div class="btn-group w-100" role="group">
                    <button
                      type="button" class="btn equal-width-button" id="companyType"
                      [ngClass]="{'btn-outline-info': signupForm.get('type').value !== FREELANCE_TYPE,'btn-info': signupForm.get('type').value === FREELANCE_TYPE}"
                      (click)="changeCompanyType(FREELANCE_TYPE)"
                    >
                      <i class="las la-rocket font-xl"></i> {{'Signup.FREELANCE' | translate}}
                    </button>
                    <button
                      type="button" class="btn equal-width-button" id="companyType2"
                      [ngClass]="{'btn-outline-info': signupForm.get('type').value !== SUPPLIER_TYPE,'btn-info': signupForm.get('type').value === SUPPLIER_TYPE}"
                      (click)="changeCompanyType(SUPPLIER_TYPE)">
                      <i class="las la-building font-xl"></i> {{'Signup.ESN' | translate}}
                    </button>
                  </div>
                </div>

                <div class="form-group">
                  <div class="row">
                    <div class="col-md-6">
                      <app-country-input formControlName="origin"></app-country-input>
                      <app-form-feedback
                        *ngIf="signupForm.get('origin').errors?.required && (signupForm.get('origin').dirty || signupForm.get('origin').touched)"
                        [message]="'Legal_information.input_form_control.field_required'| translate">
                      </app-form-feedback>
                    </div>
                    <div class="col-md-6">
                      <label for="incorporationNumber">{{'Legal_information.incorporation_number'| translate}} <span class="text-primary">*</span></label>
                      <input class="form-control" id="incorporationNumber" type="text" name="incorporationNumber" formControlName="incorporationNumber">
                      <app-form-feedback
                        *ngIf="signupForm.get('incorporationNumber').errors?.required && (signupForm.get('incorporationNumber').dirty || signupForm.get('incorporationNumber').touched)"
                        [message]="'Legal_information.input_form_control.field_required'| translate">
                      </app-form-feedback>
                      <app-form-feedback
                        *ngIf="signupForm.get('incorporationNumber').errors?.pattern && (signupForm.get('incorporationNumber').dirty || signupForm.get('incorporationNumber').touched)"
                        [message]="'Legal_information.input_form_control.field_content_2'| translate">
                      </app-form-feedback>
                      <app-form-feedback
                        *ngIf="signupForm.get('incorporationNumber').errors?.maxlength  && (signupForm.get('incorporationNumber').dirty || signupForm.get('incorporationNumber').touched)"
                        [message]="'Legal_information.input_form_control.field_maximum_14'| translate">
                      </app-form-feedback>
                    </div>
                  </div>
                </div>

                <div formGroupName="administrativeManager">
                  <div class="row">
                    <div class="col-md-12">
                      <h4 class="section-title">{{'Signup.administrative_manager' | translate}}</h4>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="firstName"
                               class="required">{{'Signup.firstname'| translate}}</label>
                        <input type="text" id="firstName" placeholder="" class="form-control"
                               formControlName="firstName">
                        <app-form-feedback *ngIf="signupForm.get('administrativeManager.firstName').errors?.required &&
                (signupForm.get('administrativeManager.firstName').dirty || signupForm.get('administrativeManager.firstName').touched)"
                                           message="{{'Signup.input_form_control.field_required'|translate}}">
                        </app-form-feedback>
                        <app-form-feedback *ngIf="signupForm.get('administrativeManager.firstName').errors?.pattern
                && (signupForm.get('administrativeManager.firstName').dirty || signupForm.get('administrativeManager.firstName').touched)"
                                           message="{{'Signup.input_form_control.field_content'|translate}}">
                        </app-form-feedback>
                        <app-form-feedback
                          *ngIf="signupForm.get('administrativeManager.firstName').errors?.maxlength  && (signupForm.get('administrativeManager.firstName').dirty || signupForm.get('administrativeManager.firstName').touched)"
                          message="{{'Signup.input_form_control.field_maximum_50'|translate}}">
                        </app-form-feedback>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="lastName"
                               class="required">{{'Signup.lastname' | translate}}</label>
                        <input type="text" id="lastName" placeholder="" class="form-control"
                               formControlName="lastName">
                        <app-form-feedback
                          *ngIf="signupForm.get('administrativeManager.lastName').errors?.required && (signupForm.get('administrativeManager.lastName').dirty || signupForm.get('administrativeManager.lastName').touched)"
                          [message]="'Signup.input_form_control.field_required'|translate">
                        </app-form-feedback>
                        <app-form-feedback
                          *ngIf="signupForm.get('administrativeManager.lastName').errors?.pattern && (signupForm.get('administrativeManager.lastName').dirty || signupForm.get('administrativeManager.lastName').touched)"
                          [message]="'Signup.input_form_control.field_content'|translate">
                        </app-form-feedback>
                        <app-form-feedback
                          *ngIf="signupForm.get('administrativeManager.lastName').errors?.maxlength  && (signupForm.get('administrativeManager.lastName').dirty || signupForm.get('administrativeManager.lastName').touched)"
                          [message]="'Signup.input_form_control.field_maximum_50'|translate">
                        </app-form-feedback>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="email" class="required">{{'Signup.email'|translate}}</label>
                        <input type="text" id="email" placeholder="" class="form-control email-sign-up"
                               formControlName="email">
                        <app-form-feedback
                          *ngIf="signupForm.get('administrativeManager.email').errors?.required && (signupForm.get('administrativeManager.email').dirty || signupForm.get('administrativeManager.email').touched)"
                          [message]="'Signup.input_form_control.field_required'|translate"
                        ></app-form-feedback>
                        <app-form-feedback
                          *ngIf="signupForm.get('administrativeManager.email').errors?.pattern && (signupForm.get('administrativeManager.email').dirty || signupForm.get('administrativeManager.email').touched)"
                          [message]="'Signup.input_form_control.field_content_email'|translate"
                        ></app-form-feedback>
                        <app-form-feedback
                          *ngIf="signupForm.get('administrativeManager.email').errors?.maxlength && (signupForm.get('administrativeManager.email').dirty || signupForm.get('administrativeManager.email').touched)"
                          [message]="'Signup.input_form_control.field_maximum_100'|translate"
                        ></app-form-feedback>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="required" [for]="'phoneNumber'">{{'Signup.phone_number'|translate}}</label>
                        <ngx-intl-tel-input
                          [cssClass]="'form-control '"
                          [preferredCountries]="preferredCountries"
                          [enableAutoCountrySelect]="true"
                          [enablePlaceholder]="true"
                          [searchCountryFlag]="true"
                          [searchCountryField]="[searchCountryField.Iso2, searchCountryField.Name]"
                          [selectFirstCountry]="false"
                          [selectedCountryISO]="countryISO.France"
                          [maxLength]="15"
                          [phoneValidation]="true"
                          [separateDialCode]="false"
                          [id]="'phoneNumber'"
                          formControlName="phoneNumber"
                        ></ngx-intl-tel-input>
                        <app-form-feedback
                          *ngIf="signupForm.get('administrativeManager.phoneNumber').errors?.required && signupForm.get('administrativeManager.phoneNumber').touched"
                          [message]="'Signup.input_form_control.field_required'|translate"
                        ></app-form-feedback>
                        <app-form-feedback
                          *ngIf="signupForm.get('administrativeManager.phoneNumber').errors?.validatePhoneNumber && (signupForm.get('administrativeManager.phoneNumber').dirty || signupForm.get('administrativeManager.phoneNumber').touched)"
                          [message]="'Signup.input_form_control.field_content_phone'|translate"
                        ></app-form-feedback>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <p>
                      {{'Signup.already_have_an_account' | translate}}  <a [routerLink]="['/login']">{{'Signup.login_here'|translate}}</a>
                    </p>
                  </div>
                  <div class="col-md-6">
                    <isp-button
                      class="float-right"
                      [type]="ButtonTypeEnum.CLASSIC"
                      [action]="ButtonActionEnum.SUBMIT"
                      [text]="'Signup.signup_button'|translate"
                      [colour]="IspColoursEnum.tertiary"
                      [loader]="this.loaders['companySignup']"
                      [disabled]="signupForm.invalid"
                    ></isp-button>
                  </div>
                </div>
              </form>
            </div>

            <div class="text-center" *ngIf="isSuccess">
              <app-counter [count]="10" [messageHeader]="headerMsg" [messageBody]="bodyMsg" [messageEnd]="endMsg"></app-counter>

              <a class="btn btn-primary btn-block  mb-2" [routerLink]="['/login']">
                {{'Back_to_login'|translate}}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nav class="login-card-footer-nav float-right">
      <span>{{'Language' | translate}}:</span>
      <ng-select [items]="languages" [(ngModel)]="selectedLanguage" [clearable]="false" (change)="changeLang($event)" class="example-wrapper">
        <ng-template ng-label-tmp let-item="item">
          <div class="flex-align-center">
            <div class="iti__flag mr-2" [ngClass]="item == 'EN' ? 'iti__gb' : 'iti__'+item | lowercase"></div>
            <span>{{item === 'US' ? 'EN' : item}}</span>
          </div>
        </ng-template>
      </ng-select>
    </nav>
  </div>
</main>
