import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {AuthGuard} from "../../core/guards/auth.guard";
import {PurchaseOrderListComponent} from "./purchase-order-list/purchase-order-list.component";
import {PurchaseOrderViewComponent} from "./purchase-order-view/purchase-order-view.component";
import {RoleGuard} from "../../core/guards/role-guard.service";
import {CompletedGuard} from "../../core/guards/completed.guard";


const routes: Routes = [
  {
    path: '',
    component: PurchaseOrderListComponent,
    canActivate: [AuthGuard, RoleGuard, CompletedGuard],
    data: {
      breadcrumb: 'Purchase order list',
      expectedRoles: ['ROLE_SUPPLIER', 'ROLE_SUPPLIER_ADMIN', 'ROLE_FREELANCER'],
    }
  },
  {
    path: ':purchaseOrderId/purchase-order',
    component: PurchaseOrderViewComponent,
    canActivate: [AuthGuard, RoleGuard, CompletedGuard],
    data: {
      breadcrumb: 'Purchase order',
      expectedRoles: ['ROLE_SUPPLIER', 'ROLE_SUPPLIER_ADMIN', 'ROLE_FREELANCER'],
    }
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PurchaseOrderRoutingModule {
}
