<ngx-extended-pdf-viewer
  [(src)]="source"
  [showOpenFileButton]="config.showOpenFileButton"
  [showSecondaryToolbarButton]="config.showSecondaryToolbarButton"
  [zoom]="config.zoom"
  [height]="config.height"
  [showDownloadButton]="config.showDownloadButton"
  [showPrintButton]="config.showPrintButton"
  [showPresentationModeButton]="config.showPresentationModeButton"
  [textLayer]="config.textLayer"
  [showEditor]="false"
  [showHandToolButton]="false"
  [handTool]="false"
  [useBrowserLocale]="config.useBrowserLocale"
  [showUnverifiedSignatures]="config.showUnverifiedSignatures"
  [showRotateButton]="config.showRotateButton"
>
</ngx-extended-pdf-viewer>
