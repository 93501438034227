import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {ListContentModel} from '../../../shared/models/list-content.model';
import {Observable} from 'rxjs';
import {
  ContractAdvancedSearch
} from '../../../shared/models/contract/contract-advanced-search.model';
import {ContractInfoModel} from '../../../shared/models/contract/contract-info-model';
import {ContractStatusEnum, ExtendedContractStatusEnum} from '../../../shared/enums/Contract.status.enum';
import {commonProperties} from '../../../../assets/environments/environment.common';
import {environment} from '../../../../assets/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ContractService {
  contractsApi = environment.api_root + commonProperties.contractList;
  contractVersionsApi = environment.api_root + commonProperties.contractVersions;

  constructor(private httpClient: HttpClient) {}

  static resolveContractStatusBadge(status: ContractStatusEnum|ExtendedContractStatusEnum) {
    switch (status) {
      case ContractStatusEnum.CANCELLED: {
        return 'badge-primary';
      }
      case ContractStatusEnum.VALIDATED: {
        return 'badge-success';
      }
      case ContractStatusEnum.EXPIRED: {
        return 'badge-secondary';
      }
      case ContractStatusEnum.REFUSED_BY_SUPA: {
        return 'badge-warning';
      }
      case ContractStatusEnum.GENERATED: {
        return 'badge-purple';
      }
      default: {
        return 'badge-primary';
      }
    }
  }

  mapAdvancedSearchForm(params: any): ContractAdvancedSearch {
    return new ContractAdvancedSearch(params);
  }


  getContractsByPage(page: number, size: number, sort: string, search: string, advancedSearch: ContractAdvancedSearch): Observable<ListContentModel> {
    let params = new HttpParams();
    if (search) {
      params = params.set('search', search);
    } else if (advancedSearch) {
      if (advancedSearch.expiryDate) {
        const requestDate = advancedSearch.expiryDate.split(',');
        params = params.set('expiryFrom', requestDate[0]);
        params = params.set('expiryTo', requestDate[1]);
      }
      if (advancedSearch.buyerName) {
        params = params.set('buyerName', advancedSearch.buyerName);
      }
      if (advancedSearch.contractNumber) {
        params = params.set('contractNumber', advancedSearch.contractNumber);
      }
      if (advancedSearch.status) {
        params = params.set('status', advancedSearch.status);
      }
    }

    params = params.set('size', size.toString()).set('page', page.toString()).set('sort', sort);
    return this.httpClient.get<ListContentModel>(environment.api_root + commonProperties.contractList, {params});
  }

  getContractById(contractId: string): Observable<ContractInfoModel> {
    const url = `${environment.api_root + commonProperties.contractList}/${contractId}`;
    return this.httpClient.get<ContractInfoModel>(url);
  }

  getContractFile(id: any, status: string) {
    const headers = new HttpHeaders().set('Accept', 'application/pdf');
    const URL = status === ContractStatusEnum.VALIDATED ||
    status === ContractStatusEnum.EXPIRED ? environment.api_root + commonProperties.contractSignedById.replace(':contractId', id)
      : environment.api_root + commonProperties.contractFile.replace(':contractId', id);
    return this.httpClient.get<Blob>(URL, {
      observe: 'response',
      headers: headers,
      responseType: 'blob' as 'json'
    });
  }

  uploadContractSignedById(contractId: string, bodyRequest: any): Observable<any> {
    return this.httpClient.post(environment.api_root + commonProperties.contractSignedById.replace(':contractId', contractId),
      bodyRequest);
  }

  deleteContractSignedById(contractId: string): Observable<any> {
    return this.httpClient.delete(environment.api_root + commonProperties.contractSignedById.replace(':contractId', contractId));
  }

  rejectContractById(contractId: string, body: any): Observable<any> {
    return this.httpClient.patch(environment.api_root + commonProperties.contractAction.replace(':contractId', contractId), body);
  }

  handleContractFileDownload(res: any, contractNumber: string) {
    const a = document.createElement('a');
    a.href = URL.createObjectURL(res.body);
    a.download = 'CONTRACT_' + contractNumber;
    a.click();
    a.remove();
  }

  getContractsWaiting(): Observable<any> {
    return this.httpClient.get(environment.api_root + commonProperties.contractsWaiting);
  }

  contractVersionGenerated(id: string) {
    const url = `${this.contractVersionsApi}/${id}/generated`;
    const headers = new HttpHeaders();
    const options = {
      headers: headers.set('Accept', 'application/pdf'),
      responseType: 'blob' as 'json'
    };
    return this.httpClient.get<Blob>(url, options);
  }

  contractVersionSigned(id: string) {
    const url = `${this.contractVersionsApi}/${id}/signed`;
    const headers = new HttpHeaders();
    const options = {
      headers: headers.set('Accept', 'application/pdf'),
      responseType: 'blob' as 'json'
    };
    return this.httpClient.get<Blob>(url, options);
  }

  contractVersionFileGenerated(versionId: string, id: string) {
    const url = `${this.contractVersionsApi}/${versionId}/files/${id}/generated`;
    const headers = new HttpHeaders();
    const options = {
      headers: headers.set('Accept', 'application/pdf'),
      responseType: 'blob' as 'json'
    };
    return this.httpClient.get<Blob>(url, options);
  }

  contractVersionFileSigned(versionId: string, id: string) {
    const url = `${this.contractVersionsApi}/${versionId}/files/${id}/signed`;
    const headers = new HttpHeaders();
    const options = {
      headers: headers.set('Accept', 'application/pdf'),
      responseType: 'blob' as 'json'
    };
    return this.httpClient.get<Blob>(url, options);
  }

  postGenerateSignatureLinks(contractVersionId: string): Observable<void> {
    return this.httpClient.post<void>(environment.api_root + commonProperties.templateContractVersionsGenerateSignatureLinks.replace(':contractVersionId', contractVersionId), {});
  }

  refuseFrameworkAgreement(contractId: string, params: { refusalComment: string }): Observable<void> {
    return this.httpClient.post<void>(environment.api_root + commonProperties.refuseFrameworkAgreement.replace(':contractId', contractId), params);
  }
}
