<button class="close-icon" (click)="close()">
  <img src="assets/img/icon/cross.svg" alt="pencil"/>
</button>

<div class="upload-header">{{'upload_modal_component.button_new'|translate}}</div>

<div class="upload-content">
  <ng-container *ngIf="!isFileUploaded">
    <file-drop2 (fileAction)="getFile($event)"></file-drop2>
  </ng-container>

  <ng-container *ngIf="isFileUploaded">
    <div class="upload-mini-view">
      <app-spinner [display]="spinnerDisplay"></app-spinner>
      <div *ngIf="source; else noPreview">
        <div class="modal-pdf-viewer">
          <app-pdf-preview [srcPdf]="source"></app-pdf-preview>
        </div>
      </div>
      <ng-template #noPreview>
        <app-no-data></app-no-data>
      </ng-template>
    </div>
  </ng-container>
</div>

<div>
  <button
    class="btn cancel-button"
    *ngIf="!isFileUploaded"
    (click)="close()"
    type="button"
  >
    {{'upload_modal_component.button_cancel'|translate}}
  </button>

  <div *ngIf="isFileUploaded" class="mt-4 upload-buttons">
    <button class="btn upload-button" (click)="reset()" type="button">
      {{'upload_modal_component.button_new'|translate}}
    </button>
    <button class="btn next-button ml-3" (click)="transferFile()" type="button">
      {{'upload_modal_component.button_next'|translate}}
    </button>
  </div>
</div>
