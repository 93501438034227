export class MissionAdvancedSearchModel {
  missionNumber: string;
  poNumber: string;
  buyer: string;
  provider: string;

  constructor(object?: any) {
    if (object) {
      this.missionNumber = object.missionNumber;
      this.poNumber = object.poNumber;
      this.buyer = object.buyer;
      this.provider = object.provider;
    }
  }
}
