import {Pipe, PipeTransform} from '@angular/core';
import {AddressModel} from "../models/companies/legal-information.model";

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {

  transform(addressModel: AddressModel): any {
    let address = '';
    if (addressModel) {
      address += addressModel.street1;
      if (addressModel.street2) {
        address += ', ' + addressModel.street2;
      }
      address += ' - ' + addressModel.postCode;
      if (addressModel.stateOrProvince) {
        address += ' ' + addressModel.stateOrProvince;
      }
      address += ' ' + addressModel.city + ', ' + addressModel.country;
    }
    return address;
  }

}
