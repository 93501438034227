import {Component, OnInit} from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {NGXLogger} from "ngx-logger";
import {ActivatedRoute, Router} from "@angular/router";
import {ListContentComponent} from "../../../shared/components/list-content/list-content.component";
import {FormBuilder} from "@angular/forms";
import {MissionService} from "../services/mission.service";
import {query, transition, trigger, useAnimation} from "@angular/animations";
import {fadeInAnimation, slideIn50StaggerAnimation, slideInAnimation} from "../../../shared/animation/common.animation";
import {AuthenticationService} from "../../../core/services/authentication.service";

@Component({
  selector: 'mission-list',
  templateUrl: './mission-list.component.html',
  styleUrls: ['./mission-list.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        useAnimation(fadeInAnimation)
      ])
    ]),
    trigger('slideIn', [
      transition(':enter', [
        useAnimation(slideInAnimation)
      ])
    ]),
    trigger('slideInStagger', [
      transition(':enter', [
        query('.slide-in-animation', [
          useAnimation(slideIn50StaggerAnimation)
        ], {optional: true})
      ])
    ])
  ]
})
export class MissionListComponent extends ListContentComponent implements OnInit {

  userRole: string;

  listParamValidator = {
    page: RegExp('^[1-9][0-9]*$'),
    size: ['8', '12', '16'],
    search: RegExp('.{3,}'),
    missionNumber: RegExp('.+'),
    poNumber: RegExp('.+'),
    buyer: RegExp('.+'),
    provider: RegExp('.+'),
  };


  constructor(private toastrService: ToastrService,
              private logger: NGXLogger,
              public router: Router,
              public route: ActivatedRoute,
              private authenticationService: AuthenticationService,
              private fb: FormBuilder,
              private missionService: MissionService) {
    super(router, route);
    this.advancedSearchForm = this.fb.group({
      missionNumber: [null],
      poNumber: [null],
      buyer: [null],
      provider: [null]
    });
  }

  ngOnInit(): void {
    this.size = 8;
    this.userRole = this.authenticationService.getRole();
    super.ngOnInit();
    super.subscribeToQueryParam();
  }


  retrieveListContent(params: any): void {
    const page = params.page ? params.page - 1 : 0;
    const search = params.search ? params.search : null;
    const sort = params.sort ? params.sort : 'createdDate,desc';
    const size = params.size ? params.size : 8;
    const advancedSearch = this.missionService.mapAdvancedSearchForm(params);
    this.subscriptions.push(this.missionService.getMissionList(page, size, sort, search, advancedSearch).subscribe(
      (res: any) => {
        this.listContent = res.content;
        this.totalElements = res.totalElements;
        this.numberOfElements = res.numberOfElements;
        this.isListEmpty = !this.isSearchActive && res.empty;

        if (res.totalPages !== 0 && params.page > res.totalPages) {
          this.updateQueryParam({page: res.totalPages});
        }

        this.firstCallDone = true;
      },
      (error: any) => {
        this.logger.error(error.url, '- STATUS :', error.status);
      }
    ));
  }

}
