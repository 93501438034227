import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class DynamicDefaultRouteGuard implements CanActivate {

  constructor(private router: Router,
              private authService: AuthenticationService
              ){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let role: string = this.authService.getRole();


      switch(role){
        case 'ROLE_FREELANCER':
        case 'ROLE_SUPPLIER':
        case 'ROLE_SUPPLIER_ADMIN':
          this.router.navigate(['/employees']);
          break;
        case 'ROLE_PROVIDER':
          this.router.navigate(['/activity-reports']);
          break;
        default:
          return false;
      }

      return true;
  }
  
}
