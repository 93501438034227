export enum ContractStatusEnum {
  GENERATED = 'GENERATED',
  REFUSED_BY_SUPA = 'REFUSED_BY_SUPA',
  VALIDATED = 'VALIDATED',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED'
}

export enum ExtendedContractStatusEnum {
  // Statuses from ContractVersionStatusEnum
  DRAFT = 'DRAFT',
  SENT = 'SENT',
  SIGNED = 'SIGNED',
  REFUSED = 'REFUSED',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',

  // Statuses from ContractVersionFileStatus
  GENERATED = 'GENERATED',
  TO_SIGN = 'TO_SIGN'
}
