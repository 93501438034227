import {Component, Input, OnDestroy} from '@angular/core';
import {transition, trigger, useAnimation} from '@angular/animations';
import {fadeInAnimation, slideInAnimation} from '../../../../../../shared/animation/common.animation';
import {MissionBillingModel} from '../../../../../../shared/models/mission/mission-billing.model';
import {Subscription} from 'rxjs';
import {
  faBuilding, faCalendarClock, faCalendarWeek,
  faFileContract,
  faHourglassHalf,
  faMoneyBills, faPhone,
  faUserCheck
} from "@fortawesome/pro-duotone-svg-icons";

@Component({
  selector: 'billing-view-detail',
  templateUrl: './billing-view-detail.component.html',
  styleUrls: ['./billing-view-detail.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        useAnimation(fadeInAnimation)
      ])
    ]),
    trigger('slideIn', [
      transition(':enter', [
        useAnimation(slideInAnimation)
      ])
    ])
  ]
})
export class BillingViewDetailComponent implements OnDestroy {
  @Input() billing: MissionBillingModel;
  subscriptions: Subscription[] = [];

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  protected readonly faBuilding = faBuilding;
  protected readonly faFileContract = faFileContract;
  protected readonly faUserCheck = faUserCheck;
  protected readonly faHourglassHalf = faHourglassHalf;
  protected readonly faMoneyBills = faMoneyBills;
  protected readonly faCalendarClock = faCalendarClock;
  protected readonly faPhone = faPhone;
  protected readonly faCalendarWeek = faCalendarWeek;
}
