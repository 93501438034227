<div class="input-group">
  <button class="button-minus" (click)="handleMinus()" [disabled]="disableMinus" title="Minus {{step}}">
    <fa-icon [icon]="faMinus"></fa-icon>
  </button>
  <label>
    <input class="quantity-field" [attr.readonly]="true" type="number" [(ngModel)]="initValue"/>
  </label>
  <button class="button-plus" (click)="handlePlus()" [disabled]="disablePlus" title="Plus {{step}}">
    <fa-icon [icon]="faPlus"></fa-icon>
  </button>
</div>
