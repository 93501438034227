import {Component, Input} from '@angular/core';
import {Subscription} from 'rxjs';
import {PurchaseOrderModel} from '../../../../shared/models/purchase-order/purchase-order.model';
import {Router} from '@angular/router';
import {PurchaseOrderStatusEnum} from '../../../../shared/enums/Purchase-order.status.enum';
import {PurchaseOrderService} from '../../services/purchase-order-service';
import {
  faCalendarCheck,
  faCommentXmark,
  faDownload,
  faHashtag
} from '@fortawesome/pro-duotone-svg-icons';
import {ButtonTypeEnum, IspBadgeColoursService, IspColoursEnum} from '@i-supplier/angular-shared-module';

@Component({
  selector: 'purchase-order-card',
  templateUrl: './purchase-order-card.component.html',
  styleUrls: ['./purchase-order-card.component.scss']
})
export class PurchaseOrderCardComponent {
  @Input() purchaseOrder: PurchaseOrderModel;
  subscriptions: Subscription[] = [];
  protected readonly faCalendarCheck = faCalendarCheck;
  protected readonly faCommentXmark = faCommentXmark;
  protected readonly ButtonTypeEnum = ButtonTypeEnum;
  protected readonly IspColours = IspColoursEnum;
  protected readonly faDownload = faDownload;
  protected readonly faHashtag = faHashtag;

  constructor(
    private router: Router,
    private service: PurchaseOrderService,
    protected ispBadgeColoursService: IspBadgeColoursService
  ) {}

  viewPurchaseOrder() {
    this.router.navigate(['/purchase-orders/' + this.purchaseOrder.id + '/purchase-order']);
  }

  displayDates() {
    return this.purchaseOrder.status === PurchaseOrderStatusEnum.VALIDATED;
  }

  displayRefusalReason() {
    return this.purchaseOrder.status === PurchaseOrderStatusEnum.REFUSED_BY_SUPPLIER;
  }

  downloadPurchaseOrder() {
    this.service.getPurchaseOrderFile(this.purchaseOrder.id, this.purchaseOrder.status).subscribe({
      next: res => this.service.handlePurchaseOrderFileDownload(res, this.purchaseOrder.contractNumber)
    });
  }
}
