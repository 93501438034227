import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MissionService} from '../services/mission.service';
import {ToastrService} from 'ngx-toastr';
import {MenuItemModel} from '../../../shared/models/menu-item.model';
import {Subscription} from 'rxjs';
import {EventBusService} from '../../../shared/utils/event-bus.service';
import {TranslateService} from '@ngx-translate/core';
import {
  problemRefusedStatus,
  toDoStatus,
  validatedStatus,
  waitingBuyerStatus
} from '../../../shared/enums/RequestStatusEnum';
import {transition, trigger, useAnimation} from '@angular/animations';
import {fadeInAnimation} from '../../../shared/animation/common.animation';

@Component({
  selector: 'mission-view',
  templateUrl: './mission-view.component.html',
  styleUrls: ['./mission-view.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        useAnimation(fadeInAnimation)
      ])
    ])
  ]
})
export class MissionViewComponent implements OnInit, OnDestroy {
  missionNumber: string;
  poNumber: string;
  missionClient: string;
  menuList: MenuItemModel[];
  missionStatus: string;
  validatedStatus = validatedStatus;
  problemRefusedStatus = problemRefusedStatus;
  waitingBuyerStatus = waitingBuyerStatus;
  toDoStatus = toDoStatus;

  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private missionService: MissionService,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private eventBusService: EventBusService
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.paramMap.subscribe((param) => {
          this.missionService.getMissionClientStepById(param.get('missionId')).subscribe({
            next: (res: any) => {
              this.missionStatus = res.status;
              this.missionClient = res.buyerName;
              this.missionNumber = res.client.requestNumber;
              this.poNumber = res.client.poNumber;
              this.constructMenu(res.additionalDocumentValid);
            },
            error: () => {
              this.toastrService.error(this.translateService.instant('global.ts.error'));
              this.router.navigate(['/mission']).then();
            }
          }
        );
      })
    );
    this.subscriptions.push(this.eventBusService.on('additionalDocumentChangeStatus', () => {
      this.ngOnInit();
    }));
  }

  constructMenu(additionalDocumentValid: boolean): void {
    this.menuList = [
      new MenuItemModel(this.translateService.instant('Mission_view.menu_list.mission_detail'), `detail`, ''),
      new MenuItemModel(this.translateService.instant('Mission_view.menu_list.additional_documents'), `additional-documents`, additionalDocumentValid ? 'fa fa-check' : 'warning'),
      new MenuItemModel(this.translateService.instant('Mission_view.menu_list.mission_history'), `history`, ''),
    ];
  }

  tabClick() {this.ngOnInit(); }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
