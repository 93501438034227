import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {AlertService} from "../../../core/services/alert.service";

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, OnDestroy {

  private subscription: Subscription;
  alert: any;

  constructor(private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.subscription = this.alertService.getAlert().subscribe(
      alert => {
        this.alert = alert;
      });
  }

  clearAlert(): void {
    this.alert = null;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
