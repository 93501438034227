import {Injectable} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {AuthenticationService} from "./authentication.service";
import {LanguageEnum} from "../../shared/enums/Language.enum";

@Injectable({
  providedIn: 'root'
})
export class TranslatorService {

  static readonly LANGUAGE = 'lang';

  languages = [LanguageEnum.GB, LanguageEnum.FR];

  constructor(private translate: TranslateService,
              private authService: AuthenticationService) {
  }

  getLanguage() {
    if (localStorage.getItem(TranslatorService.LANGUAGE)) {
      return localStorage.getItem(TranslatorService.LANGUAGE);
    }
    if (this.authService.isAuthenticated()) {
      let currentLang = this.authService.getUserLang();
      this.changeLang(currentLang);
      return currentLang;
    }
    

    return this.translate.getBrowserLang().toUpperCase() != LanguageEnum.FR ? LanguageEnum.GB : LanguageEnum.FR;
  }

  changeLang(event: any): void {
    localStorage.setItem(TranslatorService.LANGUAGE, event);
    this.translate.use(event);
  }
}
