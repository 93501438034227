<div class="modal-body">

  <fa-icon [icon]="faTriangleExclamation" class="red-icon" size="5x" (animationstart)="'beatfade'"></fa-icon>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <div class="text-align-center">
      <h4>
        {{ 'idle-modal-component.prompt' | translate: {durationInSeconds: durationInSeconds / 60} }}
      </h4>
    </div>
  </div>
</div>
<div class="progress-container">
  <div class="progress-bar" [style.width.%]="progress"></div>
</div>
