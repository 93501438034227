<main class="d-flex align-items-center min-vh-100 py-3 py-md-0">
  <img src="assets/img/isupplier-bg.jpg" alt="login" class="login-card-img">
  <div class="container">
    <div class="card login-card">
      <div class="row no-gutters d-flex justify-content-center">
        <div class="col-md-7">
          <div class="card-body">
            <div class="brand-wrapper">
              <!--<img src="assets/img/isupplier_logo_2019.svg" alt="logo" class="logo">-->
              <img src="assets/img/isupplier_logo_2019.svg" alt="logo" class="img-fluid">
            </div>
            <div *ngIf="!isSuccess && !isError">
              <p
                class="forgot-password-message text-center">{{'Account_validation.account_validation_title'|translate}}</p>
              <p class="text-center">
                {{'Account_validation.validation_msg'|translate}}
              </p>
              <form [formGroup]="accountValidationForm"
                    (ngSubmit)="accountValidationFormSubmit()">

                <div class="form-group">
                  <label for="password" hidden></label>
                  <input class="form-control" id="password" type="password"
                         placeholder="{{'Account_validation.enter_password'|translate}}" formControlName="password"/>
                </div>

                <div class="form-group">
                  <label for="confirmPassword" hidden></label>
                  <input class="form-control" id="confirmPassword" type="password"
                         placeholder="{{'Account_validation.confirm_password'|translate}}"
                         formControlName="confirmPassword"/>
                </div>

                <div class="form-group">
                  <app-password-meter
                    [password]="accountValidationForm.value.password" [numberRequired]="2"
                    (passwordValid)="setPasswordValid($event)"></app-password-meter>
                </div>

                <button class="btn btn-primary px-4" type="submit"
                        [disabled]="!(passwordValid && accountValidationForm.value.password === accountValidationForm.value.confirmPassword)">
                  <em class="fa fa-unlock"></em> {{'Account_validation.activate_account'|translate}}
                </button>
              </form>
            </div>
            <div class="text-center" *ngIf="isError && !isSuccess">

              <div class="swal2-icon swal2-error swal2-animate-error-icon"
                   style="display: flex;">
                  <span class="swal2-x-mark"><span class="swal2-x-mark-line-left"></span><span
                    class="swal2-x-mark-line-right"></span></span></div>

              <p class="error-message">
                {{'Account_validation.error.validation_message_error'|translate}}
              </p>

              <a class="btn btn-link btn-block  mb-2" [routerLink]="['/login']">
                {{'Back_to_login'|translate}}
              </a>

            </div>

            <div class="text-center" *ngIf="isSuccess && !isError">
              <app-counter [count]="10" [messageHeader]="headerMsg" [messageBody]="bodyMsg"
                           [messageEnd]="endMsg"></app-counter>
              <a class="btn btn-primary btn-block  mb-2" [routerLink]="['/login']">
                {{'Back_to_login'|translate}}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nav class="login-card-footer-nav float-right">
      <span>{{'Language' | translate}}:</span>
      <ng-select [items]="languages" [(ngModel)]="selectedLanguage" [clearable]="false"
                 (change)="changeLang($event)" class="example-wrapper">
        <ng-template ng-label-tmp let-item="item">
          <div class="flex-align-center">
            <div class="iti__flag mr-2" [ngClass]="item == 'EN' ? 'iti__gb' : 'iti__'+item | lowercase"></div>
            <span>{{item === 'US' ? 'EN' : item}}</span>
          </div>
        </ng-template>
      </ng-select>
    </nav>
  </div>
</main>
