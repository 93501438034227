import {Component, OnDestroy, OnInit} from '@angular/core';
import {query, transition, trigger, useAnimation} from '@angular/animations';
import {fadeInAnimation, slideIn50StaggerAnimation, slideInAnimation} from '../../../shared/animation/common.animation';
import {ListContentComponent} from '../../../shared/components/list-content/list-content.component';
import {ActivatedRoute, Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {FormBuilder} from '@angular/forms';
import {InvoiceStatusEnum} from '../models/invoice-status.enum';
import {Subscription} from 'rxjs';
import {InvoiceTypeEnum} from '../models/invoice-type.enum';
import * as _ from 'lodash';
import {InvoiceModeEnum} from '../models/invoice-mode.enum';
import {ListContentModel} from '../../../shared/models/list-content.model';
import {InvoiceService} from '../services/invoice.service';
import {HttpResponse} from '@angular/common/http';
import {CreditNoteModel} from '../models/credit-note.model';
import {CrediteNoteStatusEnum} from '../models/credit-note-status.enum';
import {CreditNoteService} from '../services/credit-note.service';
import {faDownload, faSearch} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'credit-note-list',
  templateUrl: './credit-note-list.component.html',
  styleUrls: ['./credit-note-list.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        useAnimation(fadeInAnimation)
      ])
    ]),
    trigger('slideIn', [
      transition(':enter', [
        useAnimation(slideInAnimation)
      ])
    ]),
    trigger('slideInStagger', [
      transition(':enter', [
        query('.slide-in-animation', [
          useAnimation(slideIn50StaggerAnimation)
        ], {optional: true})
      ])
    ])
  ]
})
export class CreditNoteListComponent extends ListContentComponent implements OnInit, OnDestroy {
  protected readonly faSearch = faSearch;
  protected readonly faDownload = faDownload;
  subscriptions: Subscription[] = [];

  listContent: CreditNoteModel[] = [];

  status = Object.values(CrediteNoteStatusEnum);

  types = Object.values(InvoiceTypeEnum);

  modes = Object.values(InvoiceModeEnum);

  listParamValidator = {
    page: RegExp('^[1-9][0-9]*$'),
    size: ['5', '10', '20'],
    sort: RegExp('^(number|invoiceNumber|buyerName|status|period|providerName||date|mode|type)\,(asc|desc)$'),
    search: RegExp('.{3,}'),
    number: RegExp('^[a-zA-Z0-9_.-]+$'),
    invoiceNumber: RegExp('^[a-zA-Z0-9_.-]+$'),
    purchaseOrderNumber: RegExp('^[a-zA-Z0-9_.-]+$'),
    status: Object.values(InvoiceStatusEnum),
    type: RegExp(`^(${this.types.join('|')})(\,(${this.types.join('|')}))*$`)
  };

  constructor(
    private invoiceService: InvoiceService,
    private creditNoteService: CreditNoteService,
    private fb: FormBuilder,
    private logger: NGXLogger,
    public route: ActivatedRoute,
    public router: Router
  ) {
    super(router, route);
    this.advancedSearchForm = this.fb.group({
      number: [null],
      purchaseOrderNumber: [null],
      buyerName: [null],
      invoiceNumber: [null],
      status: [null],
      mode: [null],
      type: [null]
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.sort = 'date';
    this.sortDirection = 'desc';
    this.subscribeToQueryParam();
  }


  // Override method
  mapQueryParamToAdvancedSearchForm(params: any): void {
    Object.keys(params).forEach(paramKey => {
      if (this.advancedSearchForm.contains(paramKey)) {
        if (paramKey === 'type') {
          const type = params[paramKey].split(',');
          this.advancedSearchForm.get('type').setValue(_.uniq(type));
        } else {
          this.advancedSearchForm.get(paramKey).setValue(params[paramKey]);
        }
        this.isAdvancedSearchDisplayed = true;
        this.isSearchActive = true;
      }
    });
  }

  // Override method
  mapAdvancedSearchFormToQueryParam(advancedSearchForm: any): any {
    if (advancedSearchForm.hasOwnProperty('type') && advancedSearchForm.type) {
      advancedSearchForm.type = advancedSearchForm.type.join();
    }
    if (advancedSearchForm.hasOwnProperty('contractMode') && advancedSearchForm.contractMode) {
      advancedSearchForm.contractMode = advancedSearchForm.contractMode.join();
    }
    return advancedSearchForm;
  }

  // Override method
  retrieveListContent(params: any): void {
    const page = params.page ? params.page - 1 : 0;
    const size = params.size ? params.size : 5;
    const sort = params.sort ? params.sort : 'date,desc,providerName,asc';
    const search = params.search ? params.search : null;
    const advancedSearch = this.creditNoteService.mapAdvancedSearchForm(this.advancedSearchForm.value);
    this.subscriptions.push(this.creditNoteService.getCreditNotesByPage(page, size, sort, search, advancedSearch).subscribe(
      (res: ListContentModel) => {
        this.listContent = res.content;
        this.totalElements = res.totalElements;
        this.numberOfElements = res.numberOfElements;
        this.isListEmpty = !this.isSearchActive && res.empty;

        if (res.totalPages !== 0 && params.page > res.totalPages) {
          this.updateQueryParam({page: res.totalPages});
        }

        this.firstCallDone = true;
      },
      (error: any) => {
        this.logger.error(error.url, '- STATUS :', error.status);
      }
    ));
  }

  downloadInvoice(invoice) {
    this.subscriptions.push(this.creditNoteService.downloadFile(invoice.id).subscribe(
      (res: HttpResponse<Blob>) => {
        const headers = res.headers;
        const contentDisposition = headers.get('content-disposition');
        let filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
        filename = filename.replace(/"/g, '');
        const a = document.createElement('a');
        a.href = URL.createObjectURL(res.body);
        a.download = filename;
        a.click();
        a.remove();
      },
      (error: any) => {
        this.logger.error(error.url, '- STATUS :', error.status);
      }
    ));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(value => value.unsubscribe());
  }
}
