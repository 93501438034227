import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {MissionAdvancedSearchModel} from "../../../shared/models/mission/mission-advanced-search.model";
import {Observable} from "rxjs";
import {MissionClientStepModel} from "../../../shared/models/mission/mission-client-step.model";
import {HistoryModel} from "../../../shared/models/mission/history.model";
import {MissionSupplierStepModel} from "../../../shared/models/mission/mission-supplier-step.model";
import {ListContentModel} from "../../../shared/models/list-content.model";
import {commonProperties} from '../../../../assets/environments/environment.common';
import {environment} from '../../../../assets/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class MissionService {

  constructor(private httpClient: HttpClient) {
  }

  getMissionList(page: number, size: any, sort: string, search: any, advancedSearch: any): Observable<ListContentModel> {

    let params = new HttpParams();
    if (search) {
      params = params.set('search', search);
    } else if (advancedSearch) {
      if (advancedSearch.missionNumber) {
        params = params.set('requestNumber', advancedSearch.missionNumber);
      }
      if (advancedSearch.poNumber) {
        params = params.set('purchaseOrderNumber', advancedSearch.poNumber);
      }
      if (advancedSearch.buyer) {
        params = params.set('buyerName', advancedSearch.buyer);
      }
      if (advancedSearch.provider) {
        params = params.set('providerName', advancedSearch.provider);
      }
    }

    params = params.set('size', size.toString()).set('page', page.toString()).set('sort', sort);
    return this.httpClient.get<ListContentModel>(environment.api_root + commonProperties.mission, {params});
  }

  mapAdvancedSearchForm(params: any) {
    return new MissionAdvancedSearchModel(params);
  }

  getMissionClientStepById(requestId: string): Observable<MissionClientStepModel> {
    return this.httpClient.get<MissionClientStepModel>(environment.api_root + commonProperties.requestClientStepById.replace(':requestId', requestId));
  }


  getMissionSupplierStepById(missionId: any) {
    return this.httpClient.get<MissionSupplierStepModel>(environment.api_root + commonProperties.missionSupplierStepById.replace(':missionId', missionId));
  }

  getHistoryListByMissionId(requestId: string): Observable<HistoryModel[]> {
    const url = environment.api_root + commonProperties.missionHistoryStatus.replace(':missionId', requestId);
    return this.httpClient.get<HistoryModel[]>(url);
  }

  getMissionAdditionalDocumentsById(missionId: string): Observable<any> {
    return this.httpClient.get(environment.api_root + commonProperties.additionalDocumentsByMissionId.replace(':missionId', missionId));
  }

  postMissionAdditionalDocumentsAttachmentById(missionId: string, additionalDocumentId: any, formValue: any): Observable<any> {
    return this.httpClient.post(environment.api_root + commonProperties.postAndGetAdditionalDocumentsByMissionId.replace(':missionId', missionId).replace(':additionalDocId', additionalDocumentId),
      {
        fileContent: formValue.fileContent,
        fileName: formValue.fileName
      });
  }

  getMissionAdditionalDocumentsAttachmentById(missionId: string, additionalDocumentId: any): Observable<any> {
    return this.httpClient.get(environment.api_root + commonProperties.postAndGetAdditionalDocumentsByMissionId.replace(':missionId', missionId).replace(':additionalDocId', additionalDocumentId));
  }
}
