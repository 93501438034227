<ng-container *ngIf="firstCallDone; else firstCallDoneBlock">
  <app-spinner  [backgroundTransparent]="true"></app-spinner>
</ng-container>

<ng-template #firstCallDoneBlock>
  <ng-container *ngIf="hasDocument; else noDocumentsBlock">
    <div class="row">
      <div class="col-3">
        <div class="addition-documents-info-box box-shadow-hover slide-in-animation">
          <div
            class="box-header text-align-center">{{"Mission_view.additional_document.text_document_required"|translate}}</div>
          <hr/>
          <mat-selection-list
            [multiple]="false"
            class="matSelectionList"
            #documentList
          >
            <mat-list-option
              *ngFor="let typeOfDocument of additionalDocumentsList"
              [value]="typeOfDocument"
            >
              <img *ngIf="typeOfDocument.status" alt="document_icon" matListIcon [src]="'assets/img/icon/' + typeOfDocument.status['icon'] + '.svg'"/>
              <h3 matLine>{{ typeOfDocument.labels[userLang] ? typeOfDocument.labels[userLang] : typeOfDocument.labels['EN'] }}</h3>
            </mat-list-option>
          </mat-selection-list>
        </div>
      </div>

      <div class="col-9">
        <additional-documents-section
          (updateDocumentStatus)="updateDocumentStatus($event)"
          *ngIf="documentList.selectedOptions.selected[0]?.value"
          [additionalDocument]="documentList.selectedOptions.selected[0]?.value"
        >
        </additional-documents-section>
      </div>
    </div>
  </ng-container>

  <ng-template #noDocumentsBlock>
    <div class="check-image-box">
      <div class="check-image-text">
        {{"Mission_view.additional_document.text_no_document_required"|translate}}
      </div>
      <img
        src="assets/img/big_check_mark.svg"
        class="check-image"
        alt="big_check_mark"
      />
    </div>
  </ng-template>
</ng-template>



