export class ProformaBodyResourceModel {
  activitySheetId: number;
  duePaymentId: number;
  onCallIds: number[];
  feeIds: number[];
  invoiceNumber: string;
  advancePayment: boolean;
  advancePaymentInDays: number;
  proforma: boolean = true;
  fileName: string;
  fileContent: string;
  taxAmount: number;
  manualInvoice: boolean = false;
  taxlessTotal: number;
  totalIncludingTax: number;
  invoiceDate: Date;
}

export class SupplierInvoiceConfig {
  billableUnder: number;
}
