import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../../core/guards/auth.guard';
import {MissionListComponent} from './mission-list/mission-list.component';
import {MissionViewComponent} from './mission-view/mission-view.component';
import {MissionDetailComponent} from './mission-view/mission-detail/mission-detail.component';
import {MissionHistoryComponent} from './mission-view/mission-history/mission-history.component';
import {AdditionalDocumentsComponent} from './mission-view/additional-documents/additional-documents.component';
import {CompletedGuard} from '../../core/guards/completed.guard';

const routes: Routes = [
  {
    path: '',
    component: MissionListComponent,
    canActivate: [AuthGuard, CompletedGuard],
  },
  {
    path: ':missionId',
    children: [
      {
        path: '',
        component: MissionViewComponent,
        children: [
          {
            path: '',
            redirectTo: 'detail',
            pathMatch: 'full'
          },
          {
            path: 'detail',
            component: MissionDetailComponent,
            canActivate: [AuthGuard, CompletedGuard]
          },
          {
            path: 'history',
            component: MissionHistoryComponent,
            canActivate: [AuthGuard, CompletedGuard]
          },
          {
            path: 'additional-documents',
            component: AdditionalDocumentsComponent,
            canActivate: [AuthGuard, CompletedGuard]
          }
        ]
      }
    ]
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MissionRoutingModule {
}
