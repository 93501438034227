import {PurchaseOrderStatusEnum} from "../../enums/Purchase-order.status.enum";

export class PurchaseOrderAdvancedSearch {
  purchaseOrderNumber: string;
  buyerName: string;
  requestNumber: string;
  status: PurchaseOrderStatusEnum;

  constructor(object?: any) {
    if (object) {
      this.purchaseOrderNumber = object.purchaseOrderNumber;
      this.buyerName = object.buyerName;
      this.requestNumber = object.requestNumber;
      this.status = object.status;
    }
  }
}
