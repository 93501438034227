import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {Subscription} from 'rxjs';
import {AlertService} from '../core/services/alert.service';
import {AuthenticationService} from "../core/services/authentication.service";
import {TranslatorService} from "../core/services/translator.service";
import {AlertEnum} from "../shared/enums/Alert.enum";
import {TranslateService} from "@ngx-translate/core";


@Component({
  selector: 'app-account-validation',
  templateUrl: './account-validation.component.html',
  styleUrls: ['./account-validation.component.scss']
})
export class AccountValidationComponent implements OnInit, OnDestroy {

  accountValidationForm: FormGroup;
  passwordValid: boolean;
  // languages
  languages: string[];
  selectedLanguage: string;
  isSuccess: boolean;
  isError: boolean;
  headerMsg: string;
  bodyMsg: string;
  endMsg: string;

  private token: string;
  private subscriptions: Subscription[] = [];


  constructor(private authService: AuthenticationService,
              private activatedRoute: ActivatedRoute,
              private translatorService: TranslatorService,
              private translateService: TranslateService,
              private router: Router,
              private alertService: AlertService,
              private fb: FormBuilder,
              private logger: NGXLogger) {

    this.isSuccess = false;
    this.isError = false;
    this.languages = this.translatorService.languages;
    this.selectedLanguage = this.translatorService.getLanguage();
    this.changeLang(this.selectedLanguage);
  }

  ngOnInit() {
    if (this.authService.isAuthenticated()) {
      this.subscriptions.push(this.translateService.get('Account_validation.error.already_connected').subscribe((error: any) => {
        this.alertService.addAlert(error, AlertEnum.WARNING, true);
        this.router.navigate(['/']).then(() =>
          this.logger.debug("Account validation process: Already connected"));
      }));
    }
    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe(params => {
        this.token = params.token;
        if (!this.token) {
          this.subscriptions.push(this.translateService.get('Account_validation.error.missing_token').subscribe((error: any) => {
            this.alertService.addAlert(error, AlertEnum.WARNING, true);
            this.router.navigate(['/login']).then(() =>
              this.logger.debug('Account validation process: Missing token'));
          }));
        }
      })
    );

    this.accountValidationForm = this.fb.group({
      password: [null, [Validators.required, Validators.minLength(8)]],
      confirmPassword: [null, [Validators.required, Validators.minLength(8)]]
    });
  }

  accountValidationFormSubmit(): void {
    this.subscriptions.push(
      this.authService.validateAccount(this.token, this.accountValidationForm.value.password).subscribe(
        () => {
          this.isSuccess = true;
          this.subscriptions.push(this.translateService.get(['Account_validation.success.account_activated',
            'Account_validation.success.validation_success_message',
            'Account_validation.success.seconds']).subscribe((msg: any) => {
            this.headerMsg = msg['Account_validation.success.account_activated'];
            this.bodyMsg = msg['Account_validation.success.validation_success_message'];
            this.endMsg = msg['Account_validation.success.seconds'];
          }));
        },
        (error: any) => {
          this.logger.error(error.url, '- STATUS :', error.status);
          this.isError = true;
        }
      )
    );
  }

  setPasswordValid($event: boolean): void {
    this.passwordValid = $event;
  }

  changeLang(event: any): void {
    this.translatorService.changeLang(event);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(value => value.unsubscribe());
  }

}
