import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {EmployeeAdvancedSearchModel} from '../../../shared/models/employee/employee-advanced-search.model';
import {
  Authority,
  EmployeeRef,
  EmployeeWithEntityModel
} from '../../../shared/models/employee/employee-post-body.model';
import {ProjectManagerModel} from '../../../shared/models/employee/project-manager.model';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {ListContentModel} from '../../../shared/models/list-content.model';
import {NGXLogger} from 'ngx-logger';
import {commonProperties} from '../../../../assets/environments/environment.common';
import {environment} from '../../../../assets/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class EmployeeService {


  constructor(private httpClient: HttpClient, private authService: AuthenticationService) {}

  mapAdvancedSearchForm(params: any): EmployeeAdvancedSearchModel {
    return new EmployeeAdvancedSearchModel(params);
  }

  getEmployeesByPage(page: number, size: number, sort: string, search: string,
                     advancedSearch: EmployeeAdvancedSearchModel, isAdvancedSearchDisplayed: boolean,
                     managing: boolean): Observable<ListContentModel> {
    let params = new HttpParams();
    if (search) {
      params = params.set('search', search);
    } else if (advancedSearch) {
      if (advancedSearch.firstName) {
        params = params.set('firstName', advancedSearch.firstName);
      }
      if (advancedSearch.lastName) {
        params = params.set('lastName', advancedSearch.lastName);
      }
      if (advancedSearch.email) {
        params = params.set('email', advancedSearch.email);
      }
      if (isAdvancedSearchDisplayed && advancedSearch.disabled) {
        params = params.set('enabled', 'false');
      }
    }
    if (managing) {
      params = params.set('managing', 'true');
    }

    params = params.set('size', size.toString());
    params = params.set('page', page.toString());
    params = params.set('sort', sort);
    return this.httpClient.get<ListContentModel>(environment.api_root + commonProperties.employeesList, {params});
  }

  getSuppliers(search: string) {
    let params = new HttpParams();
    params = params.set('search', search).set('size', '20').set('page', '0').set('authority', 'SUPPLIER');
    return this.httpClient.get<ListContentModel>(environment.api_root + commonProperties.employeesList, {params});
  }

  mapFormValueToResource(value: any): EmployeeWithEntityModel {
    const params: EmployeeWithEntityModel = new EmployeeWithEntityModel();

    params.firstName = value.firstName;
    params.lastName = value.lastName;
    params.email = value.email;
    params.phoneNumber = value.phoneNumber.internationalNumber.replace(/\s/g, '').replace('-', '');
    params.title = value.title;

    params.authorities = [];
    params.authorities.push(new Authority(value.authority.id, value.authority.name, this.authService.decodeToken('tenantId')));

    if (value.authority.name !== 'PROVIDER') {
      delete params.suppliers;
    } else {
      params.providerType = value.providerType;
      if (value.suppliers) {
        value.suppliers.forEach((supplier: any) => params.suppliers.push(this.mapSuppliersToEmployeeRef(supplier)));
      }
    }


    return params;
  }

  mapSuppliersToEmployeeRef(employee: any) {
    const employeeRef: EmployeeRef = new EmployeeRef();
    employeeRef.id = employee.id;
    employeeRef.firstName = employee.firstName;
    employeeRef.lastName = employee.lastName;
    return employeeRef;
  }

  createEmployee(result: EmployeeWithEntityModel) {
    return this.httpClient.post(environment.api_root + commonProperties.employeesList, result);
  }

  getEmployeeById(employeeId: any): Observable<EmployeeWithEntityModel> {
    return this.httpClient.get<EmployeeWithEntityModel>(environment.api_root + commonProperties.employeeById.replace(':employeeId', employeeId));
  }

  getEmployeePictureByEmployeeId(employeeId: string) {
    const url = environment.api_root + commonProperties.employeePictureById.replace(':employeeId', employeeId);
    const headers = new HttpHeaders({
      'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      'Pragma': 'no-cache',
      'Expires': '0'
    });
    const options = {
      headers: headers,
      responseType: 'blob' as 'json'
    };
    return this.httpClient.get(url, options);
  }

  deleteMyPicture() {
    return this.httpClient.delete<void>(environment.api_root + commonProperties.employeePictureById.replace(':employeeId', this.authService.decodeToken('employeeId')));
  }

  getMyPicture() {
    const employeeId = this.authService.getEmployeeId();
    return this.getEmployeePictureByEmployeeId(employeeId);
  }

  uploadMyAvatar(formData: any) {
    return this.httpClient.post(environment.api_root + commonProperties.myPicture.replace(':employeeId', this.authService.decodeToken('employeeId')), formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  updateEmployeeRoleByEmployeeId(employeeId: any, body) {
    return this.httpClient.put(environment.api_root + commonProperties.employeeRoleById.replace(':employeeId', employeeId), body);
  }

  getAllProjectManagers(search: string, page: number, size: number): Observable<ProjectManagerModel[]> {
    let params = new HttpParams();
    params = params.set('search', search).set('page', page.toString()).set('size', size.toString()).set('authority', 'PROJECT_MANAGER');
    return this.httpClient.get<ProjectManagerModel[]>(environment.api_root + commonProperties.projectManagers, {params});
  }

  testUserEmail(userEmail: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('email', userEmail);
    return this.httpClient.get(environment.api_root + commonProperties.userEmailTest, {params});
  }

  uploadEmployeeAvatar(employeeId: string, formData: any) {
    return this.httpClient.post(environment.api_root + commonProperties.employeePictureById.replace(':employeeId', employeeId), formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  getEmployeePictureByCompanyIdAndEmployeeId(employeeId: string) {
    return this.httpClient.get(environment.api_root + commonProperties.employeePictureById.replace(':employeeId', employeeId), {
      responseType: 'blob'
    });
  }

  updateEmployeeByEmployeeId(employeeId: any, body: any) {
    return this.httpClient.patch(environment.api_root + commonProperties.employeeById.replace(':employeeId', employeeId), body);
  }

  getProviderByCompanyId(search: string, page: number): Observable<any> {
    let params = new HttpParams();
    params = params.set('search', search).set('authority', 'PROVIDER');
    params = params.set('page', page.toString()).set('size', '5').set('sort', 'firstName,asc');
    return this.httpClient.get(environment.api_root + commonProperties.employeesList, {params});
  }

  updateSuppliers(employeeId: any, body: { suppliers: any[] }) {
    return this.httpClient.patch(environment.api_root + commonProperties.employeeSuppliersById.replace(':employeeId', employeeId), body);
  }

  getMyProfile() {
    return this.httpClient.get(environment.api_root + commonProperties.myProfile.replace(':employeeId', this.authService.decodeToken('employeeId')));
  }

  updateMyProfile(body: any) {
    return this.httpClient.patch(environment.api_root + commonProperties.updateMyProfile.replace(':employeeId', this.authService.decodeToken('employeeId')), body);
  }
}
