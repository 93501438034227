import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ProviderItemModel} from "../../../shared/models/call-for-tender/provider-item.model";
import {Observable} from "rxjs";
import {ListContentModel} from "../../../shared/models/list-content.model";
import {RoleEnum} from "../../../shared/enums/CallForTenderOffers.enum";
import {commonProperties} from '../../../../assets/environments/environment.common';
import {environment} from '../../../../assets/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  constructor(private httpClient: HttpClient) {
  }

  mapProviderFormValueToResource(formValue): ProviderItemModel {
    const providerMapped = new ProviderItemModel();
    providerMapped.firstName = formValue.pointOfContact.firstName;
    providerMapped.lastName = formValue.pointOfContact.lastName;
    return providerMapped;
  }

  getProviderByPage(search: string, page: number, size: string): Observable<ListContentModel> {
    let params = new HttpParams();
    params = params.set('search', search).set('page', page.toString()).set('size', size).set('authority', RoleEnum.PROVIDER);
    return this.httpClient.get<ListContentModel>(environment.api_root + commonProperties.employeesList, {params});
  }

}
