import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MissionRoutingModule} from './mission-routing.module';
import {SharedModule} from '../../shared/shared.module';
import {MissionListComponent} from './mission-list/mission-list.component';
import {MissionListCardComponent} from './mission-list/mission-list-card/mission-list-card.component';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {MissionViewComponent} from './mission-view/mission-view.component';
import {MissionDetailComponent} from './mission-view/mission-detail/mission-detail.component';
import {MissionHistoryComponent} from './mission-view/mission-history/mission-history.component';
import {AdditionalDocumentsComponent} from './mission-view/additional-documents/additional-documents.component';
import {ProjectViewDetailComponent} from './mission-view/mission-detail/section/project-view-detail/project-view-detail.component';
import {SupplierViewDetailComponent} from './mission-view/mission-detail/section/supplier-view-detail/supplier-view-detail.component';
import {BillingViewDetailComponent} from './mission-view/mission-detail/section/billing-view-detail/billing-view-detail.component';
import {ProviderViewDetailComponent} from './mission-view/mission-detail/section/provider-view-detail/provider-view-detail.component';
import {AvatarModule} from 'ngx-avatars';
import {NgSelectModule} from '@ng-select/ng-select';
import {TranslateModule} from '@ngx-translate/core';
import {TotalBillingsViewDetailComponent} from './mission-view/mission-detail/section/total-billings-view-detail/total-billings-view-detail.component';
import {MatListModule} from '@angular/material/list';
import {AdditionalDocumentsSectionComponent} from './mission-view/additional-documents/additional-documents-section/additional-documents-section.component';
import {MatIconModule} from '@angular/material/icon';
import {ActivitiesViewDetailComponent} from './mission-view/mission-detail/section/activities-view-detail/activities-view-detail.component';


@NgModule({
  declarations: [
    MissionListComponent,
    MissionListCardComponent,
    MissionViewComponent,
    MissionDetailComponent,
    MissionHistoryComponent,
    AdditionalDocumentsComponent,
    ActivitiesViewDetailComponent,
    ProjectViewDetailComponent,
    SupplierViewDetailComponent,
    BillingViewDetailComponent,
    ProviderViewDetailComponent,
    TotalBillingsViewDetailComponent,
    AdditionalDocumentsSectionComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MissionRoutingModule,
    PaginationModule,
    FormsModule,
    BsDatepickerModule,
    ReactiveFormsModule,
    AvatarModule,
    NgSelectModule,
    MatListModule,
    MatIconModule,
    TranslateModule
  ]
})
export class MissionModule {
}
