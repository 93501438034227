<ol class="breadcrumb">
  <li *ngFor="let breadcrumb of breadcrumbs ; let last = last" class="breadcrumb-item">
    <ng-container *ngIf="!last; else lastItem">
      <a *ngIf="breadcrumb.url !== '/'" [routerLink]="[breadcrumb.url]">
        {{'core.components.breadcrumb.' + transformTranslationPath(breadcrumb.label) | translate}}
      </a>
      <a *ngIf="breadcrumb.url === '/'" [routerLink]="[breadcrumb.url]">
        <fa-duotone-icon [icon]="faHouse"></fa-duotone-icon>
      </a>
    </ng-container>
    <ng-template #lastItem>
      <span>{{'core.components.breadcrumb.' + transformTranslationPath(breadcrumb.label) | translate}}</span>
    </ng-template>
  </li>
</ol>
