<div class="animated fadeIn">
  <div class="row">
    <div class="col-md-12">
      <h2 class="underline">{{'Purchase_orders.purchase_order_title'|translate}}</h2>
    </div>
  </div>

  <ng-container *ngIf="!firstCallDone; else firstCallDoneBlock">
    <app-spinner  [backgroundTransparent]="true"></app-spinner>
  </ng-container>

  <ng-template #firstCallDoneBlock>
    <div *ngIf="!isListEmpty; else noPurchaseOrderBlock" @slideInStagger>
      <!-- basic search and advanced search forms -->
      <div class="row slide-in-animation">
        <div class="col"
             [ngClass]="{'col-md-6': !isAdvancedSearchDisplayed, 'col-md-8': isAdvancedSearchDisplayed}">
          <div class="text-right" [ngClass]="{'offset-advanced-search': isAdvancedSearchDisplayed}">
            <button class="advanced-search-button" (click)="toggleAdvancedSearch()">
              <u>{{'Purchase_orders.advanced_search'|translate}}</u>&nbsp;
              <i class="fa ml-1"
                 [ngClass]="{'fa-angle-down': isAdvancedSearchDisplayed, 'fa-angle-left': !isAdvancedSearchDisplayed}"
                 aria-hidden="true"></i>
            </button>
          </div>

          <form [formGroup]="advancedSearchForm" (ngSubmit)="advancedSearchFormSubmit()"
                *ngIf="isAdvancedSearchDisplayed; else basicSearchBlock">
            <div class="row">
              <div class="col form-group">
                <label class="mb-1"
                       for="po-number-input">{{'Purchase_orders.po_number'|translate}}</label>
                <input type="text" class="form-control" id="po-number-input"
                       formControlName="purchaseOrderNumber">
              </div>
              <div class="col form-group">
                <label class="mb-1"
                       for="supplier-name-input">{{'Purchase_orders.buyer_name'|translate}}</label>
                <input type="text" class="form-control" id="supplier-name-input"
                       formControlName="buyerName">
              </div>
              <div class="col form-group">
                <label class="mb-1"
                       for="request-number-input">{{'Purchase_orders.request_number'|translate}}</label>
                <input type="text" class="form-control" id="request-number-input"
                       formControlName="requestNumber">
              </div>
              <div class="col form-group" style="flex-grow: 1.5">
                <label class="mb-1" for="status">{{'Purchase_orders.status'|translate}}</label>
                <ng-select [items]="statusForFilter"
                           [multiple]="true"
                           [closeOnSelect]="false"
                           [hideSelected]="false"
                           [labelForId]="'status'"
                           [searchable]="false"
                           formControlName="status"
                           id="status"
                           class="ng-select-custom">
                  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <input id="item-{{index}}" type="checkbox" [checked]="item$.selected"/>
                    {{'Purchase_orders.common.status.' + item|translate}}
                  </ng-template>
                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngFor="let item of items | slice:0:1">
                      <span class="ng-value-label">{{'Purchase_orders.common.status.'
                      + item|translate}}</span>
                    </div>
                    <div class="ng-value" *ngIf="items.length > 1">
                      <span class="ng-value-label">{{items.length - 1}} more...</span>
                    </div>
                  </ng-template>
                </ng-select>
              </div>
              <div class="col d-flex align-items-end pl-0">
                <button class="btn btn-outline-primary px-5 mb-3" type="submit"
                        [disabled]="advancedSearchForm.pristine">
                  {{'Purchase_orders.search'|translate}}
                </button>
              </div>
            </div>
          </form>
          <ng-template #basicSearchBlock>
            <div class="form-group basic-search-group">
              <input type="text" class="form-control"
                     placeholder="{{'Purchase_orders.search'|translate}}..." id="search"
                     [value]="basicSearch"
                     (input)="triggerBasicSearch($event.target.value)">
            </div>
          </ng-template>
        </div>
      </div>
      <!-- line for page size and message of number of elements of the page -->
      <div class="row justify-content-between align-items-center mb-3 slide-in-animation">
        <div class="col-md-3 form-inline">
          <select name="result" id="select-result" class="custom-select mr-2"
                  [(ngModel)]="size" (change)="changeSize()">
            <option [value]="6">6</option>
            <option [value]="9">9</option>
            <option [value]="12">12</option>
          </select>
          <label for="select-result">&nbsp; {{'global.pages.results_per_page'|translate}}</label>
        </div>
        <div class="col-md-2">
          <span class="float-right">{{'Purchase_orders.showing'|translate}}
            <strong>{{numberOfElements}} {{'Purchase_orders.of'|translate}} {{totalElements}}</strong> {{'Purchase_orders.entries'|translate}}</span>
        </div>
      </div>


      <div *ngIf="listContent.length > 0; else noResultPurchaseOrderBlock" @slideInStagger>

        <div class="row mt-2">
          <div class="col-lg-4 col-md-4 slide-in-animation" *ngFor="let purchaseOrder of listContent">
            <purchase-order-card [purchaseOrder]="purchaseOrder"></purchase-order-card>
          </div>
        </div>

        <!-- pagination -->
        <div class="row slide-in-animation" *ngIf="totalElements > size">
          <div class="col-md-12">
            <pagination class="float-right" [totalItems]="totalElements" [maxSize]="5"
                        [boundaryLinks]="true"
                        [ngModel]="page"
                        (pageChanged)="changePage($event)"
                        [itemsPerPage]="size"
                        previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;"
                        lastText="&raquo;">
            </pagination>
          </div>
        </div>
      </div>

      <!-- No result block-->
      <ng-template #noResultPurchaseOrderBlock>
        <div class="text-center my-3" @slideIn>
          <img src="assets/img/result_empty.svg" alt="No results" style="width: 30rem;">
          <h5 class="font-weight-bold mt-2">{{'Purchase_orders.empty_search'|translate}} </h5>
        </div>
      </ng-template>
    </div>

    <!-- no content -->
    <ng-template #noPurchaseOrderBlock>
      <app-no-content class="contract-list-empty" iconClassName="fa fa-handshake-o"
                      content="{{'Purchase_orders.empty_data'|translate}}"
                      @fadeIn>
      </app-no-content>
    </ng-template>
  </ng-template>

</div>
