import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CountryISO, SearchCountryField} from 'ngx-intl-tel-input-gg';
import {regex} from '../../../shared/regex/form.regex';
import {requiredFileType} from '../../../shared/validators/required-file-type.validator';
import {maximumSizeFile} from '../../../shared/validators/maximum-size-file.validator';
import {query, transition, trigger, useAnimation} from '@angular/animations';
import {
  fadeInAnimation,
  scaleIn50StaggerAnimation,
  scaleInAnimation,
  slideIn50StaggerAnimation
} from '../../../shared/animation/common.animation';
import {ActivatedRoute, Router} from '@angular/router';
import {CallForTenderModel} from '../../../shared/models/call-for-tender/call-for-tender.model';
import {CallForTenderService} from '../services/call-for-tender.service';
import {ToastrService} from 'ngx-toastr';
import {NGXLogger} from 'ngx-logger';
import {BehaviorSubject, forkJoin, Subscription} from 'rxjs';
import {ProviderItemModel} from '../../../shared/models/call-for-tender/provider-item.model';
import {ProviderService} from '../services/provider.service';
import {ModalConfirmComponent} from '../../../shared/components/modal-confirm/modal-confirm.component';
import {BsModalService} from 'ngx-bootstrap/modal';
import {distinctUntilChanged} from 'rxjs/operators';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {ToasterEnum} from '../../../shared/enums/Toaster.enum';
import {ListContentModel} from '../../../shared/models/list-content.model';
import {CallForTenderOffersService} from '../services/call-for-tender-offers.service';
import {ContractModeEnum, RoleEnum} from '../../../shared/enums/CallForTenderOffers.enum';
import {FileUtilsService} from '../../../shared/utils/file-utils.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'call-for-tender-offer-creation',
  templateUrl: './call-for-tender-offer-creation.component.html',
  styleUrls: ['./call-for-tender-offer-creation.component.scss'],
  animations: [
    trigger('slideInStagger', [
      transition(':enter', [
        query('.slide-in-animation', [
          useAnimation(slideIn50StaggerAnimation)
        ], {optional: true})
      ])
    ]),
    trigger('fadeIn', [
      transition(':enter', [
        useAnimation(fadeInAnimation)
      ])
    ]),
    trigger('providerSearchDisplay', [
      transition('* => *', [
        query('.col-md-6:enter', [
          useAnimation(scaleIn50StaggerAnimation)
        ], {optional: true}),
      ]),
    ]),
    trigger('providerSectionDisplay', [
      transition(':enter', [
        useAnimation(scaleInAnimation, {
          params: {from: '0.95'}
        })
      ]),
    ]),
    trigger('fadeIn', [
      transition(':enter', [
        useAnimation(fadeInAnimation)
      ])
    ])
  ]
})
export class CallForTenderOfferCreationComponent implements OnInit, OnDestroy {
  callForTenderOfferCreationForm: FormGroup;
  selectedCallForTender: CallForTenderModel;
  searchCountryField = SearchCountryField;
  preferredCountries: CountryISO[] = [CountryISO.France];
  fileUploaded: boolean;
  userRole: string;
  userName: string;
  nickName: string;
  isInUpdateMode: boolean;
  offerAlreadyCreated: boolean;
  providerSearchMode: boolean = true;
  $providerSearchValue: BehaviorSubject<string> = new BehaviorSubject<string>('');
  createProviderMode: boolean;
  providerSelected: ProviderItemModel;
  providerSearchList: ProviderItemModel[] = [];
  providerSearchTotalResult: number;
  isProviderSelected: boolean;
  source: any;
  page: number;
  private subscriptions: Subscription[] = [];

  constructor(private fb: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private callForTenderService: CallForTenderService,
              private toastrService: ToastrService,
              private logger: NGXLogger,
              private providerService: ProviderService,
              private modalService: BsModalService,
              private translateService: TranslateService,
              private authenticationService: AuthenticationService,
              private callForTenderOffersService: CallForTenderOffersService) {
    this.callForTenderOfferCreationForm = this.fb.group({
        title: [null, [Validators.maxLength(150), Validators.required]],
        comment: [null, [Validators.maxLength(200), Validators.required]],
        dailyRate: [null, [Validators.required, Validators.pattern('^[0-9]*(\\.[0-9]{1,2})?$'), this.dailyRateMinValidator.bind(this)]],
        currency: [null, [Validators.required]],
        attachmentFileName: [null, [requiredFileType('pdf'), Validators.required]],
        attachmentFile: [null, [maximumSizeFile(2, 'mo'), Validators.required]],
        amount: [null, [Validators.required, Validators.pattern('^[0-9]*(\\.[0-9]{1,2})?$'), this.dailyRateMinValidator.bind(this)]],
        providerSelected: [null, [Validators.required]],
        negotiationValue: [{value: null, disabled: true}, [Validators.pattern('^[0-9]{0,6}$')]],
        callForTenderId: [null, [Validators.required]],
        providerId: [null],
        pointOfContact: this.fb.group({
          firstName: [null, [Validators.required, Validators.pattern(regex.firstName), Validators.maxLength(50)]],
          lastName: [null, [Validators.required, Validators.pattern(regex.lastName), Validators.maxLength(50), Validators.minLength(2)]],
          email: [null, [Validators.required, Validators.pattern(regex.email), Validators.maxLength(100)]],
        })
    });
  }

  ngOnInit(): void {
    this.isInUpdateMode = this.router.url.includes('offer-update');
    this.userRole = this.authenticationService.getRole();
    this.subscribeToProviderSearch();
    this.isInUpdateMode ? this.populateUpdateForm() : this.populateCreationForm();
  }

  private dailyRateMinValidator(control: FormControl): { [key: string]: boolean } | null {
    const formValue = control.value;
    if (!this.selectedCallForTender) { return null; }

    const { contractMode, minAmount, minDailyRate, maxAmount, maxDailyRate } = this.selectedCallForTender;
    const min = contractMode === 'FIXED_PRICE' ? minAmount : minDailyRate;
    const max = contractMode === 'FIXED_PRICE' ? maxAmount : maxDailyRate;

    if (!min && formValue < 1) { return { dailyRateMin: true }; }

    if ((min && formValue < min) || formValue > max) {
      return { invalidRange: true };
    }

    return null;
  }

  checkRates() {
    const { minAmount, minDailyRate, maxAmount, maxDailyRate } = this.selectedCallForTender;
    const isRateBetween = (minDailyRate !== undefined) || (minAmount !== undefined);
    const minVal = this.selectedCallForTender.contractMode === 'FIXED_PRICE' ? minAmount : minDailyRate;
    const maxVal = this.selectedCallForTender.contractMode === 'FIXED_PRICE' ? maxAmount : maxDailyRate;
    return this.translateService.instant(
      'Call_for_tender_offer_creation.input_form_control.field_adr_between',
      { min: isRateBetween ? minVal : 1, max: maxVal }
    );
  }

  getDailyRateAmountControlName(): string {
    return this.selectedCallForTender?.contractMode === 'FIXED_PRICE' ? 'amount' : 'dailyRate';
  }

  openSubmitConfirmModal(): void {
    this.isInUpdateMode ? this.updateCallForTenderOffer() : this.createCallForTenderOffer();
  }

  private getCallForTenderById(callForTenderId: string): void {
    this.subscriptions.push(this.callForTenderService.getCallForTenderById(callForTenderId).subscribe(
      (res: CallForTenderModel) => {
        this.selectedCallForTender = res;
        if (!this.isInUpdateMode) {
          this.callForTenderOfferCreationForm.patchValue(res);
          this.callForTenderOfferCreationForm.get('attachmentFile').setValue(null);
          this.callForTenderOfferCreationForm.get('attachmentFileName').setValue(null);
        }

        if (res.contractMode === ContractModeEnum.FIXED_PRICE) {
          this.callForTenderOfferCreationForm.get('dailyRate').disable();
        } else {
          this.callForTenderOfferCreationForm.get('amount').disable();
        }
      }, (error: any) => {
        this.logger.error(error.url, '- STATUS :', error.status);
        this.toastrService.error(this.translateService.instant('global.ts.error'));
      }
    ));
  }

  cancelUploadFile(): void {
    this.fileUploaded = false;
    this.callForTenderOfferCreationForm.get('attachmentFile').enable();
    this.callForTenderOfferCreationForm.get('attachmentFileName').enable();
    this.callForTenderOfferCreationForm.get('attachmentFile').setValue(null);
    this.callForTenderOfferCreationForm.get('attachmentFileName').setValue(null);
  }

  backToOldFile(): void {
    this.callForTenderOfferCreationForm.get('attachmentFile').disable();
    this.callForTenderOfferCreationForm.get('attachmentFileName').disable();
    this.fileUploaded = true;
  }

  searchProvider(value: string): void {
    this.$providerSearchValue.next(value);
  }

  getProviderSearchValue(): string {
    return this.$providerSearchValue.getValue();
  }

  isProviderSearchValueActive(): boolean {
    return this.$providerSearchValue.getValue().length > 0;
  }

  displayCreateProviderCard() {
    this.createProviderMode = true;
    this.providerSearchMode = false;
    this.callForTenderOfferCreationForm.get('pointOfContact').reset();
  }

  selectProvider(provider: ProviderItemModel) {
    this.callForTenderOfferCreationForm.get('pointOfContact').get('firstName').setValue(provider.firstName);
    this.callForTenderOfferCreationForm.get('pointOfContact').get('lastName').setValue(provider.lastName);
    this.callForTenderOfferCreationForm.get('pointOfContact').get('email').setValue(provider.email);
    this.callForTenderOfferCreationForm.get('providerId').setValue(provider.id);
    this.callForTenderOfferCreationForm.get('providerSelected').setValue(true);
    this.providerSelected = provider;
    this.isProviderSelected = true;
    this.createProviderMode = false;
    this.providerSearchMode = false;
  }

  private subscribeToProviderSearch(): void {
    this.$providerSearchValue.pipe(
      distinctUntilChanged()
    ).subscribe(value => {
      if (value) {
        this.page = 1;
        this.getProviders();
      } else {
        this.providerSearchList = [];
      }
    });
  }

  onPageChanged($event): void {
    this.page = $event.page;
    this.getProviders();
  }


  private getProviders(): void {
    const value = this.getProviderSearchValue();
    const page = this.page ? this.page - 1 : 0;
    this.subscriptions.push(this.providerService.getProviderByPage(value.trim(), page, '4').subscribe(
      (res: ListContentModel) => {
        this.providerSearchList = res.content;
        this.providerSearchTotalResult = res.totalElements;
      },
      (error: any) => {
        this.logger.error(error.url, '- STATUS :', error.status);
      }
    ));
  }

  enableProviderSearch(): void {
    this.isProviderSelected = false;
    this.providerSearchMode = true;
    this.createProviderMode = false;
    this.callForTenderOfferCreationForm.get('pointOfContact').reset();
  }

  submitProviderForm() {
    this.providerSelected = this.providerService.mapProviderFormValueToResource(this.callForTenderOfferCreationForm.value);
    this.isProviderSelected = true;
    this.createProviderMode = false;
    this.callForTenderOfferCreationForm.get('providerSelected').setValue(true);
  }

  backToCftList(): void {
    this.subscriptions.push(this.translateService.get('Call_for_tender_offer_creation.cancel_btn_msg').subscribe((data: any) => {
      const initialState = {message: data};
      const modalRef = this.modalService.show(ModalConfirmComponent, {class: 'modal-lg', initialState});
      this.subscriptions.push(modalRef.content.actionConfirmed.subscribe(
        () => {
          this.router.navigate(['/marketplace/calls-for-tender']).then(() =>
            this.logger.debug('Redirection to CFT'));
        }
      ));
    }));
  }

  unselectProvider(): void {
    this.callForTenderOfferCreationForm.get('pointOfContact').get('firstName').setValue(null);
    this.callForTenderOfferCreationForm.get('pointOfContact').get('lastName').setValue(null);
    this.callForTenderOfferCreationForm.get('providerSelected').setValue(null);
    this.isProviderSelected = false;
    this.providerSelected = null;
    this.providerSearchMode = true;
  }

  async getFile($event: File) {
    if ($event) {
      this.fileUploaded = true;
      this.setPreviewFromFile($event);
      const fileBase64 = await FileUtilsService.convertFileToBase64($event);
      this.callForTenderOfferCreationForm.get('attachmentFile').setValue(fileBase64);
      this.callForTenderOfferCreationForm.get('attachmentFileName').setValue($event.name);
    }
  }

  private setPreviewFromFile(file: File) {
    this.source = URL.createObjectURL(new Blob([file], {type: file.type}));
  }


  private populateCreationForm(): void {
    this.isProviderSelected = false;
    const callForTenderId = this.route.snapshot.paramMap.get('callForTenderId');
    this.callForTenderOfferCreationForm.get('callForTenderId').setValue(callForTenderId);
    this.getCallForTenderById(callForTenderId);
    this.initIfFreelancer();
  }


  private populateUpdateForm() {
    const callForTenderOfferId = this.route.snapshot.paramMap.get('offerId');

    const observablesArray = [];
    observablesArray.push(this.callForTenderOffersService.getCallForTenderOffersById(callForTenderOfferId));
    observablesArray.push(this.callForTenderOffersService.getCallForTenderOfferResume(callForTenderOfferId));

    this.subscriptions.push(forkJoin(observablesArray).subscribe(
      (observableResponses: any) => {
        this.callForTenderOfferCreationForm.patchValue(observableResponses[0]);
        this.callForTenderOfferCreationForm.get('pointOfContact').get('firstName').setValue(observableResponses[0].providerFirstName);
        this.callForTenderOfferCreationForm.get('pointOfContact').get('lastName').setValue(observableResponses[0].providerLastName);
        this.callForTenderOfferCreationForm.get('pointOfContact').get('email').setValue(observableResponses[0].providerEmail);
        this.callForTenderOfferCreationForm.get('attachmentFile').disable();
        this.callForTenderOfferCreationForm.get('attachmentFileName').disable();
        this.callForTenderOfferCreationForm.get('callForTenderId').disable();
        this.getCallForTenderById(observableResponses[0].callForTenderId);

        this.providerSelected = new ProviderItemModel();
        this.providerSelected.id = observableResponses[0].providerId;
        this.providerSelected.firstName = observableResponses[0].providerFirstName;
        this.providerSelected.lastName = observableResponses[0].providerLastName;
        this.source = URL.createObjectURL(observableResponses[1]);
        this.fileUploaded = true;
        this.callForTenderOfferCreationForm.get('providerId').setValue(observableResponses[0].providerId);
        this.callForTenderOfferCreationForm.get('providerSelected').setValue(true);
        this.isProviderSelected = true;
        this.createProviderMode = false;
        this.providerSearchMode = false;
        this.initIfFreelancer();

      }, (error: any) => {
        this.logger.error(error.url, '- STATUS :', error.status);
        this.toastrService.error(this.translateService.instant('global.ts.error'));
      }
    ));
  }

  private initIfFreelancer(): void {
    if (this.userRole == RoleEnum.FREELANCER) {
      this.callForTenderOfferCreationForm.get('pointOfContact').disable();
      this.callForTenderOfferCreationForm.get('providerSelected').disable();
      this.providerSearchMode = false;
      this.createProviderMode = false;
      this.isProviderSelected = false;
      this.subscriptions.push(this.authenticationService.getUsername().subscribe(value => {
        if (value) {
          this.userName = value;
          this.nickName = value.charAt(0) + '' + value.substring(value.indexOf(' ') + 1).substring(0, 2);
        }
      }));
    }
  }


  private updateCallForTenderOffer(): void {
    this.subscriptions.push(this.callForTenderOffersService.updateOffer(this.callForTenderOfferCreationForm.value, this.route.snapshot.paramMap.get('offerId')).subscribe(
      () => {
        this.router.navigate(['/marketplace/calls-for-tender-offers']);
        this.toastrService.success('Offer Updated', ToasterEnum.SUCCESS);
      }, (error: any) => {
        this.logger.error(error.url, '- STATUS :', error.status);
        this.toastrService.error(this.translateService.instant('global.ts.error'));
      }
    ));
  }

  private createCallForTenderOffer(): void {
    this.subscriptions.push(this.translateService.get('Call_for_tender_offer_creation.accept_btn_msg').subscribe((data: any) => {
      const initialState = {message: data + this.selectedCallForTender.buyerName};
      const modalRef = this.modalService.show(ModalConfirmComponent, {class: 'modal-lg', initialState});
      this.subscriptions.push(modalRef.content.actionConfirmed.subscribe(
        () => {
          this.subscriptions.push(this.callForTenderOffersService.createCallForTenderOffer(this.callForTenderOfferCreationForm.value).subscribe(
            () => {
              this.router.navigate(['/marketplace/calls-for-tender']).then(() =>
                this.logger.debug('Redirection to CFT'));
              this.toastrService.success(this.translateService.instant('Call_for_tender_offer_creation.success_message'));
            }, (error: any) => {
              if (error.error.message == 'Freelancer cannot create more than one offer for one call for tender') {
                this.offerAlreadyCreated = true;
                this.callForTenderOfferCreationForm.disable();
              } else {
                this.logger.error(error.url, '- STATUS :', error.status);
                this.toastrService.error(this.translateService.instant('global.ts.error'));
              }
            }
          ));
        }
      ));
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(value => value.unsubscribe());
  }

}
