<div class="box box-shadow-hover">
  <div class="mx-4 py-4" @slideIn>
    <div class="d-flex">
      <div class="missions-header-border mr-2"></div>
      <div class="missions-header">
        {{'Mission_view.validated_activities.title'|translate}}
      </div>
    </div>

    <div class="mt-3">
      <div class="activities-info-box">
        <div class="activities-info-box-content">
          <div class="activities-info-box-descriptor">
            {{ (billings.contractMode === 'BILL_ONLY' || billings.contractMode === 'FIXED_PRICE') ? ("Mission_view.validated_activities.amount" | translate) : ("Mission_view.validated_activities.quantity" | translate) }} :
          </div>
          <div class="activities-info-box-descriptor">
            {{ billings.amountUsed | default: "0" }}
            {{ (billings.contractMode === 'BILL_ONLY' || billings.contractMode === 'FIXED_PRICE') ? (billings.currency | currencyFormat: "symbol") : (billings.quantityByDate ? ("global.common.days" | translate) : ("global.common.hours" | translate)) }}
          </div>
        </div>

        <hr/>
        <div class="activities-info-box-content">
          <div class="activities-info-box-descriptor">
            {{ "Mission_view.validated_activities.fee" | translate }} :
          </div>
          <div class="activities-info-box-descriptor">
            {{ billings.feesUsed | default: "0" }}
            {{ billings.currency | currencyFormat: "symbol" }}
          </div>
        </div>

        <hr/>
        <div class="activities-info-box-content">
          <div class="activities-info-box-descriptor">
            {{ "Mission_view.validated_activities.onCall" | translate }} :
          </div>
          <div class="activities-info-box-descriptor">
            {{ billings.onCallUsed | default: "0" }}
            {{ billings.currency | currencyFormat: "symbol" }}
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
