import {Component, OnDestroy} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {UserCredentialsModel} from '../shared/models/authentication/user-credentials.model';
import {AuthenticationService} from '../core/services/authentication.service';
import {AlertService} from '../core/services/alert.service';
import {TranslatorService} from '../core/services/translator.service';
import {LoginEnum} from '../shared/enums/Login.enum';
import {AlertEnum} from '../shared/enums/Alert.enum';
import {TranslateService} from '@ngx-translate/core';

import {faUser} from '@fortawesome/free-solid-svg-icons';
import {faLock} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnDestroy {
  protected readonly faUser = faUser;
  protected readonly faLock = faLock;
  protected isModified: boolean = false;
  constructor(private _fb: FormBuilder,
              private authService: AuthenticationService,
              private alertService: AlertService,
              private translatorService: TranslatorService,
              private translateService: TranslateService,
              private router: Router,
              private logger: NGXLogger
  ) {
    this.loginForm = this._fb.group({
      email: null,
      password: null
    });
    this.loginForm.valueChanges.subscribe(value => {
      this.isModified = true;
    });
    this.languages = this.translatorService.languages;
    this.selectedLanguage = this.translatorService.getLanguage();
    this.changeLang(this.selectedLanguage);
  }

  loginForm: FormGroup;
  isLoggingIn = false;

  // languages
  languages: string[];
  selectedLanguage: string;

  private subscriptions: Subscription[] = [];

  submitLogin(): void {
    this.alertService.clear();
    this.isLoggingIn = true;
    this.isModified = false;
    const userCredentials = new UserCredentialsModel();
    userCredentials.email = this.loginForm.value.email;
    userCredentials.password = this.loginForm.value.password;
    // this.logger.debug('your credentials:', userCredentials);
    this.subscriptions.push(this.authService.login(userCredentials).subscribe(
      (res: any) => {
        localStorage.removeItem(TranslatorService.LANGUAGE);
        this.authService.storeToken(res);
        this.authService.setUsername(this.authService.decodeToken(AuthenticationService.NAME_CLAIM));
        this.router.navigate(this.authService.isUserProvider() ? ['activity-reports'] : ['employees']).then(() => this.logger.debug('Redirection to employees'));
        this.translatorService.getLanguage();
        this.isLoggingIn = false;
      },
      (error: any) => {
        this.logger.error(error.url, '- STATUS :', error.status);
        this.subscriptions.push(this.translateService.get(['Login.error.bad_credentials', 'Login.error.account_not_activated', 'Login.error.account_blocked']).subscribe((data: any) => {
          if (error.error.code === LoginEnum.BAD_CREDENTIALS || error.error.code === LoginEnum.ACCOUNT_NOT_FOUND) {
            this.alertService.addAlert(data['Login.error.bad_credentials'], AlertEnum.WARNING);
          }
          if (error.error.code === LoginEnum.ACCOUNT_NOT_ACTIVATED) {
            this.alertService.addAlert(data['Login.error.account_not_activated'], AlertEnum.WARNING);
          }
          if (error.error.code === LoginEnum.ACCOUNT_BLOCKED) {
            this.alertService.addAlert(data['Login.error.account_blocked'], AlertEnum.WARNING);
          }
        }));
        this.isLoggingIn = false;
      })
    );
  }


  changeLang(event: any): void {
    this.translatorService.changeLang(event);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
